import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BiSearch } from 'react-icons/bi'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { MultiSelect } from 'react-multi-select-component'
import Api from '../../../../Api'
import { Modal, Navbar } from '../../../../components'
import InputComponent from '../../../../components/Input/inputComponent'
import languages from '../../../../utils/languages'

const Punishment = () => {
    const [showModalAddPunishment, setShowModalAddPunishment] = useState(false)
    const [showModalEditPunishment, setShowModalEditPunishment] = useState(false)
    const [dataPunishment, setDataPunishment] = useState('')
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const getPunishment = async () => {
        try {
            const response = await Api.GetPunishment(localStorage.getItem('hris-token'))
            setDataPunishment(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const [punishmentID, setPunishmentID] = useState('')
    const [category, setCategory] = useState('Hukuman Disiplin')
    const [name, setName] = useState('')
    const [typeAmount, setTypeAmount] = useState('Persentase')
    const [amount, setAmount] = useState('')
    const [skalaPotongan, setSkalaPotongan] = useState('')
    const [gptp, setGPTP] = useState('')
    const [ttkp, setTTKP] = useState('')
    const [ika, setIKA] = useState('')
    const [tkp, setTKP] = useState('')
    const [ump, setUMP] = useState('')
    const [lembur, setLembur] = useState('')
    const [uml, setUML] = useState('')
    const [keterangan, setKeterangan] = useState('')
    const [assignTo, setAssignTo] = useState('')

    const [selectedAssignTo, setSelectedAssignTo] = useState([])
    const [selected, setSelected] = useState([])
    const [jobGrade, setJobGrade] = useState([])
    const [jobLevel, setJobLevel] = useState([])
    const [jobPosition, setJobPosition] = useState([])

    const getSelectJobGrade = async () => {
        try {
            const res = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.job_grade
            }))

            setJobGrade(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobLevel = async () => {
        try {
            const res = await Api.GetJobLevelDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.level_name
            }))

            setJobLevel(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobPosition = async () => {
        try {
            const res = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.position_name
            }))

            setJobPosition(options)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const data = []
        e && e.map(val => (
            data.push(val.value)
        ))
        setSelected(e)
        setSelectedAssignTo(data)
    }

    const resetForm = () => {
        setCategory("")
        setName("")
        setTypeAmount("")
        setAmount("")
        setSkalaPotongan("")
        setGPTP("")
        setTTKP("")
        setIKA("")
        setTKP("")
        setUMP("")
        setLembur("")
        setUML("")
        setKeterangan("")
        setAssignTo("")
    }
    const PostPunishment = async () => {
        var dataAssigTo = {}
        if (assignTo === "ALL") {
            dataAssigTo.ALL = true
        } else if (assignTo === "job_grade") {
            dataAssigTo.job_grade = selectedAssignTo
        } else if (assignTo === "job_position") {
            dataAssigTo.job_position = selectedAssignTo
        } else if (assignTo === "job_level") {
            dataAssigTo.job_level = selectedAssignTo
        }
        const data = {
            category: category,
            name: name,
            type_amount: typeAmount,
            amount: amount,
            skala_potongan: skalaPotongan,
            gaji_pokok_tenaga_pendukung: gptp,
            tunjangan_transportasi_kepala_perwakilan: ttkp,
            insentif_kerja_asisten: ika,
            tunjangan_kinerja_pns: tkp,
            uang_makan_pns: ump,
            lembur: lembur,
            uang_makan_lembur: uml,
            keterangan: keterangan,
            assign_to: dataAssigTo
        }
        try {
            await Api.CreatePunishment(localStorage.getItem('hris-token'), data)
            setShowModalAddPunishment(!showModalAddPunishment)
            resetForm()
            toast.success('Sukses menambahkan data!!!')
            setRefresh(true)
        } catch (error) {
            toast.error('Terjadi Kesalahan!!!')
        }
    }

    const GetDetailPunishment = async (id) => {
        setShowModalEditPunishment(!showModalEditPunishment)
        setPunishmentID(id)
        try {
            const response = await Api.GetPunishmentById(localStorage.getItem('hris-token'), id)
            setCategory(response?.data?.category?.name)
            setName(response.data.name)
            setTypeAmount(response.data.type_amount)
            setAmount(response.data.amount)
            setSkalaPotongan(response.data.skala_potongan)
            setGPTP(response.data.gaji_pokok_tenaga_pendukung)
            setTTKP(response.tunjangan_transportasi_kepala_perwakilan)
            setIKA(response.data.insentif_kerja_asisten)
            setTKP(response.data.tunjangan_kinerja_pns)
            setUMP(response.data.uang_makan_pns)
            setLembur(response.data.lembur)
            setUML(response.data.uang_makan_lembur)
            setKeterangan(response.data.keterangan)
            // const assign_to = Object.keys(response.data.assign_to);
            setAssignTo(response.data.assign_to)
            // if (assign_to[0] === "ALL") {
            //     setAssignTo(assign_to[0])
            // } else if (assign_to[0] === "job_grade") {
            //     setAssignTo(assign_to[0])
            //     // console.log(response.data.assign_to.job_grade)
            //     // const options = response.data.assign_to.job_grade && response.data.assign_to.job_grade.map(val => ({
            //     //     "value": val.id,
            //     //     "label": val.name
            //     // }))
            //     // setSelected(options)
            // } else if (assign_to[0] === "job_position") {
            //     setAssignTo(assign_to[0])
            //     // console.log(response.data.assign_to.job_position)
            //     // const options = response.data.assign_to.job_position && response.data.assign_to.job_position.map(val => ({
            //     //     "value": val.id,
            //     //     "label": val.name
            //     // }))
            //     // setSelected(options)
            // } else if (assign_to[0] === "job_level") {
            //     setAssignTo(assign_to[0])
            //     // console.log(response.data.assign_to.job_level)
            //     // const options = response.data.assign_to.job_level && response.data.assign_to.job_level.map(val => ({
            //     //     "value": val.id,
            //     //     "label": val.name
            //     // }))
            //     // setSelected(options)
            // }
        } catch (error) {
            toast.error('Terjadi Kesalahan!!!')
        }
    }

    const UpdatePunishment = async () => {
        // var dataAssigTo = {}
        // if (assignTo === "ALL") {
        //     dataAssigTo.ALL = true
        // } else if (assignTo === "job_grade") {
        //     dataAssigTo.job_grade = selectedAssignTo
        // } else if (assignTo === "job_position") {
        //     dataAssigTo.job_position = selectedAssignTo
        // } else if (assignTo === "job_level") {
        //     dataAssigTo.job_level = selectedAssignTo
        // }
        const data = {
            // category: category,
            // name: name,
            // type_amount: typeAmount,
            amount: amount,
            // skala_potongan: skalaPotongan,
            // gaji_pokok_tenaga_pendukung: gptp,
            // tunjangan_transportasi_kepala_perwakilan: ttkp,
            // insentif_kerja_asisten: ika,
            // tunjangan_kinerja_pns: tkp,
            // uang_makan_pns: ump,
            // lembur: lembur,
            // uang_makan_lembur: uml,
            keterangan: keterangan,
            // assign_to: dataAssigTo
        }
        try {
            await Api.UpdatePunishment(localStorage.getItem('hris-token'), data, punishmentID)
            setShowModalEditPunishment(!showModalEditPunishment)
            resetForm()
            toast.success('Sukses merubah data!!!')
            setRefresh(true)
        } catch (error) {
            toast.error('Terjadi Kesalahan!!!')
        }
    }

    const handleCollapse = (id) => {
        if (id) {
            setCurrent(id)
            setActive(!Active)
        }
    }


    useEffect(() => {
        getSelectJobGrade()
        getSelectJobLevel()
        getSelectJobPosition()
        getPunishment()
        // GetJobCategory()
        setRefresh(false)
    }, [refresh])


    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModalAddPunishment}
                title={'Tambah Hukuman'}
                buttonClose={() => { setShowModalAddPunishment(!showModalAddPunishment); resetForm() }}
                width={'750px'}
                content={
                    <div className='space-y-6'>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Kategori Hukuman<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <select onChange={(e) => setCategory(e.target.value)} value={category} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                {/* <option disabled selected value={''}>Pilih tipe pemasukan yang akan dipotong</option>
                            <option value={'Perhitungan hari kerja per bulan'}>Perhitungan hari kerja per bulan</option>
                            <option value={'Perhingan jam kerja selama 1 hari'}>Perhingan jam kerja selama 1 hari</option>
                            <option value={'Absen manual'}>Absen manual</option>
                            <option value={'Cuti'}>Cuti</option>
                            <option value={'Izin'}>Izin</option>
                            <option value={'Perjalanan dinas'}>Perjalanan dinas</option>
                            <option value={'Status Pegawai'}>Status Pegawai</option>
                            <option value={'Laporan Kinerja'}>Laporan Kinerja</option> */}
                                <option selected value={'Hukuman Disiplin'}>Hukuman Disiplin</option>
                                {/* <option value={'Tugas Belajar'}>Tugas Belajar</option> */}
                            </select>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Nama Hukuman<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input type={'text'} onChange={(e) => setName(e.target.value)} value={name} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Input nama hukuman..' />
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Besar potongan perhari<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='flex items-center'>
                                <input type={'number'} onChange={(e) => setAmount(e.target.value)} value={amount} className='bg-white border-t border-l border-b rounded-l-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] h-[40px] w-full outline-none' placeholder='0 [ Nominal; Persentase]' />
                                <select onChange={(e) => setTypeAmount(e.target.value)} value={typeAmount} className='bg-[#F1F1F1] border-t border-r border-b rounded-r-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] h-[40px] w-full outline-none'>
                                    <option>Persentase</option>
                                    <option>Nominal</option>
                                </select>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Skala Potongan<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <select onChange={(e) => setSkalaPotongan(e.target.value)} value={skalaPotongan} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                <option disabled selected value={''}>Pilih tipe pemasukan yang akan dipotong</option>
                                <option value={'Harian'}>Harian</option>
                                <option value={'Mingguan'}>Mingguan</option>
                                <option value={'1 Bulan'}>1 Bulan</option>
                                <option value={'2 Bulan'}>2 Bulan</option>
                                <option value={'3 Bulan'}>3 Bulan</option>
                                <option value={'4 Bulan'}>4 Bulan</option>
                                <option value={'5 Bulan'}>5 Bulan</option>
                                <option value={'6 Bulan'}>6 Bulan</option>
                                <option value={'7 Bulan'}>7 Bulan</option>
                                <option value={'8 Bulan'}>8 Bulan</option>
                                <option value={'9 Bulan'}>9 Bulan</option>
                                <option value={'10 Bulan'}>10 Bulan</option>
                                <option value={'11 Bulan'}>11 Bulan</option>
                                <option value={'Tahunan'}>Tahunan</option>
                            </select>
                        </div>
                        <div className='flex items-center gap-[15px]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Gaji pokok tenaga pendukung<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <select onChange={(e) => setGPTP(e.target.value)} value={gptp} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                    <option disabled selected value={''}>Pilih pembayaran penghasilan</option>
                                    <option value={'Dipotong'}>Dipotong</option>
                                    <option value={'Tidak Dibayarkan'}>Tidak Dibayarkan</option>
                                    <option value={'Dibayarkan'}>Dibayarkan</option>
                                </select>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Tunjangan transportasi kpl perwakilan<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <select onChange={(e) => setTTKP(e.target.value)} value={ttkp} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                    <option disabled selected value={''}>Pilih pembayaran penghasilan</option>
                                    <option value={'Dipotong'}>Dipotong</option>
                                    <option value={'Tidak Dibayarkan'}>Tidak Dibayarkan</option>
                                    <option value={'Dibayarkan'}>Dibayarkan</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex items-center gap-[15px]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Intensif kerja asisten<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <select onChange={(e) => setIKA(e.target.value)} value={ika} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                    <option disabled selected value={''}>Pilih pembayaran penghasilan</option>
                                    <option value={'Dipotong'}>Dipotong</option>
                                    <option value={'Tidak Dibayarkan'}>Tidak Dibayarkan</option>
                                    <option value={'Dibayarkan'}>Dibayarkan</option>
                                </select>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Tunjangan kinerja PNS<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <select onChange={(e) => setTKP(e.target.value)} value={tkp} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                    <option disabled selected value={''}>Pilih pembayaran penghasilan</option>
                                    <option value={'Dipotong'}>Dipotong</option>
                                    <option value={'Tidak Dibayarkan'}>Tidak Dibayarkan</option>
                                    <option value={'Dibayarkan'}>Dibayarkan</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex items-center gap-[15px]'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Uang Makan PNS<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <select onChange={(e) => setUMP(e.target.value)} value={ump} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                    <option disabled selected value={''}>Pilih pembayaran penghasilan</option>
                                    <option value={'Dipotong'}>Dipotong</option>
                                    <option value={'Tidak Dibayarkan'}>Tidak Dibayarkan</option>
                                    <option value={'Dibayarkan'}>Dibayarkan</option>
                                </select>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Lembur<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <select onChange={(e) => setLembur(e.target.value)} value={lembur} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                    <option disabled selected value={''}>Pilih pembayaran penghasilan</option>
                                    <option value={'Dipotong'}>Dipotong</option>
                                    <option value={'Tidak Dibayarkan'}>Tidak Dibayarkan</option>
                                    <option value={'Dibayarkan'}>Dibayarkan</option>
                                </select>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Uang Makan Lembur<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <select onChange={(e) => setUML(e.target.value)} value={uml} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none'>
                                <option disabled selected value={''}>Pilih pembayaran penghasilan</option>
                                <option value={'Dipotong'}>Dipotong</option>
                                <option value={'Tidak Dibayarkan'}>Tidak Dibayarkan</option>
                                <option value={'Dibayarkan'}>Dibayarkan</option>
                            </select>
                        </div>
                        <div className='w-full'>
                            <div className='w-full'>
                                <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>Keterangan</h1>
                                <input type={'text'} onChange={(e) => setKeterangan(e.target.value)} value={keterangan} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Input keterangan....' />
                            </div>
                        </div>

                        <div>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>{languages.language === 'id' ? "Ditujukan Kepada" : "Assign To"}<span className='text-red-primer'>*</span></h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "ALL"} id="allIncome" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="allIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                </div>
                                {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="searchIncome" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="searchIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_grade"} id="jobGradeIncome" name="radio-option" value='job_grade' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobGradeIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Kelas Jabatan" : "Based on Job Grade"}</label>
                                </div>
                                {
                                    assignTo === "job_grade" ?
                                        <MultiSelect
                                            options={jobGrade}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_level"} id="jobLevelIncome" name="radio-option" value='job_level' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobLevelIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jenis Jabatan" : "Based on Job Level"}</label>
                                </div>
                                {
                                    assignTo === "job_level" ?
                                        <MultiSelect
                                            options={jobLevel}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_position"} id="jobPositionIncome" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobPositionIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                </div>
                                {
                                    assignTo === "job_position" ?
                                        <MultiSelect
                                            options={jobPosition}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                            </div>
                        </div>

                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => { setShowModalAddPunishment(!showModalAddPunishment); resetForm() }} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                            <button onClick={PostPunishment} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEditPunishment}
                title={'Edit Hukuman'}
                buttonClose={() => { setShowModalEditPunishment(!showModalEditPunishment); resetForm() }}
                width={'750px'}
                content={
                    <div className='space-y-6'>
                        <div className='w-full'>
                            <InputComponent
                                disabled={true}
                                label={"Kategori Hukuman"}
                                value={category}
                                required={true}
                                type={"text"}
                            />
                        </div>
                        <div className='w-full'>
                            <InputComponent
                                disabled={true}
                                label={"Nama Hukuman"}
                                value={name}
                                required={true}
                                type={"text"}
                            />
                        </div>
                        <div className='w-full grid grid-cols-2 items-end gap-[15px]'>
                            <InputComponent
                                disabled={false}
                                label={"Besar Potongan Perhari"}
                                value={amount}
                                onChange={(e) => setAmount(e)}
                                required={true}
                                type={"number"}
                            />
                            <InputComponent
                                disabled={true}
                                label={"Jenis Potongan"}
                                value={typeAmount}
                                required={true}
                                type={"text"}
                            />
                        </div>
                        <div className='w-full'>
                            <InputComponent
                                disabled={true}
                                label={"Skala Potongan"}
                                value={skalaPotongan}
                                required={true}
                                type={"text"}
                            />
                        </div>
                        <div className='flex items-center gap-[15px]'>
                            <div className='w-full'>
                                <InputComponent
                                    disabled={true}
                                    label={"Gaji Pokok Tenaga Pendukung"}
                                    value={gptp ?? "-"}
                                    required={true}
                                    type={"text"}
                                />
                            </div>
                            <div className='w-full'>
                                <InputComponent
                                    disabled={true}
                                    label={"Tunjangan Transportasi Kepala Perwakilan"}
                                    value={ttkp ?? "-"}
                                    required={true}
                                    type={"text"}
                                />
                            </div>
                        </div>
                        <div className='flex items-center gap-[15px]'>
                            <div className='w-full'>
                                <InputComponent
                                    disabled={true}
                                    label={"Intensif Kerja Asisten"}
                                    value={ika ?? "-"}
                                    required={true}
                                    type={"text"}
                                />
                            </div>
                            <div className='w-full'>
                                <InputComponent
                                    disabled={true}
                                    label={"Tunjangan Kinerja PNS"}
                                    value={tkp ?? "-"}
                                    required={true}
                                    type={"text"}
                                />
                            </div>
                        </div>
                        <div className='flex items-center gap-[15px]'>
                            <div className='w-full'>
                                <InputComponent
                                    disabled={true}
                                    label={"Uang makan PNS"}
                                    value={name}
                                    required={true}
                                    type={"text"}
                                />
                            </div>
                            <div className='w-full'>
                                <InputComponent
                                    disabled={true}
                                    label={"Lembur"}
                                    value={lembur ?? "-"}
                                    required={true}
                                    type={"text"}
                                />
                            </div>
                        </div>
                        <div className='w-full'>
                            <InputComponent
                                disabled={true}
                                label={"Uang Makan Lembur"}
                                value={uml ?? "-"}
                                required={true}
                                type={"text"}
                            />
                        </div>
                        <div className='w-full'>
                            <InputComponent
                                disabled={false}
                                label={"Keterangan"}
                                value={keterangan}
                                onChange={(e) => setKeterangan(e)}
                                required={true}
                                type={"text"}
                            />
                        </div>
                        <div>
                            <InputComponent
                                disabled={true}
                                label={"Ditujukan Kepada"}
                                value={assignTo.job_category?.name}
                                required={true}
                                type={"text"}
                            />
                        </div>

                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => { setShowModalEditPunishment(!showModalEditPunishment); resetForm() }} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                            <button onClick={UpdatePunishment} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Simpan</button>
                        </div>
                    </div>
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Hukuman'} LinkBack={'/dashboard'} />
                <h1 className="text-zinc-800 text-xl font-semibold">Pengaturan Hukuman </h1>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    {/* <div className='bg-white border shadow-sm rounded-[12px]'> */}
                    <div className='flex lg:flex-row items-center justify-between  '>
                        <div className='w-full lg:w-auto mb-[20px]'>
                            <h1 className='text-dark-3 text-[20px] font-[600]'>Daftar Hukuman</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[400]'>Daftar hukuman yang berlaku di Perusahaan</h1>
                        </div>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                                <BiSearch className='absolute left-[12px] text-lg' />
                                <input type="text" placeholder='Cari...' className='outline-none text-[10px] w-full' />
                            </div>
                            {/* <button onClick={() => setShowModalAddPunishment(!showModalAddPunishment)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 text-sm font-[500]'>Tambah Hukuman</h1>
                            </button> */}
                        </div>
                    </div>
                    <div className='mt-[44px] overflow-auto'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[400px] max-w-[400px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Kategori Hukuman</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[350px] max-w-[350px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Nama Hukuman</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Besar Potongan</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Skala Potongan</h1>
                                    <MdImportExport className='text-dark-5 text-xs' />
                                </div>
                                <div className='flex items-center justify-center gap-[15px] w-full'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Aksi</h1>
                                </div>
                            </div>
                            {Object.values(dataPunishment).map((data, index) => {
                                return (
                                    <div key={index}>
                                        <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[400px] max-w-[400px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data?.category?.name}</h1>
                                            </div>
                                            <div className='min-w-[350px] max-w-[350px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data?.name}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data?.type_amount === 'Persentase' ? `${data?.amount}%` : `Rp. ${data?.amount}`}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data?.skala_potongan}</h1>
                                            </div>
                                            <div className='flex items-center justify-center w-full gap-[12px]'>
                                                <button onClick={() => GetDetailPunishment(data?.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                {/* <button onClick={() => setShowModalDelete(!showModalDelete)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]' />
                                                </button>
                                                <ModalDelete
                                                    activeModal={showModalDelete}
                                                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                                                    submitButton={() => DeletePunishment(data?.id)}
                                                /> */}
                                                <button onClick={() => handleCollapse(data?.id)} className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                    {data?.id === Current && !Active ?
                                                        <MdKeyboardArrowUp className='text-[#003049]' />
                                                        :
                                                        <MdKeyboardArrowDown className='text-[#003049]' />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${data.id === Current && !Active ? 'h-52 border' : 'h-0 border-0'}`}>
                                            <div className='grid grid-cols-12 gap-5 px-4 py-4'>
                                                <h1 className='text-dark-5 text-xs font-[500] col-span-6 underline underline-offset-3'>Detail Pembayaran Penghasilan</h1>
                                            </div>
                                            <div className='grid grid-cols-12 gap-5'>
                                                <div className='grid grid-cols-12 gap-5 px-4 py-4 col-span-4'>
                                                    <div className='flex flex-col gap-3 col-span-6'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Gaji pokok tenaga pendukung</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Tunjangan transportasi kpl perwakilan</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Intensif kerja asisten</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Tunjangan kinerja PNS</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-1'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-5'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{!data.gaji_pokok_tenaga_pendukung ? '-' : data.gaji_pokok_tenaga_pendukung}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{!data.tunjangan_transportasi_kepala_perwakilan ? '-' : data.tunjangan_transportasi_kepala_perwakilan}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{!data.insentif_kerja_asisten ? '-' : data.insentif_kerja_asisten}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{!data.tunjangan_kinerja_pns ? '-' : data.tunjangan_kinerja_pns}</h1>
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-12 gap-5 px-4 py-4 col-span-4'>
                                                    <div className='flex flex-col gap-3 col-span-6'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Uang Makan PNS</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Lembur</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Uang Makan PNS</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-1'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-5'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{!data.uang_makan_pns ? '-' : data.uang_makan_pns}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{!data.lembur ? '-' : data.lembur}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{!data.uang_makan_pns ? '-' : data.uang_makan_pns}</h1>
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-12 gap-5 px-4 py-4 col-span-4'>
                                                    <div className='flex flex-col gap-3 col-span-3'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Ditujukan Pada</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Keterangan</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-1'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-8'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>
                                                            {
                                                                "ALL" in data.assign_to ?
                                                                    "Semua Pegawai"
                                                                    : "job_grade" in data.assign_to ?
                                                                        (`${data.assign_to.job_grade?.length} Job Grade`)
                                                                        : "job_position" in data.assign_to ?
                                                                            (`${data.assign_to.job_position?.length} Job Position`)
                                                                            : "job_level" in data.assign_to ?
                                                                                (`${data.assign_to.job_level?.length} Job Level`)
                                                                                : "job_category" in data.assign_to ?
                                                                                    (`${data.assign_to.job_category?.name}`)
                                                                                    : null
                                                            }
                                                        </h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{!data.keterangan ? '-' : data.keterangan}</h1>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </table>
                    </div>

                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default Punishment