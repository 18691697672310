import React, { useState } from 'react'
import { Navbar } from '../../../../../components'
import { BiSearch } from 'react-icons/bi'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

const DetailPayrollHistory = () => {
    // openIndex
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)
    const handleCollapse = (id) => {
            if(id){
                setCurrent(id)
                setActive(!Active)
            }
    }
    const id = 1
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
        <div className='flex px-[30px] py-[35px] gap-[30px]'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Detail Payroll History'} LinkBack={'/payroll/payroll-history'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='flex justify-between items-center'>
                        <div>
                            <h1 className='text-[20px] font-semibold text-[#272B30]'>Employee Payroll History</h1>
                            <h2 className='text-[12px] text-dark-5'>List of detail payroll history</h2>
                        </div>
                        <div>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input placeholder='Search...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center justify-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Employee Name </h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Payment Date</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Total Pay</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center  gap-[15px] w-full'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Download </h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Action </h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                </div>
                                
                                    <div className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate text-center'>Jeremy Natanael</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate text-center'>9 September 2002</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate text-center'>Rp 180.000.000</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <div className='rounded-[9px] flex flex-col items-center justify-center'>
                                                <button className='flex text-base-black items-center bg-[#CEDFEA] rounded-[9px] px-[10px] py-[6px] gap-[4px]'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <path d="M4.5 15C4.0875 15 3.7345 14.8533 3.441 14.5597C3.147 14.2657 3 13.9125 3 13.5V11.25H4.5V13.5H13.5V11.25H15V13.5C15 13.9125 14.8533 14.2657 14.5597 14.5597C14.2657 14.8533 13.9125 15 13.5 15H4.5ZM9 12L5.25 8.25L6.3 7.1625L8.25 9.1125V3H9.75V9.1125L11.7 7.1625L12.75 8.25L9 12Z" fill="#113B61"/>
                                                    </svg>
                                                    <h1 className='text-[8px] font-medium'>Download Payslip</h1>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={ () => handleCollapse(id) } className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                { id === Current && !Active ?
                                                    <MdKeyboardArrowUp className='text-[#003049]'/>
                                                    :
                                                    <MdKeyboardArrowDown className='text-[#003049]'/>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${ id === Current && !Active  ? 'h-fit border' : 'h-0 border-0' }`}>
                                        <div className='py-[28px] px-[26px]'>
                                            <div className='flex gap-[72px] items-start justify-around'>
                                                <div>
                                                    <h1 className="text-zinc-700 text-sm font-semibold mb-2">Detail Income</h1>
                                                    <div className='grid grid-cols-10 text-grey-thick text-[10px] font-medium gap-y-2'>
                                                        <div className='col-span-7'>Tunjangan Kinerja</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                        <div className='col-span-7'>Tunjangan Tetap</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                        <div className='col-span-7'>Tunjangan Telekomunikasi</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h1 className="text-zinc-700 text-sm font-semibold mb-2">Detail Deduction</h1>
                                                    <div className='grid grid-cols-10 text-grey-thick text-[10px] font-medium gap-y-2'>
                                                        <div className='col-span-7'>Absence</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                        <div className='col-span-7'>Potongan lain-lain</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                        <div className='col-span-7'>Tunjangan Telekomunikasi</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h1 className="text-zinc-700 text-sm font-semibold mb-2">Detail Benefit</h1>
                                                    <div className='grid grid-cols-10 text-grey-thick text-[10px] font-medium gap-y-2 mb-5'>
                                                        <div className='col-span-7'>Tunjangan Kinerja</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                        <div className='col-span-7'>Tunjangan Tetap</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                        <div className='col-span-7'>Tunjangan Telekomunikasi</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                    </div>
                                                    <div className='grid grid-cols-10 text-cyan-900 text-sm font-bold'>
                                                        <div className='col-span-7'>Total Pay</div>
                                                        <div className='col-span-3'>: Rp 600.000</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                            </table>
                        </div>
                        {/* <Pagination
                            currentPage={currentPage} 
                            totalPages={totalPages} 
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DetailPayrollHistory