import React from 'react'
import { Logo } from '../../assets'

const Footer = () => {


    return (
        <div className='bg-[#F2F2F3] px-[16px] lg:px-[150px] py-[43px] '>
            <div className="flex flex-col lg:grid grid-cols-12 gap-10">
                <div className="col-span-4 self-center lg:self-start">
                    <a href="/" className="flex items-center">
                        <img src={Logo} alt="humanusia Logo" className='w-56'/>
                    </a>
                </div>
                <div className="col-span-8 flex flex-col gap-4 lg:grid grid-cols-12">
                    <div className='col-span-4'>
                        <h2 className="mb-6 text-base font-semibold text-[#2B2A35] uppercase ">Fitur</h2>
                        <ul>
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">Management Employee</a>
                            </li>
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">Document Management System</a>
                            </li>
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">Time Management </a>
                            </li>
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">Management Payroll</a>
                            </li>
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">Finance</a>
                            </li>
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">Recruitment</a>
                            </li>
                            <li>
                                <a href="" className="text-base text-[#545465] hover:underline">Maintenance</a>
                            </li>
                        </ul>
                    </div>
                    <div className='col-span-4'>
                        <h2 className="mb-6 text-base font-semibold text-[#2B2A35] uppercase ">Insight</h2>
                        <ul className="text-gray-600">
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">HR Artikel</a>
                            </li>
                            <li className="">
                                <a href="" className="text-base text-[#545465] hover:underline">Company News</a>
                            </li>

                        </ul>
                    </div>
                    <div className='col-span-4'>
                        <h2 className="mb-6 text-base font-semibold text-[#2B2A35] uppercase ">Perusahaan</h2>
                        <ul className="text-gray-600">
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">Tentang Humanusia</a>
                            </li>
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">Karir</a>
                            </li>
                            <li className="mb-4">
                                <a href="" className="text-base text-[#545465] hover:underline">Syarat & Ketentuan</a>
                            </li>
                            <li className="mb-4">
                                <a href="/privacy-policy" className="text-base text-[#545465] hover:underline">Privacy Policy</a>
                            </li>
                            <li className="">
                                <a href="" className="text-base text-[#545465] hover:underline">Help </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-[#E2E2E2]" />
            <div className="flex flex-col lg:grid grid-cols-12 gap-10">
                <div className='col-span-3 flex gap-6 items-center'>
                    <a href='/'>
                        <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.2674 2.75C23.4012 3.14375 22.4674 3.4025 21.4999 3.52625C22.4899 2.93 23.2549 1.985 23.6149 0.84875C22.6812 1.41125 21.6462 1.805 20.5549 2.03C19.6662 1.0625 18.4174 0.5 16.9999 0.5C14.3562 0.5 12.1962 2.66 12.1962 5.32625C12.1962 5.70875 12.2412 6.08 12.3199 6.42875C8.31492 6.22625 4.74867 4.3025 2.37492 1.38875C1.95867 2.0975 1.72242 2.93 1.72242 3.8075C1.72242 5.48375 2.56617 6.96875 3.87117 7.8125C3.07242 7.8125 2.32992 7.5875 1.67742 7.25V7.28375C1.67742 9.62375 3.34242 11.5813 5.54742 12.02C4.83949 12.2137 4.09628 12.2407 3.37617 12.0988C3.68173 13.0578 4.28015 13.897 5.08732 14.4983C5.89448 15.0996 6.8698 15.4329 7.87617 15.4513C6.17026 16.8017 4.05567 17.5317 1.87992 17.5212C1.49742 17.5212 1.11492 17.4987 0.732422 17.4538C2.86992 18.8263 5.41242 19.625 8.13492 19.625C16.9999 19.625 21.8712 12.2675 21.8712 5.88875C21.8712 5.675 21.8712 5.4725 21.8599 5.25875C22.8049 4.58375 23.6149 3.72875 24.2674 2.75Z" fill="black" />
                        </svg>
                    </a>
                    <a href='/'>
                        <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.34784 22.1875H7.84784V13.1762H11.9023L12.3478 8.69875H7.84784V6.4375C7.84784 6.13913 7.96637 5.85298 8.17735 5.642C8.38833 5.43103 8.67447 5.3125 8.97284 5.3125H12.3478V0.8125H8.97284C7.481 0.8125 6.05026 1.40513 4.99537 2.46002C3.94048 3.51492 3.34784 4.94566 3.34784 6.4375V8.69875H1.09784L0.652344 13.1762H3.34784V22.1875Z" fill="black" />
                        </svg>
                    </a>
                    <a href='/'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.875 11.5C14.875 12.1675 14.6771 12.82 14.3062 13.375C13.9354 13.9301 13.4083 14.3626 12.7916 14.6181C12.1749 14.8735 11.4963 14.9404 10.8416 14.8102C10.1869 14.6799 9.58552 14.3585 9.11351 13.8865C8.64151 13.4145 8.32007 12.8131 8.18985 12.1584C8.05962 11.5037 8.12646 10.8251 8.38191 10.2084C8.63735 9.59174 9.06993 9.06464 9.62495 8.69379C10.18 8.32294 10.8325 8.125 11.5 8.125C12.3942 8.12778 13.2511 8.48425 13.8834 9.11659C14.5157 9.74892 14.8722 10.6058 14.875 11.5ZM22.0469 6.85938V16.1406C22.0469 17.7071 21.4246 19.2093 20.317 20.317C19.2093 21.4246 17.7071 22.0469 16.1406 22.0469H6.85938C5.29294 22.0469 3.79066 21.4246 2.68303 20.317C1.57539 19.2093 0.953125 17.7071 0.953125 16.1406V6.85938C0.953125 5.29294 1.57539 3.79066 2.68303 2.68303C3.79066 1.57539 5.29294 0.953125 6.85938 0.953125H16.1406C17.7071 0.953125 19.2093 1.57539 20.317 2.68303C21.4246 3.79066 22.0469 5.29294 22.0469 6.85938ZM16.5625 11.5C16.5625 10.4987 16.2656 9.51995 15.7093 8.68743C15.153 7.8549 14.3624 7.20603 13.4373 6.82286C12.5123 6.43969 11.4944 6.33944 10.5124 6.53477C9.53033 6.73011 8.62828 7.21227 7.92027 7.92027C7.21227 8.62828 6.73011 9.53033 6.53477 10.5124C6.33944 11.4944 6.43969 12.5123 6.82286 13.4373C7.20603 14.3624 7.8549 15.153 8.68743 15.7093C9.51995 16.2656 10.4987 16.5625 11.5 16.5625C12.8427 16.5625 14.1303 16.0291 15.0797 15.0797C16.0291 14.1303 16.5625 12.8427 16.5625 11.5ZM18.25 6.01562C18.25 5.76531 18.1758 5.52061 18.0367 5.31248C17.8976 5.10435 17.7 4.94213 17.4687 4.84634C17.2374 4.75055 16.983 4.72548 16.7375 4.77432C16.492 4.82315 16.2664 4.94369 16.0894 5.12069C15.9124 5.29769 15.7919 5.52321 15.7431 5.76871C15.6942 6.01422 15.7193 6.2687 15.8151 6.49996C15.9109 6.73122 16.0731 6.92889 16.2812 7.06795C16.4894 7.20702 16.7341 7.28125 16.9844 7.28125C17.32 7.28125 17.642 7.14791 17.8793 6.91056C18.1167 6.67321 18.25 6.35129 18.25 6.01562Z" fill="black" />
                        </svg>
                    </a>
                    <a href='/'>
                        <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.25 11.875L15.0887 8.5L9.25 5.125V11.875ZM22.255 3.06625C22.4012 3.595 22.5025 4.30375 22.57 5.20375C22.6488 6.10375 22.6825 6.88 22.6825 7.555L22.75 8.5C22.75 10.9637 22.57 12.775 22.255 13.9338C21.9737 14.9462 21.3212 15.5987 20.3088 15.88C19.78 16.0262 18.8125 16.1275 17.3275 16.195C15.865 16.2738 14.5262 16.3075 13.2887 16.3075L11.5 16.375C6.78625 16.375 3.85 16.195 2.69125 15.88C1.67875 15.5987 1.02625 14.9462 0.745 13.9338C0.59875 13.405 0.4975 12.6962 0.43 11.7963C0.35125 10.8963 0.3175 10.12 0.3175 9.445L0.25 8.5C0.25 6.03625 0.43 4.225 0.745 3.06625C1.02625 2.05375 1.67875 1.40125 2.69125 1.12C3.22 0.97375 4.1875 0.8725 5.6725 0.805C7.135 0.72625 8.47375 0.6925 9.71125 0.6925L11.5 0.625C16.2137 0.625 19.15 0.805 20.3088 1.12C21.3212 1.40125 21.9737 2.05375 22.255 3.06625Z" fill="black" />
                        </svg>

                    </a>
                </div>
                <div className='col-span-9 flex flex-col lg:grid grid-cols-12 gap-[28px]'>
                    <h3 className='font-inter col-span-2 text-[#2B2A35] font-medium'>Purwokerto</h3>
                    <h3 className='font-inter col-span-6 text-[#545465] w-full'>Jl Brubahan 1 no.25, Brubahan, Purwanegara, Kec. Purwokerto Utara, Kabupaten Banyumas, Jawa Tengah 53116</h3>
                    <h3 className='font-inter col-span-4 text-[#545465]'>Telp: +62 811-2935-992</h3>
                </div>
            </div>
        </div>
    )
}

export default Footer