import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { TbFileExport } from 'react-icons/tb'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import Api from '../../../../../Api'
import { useLocation } from 'react-router-dom'
import { ModalDelete, Pagination } from '../../../../../components'
import { toast } from 'react-hot-toast'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

const EmergencyContact = ({modalAddContact, modalEditContact, idContact, refreshPage}) => {
    const [dataEmergencyContact, setDataEmergencyContact] = useState('')
    const [refresh, setRefresh] = useState(false)
    const params = useLocation()
    const [showModalDelete, setShowModalDelete] = useState()
    const [idEmergencyContact, setIdEmergencyContact] = useState()
    //id yang dibawa

    const limit = 5
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const getData = async () => {
        try {
            if(!params.state){
                const response = await Api.GetEmergencyContactByLogin(localStorage.getItem('hris-token'), currentPage, limit)
                setDataEmergencyContact(response.data.data)
                setCurrentPage(response.data.page)
                setTotalPages(response.data.totalPages)
                // console.log(response.data, 'byLogin')
            } else {
                const response = await Api.GetEmergencyContactByParam(localStorage.getItem('hris-token'), params.state.employeeId, currentPage, limit)
                setDataEmergencyContact(response.data.data)
                setCurrentPage(response.data.page)
                setTotalPages(response.data.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteEmergencyContact = async () =>{
        try {
            const response = await Api.DeleteEmergencyContact(localStorage.getItem('hris-token'), idEmergencyContact)
            setShowModalDelete(!showModalDelete)
            toast.success('Success Delete')
            setRefresh(true)
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }

    const deleteEmergencyContactModal = (id) =>{
        setShowModalDelete(!showModalDelete)
        setIdEmergencyContact(id)
    }
    // Export Employee
    const exportToExcel = () => {
        // Sample data array
        const data = dataEmergencyContact;

        // Define custom headers for each table
        const Headers = ['Name', 'Telephone', 'Mobile', 'Relationship', 'Address'];

        // Create modified data arrays with custom headers
        const emergencyContact = data.map(({ name, telephone, mobile, relationship, addres }) => ({
            'Name': name, 
            'Telephone': telephone, 
            'Mobile': mobile, 
            'Relationship': relationship,
            'Address' : addres
        }));

        // Create a new worksheet for each table
        const worksheetGrade = XLSX.utils.json_to_sheet(emergencyContact, { header: Headers });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Emergency Contact');
        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, 'EmergencyContact.xlsx');
    };
    
    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh, refreshPage])

  return (
    <div>
        <ModalDelete
            activeModal={showModalDelete}
            buttonClose={() => setShowModalDelete(!showModalDelete)}
            submitButton={deleteEmergencyContact}
        />
        <div className="shadow rounded-xl px-[19px] py-[19px]">
            <h1 className='text-base-black text-[20px] font-medium'>Kontak Darurat</h1>
            <h2 className='text-dark-5 text-[10px] mb-[35px]'>Daftar Kontak Darurat</h2>

            <div className="flex gap-2 mt-[31px] relative mb-[35px]">
                <button onClick={exportToExcel} className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                    <TbFileExport className='text-[#003049]'/>
                    <h1 className='text-[#003049] text-sm font-[500]'>Expor</h1>
                </button>
                <div className="absolute right-0">
                    <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                        <button onClick={ modalAddContact} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                            <AiOutlinePlus  className='text-xl text-white'/>
                            <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <div className='overflow-auto scrollbar-hide'>
                    <table className='w-full space-y-[10px] text-dark-5'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[220px] max-w-[220px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Nama</h1>
                                <MdImportExport className='text-dark-5 text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>No. Handphone</h1>
                                <MdImportExport className='text-dark-5 text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Hubungan</h1>
                                <MdImportExport className='text-dark-5 text-xs'/>
                            </div>
                            
                            <div className='flex items-center justify-center gap-[6px] w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                                <MdImportExport className='text-dark-5 text-xs'/>
                            </div>
                        </div>
                        {Object.values(dataEmergencyContact).map((item, idx) => (
                            <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[220px] max-w-[220px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.name ? item.name : '-'}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.mobile? item.mobile: '-'}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.relationship? item.relationship : '-'}</h1>
                                </div>
                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                    <button onClick={() => deleteEmergencyContactModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <CgTrashEmpty className='text-[#003049]'/>
                                    </button>
                                    <button onClick={() => modalEditContact(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <HiOutlinePencil className='text-[#003049]'/>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage} 
                    totalPages={totalPages} 
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div>
    </div>
  )
}

export default EmergencyContact