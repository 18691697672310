import React from 'react'
import html2pdf from 'html2pdf.js'

const ExportPenilaianSKA = () => {
    const exportToPDF = () => {
        console.log("export")
        const content = document.getElementById('penilaian')
        const options = {
            margin: 10,
            filename: 'SKA.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'pt', format: 'letter', orientation: 'landscape' },
        };

        html2pdf().from(content).set(options).save();
        // const report = new jsPDF('portrait','pt', 'a4')
        // report.html(Template).then(()=>{
        //     report.save('report.pdf')
        // })
    };
    return (
        <>
            <button onClick={() => exportToPDF()}>print</button>
            <div id='penilaian' className='font-export'>
                <p className="m-0 text-center leading-normal text-xs">
                    <strong className="font-sans">
                        PENILAIAN SASARAN KERJA
                    </strong>
                </p>
                <p className="m-0 text-center leading-normal text-xs">
                    <strong className="font-sans">
                        ASISTEN OMBUDSMAN RI
                    </strong>
                </p>
                <div className=' mx-auto mt-5'>
                    <table className='table-fixed border-collapse border border-black w-full'>
                        <thead>
                            <tr className='uppercase bg-[#D9D9D9]'>
                                <th className='border-l border-black px-2 pb-2 text-[10px] w-10'>No</th>
                                <th className='border-l border-black px-1 pb-2 text-[10px] text-start'>III. KEGIATAN TUGAS POKOK JABATAN</th>
                                <th className='border-l border-black px-2 pb-2 text-[10px] w-10'></th>
                                <th colSpan={4} className='border-b border-l border-black px-1 pb-2 text-[10px] text-center'>TARGET</th>
                                <th className='border-l border-black px-2 pb-2 text-[10px] w-10'></th>
                                <th colSpan={4} className='border-b border-l border-black px-1 pb-2 text-[10px] text-center'>TARGET</th>
                                <th className='border-l border-black px-2 pb-2 text-[10px] w-10'></th>
                                <th colSpan={4} className='border-b border-l border-black px-1 pb-2 text-[10px] text-center'>TARGET</th>
                            </tr>
                            <tr className='uppercase bg-[#D9D9D9]'>
                                <th className='border-b border-l border-black px-2 pb-2 text-[10px] w-10'></th>
                                <th className='border-b border-l border-black px-2 pb-2 text-[10px]'></th>
                                <th className='border-b border-l border-black px-2 pb-2 text-[10px]'></th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>KUANTITAS/ OUTPUT </th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>KUALITAS/<br />MUTU</th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>WAKTU</th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>BIAYA</th>
                                <th className='border-b border-l border-black px-2 pb-2 text-[10px]'></th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>KUANTITAS/ OUTPUT </th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>KUALITAS/<br />MUTU</th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>WAKTU</th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>BIAYA</th>
                                <th className='border-b border-l border-black px-2 pb-2 text-[10px]'></th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>KUANTITAS/ OUTPUT </th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>KUALITAS/<br />MUTU</th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>WAKTU</th>
                                <th className='border-b border-l border-black px-1 pb-2 text-[10px] text-start'>BIAYA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='border-b border-black px-2 pb-2 text-[10px]'>1</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>Pelayanan konsultasi non laporan pelapor dan/atau calon pelapor atau masyarakat umum</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'></td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>250 Dokumen</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>100</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>12 bulan</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>-</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'></td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>250 Dokumen</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>100</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>12 bulan</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>-</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'></td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>250 Dokumen</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>100</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>12 bulan</td>
                                <td className='border-b border-l border-black px-2 pb-2 text-[10px]'>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='w-[532.35pt] mx-auto my-10'>
                    <table className='table-fixed w-full'>
                        <tbody>
                            <tr>
                                <td className='text-[10px] text-center'>Pejabat Penilai</td>
                                <td className='text-[10px] text-center'>Asisten Yang Dinilai</td>
                            </tr>
                            <tr className='h-14'>
                                <td className='text-[10px] text-center'></td>
                                <td className='text-[10px] text-center'></td>
                            </tr>
                            <tr>
                                <td className='text-[10px] text-center font-bold'>(Sofyan Ali, SE)</td>
                                <td className='text-[10px] text-center font-bold'>(Muhammad Iradat, ST)</td>
                            </tr>
                            <tr>
                                <td className='text-[10px] text-center'>NIO.198603232015093001</td>
                                <td className='text-[10px] text-center'>NIO.198603232015093001</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ExportPenilaianSKA