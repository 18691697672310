import React, { useEffect, useState } from 'react'
import InputComponent from '../../../../../components/Input/inputComponent'
import { IoMdArrowDropdown } from 'react-icons/io';
import { RiDownload2Fill } from 'react-icons/ri';
import Api from '../../../../../Api';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-hot-toast';

const Payroll = () => {
    const params = useLocation()
    const navigate = useNavigate()
    const employeeID = params.state.employeeId
    const [tabIndex, setTabIndex] = useState('info')
    const [filePath, setFilePath] = useState('')
    const [data, setData] = useState('')
    const [basicSalary, setBasicSalary] = useState('')
    const [income, setIncome] = useState('')
    const [deduction, setDeduction] = useState('')
    const [tglKenaikanGajiBerkala, setTglKenaikanGajiBerkala] = useState('')
    const [fileKenaikanGajiBerkala, setFileKenaikanGajiBerkala] = useState('')
    const [fileKenaikanGajiBerkalaName, setFileKenaikanGajiBerkalaName] = useState('')
    const [bankName, setBankName] = useState('')
    const [accountName, setAccountName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [kppnName, setKppnName] = useState('')
    const [npwp, setNpwp] = useState('')
    const [ptkp, setPtkp] = useState('')
    const [taxConfiguration, setTaxConfiguration] = useState('')
    const [nppBPJS, setNppBPJS] = useState('')
    const [BPJSKesehatan, setBPJSKesehatan] = useState('')
    const [BPJSKetenagakerjaan, setBPJSKetenagakerjaan] = useState('')
    const [nomorTaspen, setNomorTaspen] = useState('')
    const [nomorJasindo, setNomorJasindo] = useState('')
    const [dataJKK, setDataJKK] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [userId, setUserId] = useState('')
    const [tahun, setTahun] = useState(2024)
    const [dataHistory, setDataHistory] = useState([])

    const role = localStorage.getItem('hris-role')
    const dataPayroll = async () => {
        try {
            if (role === "ADMIN") {
                const res = await Api.getMyPayrollByAdmin(localStorage.getItem('hris-token'), employeeID)
                setData(res.data.results.data)
                setBasicSalary(res.data.results.data.basic_salary ?? 0)
                setIncome(res.data.results.data.incomeToPayroll)
                setDeduction(res.data.results.data.deductionToPayroll)
                setTglKenaikanGajiBerkala(res.data.results.data.tanggal_kenaikan_gaji_berkala)
                setFileKenaikanGajiBerkala(res.data.results.data.file_tanggal_kenaikan_gaji_berkala)
                setBankName(res.data.results.data.bank_name)
                setAccountName(res.data.results.data.account_name)
                setAccountNumber(res.data.results.data.account_number)
                setKppnName(res.data.results.data.nama_kppn)
                setNpwp(res.data.results.data.npwp)
                setPtkp(res.data.results.data.ptkp_status)
                setTaxConfiguration(res.data.results.data.tax_configuration)
                setNppBPJS(res.data.results.data.npp_bpjs_ketenagakerjaan)
                setBPJSKesehatan(res.data.results.data.bpjs_kesehatan)
                setBPJSKetenagakerjaan(res.data.results.data.bpjs_ketenagakerjaan)
                setNomorTaspen(res.data.results.data.nomor_taspen)
                setNomorJasindo(res.data.results.data.nomor_jasindo)
            } else {
                const res = await Api.getPayrollByLogin(localStorage.getItem('hris-token'))
                setData(res.data.results.data)
                setBasicSalary(res.data.results.data.basic_salary ?? 0)
                setIncome(res.data.results.data.incomeToPayroll)
                setDeduction(res.data.results.data.deductionToPayroll)
                setTglKenaikanGajiBerkala(res.data.results.data.tanggal_kenaikan_gaji_berkala)
                setFileKenaikanGajiBerkala(res.data.results.data.file_tanggal_kenaikan_gaji_berkala)
                setBankName(res.data.results.data.bank_name)
                setAccountName(res.data.results.data.account_name)
                setAccountNumber(res.data.results.data.account_number)
                setKppnName(res.data.results.data.nama_kppn)
                setNpwp(res.data.results.data.npwp)
                setPtkp(res.data.results.data.ptkp_status)
                setTaxConfiguration(res.data.results.data.tax_configuration)
                setNppBPJS(res.data.results.data.npp_bpjs_ketenagakerjaan)
                setBPJSKesehatan(res.data.results.data.bpjs_kesehatan)
                setBPJSKetenagakerjaan(res.data.results.data.bpjs_ketenagakerjaan)
                setNomorTaspen(res.data.results.data.nomor_taspen)
                setNomorJasindo(res.data.results.data.nomor_jasindo)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserId(response.data.results.user.id)
        } catch (error) {
            console.log(error)
            navigate('/')
        }
    }
    const dataHistoryPayroll = async () => {
        await Api.getPayrollHistory(localStorage.getItem('hris-token'), employeeID, tahun)
            .then((response) => {
                console.log(response.data.results.data)
                setDataHistory(response.data.results.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const getJKK = async () => {
        try {
            const res = await Api.getJKK(localStorage.getItem('hris-token'))
            setDataJKK(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {
        const maxSize = 10 * 1024 * 1024
        const file = e.target.files[0]
        if (file && file.size > maxSize) {
            toast.error('file tidak boleh lebih dari 10 MB')
            setFileKenaikanGajiBerkala('')
        } else {
            setFileKenaikanGajiBerkalaName(e.target.files[0].name)
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                setFileKenaikanGajiBerkala(reader.result)
            };
        }
    };

    const updateMyPayroll = async () => {
        try {
            const formData = new FormData();
            if (!fileKenaikanGajiBerkala) {
                formData.append('basic_salary', basicSalary);
                formData.append('tanggal_kenaikan_gaji_berkala', tglKenaikanGajiBerkala);
                formData.append('bank_name', bankName);
                formData.append('account_name', accountName);
                formData.append('account_number', accountNumber);
                formData.append('nama_kppn', kppnName);
                formData.append('npwp', npwp);
                formData.append('ptkp_status', ptkp);
                formData.append('tax_configuration', taxConfiguration);
                formData.append('npp_bpjs_ketenagakerjaan', nppBPJS);
                formData.append('bpjs_kesehatan', BPJSKesehatan);
                formData.append('bpjs_ketenagakerjaan', BPJSKetenagakerjaan);
                formData.append('nomor_taspen', nomorTaspen);
                formData.append('nomor_jasindo', nomorJasindo);
            } else {
                formData.append('basic_salary', basicSalary);
                formData.append('tanggal_kenaikan_gaji_berkala', tglKenaikanGajiBerkala);
                formData.append('file_tanggal_kenaikan_gaji_berkala', fileKenaikanGajiBerkala);
                formData.append('bank_name', bankName);
                formData.append('account_name', accountName);
                formData.append('account_number', accountNumber);
                formData.append('nama_kppn', kppnName);
                formData.append('npwp', npwp);
                formData.append('ptkp_status', ptkp);
                formData.append('tax_configuration', taxConfiguration);
                formData.append('npp_bpjs_ketenagakerjaan', nppBPJS);
                formData.append('bpjs_kesehatan', BPJSKesehatan);
                formData.append('bpjs_ketenagakerjaan', BPJSKetenagakerjaan);
                formData.append('nomor_taspen', nomorTaspen);
                formData.append('nomor_jasindo', nomorJasindo);
            }
            for (let [key, value] of formData.entries()) {
                console.log(`${key}:`, value);
            }
            const res = await Api.editMyPayroll(localStorage.getItem('hris-token'), formData, employeeID)
            console.log(res)
            setRefresh(true)
            toast.success("Data berhasil dikirim!");
        } catch (error) {
            toast.error("Terdapat kesalahan ketika mengirim data!");
        }
    }

    const formatRupiah = (number) => {
        // Menggunakan regex untuk menambahkan separator titik setiap 3 digit
        return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    useEffect(() => {
        dataPayroll()
        getJKK()
        setRefresh(false)
    }, [refresh])
    useEffect(() => {
        Fetch()
        dataHistoryPayroll()
    }, [tahun])

    return (
        <div>
            <div className='space-y-[20px]'>
                <div className='border-b-2 border-dark-9 pb-[12px]'>
                    <h1 className='text-dark-1 font-semibold text-[20px]'>Penghasilan</h1>
                </div>
                <div className='flex items-center gap-[75px] border-b-2 border-base-outline'>
                    <button className={`${tabIndex === 'info' ? 'text-red-primer text-[16px] font-semibold border-b-2 border-red-primer' : 'text-grey-medium text-[16px]'} pb-[10px]`} onClick={() => setTabIndex('info')}>Info Penghasilan</button>
                    <button className={`${tabIndex === 'riwayat' ? 'text-red-primer text-[16px] font-semibold border-b-2 border-red-primer' : 'text-grey-medium text-[16px]'} pb-[10px]`} onClick={() => setTabIndex('riwayat')}>Riwayat Penghasilan</button>
                </div>
                {tabIndex === 'info' ?
                    <div>
                        <div className='w-full space-y-[14px] py-[10px]'>
                            <div className='space-y-[20px]'>
                                <h1 className='text-grey-thick font-medium text-[16px]'>Informasi Penghasilan</h1>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={localStorage.getItem('hris-role') === 'USER'}
                                            label={"Gaji Pokok"}
                                            value={basicSalary}
                                            onChange={setBasicSalary}
                                            required={true}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={true}
                                            label={"Tipe Pembayaran"}
                                            value={'Bulan'}
                                            onChange={''}
                                            required={false}
                                            type={"text"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='space-y-[8px]'>
                                <h1 className='text-grey-thick font-medium text-[14px]'>Penghasilan</h1>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className="w-full">
                                        {/* <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">Penghasilan <span className="text-red-primer">*</span></h2>
                                        <div className="relative">
                                        <select value={''} onChange={''} disabled={true} className="bg-base-outline border rounded-[6px] text-grey-thick text-[12px] pl-[21.74px] py-[10px] w-full appearance-none">
                                        <option selected disabled value={""}>Nama Income</option>
                                        </select>
                                        <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                                    </div> */}
                                        {Object.values(income).map((data, index) => {
                                            return (
                                                <InputComponent
                                                    disabled={true}
                                                    label={`Penghasilan ${index + 1}`}
                                                    value={data.income.title}
                                                    onChange={''}
                                                    required={false}
                                                    type={"text"}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className='w-full'>
                                        {Object.values(income).map((data, index) => {
                                            return (
                                                <InputComponent
                                                    disabled={true}
                                                    label={`Jumlah ${index + 1}`}
                                                    value={data.income.amount}
                                                    onChange={''}
                                                    required={false}
                                                    type={"text"}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            {/* <div className='space-y-[8px]'>
                                <h1 className='text-grey-thick font-medium text-[14px]'>Potongan</h1>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className="w-full">
                                        {Object.values(deduction).map((data, index) => {
                                            return (
                                                <InputComponent
                                                    disabled={true}
                                                    label={`Potongan ${index + 1}`}
                                                    value={data.deduction.title}
                                                    onChange={''}
                                                    required={false}
                                                    type={"text"}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className='w-full'>
                                        {Object.values(deduction).map((data, index) => {
                                            return (
                                                <InputComponent
                                                    disabled={true}
                                                    label={`Jumlah ${index + 1}`}
                                                    value={data.deduction.amount}
                                                    onChange={''}
                                                    required={false}
                                                    type={"text"}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div> */}

                            <div className='space-y-[8px]'>
                                <div className='flex items-baseline gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={localStorage.getItem('hris-role') === 'USER'}
                                            label={"Tanggal Kenaikan Gaji Berkala Terakhir"}
                                            value={moment(tglKenaikanGajiBerkala).format('YYYY-MM-DD')}
                                            onChange={setTglKenaikanGajiBerkala}
                                            required={false}
                                            type={"date"}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <h1 className="text-grey-thick text-[12px] font-medium mb-[5px]">Unggah SK Kenaikan Gaji Berkala Terakhir <span className="text-red-primer">*</span></h1>
                                        <div className="flex items-center gap-[13px] w-full">
                                            <label htmlFor="upload-sign" className="cursor-pointer w-full">
                                                <div className="bg-base-outline w-full border pr-2 rounded-md py-[5px] pl-[21.74px] text-grey-thick text-[12px] flex items-center gap-[8px]">
                                                    <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                                        <div className="text-center text-sky-950 text-[9px] font-medium">Browse</div>
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id="upload-sign"
                                                        accept=".docx, .doc, .pdf, .xlsx, .ppt"
                                                        onChange={(e) => handleChange(e)}
                                                        className="rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden"
                                                        required
                                                        disabled={localStorage.getItem('hris-role') === 'USER'}
                                                    />
                                                    <h1 className="text-zinc-700 text-xs font-medium truncate">{fileKenaikanGajiBerkalaName ? fileKenaikanGajiBerkalaName : "Tidak ada file yang dipilih"}</h1>
                                                </div>
                                            </label>
                                        </div>
                                        <h1 className=" text-red-600 text-[8px] font-normal">* Maks 500 KB</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='space-y-[8px]'>
                                <div className='flex items-baseline gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={true}
                                            label={"Tanggal Kenaikan Gaji Berkala Berikutnya"}
                                            value={''}
                                            onChange={''}
                                            required={false}
                                            type={"date"}
                                        />
                                    </div>
                                    <div className='w-full'></div>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <div className='w-full space-y-[14px] py-[10px]'>
                            <div className='space-y-[20px]'>
                                <h1 className='text-grey-thick font-medium text-[16px]'>Bank Info</h1>
                            </div>
                            <div className='space-y-[8px]'>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className="w-full">
                                        <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">Nama Bank <span className="text-red-primer">*</span></h2>
                                        <div className="relative">
                                            <select disabled={localStorage.getItem('hris-role') === 'USER'} value={bankName} onChange={(e) => setBankName(e.target.value)} className="border rounded-[6px] text-grey-thick text-[12px] pl-[21.74px] py-[10px] w-full appearance-none">
                                                <option selected disabled value={""}>Pilih Nama Bank</option>
                                                <option value={"BRI"}>BRI</option>
                                                <option value={"BNI"}>BNI</option>
                                                <option value={"BCA"}>BCA</option>
                                                <option value={"MANDIRI"}>MANDIRI</option>
                                            </select>
                                            <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={localStorage.getItem('hris-role') === 'USER'}
                                            label={"Nama Pemilik Rekening"}
                                            value={accountName}
                                            onChange={setAccountName}
                                            required={false}
                                            type={"text"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='space-y-[8px]'>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={localStorage.getItem('hris-role') === 'USER'}
                                            label={"Nomor Rekening"}
                                            value={accountNumber}
                                            onChange={setAccountNumber}
                                            required={false}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className="w-full"></div>
                                </div>
                            </div>

                            <div className='space-y-[20px]'>
                                <h1 className='text-grey-thick font-medium text-[16px]'>Pengaturan Pajak</h1>
                            </div>
                            <div className='space-y-[8px]'>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={localStorage.getItem('hris-role') === 'USER'}
                                            label={"Nama KPPN"}
                                            value={kppnName}
                                            onChange={setKppnName}
                                            required={true}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className="w-full"></div>
                                </div>
                            </div>
                            <div className='space-y-[8px]'>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={false}
                                            label={"NPWP"}
                                            value={npwp}
                                            onChange={setNpwp}
                                            required={false}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">Status PTKP <span className="text-red-primer">*</span></h2>
                                        <div className="relative">
                                            <select disabled={localStorage.getItem('hris-role') === 'USER'} value={ptkp} onChange={(e) => setPtkp(e.target.value)} className="border rounded-[6px] text-grey-thick text-[12px] pl-[21.74px] py-[10px] w-full appearance-none">
                                                <option selected disabled value={""}>Pilih Status PTKP</option>
                                                <option value={'TK/0'}>TK/0</option>
                                                <option value={'TK/1'}>TK/1</option>
                                                <option value={'TK/2'}>TK/2</option>
                                                <option value={'TK/3'}>TK/3</option>
                                                <option value={'K/I/0'}>K/I/0</option>
                                                <option value={'K/I/1'}>K/I/1</option>
                                                <option value={'K/I/2'}>K/I/2</option>
                                                <option value={'K/I/3'}>K/I/3</option>
                                                <option value={'K/0'}>K/0</option>
                                                <option value={'K/1'}>K/1</option>
                                                <option value={'K/2'}>K/2</option>
                                                <option value={'K/3'}>K/3</option>
                                            </select>
                                            <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='space-y-[8px]'>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className="w-full">
                                        <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">Pengaturan Pajak Penghasilan <span className="text-red-primer">*</span></h2>
                                        <div className="relative">
                                            <select value={taxConfiguration} disabled={localStorage.getItem('hris-role') === 'USER'} onChange={(e) => setTaxConfiguration(e.target.value)} className="border rounded-[6px] text-grey-thick text-[12px] pl-[21.74px] py-[10px] w-full appearance-none">
                                                <option selected disabled value={""}>Pilih Pengaturan Pajak Penghasilan</option>
                                                <option value={'Gross'}>Gross</option>
                                                <option value={'Gross Up'}>Gross Up</option>
                                                <option value={'Netto'}>Netto</option>
                                            </select>
                                            <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                                        </div>
                                    </div>
                                    <div className='w-full'></div>
                                </div>
                            </div>
                            <div className='space-y-[8px]'></div>

                            <div className='space-y-[20px]'>
                                <h1 className='text-grey-thick font-medium text-[16px]'>Tambahan Penghasilan</h1>
                            </div>
                            <div className='space-y-[8px]'>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className="w-full">
                                        <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">NPP BPJS Ketengakerjaan</h2>
                                        <div className="relative">
                                            <select value={nppBPJS} disabled={localStorage.getItem('hris-role') === 'USER'} onChange={(e) => setNppBPJS(e.target.value)} className="border rounded-[6px] text-grey-thick text-[12px] pl-[21.74px] py-[10px] w-full appearance-none">
                                                <option selected disabled value={""}>Pilih NPP BPJS Ketengakerjaan</option>
                                                {Object.values(dataJKK).map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.id}>{data.title}</option>
                                                    )
                                                })}
                                            </select>
                                            <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                                        </div>
                                    </div>
                                    <div className='w-full'></div>
                                </div>
                            </div>
                            <div className='space-y-[8px]'>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={false}
                                            label={"BPJS Kesehatan "}
                                            value={BPJSKesehatan}
                                            onChange={setBPJSKesehatan}
                                            required={false}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={false}
                                            label={"BPJS Ketenagakerjaan "}
                                            value={BPJSKetenagakerjaan}
                                            onChange={setBPJSKetenagakerjaan}
                                            required={false}
                                            type={"text"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='space-y-[8px]'>
                                <div className='flex items-center gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={false}
                                            label={"Nomor Taspen"}
                                            value={nomorTaspen}
                                            onChange={setNomorTaspen}
                                            required={false}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <InputComponent
                                            disabled={false}
                                            label={"Nomor Jasindo"}
                                            value={nomorJasindo}
                                            onChange={setNomorJasindo}
                                            required={false}
                                            type={"text"}
                                        />
                                    </div>
                                </div>
                            </div>
                            {localStorage.getItem('hris-role') === 'USER' ?
                                null
                                :
                                <div className='flex items-center justify-end gap-[18px]'>
                                    <button onClick={updateMyPayroll} className='px-[21px] py-[7px] rounded-[8px] bg-blue-thick text-grey-scale text-[14px] font-medium'>Simpan</button>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <div className='space-y-[36px]'>
                        <div className='space-y-1'>
                            <h1 className='text-[20px] text-grey-primer font-semibold'>Riwayat Penghasilan</h1>
                            <p className='text-grey-medium text-[12px]'>Daftar Riwayat Penghasilan</p>
                        </div>
                        <div>
                            <div className='relative w-[150px]'>
                                <select onChange={(e) => setTahun(e.target.value)} value={tahun} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                    <option selected disabled value="">Pilih Tahun</option>
                                    {/* JavaScript untuk membuat opsi-opsi tahun */}
                                    {Array.from({ length: 31 }, (_, i) => {
                                        const year = new Date().getFullYear() - i;
                                        return <option key={year} value={year}>{year}</option>;
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                            </div>
                        </div>
                        <div className='mt-[44px] mb-[44px] overflow-auto'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Bulan</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Gaji Pokok</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Tunjangan Kinerja</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Insentif Kerja</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Tunjangan Transportasi</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Uang Makan</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Uang Lembur</h1>
                                    </div>
                                </div>
                                {dataHistory && dataHistory.map((val, index) => (
                                    <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>{val?.bulan}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Rp. {formatRupiah(val?.gaji_pokok ?? 0)}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>{val?.tunjangan_kinerja ? `Rp. ${formatRupiah(val?.tunjangan_kinerja ?? 0)}`:"-"}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>{val?.insentif_kerja ? `Rp. ${formatRupiah(val?.insentif_kerja ?? 0)}`:"-"}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>{val?.tunjangan_transportasi ? `Rp. ${formatRupiah(val?.tunjangan_transportasi ?? 0)}`:"-"}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Rp. {formatRupiah(val?.uang_makan ?? 0)}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='col-span-4 text-[12px] text-grey-thick font-semibold'>Rp. {formatRupiah(val?.uang_lembur ?? 0)}</h1>
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Payroll