import React, { Fragment, useState } from 'react'
import { Footer, HelmetReact, NavbarLandingPage } from '../../components'
import { AyoStart, BackgroundBlog, Blog1, Blog2, Blog3, FitnesPlus, IlustrasiCheckIn, Jagat, Jaris, Landing2, LogoH, Postgym, Profile, Survein, SurveyGo, Tablet, TickGreen, Vin } from '../../assets'
import { IoLogoWhatsapp } from 'react-icons/io5'
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react'
import { Link, useNavigate } from 'react-router-dom'
import LazyLoad from 'react-lazy-load'

const Home = () => {
    const navigate = useNavigate()
    const [features, setFeatures] = useState('User Management')
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const Icon = ({ id, open }) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`${id === open ? "rotate-180" : ""
                    } h-5 w-5 transition-transform`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
        );
    }

    return (
        <div className='scroll-smooth'>

            <HelmetReact
                description={'Humanusia adalah aplikasi HRIS untuk UKM yang terjangkau, lengkap dan mudah digunakan. UKM tidak perlu lagi membayar mahal untuk aplikasi pengelolaan SDM, cukup dengan biaya berlangganan satu harga yang tidak memberatkan keuangan perusahaan. Humanusia memiliki semua fitur yang dimiliki oleh aplikasi HRIS yang saat ini banyak digunakan perusahaan-perusahaan besar. '}
                title={'Humanusia adalah aplikasi HRIS untuk UKM'}
            />

            <NavbarLandingPage
                features={'#features'}
                pricing={'#pricing'}
            />

            {/* Begin : Section 1 */}
            <div className='px-[16px] lg:px-[150px] py-[75px] flex flex-col lg:flex-row items-start lg:items-center justify-between gap-8 lg:gap-[150px] scroll-smooth'>
                <div className='flex flex-col gap-8 lg:w-[700px] order-2 lg:order-1'>
                    <h1 className='text-[#2B2A35] font-medium text-[30px] lg:text-[40px]'>Humanusia sebagai penunjang untuk meningkatkan operasi bisnis.</h1>
                    <div className='space-y-4'>
                        <div className='flex items-start gap-[22px]'>
                            <img src={TickGreen}/>
                            <p className='text-[#545465] text-sm lg:text-base'>Memungkinkan otomatisasi dan manajemen yang efisien dari berbagai proses SDM</p>
                        </div>
                        <div className='flex items-start gap-[22px]'>
                            <img src={TickGreen}/>
                            <p className='text-[#545465] text-sm lg:text-base'>Mengurangi biaya operasional yang terkait dengan administrasi dan pengelolaan sumber daya manusia</p>
                        </div>
                        <div className='flex items-start gap-[22px]'>
                            <img src={TickGreen}/>
                            <p className='text-[#545465] text-sm lg:text-base'>Dapat membantu manajemen dalam membuat keputusan strategis</p>
                        </div>
                        <div className='flex items-start gap-[22px]'>
                            <img src={TickGreen}/>
                            <p className='text-[#545465] text-sm lg:text-base'>Membantu melindungi data karyawan dan menjaga kerahasiaan informasi sensitif Skalabilitas dan Pertumbuhan Bisnis Pengembangan dan Retensi Karyawan</p>
                        </div>
                    </div>
                    <div className='flex items-center gap-4'>
                        <a href='#pricing' className='bg-[#02508D] rounded-[6px] w-[184px] h-[50px] px-[37px] py-[13px] text-white text-center'>Coba Demo</a>
                        <a href={`https://wa.me/+628112935992?text=hello,%20I'm%20interested%20in%20humanusia,%20can%20you%20help%20me%20explain%20humanusia`} target='_blank' className='bg-white border border-[#02508D] rounded-[6px] w-[184px] h-[50px] px-[37px] py-[13px] text-[#02508D] text-center'>Hubungi Kami</a>
                    </div>
                </div>
                <img src={IlustrasiCheckIn} className='order-1 lg:order-2'/>
            </div>
            {/* End : Section 1 */}

            {/* Begin : Section 2 */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='flex flex-col items-center px-[16px] lg:px-[150px] py-[50px] space-y-[40px]' id='partnership'>
                    <h2 className='text-[#2B2A35] font-medium text-[30px] lg:text-[32px] lg:w-[800px] text-center'>Lebih dari 10.000+ perusahaan di berbagai industri tumbuh bersama Humanusia</h2>
                    <div className='space-y-[48px]'>
                        <div className='flex flex-wrap justify-center lg:flex-nowrap gap-14 lg:px-0 px-5 lg:gap-[87px]'>
                            <img src={Vin} alt='LogoVin' />
                            <img src={SurveyGo} alt='LogoSurveyGo' />
                            <img src={Survein} alt='LogoSurvein' />
                            <img src={AyoStart} alt='LogoAyoStart' />
                            <img src={Jagat} alt='LogoJagat' />
                            <img src={Postgym} alt='LogoPostgym' />
                        </div>
                        <div className='flex flex-wrap justify-center lg:flex-nowrap gap-14 lg:px-0 lg:gap-[87px]'>
                            <img src={FitnesPlus} alt='LogoFitnesPlush' />
                            <img src={Jaris} alt='LogoJaris' />
                        </div>
                    </div>
                </div>
            </LazyLoad>
            {/* End : Section 2 */}

            {/* Begin : Section 3 */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='px-[16px] lg:px-[150px] py-[75px] flex flex-col lg:flex-row items-start lg:items-center justify-between gap-8 lg:gap-[150px]'>
                    <div className='flex flex-col gap-8 lg:w-[700px] order-2 lg:order-1'>
                        <h2 className='text-[#2B2A35] font-medium text-[30px] lg:text-[32px]'>Kelebihan menggunakan HRIS dengan fitur yang kami miliki</h2>
                        <div className='space-y-4'>
                            <div className='flex items-center gap-[22px]'>
                                <div className='bg-[#B70000] min-w-[12px] h-[12px] rounded-full'/>
                                <p className='text-[#545465] text-sm lg:text-base'>Pengelolaan Data Pegawai</p>
                            </div>
                            <div className='flex items-center gap-[22px]'>
                                <div className='bg-[#B70000] min-w-[12px] h-[12px] rounded-full'/>
                                <p className='text-[#545465] text-sm lg:text-base'>Recruitment and Hiring: Fitur rekrutmen dan perekrutan membantu perusahaan dalam menyebarkan lowongan kerja</p>
                            </div>
                            <div className='flex items-center gap-[22px]'>
                                <div className='bg-[#B70000] min-w-[12px] h-[12px] rounded-full'/>
                                <p className='text-[#545465] text-sm lg:text-base'>Employee Self-Service: Membantu mengurangi beban administrasi SDM</p>
                            </div>
                            <div className='flex items-center gap-[22px]'>
                                <div className='bg-[#B70000] min-w-[12px] h-[12px] rounded-full'/>
                                <p className='text-[#545465] text-sm lg:text-base'>Performance management : Merampingkan proses penilaian kinerja dan membantu meningkatkan produktivitas dan keterlibatan karyawan.</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-[21px]'>
                            <a href='#pricing' className='bg-[#02508D] rounded-[6px] lg:w-[204px] h-[50px] px-[37px] py-[13px] text-white text-center'>Mulai Sekarang</a>
                            <a href='#pricing' className='bg-white border border-[#02508D] rounded-[6px] lg:w-[184px] h-[50px] px-[37px] py-[13px] text-[#02508D] text-center'>Coba Demo</a>
                        </div>
                    </div>
                    <img src={Tablet} className='order-1 lg:order-2'/>
                </div>
            </LazyLoad>
            {/* End : Section 3 */}

            {/* Begin : Section 4 */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='flex flex-col items-center px-[16px] lg:px-[150px] py-[70px] space-y-[20px]'>
                    <h2 className='text-[#2B2A35] font-medium text-[30px] lg:text-[32px] lg:w-[800px] text-center'>Apa kata pelanggan tentang produk kami</h2>
                    <div className='lg:grid grid-cols-12 flex flex-col'>
                        <div className='col-span-9 bg-[#F3E7E7] px-[27px] lg:px-[68px] py-[60px] order-2 lg:order-1'>
                            <p className='text-[20px] text-dark-4 text-justify'>"Sebagai seorang manajer sumber daya manusia, saya terus menangani berbagai tugas dan tanggung jawab. Platform Humanusia telah menyelamatkan hidup saya dengan merampingkan dan mengotomatiskan banyak proses SDM saya. Platform Humanusia memiliki semuanya, mulai dari melacak kehadiran dan kinerja karyawan hingga mengelola manfaat dan pelatihan. Saya sangat bersyukur memiliki alat ini dan sangat merekomendasikannya kepada siapa pun yang bekerja di bagian sumber daya manusia."</p>
                        </div>
                        <div className='col-span-3 bg-[#A61C1C] px-[27px] py-[60px] space-y-[20px] order-1 lg:order-2'>
                            <img src={Vin} alt='LogoVin' />
                            <div className='flex gap-4'>
                                <img className='w-12 h-12 rounded-full' src={Profile} alt='Profile' />
                                <div className=''>
                                    <p className='text-white text-sm'>Sabilla</p>
                                    <p className='text-white text-sm'>Human Resources of VIN Protocol</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LazyLoad>
            {/* End : Section 4 */}

            {/* Begin : Section 5 */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='px-[16px] lg:px-[150px] py-[70px] space-y-[50px]' id='features'>
                    <div className='space-y-[20px] flex flex-col items-center'>
                        <h2 className='text-[#2B2A35] font-medium text-[30px] lg:text-[32px] lg:w-[1112px] text-center'>Humanusia adalah alat yang ampuh yang dapat meningkatkan operasi bisnis dan meningkatkan efisiensi.</h2>
                        <p className='text-[#545465] lg:text-base text-sm lg:w-[1112px] text-center'>Humanusia memiliki fitur yang lengkap, mulai dari manajemen penggajian hingga tunjangan karyawan dan operasional SDM lainnya.</p>
                    </div>
                    <div className='flex flex-col lg:flex-row items-start justify-between gap-4'>
                        <div className='w-full lg:w-[500px]'>
                            <div className={`flex items-center gap-[12px] border-b ${features === 'User Management' ? 'border-[#C02A34]' : 'border-[#D0D6DD]'} py-[14px] w-full`}>
                                <div className='min-w-[12px] h-[12px] bg-[#147DB4] rounded-full'/>
                                <button onClick={() => setFeatures('User Management')} className='text-[#232933] '>User Management</button>
                            </div>
                            <div className={`flex items-center gap-[12px] border-b ${features === 'Job Management' ? 'border-[#C02A34]' : 'border-[#D0D6DD]'} py-[14px] w-full`}>
                                <div className='min-w-[12px] h-[12px] bg-[#147DB4] rounded-full'/>
                                <button onClick={() => setFeatures('Job Management')} className='text-[#232933] '>Job Management</button>
                            </div>
                            <div className={`flex items-center gap-[12px] border-b ${features === 'Organizational Management' ? 'border-[#C02A34]' : 'border-[#D0D6DD]'} py-[14px] w-full`}>
                                <div className='min-w-[12px] h-[12px] bg-[#147DB4] rounded-full'/>
                                <button onClick={() => setFeatures('Organizational Management')} className='text-[#232933] '>Organizational Management</button>
                            </div>
                            <div className={`flex items-center gap-[12px] border-b ${features === 'Employee Management' ? 'border-[#C02A34]' : 'border-[#D0D6DD]'} py-[14px] w-full`}>
                                <div className='min-w-[12px] h-[12px] bg-[#147DB4] rounded-full'/>
                                <button onClick={() => setFeatures('Employee Management')} className='text-[#232933] '>Employee Management</button>
                            </div>
                            <div className={`flex items-center gap-[12px] border-b ${features === 'Document Management System' ? 'border-[#C02A34]' : 'border-[#D0D6DD]'} py-[14px] w-full`}>
                                <div className='min-w-[12px] h-[12px] bg-[#147DB4] rounded-full'/>
                                <button onClick={() => setFeatures('Document Management System')} className='text-[#232933] '>Document Management System</button>
                            </div>
                            <div className={`flex items-center gap-[12px] border-b ${features === 'Time Management' ? 'border-[#C02A34]' : 'border-[#D0D6DD]'} py-[14px] w-full`}>
                                <div className='min-w-[12px] h-[12px] bg-[#147DB4] rounded-full'/>
                                <button onClick={() => setFeatures('Time Management')} className='text-[#232933] '>Time Management</button>
                            </div>
                            <div className={`flex items-center gap-[12px] border-b ${features === 'Recruitment' ? 'border-[#C02A34]' : 'border-[#D0D6DD]'} py-[14px] w-full`}>
                                <div className='min-w-[12px] h-[12px] bg-[#147DB4] rounded-full'/>
                                <button onClick={() => setFeatures('Recruitment')} className='text-[#232933] '>Recruitment</button>
                            </div>
                        </div>
                        <div className='px-[16px] lg:px-[74px] py-[42px] flex flex-col items-center bg-[#F3E7E7] rounded-[12px] lg:w-[676px]'>
                            <img src={Landing2}/>
                            <h2 className='text-[#545465]'>
                                { features === 'User Management' ? 
                                    'Efektif untuk mengelola dan mengatur pengguna dan kebutuhannya' 
                                : features === 'Job Management' ? 
                                    'Akan membantu menyelesaikan pemetaan disetiap divisi' 
                                : features === 'Organizational Management' ? 
                                    'Memiliki peran penting dalam membantu perusahaan untuk mengontrol sumber daya mereka dan mencapai tujuan mereka' 
                                : features === 'Employee Management' ? 
                                    'Membantu perusahaan untuk memantau kinerja karyawan mereka dan bagaimana mempertahankannya' 
                                : features === 'Document Management System' ? 
                                    'Efektif untuk membantu perusahaan dalam mengelola dan melacak dokumen mereka' 
                                : features === 'Time Management' ? 
                                    `Membantu mengoordinasikan tugas dan aktivitas untuk memaksimalkan efektivitas aktivitas karyawan` 
                                : features === 'Recruitment' ? 
                                    'Membantu dalam mengelola semua proses perekrutan' 
                                : null
                                }
                            </h2>
                        </div>
                    </div>
                </div>
            </LazyLoad>
            {/* End : Section 5 */}
            
            {/* Begin : Section 6 */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='px-[16px] lg:px-[150px] py-[70px] space-y-[50px]' id='pricing'>
                    <div className='space-y-[20px] flex flex-col items-center'>
                        <h2 className='text-[#2B2A35] font-medium text-[30px] lg:text-[32px] lg:w-[1112px] text-center'>Plan & Pricing </h2>
                        <p className='text-[#545465] lg:text-base text-sm lg:w-[1112px] text-center'>Select  your plan and manage it according to company requirement</p>
                    </div>
                    <div className='lg:grid flex flex-col grid-cols-12 gap-[24px]'>
                        <div className='col-span-3 w-full h-[389px] px-[21px] py-[26px] bg-[#A61C1C] rounded-tr-[40px] flex flex-col justify-between'>
                            <div className='space-y-4'>
                                <h3 className='text-white font-semibold text-[30px]'>Starter</h3>
                                <div className='space-1'>
                                    <p className='text-white text-sm'>Paket Starter sangat ideal untuk usaha kecil atau individu dengan sumber daya terbatas</p>
                                    <p className='text-white text-sm font-bold'>Untuk 1 - 100 karyawan</p>
                                </div>
                            </div>
                            <h3 className='text-[#FDF0D5] font-bold text-[30px]'>Rp. 599.000<span className='text-base text-white mr-[2px] font-normal'>/ Bulan</span></h3>
                            <div className='space-y-[12px]'>
                                <h3 className='text-white text-center text-sm'>Diskusi dengan kami</h3>
                                <a href={`https://wa.me/+628112935992?text=hello,%20I'm%20interested%20in%20humanusia,%20can%20you%20help%20me%20explain%20humanusia`} target='_blank' className='bg-gradient-to-r from-[#015F8F] via-[#167AAD] to-[#015F8F] w-full rounded-full px-[37px] py-[11px] text-white text-center flex items-center justify-center gap-[16px]'>
                                    <IoLogoWhatsapp className='text-green-500 text-xl' />
                                    Hubungi Kami
                                </a>
                            </div>
                        </div>
                        <div className='col-span-3 w-full h-[389px] px-[21px] py-[26px] border border-[#C2C2C2] rounded-tr-[40px] flex flex-col justify-between'>
                            <div className='space-y-4'>
                                <h3 className='text-dark-3 font-semibold text-[30px]'>Base</h3>
                                <div className='space-y-1'>
                                    <p className='text-dark-3 text-sm'>Paket Base membantu membangun fondasi yang kuat untuk bisnis</p>
                                    <p className='text-dark-3 text-sm font-bold'>Untuk 101-250 karyawan</p>
                                </div>
                            </div>
                            <h3 className='text-[#C1121F] font-bold text-[30px]'>Rp. 999.000<span className='text-base text-[#969696] mr-[2px] font-normal'>/ Bulan</span></h3>
                            <div className='space-y-[12px]'>
                                <h3 className='text-dark-3 text-center text-sm'>Diskusi dengan kami</h3>
                                <a href={`https://wa.me/+628112935992?text=hello,%20I'm%20interested%20in%20humanusia,%20can%20you%20help%20me%20explain%20humanusia`} target='_blank' className='bg-gradient-to-r from-[#003049] via-[#0E5073] to-[#003049] w-full rounded-full px-[37px] py-[11px] text-white text-center flex items-center justify-center gap-[16px]'>
                                    <IoLogoWhatsapp className='text-green-500 text-xl' />
                                    Hubungi Kami
                                </a>
                            </div>
                        </div>
                        <div className='col-span-3 w-full h-[389px] px-[21px] py-[26px] border border-[#C2C2C2] rounded-tr-[40px] flex flex-col justify-between'>
                            <div className='space-y-4'>
                                <h3 className='text-dark-3 font-semibold text-[30px]'>Pro</h3>
                                <div className='space-y-1'>
                                    <p className='text-dark-3 text-sm'>Paket Pro  adalah seperangkat alat dan sumber daya lengkap yang dirancang</p>
                                    <p className='text-dark-3 text-sm font-bold'>Untuk 251 - 500 karyawan</p>
                                </div>
                            </div>
                            <h2 className='text-[#C1121F] font-bold text-[30px]'>Rp. 1.399.000<span className='text-base text-[#969696] mr-[2px] font-normal'>/ Bulan</span></h2>
                            <div className='space-y-[12px]'>
                                <h3 className='text-dark-3 text-center text-sm'>Diskusi dengan kami</h3>
                                <a href={`https://wa.me/+628112935992?text=hello,%20I'm%20interested%20in%20humanusia,%20can%20you%20help%20me%20explain%20humanusia`} target='_blank' className='bg-gradient-to-r from-[#003049] via-[#0E5073] to-[#003049] w-full rounded-full px-[37px] py-[11px] text-white text-center flex items-center justify-center gap-[16px]'>
                                    <IoLogoWhatsapp className='text-green-500 text-xl' />
                                    Hubungi Kami
                                </a>
                            </div>
                        </div>
                        <div className='col-span-3 w-full h-[389px] px-[21px] py-[26px] border border-[#C2C2C2] rounded-tr-[40px] flex flex-col justify-between'>
                            <div className='space-y-4'>
                                <h3 className='text-dark-3 font-semibold text-[30px]'>Custom Plan</h3>
                                <p className='text-dark-3 text-sm'>Sebuah Paket untuk <span className='text-dark-3 text-sm font-bold'>lebih dari 500 karyawan</span> dapat didiskusikan dengan tim Humanusia</p>
                            </div>
                            <div className='space-y-[12px]'>
                                <h3 className='text-dark-3 text-center text-sm'>Diskusi dengan kami</h3>
                                <a href={`https://wa.me/+628112935992?text=hello,%20I'm%20interested%20in%20humanusia,%20can%20you%20help%20me%20explain%20humanusia`} target='_blank' className='bg-gradient-to-r from-[#003049] via-[#0E5073] to-[#003049] w-full rounded-full px-[37px] py-[11px] text-white text-center flex items-center justify-center gap-[16px]'>
                                    <IoLogoWhatsapp className='text-green-500 text-xl' />
                                    Hubungi Kami
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </LazyLoad>
            {/* End : Section 6 */}

            {/* Begin : Section 7 */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='bg-[#F9E3E3]'>
                    <div className='px-[16px] lg:px-[150px] py-[70px] lg:grid grid-cols-12 flex flex-col gap-4'>
                        <div className='col-span-4 w-full space-y-[7px]'>
                            <p className='text-[#B70000] font-medium text-[40px]'>450.000+</p>
                            <p className='text-[#545465]'>Pengguna terdaftar di Humanusia termasuk tim HR dan karyawan perusahaan</p>
                        </div>
                        <div className='col-span-4 w-full space-y-[7px]'>
                            <p className='text-[#B70000] font-medium text-[40px]'>35.000+</p>
                            <p className='text-[#545465]'>Bisnis berkembang dengan Humanusia termasuk manajemen penggajian dan database karyawan</p>
                        </div>
                        <div className='col-span-4 w-full space-y-[7px]'>
                            <p className='text-[#B70000] font-medium text-[40px]'>&gt; 9 Triliun</p>
                            <p className='text-[#545465]'>Menyelesaikan transaksi bisnis dengan Humanusia termasuk perhitungan gaji dan pembayaran</p>
                        </div>
                    </div>
                </div>
            </LazyLoad>
            {/* End : Section 7 */}

            {/* Begin : Blog */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='object-cover' style={{ backgroundImage: `url(${BackgroundBlog})` }}>
                    <div className='px-[16px] lg:px-[150px] py-[70px] lg:grid lg:grid-cols-12 flex flex-col lg:space-x-[150px]'>
                        <div className='col-span-6 lg:w-[528px] flex flex-col justify-center'>
                            <div className='space-y-2'>
                                <h2 className='text-white text-[60px] font-bold border-b-4 border-b-white'><span className='text-[#219EBC]'>Blog</span> Humanusia</h2>
                                <h3 className='text-white text-justify'>Dengan Humanusia, Anda akan selalu mendapatkan berita terkini seputar informasi pengelolaan pegawai yang dapat membantu Anda mengoptimalkan efisiensi dan produktivitas. Dapatkan pemahaman yang komprehensif tentang strategi pengembangan SDM, manajemen kinerja, penghargaan dan insentif, serta teknologi terbaru yang dapat memperkuat tim Anda. Jadilah yang terdepan dalam menghadapi tantangan pengelolaan pegawai dengan mendapatkan informasi terkini yang hanya dapat Anda temukan di Humanusia.</h3>
                            </div>
                            <div className='mt-10'>
                                <Link to={'/blogs'} className='py-[18px] px-[24px] border border-white rounded-[10px] text-white'>Lihat Semua Blog</Link>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[23px] mt-10 lg:mt-0'>
                            <Link to={'/detail-blog/menciptakan-pengalaman-pengelolaan-sdm-yang-berfokus-pada-karyawan'} className='bg-white rounded-xl overflow-hidden flex items-center gap-[24px] w-full'>
                                <img src={Blog1} className='min-w-[176px] h-[167px] object-cover'/>
                                <div className='space-y-[16px]'>
                                    <p className='text-[#11142D] font-bold text-xs lg:text-[20px]'>Humanusia: Menciptakan Pengalaman Pengelolaan SDM yang Berfokus pada Karyawan</p>
                                    <p className='text-[#9A9AB0] text-xs font-semibold'>Diupload 2 jam yang lalu</p>
                                </div>
                            </Link>
                            <Link to={'/detail-blog/maksimalkan-efisiensi-sdm-dengan-humansia-yang-unggul-dengan-fitur-fitur-hris-terbaik'} className='bg-white rounded-xl overflow-hidden flex items-center gap-[24px] w-full'>
                                <img src={Blog2} className='min-w-[176px] h-[167px] object-cover'/>
                                <div className='space-y-[16px]'>
                                    <p className='text-[#11142D] font-bold text-xs lg:text-[20px]'>Maksimalkan Efisiensi SDM dengan Humansia yang unggul dengan Fitur-Fitur HRIS Terbaik</p>
                                    <p className='text-[#9A9AB0] text-xs font-semibold'>Diupload 2 jam yang lalu</p>
                                </div>
                            </Link>
                            <Link to={'/detail-blog/humanusia-sebagai-rekomendasi-sistem-manajemen-hr-dengan-harga-terjangkau'} className='bg-white rounded-xl overflow-hidden flex items-center gap-[24px] w-full'>
                                <img src={Blog3} className='min-w-[176px] h-[167px] object-cover'/>
                                <div className='space-y-[16px]'>
                                    <p className='text-[#11142D] font-bold text-xs lg:text-[20px]'>Humanusia sebagai Rekomendasi Sistem Manajemen HR dengan Harga Terjangkau</p>
                                    <p className='text-[#9A9AB0] text-xs font-semibold'>Diupload 2 jam yang lalu</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </LazyLoad>
            {/* End : Blog */}
                        
            {/* Begin : Section 8 */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='px-[16px] lg:px-[150px] py-[70px] space-y-[50px] flex flex-col items-center' id='solution'>
                    <h2 className='text-[#2B2A35] font-medium text-[30px] lg:text-[32px] lg:w-[1112px] text-center'>Pertanyaan Tentang Humanusia</h2>
                    <div className='lg:w-[776px]'>
                        <Fragment>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader className='lg:text-base text-sm text-start text-[#2B2A35]' onClick={() => handleOpen(1)}>Apa itu Humanusia?</AccordionHeader>
                                <AccordionBody>
                                    <p className='text-black mb-2'>HUMANUSIA adalah sistem informasi kepegawaian yang tak tergantikan yang memainkan peran penting dalam memastikan kelancaran operasi perusahaan. Dengan mengintegrasikan data personalia, manajemen, dan administrasi, HUMANUSIA dirancang untuk memfasilitasi manajemen yang efisien dan akses mudah ke informasi karyawan.</p>
                                    <p className='text-black mb-2'>Dengan HUMANUSIA, perusahaan dapat menyusun informasi karyawan dengan cepat dan mudah. Dari mengelola data pribadi hingga riwayat pekerjaan, aplikasi ini mempersingkat pencarian informasi yang dibutuhkan untuk pengambilan keputusan yang lebih cerdas.</p>
                                    <p className='text-black'>Tingkatkan produktivitas dan efektivitas tim SDM Anda dengan HUMANUSIA. Dapatkan akses instan ke fitur-fitur luar biasa, termasuk manajemen kehadiran otomatis dan pemrosesan cuti, perjalanan bisnis, dan formulir aplikasi lembur yang lebih cepat.</p>
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader className='lg:text-base text-sm text-start text-[#2B2A35]' onClick={() => handleOpen(2)}>Siapa yang harus menggunakan Humanusia?</AccordionHeader>
                                <AccordionBody>
                                <p className='text-black'>Humanusia adalah solusi ideal untuk perusahaan di berbagai industri dan skala bisnis, mulai dari perusahaan rintisan hingga perusahaan berskala besar. Dengan fitur-fiturnya yang lengkap, Humanusia menyederhanakan dan merampingkan proses manajemen sumber daya manusia secara efisien. Platform mengotomatiskan tugas-tugas penting seperti manajemen data karyawan, pemrosesan penggajian, dan analisis kinerja. Dengan memanfaatkan Humanusia, perusahaan dapat meningkatkan produktivitas, kepuasan karyawan, dan pengambilan keputusan berdasarkan data. Dengan Humanusia, bisnis dapat fokus pada pengembangan bisnis strategis sambil memastikan pengelolaan sumber daya manusia yang efektif.</p>
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader className='lg:text-base text-sm text-start text-[#2B2A35]' onClick={() => handleOpen(3)}>Bagaimana kehadiran Humanusia membantu perkembangan perusahaan?</AccordionHeader>
                                <AccordionBody>
                                    <p className='text-black'>Humanusia adalah solusi yang mendukung pengembangan perusahaan melalui proses HR yang efisien, akses mudah ke informasi karyawan, pengambilan keputusan berbasis data, peningkatan kepatuhan, dan peningkatan pengalaman karyawan. Dengan mengotomatisasi dan merampingkan tugas HR, Humanusia memungkinkan tim HR untuk fokus pada inisiatif strategis, sementara akses mudah ke data karyawan dan laporan analitik memungkinkan pengambilan keputusan berdasarkan data. Selain itu, Humanusia memastikan kepatuhan terhadap peraturan ketenagakerjaan, sementara pengalaman karyawan yang ditingkatkan melalui fitur layanan mandiri membantu menumbuhkan budaya perusahaan yang positif.</p>
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                                <AccordionHeader className='lg:text-base text-sm text-start text-[#2B2A35]' onClick={() => handleOpen(4)}>Bagaimana jika perusahaan saya ingin berlangganan Humanusia ?</AccordionHeader>
                                <AccordionBody>
                                    Untuk berlangganan software Humanusia HR, ikuti langkah berikut:
                                    <p>1. Hubungi Humanusia: Hubungi tim Humanusia untuk mengungkapkan minat Anda untuk berlangganan perangkat lunak SDM mereka.</p>
                                    <p>2. Konsultasi dan Demo: Jadwalkan konsultasi dan demo produk dengan tim Humanusia.</p>
                                    <p>3. Berlangganan Paket Pilihan: Pilih paket berlangganan yang sesuai dengan kebutuhan perusahaan Anda.</p>
                                    <p>4. Implementasi dan Pelatihan: Tim Humanusia akan membantu proses implementasi dan memberikan pelatihan kepada tim SDM Anda.</p>
                                    <p>5. Dukungan dan Pembaruan Berkelanjutan: Nikmati dukungan teknis berkelanjutan dan pembaruan perangkat lunak dari Humanusia.</p>
                                    Pastikan untuk meninjau dan memahami syarat dan ketentuan langganan dengan saksama sebelum membuat keputusan akhir. Selain itu, tanyakan tentang layanan tambahan yang ditawarkan oleh Humanusia untuk meningkatkan kemampuan manajemen SDM perusahaan Anda.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
                                <AccordionHeader className='lg:text-base text-sm text-start text-[#2B2A35]' onClick={() => handleOpen(5)}>Apa saja rekomendasi fitur terbaik dari Humanusia ?</AccordionHeader>
                                <AccordionBody>
                                    Berikut adalah beberapa fitur yang direkomendasikan dari software Humanusia HR:
                                    <p>Manajemen Data Karyawan: Humanusia menyediakan sistem manajemen data karyawan yang komprehensif, memungkinkan Anda untuk menyimpan dan mengatur informasi karyawan dalam database terpusat. Ini termasuk detail pribadi, riwayat pekerjaan, catatan kinerja, dan banyak lagi.</p>
                                    <p>1. Manajemen Kehadiran dan Cuti: Humanusia menawarkan pelacakan kehadiran otomatis dan fitur manajemen cuti. Ini memungkinkan karyawan untuk mencatat kehadiran mereka dengan mudah dan mengirimkan permintaan cuti, sementara manajer dapat menyetujui atau menolaknya secara efisien. Ini merampingkan proses dan menghilangkan dokumen manual.</p>
                                    <p>2. Manajemen Penggajian dan Kompensasi: Humanusia menyederhanakan pemrosesan penggajian dengan mengotomatiskan perhitungan, pemotongan pajak, dan menghasilkan slip gaji yang akurat. Ini memungkinkan Anda mengelola gaji, bonus, potongan, dan tunjangan karyawan secara efektif, memastikan pembayaran tepat waktu dan akurat.</p>
                                    <p>3. Rekrutmen dan Onboarding: Humanusia membantu merampingkan proses rekrutmen dengan menyediakan alat untuk posting pekerjaan, pelacakan pelamar, dan evaluasi kandidat. Ini juga memfasilitasi orientasi yang lancar dengan memusatkan dokumentasi karyawan baru dan melakukan program orientasi digital.</p>
                                    <p>4. Manajemen Kinerja: Humanusia menyertakan fitur manajemen kinerja yang memungkinkan Anda menetapkan tujuan, melakukan tinjauan kinerja, dan memberikan umpan balik kepada karyawan. Ini membantu dalam mengidentifikasi kinerja terbaik, mengatasi kesenjangan kinerja, dan mendorong pertumbuhan dan pengembangan karyawan.</p>
                                    <p>5. Portal Swalayan: Humanusia menawarkan portal swalayan untuk karyawan dan manajer, memungkinkan mereka untuk mengakses informasi pribadi mereka, mengirimkan permintaan, melihat kebijakan perusahaan, dan berpartisipasi dalam proses terkait SDM. Ini memberdayakan karyawan dan mengurangi beban kerja administratif untuk tim SDM.</p>
                                    <p>6. Pelaporan dan Analitik: Humanusia menyediakan kemampuan pelaporan dan analitik yang kuat, memungkinkan Anda menghasilkan berbagai laporan SDM, melacak metrik utama, dan mendapatkan wawasan tentang tren tenaga kerja. Pendekatan berbasis data ini mendukung pengambilan keputusan dan perencanaan strategis.</p>
                                    Ingatlah untuk mengevaluasi kebutuhan spesifik SDM Anda dan pertimbangkan bagaimana setiap fitur selaras dengan kebutuhan perusahaan Anda saat memilih fitur terbaik perangkat lunak Humanusia HR untuk organisasi Anda.
                                </AccordionBody>
                            </Accordion>
                        </Fragment>
                    </div>
                </div>
            </LazyLoad>
            {/* End : Section 8 */}

            {/* Begin : Section 9 */}
            <LazyLoad offset={400} threshold={0.95}>
                <div className='bg-[#A61C1C]'>
                    <div className='px-[16px] lg:px-[150px] py-[70px] flex flex-col items-center justify-center gap-4'>
                        <img src={LogoH} className='w-[70px]'/>
                        <h2 className='text-center text-white text-[40px] font-semibold'>Mulai Sekarang</h2>
                        <p className='text-dark-9 lg:w-[581px] text-center font-thin'>Halo dan selamat datang di Humanusia! Kami senang dapat menawarkan kepada Anda alat yang komprehensif dan mudah digunakan untuk mengelola semua aspek proses HR Anda.</p>
                        <a href='' className='bg-[#02508D] rounded-[6px] lg:w-[284px] h-[50px] px-[37px] py-[13px] text-white text-center'>Mulai Sekarang</a>
                    </div>
                </div>
            </LazyLoad>
            {/* End : Section 9 */}
            
            {/* Begin : Footer */}
            <LazyLoad offset={400} threshold={0.95}>
                <Footer/>
            </LazyLoad>
            <LazyLoad offset={400} threshold={0.95}>
                <div className='px-[16px] lg:px-[150px] py-[41px] space-y-[50px]'>
                    <h2 className='text-dark-4 text-center'>@2022 Humanusia. All rights reserved.</h2>
                </div>
            </LazyLoad>
            {/* End : Footer */}
                
        </div>
    )
}

export default Home