import React from 'react'
import { IoMdArrowDropdown } from 'react-icons/io'

const InputOption = ({ label, value, onChange, required, data, disabled, placeholder }) => {

    return (
        <div className='flex-row space-y-1'>
            <label className='w-full text-grey-thick text-[12px] font-medium mb-[9px]'>{label} {required ? <span className='text-red-primer'> *</span> : ''}</label>
            <div className='relative'>
                <select disabled={disabled} value={value} onChange={(e) => onChange(e.target.value)} className={`${disabled ? 'bg-base-outline' : 'bg-white'} border rounded-[6px] text-grey-thick text-[12px] pl-[21.74px] py-[10px] w-full appearance-none`}>
                    <option selected value=''>{placeholder ?? label}</option>
                    {data ? data.map((item, index) => {
                        return (
                            <option key={index} value={item.value}>{item.label}</option>
                        )
                    })
                        :
                        null
                    }
                </select>
                {!disabled ?
                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                    : null
                }
            </div>
        </div>
    )
}

export default InputOption