import toast from "react-hot-toast";

const CheckFile = (e, size, extension) => {
    const maxSize = size * 1024 * 1024; // Mengkonversi ukuran ke byte
    const allowedExtensions = extension.map(ext => ext.toLowerCase());
    const file = e.target.files[0];
    
    // Memeriksa apakah file ada
    if (!file) {
        toast.error('Tidak ada file yang dipilih');
        return null;
    }
    
    const fileSize = file.size;
    const fileExtension = file.name.split('.').pop().toLowerCase();

    // Memeriksa ukuran file
    if (fileSize > maxSize) {
        toast.error(`Ukuran file tidak boleh lebih dari ${size} MB`);
        return null;
    }

    // Memeriksa ekstensi file
    if (!allowedExtensions.includes(fileExtension)) {
        toast.error(`File harus berformat ${extension.join(', ')}`);
        return null;
    }

    const data = {
        path: file.name,
        file: file
    };

    return data;
};

export default CheckFile;