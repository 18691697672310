// components/OrganizationTree.js

import React, { useState } from 'react';

const TreeNode = ({ node }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    if (!node) return null;

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="ml-4 border px-2 py-1 mb-2 border-gray-800 rounded-lg">
            <div className="flex items-center">
                <button
                    onClick={handleToggleCollapse}
                    className="text-gray-600 focus:outline-none"
                >
                    {isCollapsed ? '▶' : node.child?.length > 1 ? '▼' : null}
                </button>
                <div className="ml-2">
                    {node.unit_kerja}
                </div>
            </div>
            {!isCollapsed && (
                <div className="pl-4">
                    <div className="text-gray-500 ml-2">{node.jabatan}</div>
                    {node.child && (
                        <div className="pl-4">
                            {node.child.map((childNode, index) => (
                                <TreeNode key={index} node={childNode} />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const OrganizationTree = ({ data }) => {
    // Group data based on unit_kerja
    const groupedData = data.reduce((result, node) => {
        const key = node.unit_kerja;

        if (!result[key]) {
            result[key] = [];
        }

        result[key].push(node);
        return result;
    }, {});

    return (
        <div className="p-4">
            {Object.keys(groupedData).map((key, index) => (
                <div key={index}>
                    <h2 className="font-bold mb-2 border px-2 py-1 border-gray-800 rounded-lg">{key}</h2>
                    {groupedData[key].map((node, index) => (
                        <TreeNode key={index} node={node} />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default OrganizationTree;
