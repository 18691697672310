import { debounce } from 'lodash';
import React, { useCallback } from 'react'
import { BiSearch } from 'react-icons/bi'
import { IoIosSearch } from 'react-icons/io'

const SearchComponent = ({ width, textSize, placeholder, onChange }) => {
    // Event handler debounce
    const debouncedSetName = useCallback(debounce((value) => onChange(value), 500), []);

    const handleInputChange = (e) => {
        debouncedSetName(e.target.value);
    };
    return (
        <div className='flex items-center gap-[14px] border border-grey-extra-thin rounded-[8px] px-[13px] py-[11px] lg:min-w-[250px] min-w-[100px]'>
            <IoIosSearch className='text-[#8E95A2]' />
            <input
                placeholder={placeholder ?? ""}
                onChange={(e) => handleInputChange(e)}
                className={`${textSize ? `text-[${textSize}px]` : 'text-sm'} text-[#A8A8A8] outline-none appearance-none`}
                style={{ width: width ? `${width}px` : '100%' }}
            />
        </div>
    )
}

export default SearchComponent