import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { HiOutlinePencil } from 'react-icons/hi'
import Api from '../../../../../Api'
import languages from '../../../../../utils/languages'

const Account = () => {
    const [editActivated, setEditActivated] = useState(false)
    const [fullname, setFullname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [userId, setUserId] = useState("")
    const [refresh, setRefresh] = useState(false)

    const resetForm = () => {
        setPassword("")
        setNewPassword("")
        setConfirmNewPassword("")
    }

    const GetData = async () => {
        try {
            const response = await Api.GetGeneralInformation(localStorage.getItem('hris-token'))
            setUserId(response?.data?.id)
            setFullname(response?.data?.fullname)
            setEmail(response?.data?.email)
        } catch (error) {
            console.log(error)
        }
    }
    const postData = async () => {
        const dataProfile = {
            "fullname": fullname,
            "email": email
        }
        const dataPassword = {
            "current_password": password,
            "new_password": newPassword,
            "confirm_password": confirmNewPassword
        }
        try {
            await Api.PostPersonalDetail(localStorage.getItem('hris-token'), dataProfile, userId).then(async (response) => {
                if (password !== "" && newPassword !== "" && confirmNewPassword !== "") {
                    await Api.ChangePassword(localStorage.getItem('hris-token'), dataPassword).then((response) => {
                        toast.success("Data Berhasil Diubah!")
                        setRefresh(true)
                    }).catch((error) => {
                        toast.error(error?.response?.data?.message)
                        console.log(error)
                    })
                } else {
                    toast.success("Data Berhasil Diubah!")
                }
                setEditActivated(false)
                resetForm()
            }).catch((error) => {
                console.log(error)
                toast.error("Data Gagal Diubah!")
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData()
        setRefresh(false)
    }, [refresh])
    return (
        <>
            <div className='flex items-center justify-between border-b py-[16px]'>
                <div className='w-full lg:w-auto'>
                    <h1 className='text-dark-3 text-[16px] font-[500]'>
                        Informasi Akun
                    </h1>
                    <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                        Informasi Akun Instansi
                    </h1>
                </div>
                <button onClick={() => setEditActivated(!editActivated)} className={`${editActivated ? 'bg-[#A8A8A8]' : 'bg-[#0E5073]'} text-white text-sm rounded-[6px] py-[10px] px-[16px] flex items-center gap-[11px]`}>
                    <HiOutlinePencil className='text-white' />
                    Edit
                </button>
            </div>
            <div className='space-y-[25px]'>
                <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2'>
                    <div className='lg:col-span-2 md:col-span-2 w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Nama Akun <span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={fullname} onChange={(e) => setFullname(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Nama Akun...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Email <span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} disabled={editActivated ? false : true} type={'email'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Email...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Password</h1>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} disabled={editActivated ? false : true} type={'password'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Password...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Password Baru</h1>
                        <input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} disabled={editActivated ? false : true} type={'password'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Password Baru...' />
                    </div>
                    <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Konfirmasi Password Baru</h1>
                        <input value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} disabled={editActivated ? false : true} type={'password'} className={`${editActivated ? 'bg-white' : 'bg-dark-9'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Konfirmasi Password Baru...' />
                    </div>
                </div>
                {editActivated &&
                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={() => setEditActivated(!editActivated)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                        <button onClick={postData} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Simpan" : "Save"}</button>
                    </div>
                }
            </div>
        </>
    )
}

export default Account