import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BiPlus, BiSearch } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../../Api'
import { Modal, ModalDelete, Pagination } from '../../../../../components'
import languages from '../../../../../utils/languages'

function KelasJabatan() {
    const [showModalAddJobGrade, setShowModalAddJobGrade] = useState(false)
    const [showModalEditJobGrade, setShowModalEditJobGrade] = useState(false)
    const [showModalDeleteJobGrade, setShowModalDeleteJobGrade] = useState(false)


    // Pagination
    const limit = 10
    const [refresh, setRefresh] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    // Job Grade
    const [dataJobGrade, setDataJobGrade] = useState('')
    const [jobGrade, setJobGrade] = useState('')
    const [currencyType, setCurrencyType] = useState('')
    const [minimumSalary, setMinimumSalary] = useState('')
    const [maximumSalary, setMaximumSalary] = useState('')
    const [idJobGrade, setIdJobGrade] = useState('')

    const resetForm = () => {
        setJobGrade("")
        setCurrencyType("")
        setMinimumSalary("")
        setMaximumSalary("")
    }

    const getJobGrade = async () => {
        try {
            const response = await Api.GetJobGrade(localStorage.getItem('hris-token'), currentPage, limit, name)
            setDataJobGrade(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    const postJobGrade = async () => {
        if (!jobGrade.trim()) {
            toast.error('Nama Kelas Jabatan Tidak Boleh Kosong!')
        } else if (!currencyType.trim()) {
            toast.error('Mata Uang Tidak Boleh Kosong!')
        } else if (!minimumSalary.trim()) {
            toast.error('Gaji minimum Tidak Boleh Kosong!')
        } else if (!maximumSalary.trim()) {
            toast.error('Gaji maximum Tidak Boleh Kosong!')
        } else {
            try {
                const data = {
                    job_grade: jobGrade,
                    currency_type: currencyType,
                    minimum_salary: minimumSalary,
                    maximum_salary: maximumSalary
                }
                await Api.PostJobGrade(localStorage.getItem('hris-token'), data).then((response) => {
                    resetForm()
                    setShowModalAddJobGrade(!showModalAddJobGrade)
                    setRefresh(true)
                    toast.success('Berhasil Menambahkan Data!')
                }).catch((error) => {
                    console.log(error)
                    const e = error.response.data.errors.error.details
                    for (let i = 0; i < e.length; i++) {
                        toast.error(e[i].message)
                    }
                })
            } catch (error) {
                console.log(error)
                toast.error("Gagal Menambahkan Data!")
            }
        }
    }

    const OpenEditJobGrade = async (id) => {
        setIdJobGrade(id)
        setShowModalEditJobGrade(!showModalEditJobGrade)
        try {
            const response = await Api.GetJobGradeByID(localStorage.getItem('hris-token'), id)
            setJobGrade(response.data[0].job_grade)
            setCurrencyType(response.data[0].currency_type)
            setMinimumSalary(response.data[0].minimum_salary)
            setMaximumSalary(response.data[0].maximum_salary)
        } catch (error) {
            console.log(error)
        }
    }

    const editJobGrade = async () => {
        if (!jobGrade.trim()) {
            toast.error('Nama Kelas Jabatan Tidak Boleh Kosong!')
        } else if (!currencyType.trim()) {
            toast.error('Mata Uang Tidak Boleh Kosong!')
        } else if (!minimumSalary.trim()) {
            toast.error('Gaji minimum Tidak Boleh Kosong!')
        } else if (!maximumSalary.trim()) {
            toast.error('Gaji maximum Tidak Boleh Kosong!')
        } else {
            try {
                const data = {
                    job_grade: jobGrade,
                    currency_type: currencyType,
                    minimum_salary: minimumSalary,
                    maximum_salary: maximumSalary
                }
                await Api.UpdateJobGrade(localStorage.getItem('hris-token'), data, idJobGrade).then((response) => {
                    resetForm()
                    setShowModalEditJobGrade(!showModalEditJobGrade)
                    setRefresh(true)
                    toast.success('Berhasil Edit Data!')
                }).catch((error) => {
                    console.log(error)
                    const e = error.response.data.errors.error.details
                    for (let i = 0; i < e.length; i++) {
                        toast.error(e[i].message)
                    }
                })
            } catch (error) {
                toast.error('Gagal Edit Data!')
            }
        }
    }

    const deleteJobGradeModal = async (id) => {
        setShowModalDeleteJobGrade(!showModalDeleteJobGrade)
        setIdJobGrade(id)
    }

    const deleteJobGrade = async (id) => {
        try {
            await Api.DeleteJobGrade(localStorage.getItem('hris-token'), idJobGrade).then((res) => {
                setShowModalDeleteJobGrade(!showModalDeleteJobGrade)
                setRefresh(true)
                toast.success('Berhasil Hapus Data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            toast.error('Gagal Menghapus Data!')
        }
    }

    const [name, setName] = useState("")

    // Implementasi fungsi debounce
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    // Event handler untuk input perubahan dengan debounce
    const debouncedSetName = useCallback(debounce((value) => setName(value), 500), []);

    const handleInputChange = (e) => {
        debouncedSetName(e.target.value);
    };

    const [role, setRole] = useState('')
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setRole(response.data.results.user.role)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getJobGrade()
        Fetch()
        setRefresh(false)
    }, [refresh, name])
    return (
        <>
            <div className='relative'>
                <Modal
                    activeModal={showModalAddJobGrade}
                    title={languages.language === 'id' ? "Tambah Kelas Jabatan" : "Add Kelas Jabatan"}
                    buttonClose={() => { setShowModalAddJobGrade(!showModalAddJobGrade); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='flex items-center gap-[20px]'>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Kelas Jabatan" : "Kelas Jabatan Name"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                    <input onChange={(e) => setJobGrade(e.target.value)} value={jobGrade} type={'text'} className="bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full" placeholder={languages.language === 'id' ? "Input Nama Kelas Jabatan..." : "Kelas Jabatan Name..."} />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Mata Uang" : "Currency"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <select onChange={(e) => setCurrencyType(e.target.value)} value={currencyType} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                            <option value={''} disabled selected>{languages.language === 'id' ? "Pilih Mata Uang" : "Select Currency"}</option>
                                            <option value={'Indonesian Rupiah'}>Rp (Indonesia Rupiah)</option>
                                            <option value={'American Dollars'}>$ (American Dollars)</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center gap-[20px]'>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Gaji Minimum" : "Minimum Salary"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                    <input onChange={(e) => setMinimumSalary(e.target.value)} value={minimumSalary} type={'number'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={languages.language === 'id' ? "Input Gaji Minimum..." : "Minimum Salary..."} />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Gaji Maksimum" : "Maximum Salary"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                    <input onChange={(e) => setMaximumSalary(e.target.value)} value={maximumSalary} type={'number'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={languages.language === 'id' ? "Input Gaji Minimum..." : "Maximum Salary..."} />
                                </div>
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => { setShowModalAddJobGrade(!showModalAddJobGrade); resetForm() }} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                                <button onClick={postJobGrade} className='bg-blue-primer text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Simpan</button>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='relative'>
                <Modal
                    activeModal={showModalEditJobGrade}
                    title={'Edit Kelas Jabatan'}
                    buttonClose={() => { setShowModalEditJobGrade(!showModalEditJobGrade); resetForm() }}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='flex items-center gap-[20px]'>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Kelas Jabatan" : "Job Grade Name"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                    <input value={jobGrade} onChange={(e) => setJobGrade(e.target.value)} type={'text'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={languages.language === 'id' ? "Nama Kelas Jabatan..." : "Job Grade Name..."} />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Mata Uang" : "Currency"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <select value={currencyType} onChange={(e) => setCurrencyType(e.target.value)} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                            <option value={''} disabled>{languages.language === 'id' ? "Pilih Mata Uang" : "Select Currency"}</option>
                                            <option value={'Indonesian Rupiah'}>Rp (Indonesia Rupiah)</option>
                                            <option value={'American Dollars'}>$ (American Dollars)</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center gap-[20px]'>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Gaji Minimum" : "Minimum Salary"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                    <input value={minimumSalary} onChange={(e) => setMinimumSalary(e.target.value)} type={'number'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={languages.language === 'id' ? "Input Gaji Minimum..." : "Minimum Salary..."} />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Gaji Maksimum" : "Maximum Salary"}<span className='text-red-primer ml-[4px]'>*</span></h1>
                                    <input value={maximumSalary} onChange={(e) => setMaximumSalary(e.target.value)} type={'number'} className='bg-white border rounded-[6px] text-grey-thick text-[13px] px-[8px] py-[10px] w-full' placeholder={languages.language === 'id' ? "Input Gaji Maksimum" : "Maximum Salary..."} />
                                </div>
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => { setShowModalEditJobGrade(!showModalEditJobGrade); resetForm() }} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                                <button onClick={editJobGrade} className='bg-blue-primer text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Simpan</button>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='relative'>
                <ModalDelete
                    activeModal={showModalDeleteJobGrade}
                    buttonClose={() => setShowModalDeleteJobGrade(!showModalDeleteJobGrade)}
                    submitButton={deleteJobGrade}
                />
            </div>
            <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                <div className='flex flex-wrap justify-between items-center gap-2 mb-[36px]'>
                    <div className='w-full lg:w-auto'>
                        <h1 className='text-dark-3 text-[16px] font-[500]'>Kelas Jabatan</h1>
                        <h1 className='text-grey-thick text-xs font-[500]'>Daftar Kelas Jabatan</h1>
                    </div>
                    <div className='flex flex-wrap gap-2'>
                        <div className='relative'>
                            <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                            <input onChange={(e) => handleInputChange(e)} placeholder='Cari ...' className='text-[#A8A8A8] text-[12px] font-[500] pl-12 border rounded-md py-2 w-full lg:w-[200px]' />
                        </div>
                        {role === 'ADMIN' ?
                            <button onClick={() => setShowModalAddJobGrade(!showModalAddJobGrade)} className='bg-blue-primer rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <BiPlus className='text-white' />
                                <h1 className='text-white text-sm font-[500]'>Tambah Kelas Jabatan</h1>
                            </button>
                            : null
                        }
                    </div>
                </div>
                <div>
                    <div className='overflow-auto'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                    <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Nama Kelas Jabatan" : "Grade Name"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                    <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Mata Uang" : "Currency"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                    <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Gaji Minimum" : "Minimum Salary"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                    <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Gaji Maximum" : "Maximum Salary"}</h1>
                                </div>
                                {role === 'ADMIN' ?
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-grey-thick text-xs font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                    </div>
                                    : null
                                }
                            </div>
                            {Object.values(dataJobGrade).map((data, index) => {
                                return (
                                    <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[190px] max-w-[190px]'>
                                            <h1 className='text-grey-thick text-xs font-[500] truncate'>{data.job_grade}</h1>
                                        </div>
                                        <div className='min-w-[190px] max-w-[190px]'>
                                            <h1 className='text-grey-thick text-xs font-[500] truncate'>{data.currency_type}</h1>
                                        </div>
                                        <div className='min-w-[190px] max-w-[190px]'>
                                            <h1 className='text-grey-thick text-xs font-[500] truncate'>{data.currency_type === 'Indonesian Rupiah' ? `Rp ${data.minimum_salary}` : `$ ${data.minimum_salary}`}</h1>
                                        </div>
                                        <div className='min-w-[190px] max-w-[190px]'>
                                            <h1 className='text-grey-thick text-xs font-[500] truncate'>{data.currency_type === 'Indonesian Rupiah' ? `Rp ${data.maximum_salary}` : `$ ${data.maximum_salary}`}</h1>
                                        </div>
                                        {role === 'ADMIN' ?
                                            <div className='w-full flex items-center justify-center gap-[12px]'>
                                                <button onClick={() => OpenEditJobGrade(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                <button onClick={() => deleteJobGradeModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]' />
                                                </button>
                                            </div>
                                            : null
                                        }
                                    </div>
                                )
                            })}
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        lengthData={totalCount}
                        limitData={dataJobGrade?.length}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </>
    )
}

export default KelasJabatan