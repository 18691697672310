import React, { useEffect, useState } from 'react'
import { ModalDelete, Navbar, Pagination, Sidebar } from '../../../../components'
import { FaClipboardList } from 'react-icons/fa'
import { BsEnvelope, BsEnvelopeOpen, BsFillCalendarDateFill } from 'react-icons/bs'
import { FiTrash } from 'react-icons/fi'
import { BiExport, BiSearch } from 'react-icons/bi'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import { RiInboxArchiveFill } from 'react-icons/ri'
import toast from 'react-hot-toast'

const Inbox = () => {
    const navigate = useNavigate()

    const [inboxData, setInboxData] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [whois, setWhois] = useState('')
    const [myId, setMyID] = useState('')

    const fetchUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setWhois(response.data.results.user.role)
            setMyID(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }

    const GetInbox = async () => {
        try {
            if (whois !== 'ADMIN') {
                const response = await Api.GetInbox(localStorage.getItem('hris-token'), query, page, limit)
                setTotalPages(response.data.totalPages)
                setTotalCount(response.data.totalCount)
                setPage(response.data.currentPage)
                setInboxData(response.data.inbox.sort((a, b) => {
                    const timeA = new Date(a.createdAt).getTime();
                    const timeB = new Date(b.createdAt).getTime();
                    return timeB - timeA;
                }));
            } else {
                const response = await Api.GetInbox(localStorage.getItem('hris-token'), query, page, limit)
                setInboxData(response.data.data.sort((a, b) => {
                    const timeA = new Date(a.createdAt).getTime();
                    const timeB = new Date(b.createdAt).getTime();
                    return timeB - timeA;
                }));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleButton = async (data) => {
        const updateData = {
            id: data.id
        }
        try {
            // console.log(data.inbox.type)
            // if (data.isApproval) {
            if (data.inbox.type === 'Document') {
                navigate('/document/answer-document', { state: { documentId: data.applicantId, myId: myId } })
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            } else if (data.inbox.type === 'RHK') {
                navigate('/laporan-kinerja')
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            } else if (data.inbox.type === 'SKP' || data.inbox.type === 'SignedSKP') {
                navigate('/laporan-kinerja/detail-skp', {
                    state: { id: data?.inbox?.applicantId }
                })
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
                // } else if (data.inbox.type === 'PenilaianLaporan') {
                //     navigate('/laporan-kinerja/penilaian-bulanan', {
                //         state: { id: data?.inbox?.applicantId }
                //     })
                //     const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            } else if (data.inbox.type === 'PenilaianLaporanPeriode') {
                navigate('/laporan-kinerja/penilaian-periodik', {
                    state: { id: data?.inbox?.applicantId }
                })
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            } else if (data.inbox.type === 'SignedLaporanPeriode') {
                navigate('/laporan-kinerja/detail-saya/periode', {
                    state: { id: data?.inbox?.applicantId }
                })
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            } else if (data.inbox.type === 'Laporan' || data.inbox.type === 'PenilaianLaporan') {
                navigate('/laporan-kinerja/detail-bawahan/bulanan', {
                    state: { id: data?.inbox?.applicantId }
                })
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            } else if (data.inbox.type === 'LaporanPeriode') {
                navigate('/laporan-kinerja/detail-bawahan/periode', {
                    state: { id: data?.inbox?.applicantId }
                })
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            } else if (data.inbox.type === 'Leave' || data.inbox.type === 'Permission' || data.inbox.type === 'PresensiManual' || data.inbox.type === 'LeaveDelegasi') {
                navigate('/inbox/inbox-detail', { state: { inboxId: data.id, applicantId: data?.inbox?.applicantId, type: data?.inbox?.type, myId: myId } })
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            }
            setRefresh(true)
            // }
        } catch (error) {
            console.log(error)
        }
    }

    const [limit, setLimit] = useState(10)
    const [query, setQuery] = useState('')
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (val) => {
        setPage(val);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (page === 1) {
            setPage(1)
        } else {
            setPage(page - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (page === 10) {
            setPage(10)
        } else {
            setPage(page + 1);
        }
        setRefresh(true)
    };


    const [showModalDelete, setShowModalDelete] = useState()
    const [id, setId] = useState('')
    const deleteDataModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
    }
    const deleteData = async () => {
        const data = {
            "id": [id]
        }
        try {
            const response = await Api.DeleteInbox(localStorage.getItem('hris-token'), data)
            setShowModalDelete(!showModalDelete)
            setRefresh(true)
            toast.success('Berhasil Menghapus Data!')
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data!')
        }
    }

    useEffect(() => {
        GetInbox()
        fetchUser()
        setRefresh(false)
    }, [refresh, query])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div>
                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteData}
                />
            </div>
            <div className='w-full space-y-[24px] mb-10 overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Pesan Masuk'} LinkBack={'/dashboard'} />
                <div className='space-y-[33px] bg-white border px-[36px] py-[26px] rounded-[12px]'>
                    <div className='flex flex-col lg:flex-row items-start lg:items-center justify-between'>
                        <div>
                            <h1 className='text-dark-3 text-[20px] font-[600]'>Pesan Masuk</h1>
                            {/* <h1 className='text-dark-5 text-[10px]'>12 unread messages</h1> */}
                        </div>
                        <div className='relative'>
                            <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                            <input onChange={(e) => setQuery(e.target.value)} placeholder="Cari..." className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]' />
                        </div>
                        {/* <div className='flex flex-col lg:flex-row items-start lg:items-center gap-[5px] mt-2 lg:mt-0'>
                            <button className='flex items-center gap-[13px] border border-[#A8A8A8] rounded-[6px] px-[13px] py-[11px] w-full lg:w-auto'>
                                <BsFillCalendarDateFill className='text-dark-5'/>
                                <h1 className='text-dark-5 text-[12px] font-[500]'>Filter By Date</h1>
                            </button>
                            <Link to={'/inbox/approval'} className='flex items-center gap-[13px] border border-[#A8A8A8] rounded-[6px] px-[13px] py-[11px] w-full lg:w-auto'>
                                <FaClipboardList className='text-dark-5'/>
                                <h1 className='text-dark-5 text-[12px] font-[500]'>History Approval</h1>
                            </Link>
                        </div> */}
                    </div>
                    <div>
                        <div className='h-[500px] space-y-[5px] overflow-auto'>
                            {inboxData.length > 0 ? Object.values(inboxData).map((data, index) => {
                                return (
                                    <div key={index} className={`lg:w-full w-[600px] flex items-center justify-between border ${data.isRead === true ? 'bg-white' : 'bg-[#EBF7FF]'} rounded-[6px] p-[16px]`}>
                                        <button onClick={() => handleButton(data)} className='w-full'>
                                            <div className='flex items-center gap-[40px]'>
                                                {data.isRead === true ?
                                                    <BsEnvelopeOpen className='text-dark-5' />
                                                    :
                                                    <BsEnvelope className='text-dark-5' />
                                                }
                                                <h1 className='text-dark-5 text-sm text-left'>{data?.inbox?.title}</h1>
                                            </div>
                                        </button>
                                        <div className='flex items-center gap-[20px]'>
                                            <h1 className='text-dark-5 text-sm truncate'>{moment().format("YYYY-MM-DD") === moment(data.createdAt).format("YYYY-MM-DD") ? moment(data.createdAt).format('HH:mm') : moment(data.createdAt).format('DD MMM')}</h1>
                                            <button onClick={() => deleteDataModal(data.id)}>
                                                <FiTrash className='text-dark-5 hover:text-[#C1121F]' />
                                            </button>
                                        </div>
                                    </div>
                                )
                            }) :
                                <div className='text-center align-middle pt-20 text-2xl w-full h-[400px]'>
                                    <h1 className='text-dark-5 text-sm'>Belum ada pesan masuk</h1>
                                </div>}
                        </div>
                        <Pagination
                            currentPage={page}
                            totalPages={totalPages}
                            lengthData={totalCount}
                            limitData={limit}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inbox