import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import Api from '../../../../Api'
import { Modal, ModalDelete, Pagination } from '../../../../components'
import InputOption from '../../../../components/Input/inputOption'

const IndikatorKuantitatif = () => {
    const [refresh, setRefresh] = useState(false)
    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)
    const [data, setData] = useState([])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const getIndikatorKuantitatif = async () => {
        try {
            await Api.GetIndikatorKinerja(localStorage.getItem('hris-token'), currentPage, limit).then((response) => {
                setData(response.data.results.data)
                setCurrentPage(response.data.results.currentPage)
                setTotalPages(response.data.results.totalPages)
                setTotalCount(response.data.results.totalCount)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [idIndikator, setIdIndikator] = useState('')
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const resetForm = () => {
        setIdIndikator('')
        setUnitKerja('')
        setRepeaters([])
    }
    const getIndikatorKuantitatifById = async (id) => {
        setShowEdit(true)
        setIdIndikator(id)
        try {
            await Api.GetIndikatorKinerjaById(localStorage.getItem('hris-token'), id).then((response) => {
                setRepeaters(response.data.results.data.indikator)
                setUnitKerja(response.data.results.data.unitKerjaEselon)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [repeaters, setRepeaters] = useState([""]);
    const handleAddRepeater = () => {
        const newRepeater = "";
        setRepeaters([...repeaters, newRepeater]);
    };

    const handleRemoveRepeater = (index) => {
        const updatedRepeaters = repeaters.filter((_, i) => i !== index)
        setRepeaters(updatedRepeaters);
    };

    const handleRepeaterChange = (index, value) => {
        const updatedRepeaters = repeaters.map((repeater, i) =>
            i === index ? value : repeater
        );
        setRepeaters(updatedRepeaters);
    };

    const postData = async () => {
        try {
            const data = {
                unitKerjaEselon: unitKerja,
                indikator: repeaters
            }
            await Api.PostIndikator(localStorage.getItem('hris-token'), data).then((response) => {
                setRefresh(true)
                setShowAdd(false)
                resetForm()
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                toast.error(error?.response?.data?.message)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data!')
        }
    }

    const editData = async () => {
        try {
            const data = {
                unitKerjaEselon: unitKerja,
                indikator: repeaters
            }
            await Api.UpdateIndikator(localStorage.getItem('hris-token'), data, idIndikator).then((response) => {
                setRefresh(true)
                setShowEdit(false)
                resetForm()
                toast.success('Berhasil Mengubah Data!')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Mengubah Data!')
        }
    }

    const [showModalDelete, setShowModalDelete] = useState(false)

    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setIdIndikator(id)
        setRefresh(true)
    }

    const deleteIndikator = async () => {
        try {
            await Api.DeleteIndikator(localStorage.getItem('hris-token'), idIndikator).then((response) => {
                setRefresh(true)
                setShowModalDelete(false)
                toast.success('Hapus File Berhasil')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }

    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [unitKerja, setUnitKerja] = useState("")

    const getUnitKerja = async () => {
        try {
            await Api.GetUnitKerjaEselon(localStorage.getItem('hris-token')).then((response) => {
                const val = response?.data?.results?.data
                const formattedData = val.map(item => ({ value: item.name, label: item.name }));
                setDataUnitKerja(formattedData)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getIndikatorKuantitatif()
        setRefresh(false)
    }, [refresh])

    useEffect(() => {
        getUnitKerja()
    }, [])
    return (
        <div>
            <div className='relative'>
                <Modal
                    activeModal={showAdd}
                    title={"Tambah Indikator Kinerja"}
                    buttonClose={() => { setShowAdd(!showAdd); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div className='space-y-3'>
                                <InputOption data={dataUnitKerja} label={'Unit Kerja'} value={unitKerja} onChange={(e) => setUnitKerja(e)} required={true} />
                                <div className='space-y-3 w-full'>
                                    {repeaters.map((repeater, index) => (
                                        <div key={index} className='mb-[10px] w-full'>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>
                                                Nama Indikator Kinerja <span className='text-[#780000]'>*</span>
                                            </h1>
                                            <div className='flex gap-3 '>
                                                <input
                                                    onChange={(e) => handleRepeaterChange(index, e.target.value)}
                                                    value={repeater}
                                                    type='text'
                                                    placeholder='Nama indikator kinerja ....'
                                                    className='py-[10px] border rounded-md pl-[13px] pr-2 text-[11px] text-dark-5 w-full'
                                                />

                                                <button onClick={() => handleRemoveRepeater(index)}>
                                                    <CgTrashEmpty className='text-[#780000] text-xl' />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={handleAddRepeater} className='px-[8px] py-[7px] flex gap-[4px] bg-blue-thin text-blue-thick rounded-lg'>
                                        <AiOutlinePlus />
                                        <h1 className="text-xs font-medium">Tambah Indikator</h1>
                                    </button>
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <button onClick={() => { setShowAdd(!showAdd); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={postData} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEdit}
                    title={"Edit Indikator Kinerja"}
                    buttonClose={() => { setShowEdit(!showEdit); resetForm() }}
                    width={'832px'}
                    content={
                        <div className='space-y-[24px] w-[full]'>
                            <div className='space-y-3'>
                                <InputOption data={dataUnitKerja} label={'Unit Kerja'} value={unitKerja} onChange={(e) => setUnitKerja(e)} required={true} />
                                <div className='space-y-3 w-full'>
                                    {repeaters.map((repeater, index) => (
                                        <div key={index} className='mb-[10px] w-full'>
                                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>
                                                Nama Indikator Kinerja <span className='text-[#780000]'>*</span>
                                            </h1>
                                            <div className='flex gap-3 '>
                                                <input
                                                    onChange={(e) => handleRepeaterChange(index, e.target.value)}
                                                    value={repeater}
                                                    type='text'
                                                    placeholder='Nama indikator kinerja ....'
                                                    className='py-[10px] border rounded-md pl-[13px] pr-2 text-[11px] text-dark-5 w-full'
                                                />

                                                <button onClick={() => handleRemoveRepeater(index)}>
                                                    <CgTrashEmpty className='text-[#780000] text-xl' />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={handleAddRepeater} className='px-[8px] py-[7px] flex gap-[4px] bg-blue-thin text-blue-thick rounded-lg'>
                                        <AiOutlinePlus />
                                        <h1 className="text-xs font-medium">Tambah Indikator</h1>
                                    </button>
                                </div>
                            </div>
                            <div className="flex justify-end gap-[10px]">
                                <button onClick={() => { setShowEdit(!showEdit); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={editData} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deleteIndikator}
                />
            </div>
            <div className='mt-[44px] mb-[44px] space-y-5 overflow-auto'>
                <div className='flex justify-end'>
                    <button onClick={() => setShowAdd(!showAdd)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                        <AiOutlinePlus className='text-xl text-white' />
                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah Indikator</h1>
                    </button>
                </div>
                <table className='min-w-full'>
                    <thead>
                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                            <th className='border py-3 px-5'>No.</th>
                            <th className='border py-3 px-5'>Unit Kerja</th>
                            <th className='border py-3 px-5'>Indikator Kinerja</th>
                            <th className='border py-3 px-5'>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((val, index) => (
                            <tr key={index} className='border'>
                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium text-center'>{index + 1}</td>
                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.unitKerjaEselon ?? "-"}</td>
                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                    <ol className='list-decimal'>
                                        {val?.indikator && val?.indikator.map((v, i) => (
                                            <li key={i}>{v}</li>
                                        ))}
                                    </ol>
                                </td>
                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                    <div className='flex justify-center items-center gap-2'>
                                        <button
                                            onClick={() => getIndikatorKuantitatifById(val.id)}
                                            className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-blue-thick' />
                                        </button>
                                        <button
                                            onClick={() => {
                                                deleteModal(val?.id);
                                            }}
                                            className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                            <AiOutlineDelete className='text-sm text-blue-thick' />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    lengthData={totalCount}
                    limitData={limit}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div >
    )
}

export default IndikatorKuantitatif