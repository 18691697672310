import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Api from '../../Api'
import Pagination from '../Pagination'

const TableTugasBelajar = ({ startDate, endDate, name, isAsc, exportData, limitData }) => {
    const [data, setData] = useState([])

    const [refresh, setRefresh] = useState(false)
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const getDataTugasBelajar = async () => {
        try {
            const response = await Api.GetTugasBelajarDashboard(localStorage.getItem('hris-token'), currentPage, limitData, startDate, endDate, name, isAsc)
            setData(response.data.results.data)
            setCurrentPage(response.data.results.currentPage)
            setTotalPages(response.data.results.totalPages)
            setTotalCount(response.data.results.totalCount)
            exportData(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getDataTugasBelajar()
        setRefresh(false)
    }, [refresh, startDate, endDate, name, isAsc, limitData])
    return (
        <div className='rounded shadow'>
            <div className='px-2 py-4'>
                <div className='overflow-auto'>
                    <table className='w-full space-y-[10px] text-dark-5'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nama Pegawai</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Jenis Tugas Belajar</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Jenjang Pendidikan Yang Ditempuh</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nomor SK Tugas Belajar</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal SK Tugas Belajar</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Pejabat Yang Menetapkan</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal Mulai Tugas Belajar</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal Selesai Tugas Belajar</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Sumber Pembiayaan Pendidikan</h1>
                            </div>
                        </div>
                        {data && data.map((val, index) => (
                            <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val?.user?.fullname ?? "-"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.jenis ?? "-"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.jenjang_pendidikan ?? "-"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.nomor_sk ?? "-"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.tanggal_sk ? moment(val.tanggal_sk).format('DD MMMM YYYY') : "-"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val?.pejabatYangMenetapkan?.fullname ?? "-"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.tanggal_mulai ? moment(val.tanggal_mulai).format('DD MMMM YYYY') : "-"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.tanggal_selesai ? moment(val.tanggal_selesai).format('DD MMMM YYYY') : "-"}</h1>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.sumber_pembiayaan ?? "-"}</h1>
                                </div>
                            </div>
                        ))}
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    limitData={limitData}
                    lengthData={totalCount}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div>
    )
}

export default TableTugasBelajar