import React, { useState } from 'react'
import { Logo } from '../../assets'
import { MdMenu, MdMenuOpen } from 'react-icons/md'
import { Link } from 'react-router-dom'

const NavbarLandingPage = ({features, pricing, getStarted}) => {
    const [mobileMenu, setMobileMenu] = useState(false)

    return (
        <div>
            {/* Desktop Version */}
            <div className={`bg-white border shadow-sm rounded-[6px] py-[16px] px-[16px] lg:px-[150px] w-full flex items-center justify-between`}>
                <Link to='/'>
                    <img src={Logo} className='w-[120px] lg:w-[162px]'/>
                </Link>
                <div className='hidden lg:flex items-center gap-4 lg:gap-[66px]'>
                    <Link to={'/'} className='text-[#232933] lg:text-base text-sm'>Beranda</Link>
                    <a href={features} className='text-[#232933] lg:text-base text-sm'>Fitur</a>
                    <a href={pricing} className='text-[#232933] lg:text-base text-sm'>Harga</a>
                    <Link to={'/blogs'} className='text-[#232933] lg:text-base text-sm'>Blog</Link>
                    <Link to={'/about-us'} className='text-[#232933] lg:text-base text-sm'>Tentang Kami</Link>
                </div>
                <div className='hidden lg:flex items-center gap-4'>
                    <a href={getStarted} className='bg-[#001296] text-white w-[173px] h-[40px] rounded-[6px] px-[21px] py-[8px] text-center'>Mulai Sekarang</a>
                    <Link to={'/login'} className='bg-white text-[#001296] border border-[#001296] w-[153px] h-[40px] rounded-[6px] px-[21px] py-[8px] text-center'>Masuk</Link>
                </div>
                <button onClick={() => setMobileMenu(!mobileMenu)} className='block lg:hidden'>
                    {!mobileMenu ?
                        <MdMenu className='text-2xl'/>
                        :
                        <MdMenuOpen className='text-2xl'/>
                    }
                </button>
            </div>
            {/* Mobile Version */}
            <div className={`border bg-white shadow-sm transition-all duration-1000 ease-in-out overflow-hidden ${mobileMenu ? 'h-[340px] px-[16px] py-[16px]' : 'h-[0px] px-[0px] py-[0px]'}`}>
                <div className='flex flex-col items-center gap-4 lg:gap-[66px]'>
                    <Link to={'/'} className='text-[#232933] text-sm border-b w-full text-center py-1'>Beranda</Link>
                    <a href={features} className='text-[#232933] text-sm border-b w-full text-center py-1'>Fitur</a>
                    <a href={pricing} className='text-[#232933] text-sm border-b w-full text-center py-1'>Harga</a>
                    <Link to={'/blogs'} className='text-[#232933] text-sm border-b w-full text-center py-1'>Blog</Link>
                    <Link to={'/about-us'} className='text-[#232933] text-sm border-b w-full text-center py-1'>Tentang Kami</Link>
                    <a href={getStarted} className='bg-[#001296] text-white w-full rounded-[6px] px-[21px] py-[4px] text-center'>Mulai Sekarang</a>
                    <Link to={'/login'} className='bg-white text-[#001296] border border-[#001296] w-full rounded-[6px] px-[21px] py-[4px] text-center'>Masuk</Link>
                </div>
            </div>
        </div>
    )
}

export default NavbarLandingPage