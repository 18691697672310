import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSearch } from 'react-icons/bi';
import { CgTrashEmpty } from "react-icons/cg";
import { HiOutlinePencil } from "react-icons/hi";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  Circle,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import Api from "../../../../../Api";
import { LogoH } from "../../../../../assets";
import { Modal, ModalDelete, Pagination } from "../../../../../components";
import languages from "../../../../../utils/languages";

function AturLokasi() {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [geoid, setGeoId] = useState("");
  const [geodata, setGeoData] = useState("");
  const [geoname, setGeoName] = useState("");
  const [georadius, setGeoRadius] = useState("");
  const [markerPosition, setMarkerPosition] = useState(null);

  const customIcon = L.icon({
    iconUrl: LogoH,
    iconSize: [24, 32],
  });

  const handleClick = (e) => {
    const { lat, lng } = e.latlng;
    setMarkerPosition([lat, lng]);
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: handleClick,
    });

    return null;
  };

  const resetForm = () => {
    setGeoName("");
    setMarkerPosition("");
    setGeoRadius("");
    setPenempatan("");
  };

  const postGeoData = async () => {
    try {
      const data = {
        companyId: penempatan,
        geoname: geoname,
        geocode: JSON.stringify(markerPosition),
        georadius: georadius,
      };
      if (georadius >= 0) {
        await Api.PostGeoLocation(
          localStorage.getItem("hris-token"),
          data
        ).then((response) => {
          resetForm();
          setRefresh(true);
          setShowModalAdd(!showModalAdd);
          toast.success("Berhasil Menambahkan Data!");
        }).catch((e) => {
          console.log(e)
        })
      } else {
        toast.error("Gagal Menambahkan Data!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Gagal Menambahkan Data!");
    }
  };

  const getGeoData = async () => {
    try {
      const response = await Api.GetGeoLocation(localStorage.getItem("hris-token"), limit, currentPage, search);
      setGeoData(response.data.data);
      setCurrentPage(response.data.currentPage)
      setTotalPages(response.data.totalPages)
      setTotalCount(response.data.totalCount)
    } catch (error) {
      console.log(error);
    }
  };

  const getGeoDataById = async (id) => {
    setShowModalEdit(!showModalEdit);
    setGeoId(id);
    try {
      const response = await Api.GetGeoLocationById(
        localStorage.getItem("hris-token"),
        id
      );
      const markerPositionArray = response.data[0].geocode;
      const markerPositionParse = JSON.parse(markerPositionArray);
      setMarkerPosition(markerPositionParse);
      setGeoName(response.data[0].geoname);
      setGeoRadius(response.data[0].georadius);
      setPenempatan(response.data[0].companyId);
    } catch (error) {
      console.log(error);
    }
  };

  const updateGeoData = async () => {
    try {
      const data = {
        companyId: penempatan,
        geoname: geoname,
        geocode: JSON.stringify(markerPosition),
        georadius: georadius,
      };
      await Api.UpdateGeoLocation(
        localStorage.getItem("hris-token"),
        data,
        geoid
      ).then((res) => {
        resetForm();
        setRefresh(true);
        setShowModalEdit(!showModalEdit);
        toast.success("Success update absence distance");
      }).catch((err) => {
        console.log(err)
      })
    } catch (error) {
      console.log(error);
      toast.error("Data cannot be empty!");
    }
  };

  const deleteGeoDataId = (id) => {
    setShowModalDelete(!showModalDelete);
    setGeoId(id);
  };

  const deleteGeoData = async () => {
    try {
      await Api.DeleteGeoLocation(
        localStorage.getItem("hris-token"),
        geoid
      ).then((res) => {
        setRefresh(true);
        setShowModalDelete(!showModalDelete);
        toast.success("Success delete absence distance");
      }).catch((err) => {
        console.log(err)
      })
    } catch (error) {
      console.log(error);
      toast.error("Failed delete!");
    }
  };

  const [dataPenempatan, setDataPenempatan] = useState([]);
  const [penempatan, setPenempatan] = useState([]);
  const getSubsidiary = async () => {
    try {
      const response = await Api.GetSubsidiaryDropdown(
        localStorage.getItem("hris-token")
      );
      setDataPenempatan(response.data.results.data);
    } catch (error) {
      console.log(error);
    }
  };
  const limit = 10

  const [search, setSearch] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState('')
  const [totalCount, setTotalCount] = useState(1)

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setRefresh(true)
  };

  const handlePrevChange = () => {
    if (currentPage === 1) {
      setCurrentPage(1)
    } else {
      setCurrentPage(currentPage - 1);
    }
    setRefresh(true)
  };

  const handleNextChange = () => {
    if (currentPage === totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(currentPage + 1);
    }
    setRefresh(true)
  };

  useEffect(() => {
    getGeoData();
    getSubsidiary();
    setRefresh(false);
  }, [refresh, search]);
  return (
    <>
      <div className="relative">
        <Modal
          activeModal={showModalAdd}
          title={"Tambah Jarak Presensi"}
          buttonClose={() => { setShowModalAdd(!showModalAdd); resetForm() }}
          width={"850px"}
          content={
            <div className="space-y-6">
              <div className="relative w-full">
                <select
                  onChange={(e) => setPenempatan(e.target.value)}
                  value={penempatan}
                  className="text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none"
                >
                  <option selected disabled value="">
                    Pilih Penempatan
                  </option>
                  {dataPenempatan &&
                    dataPenempatan.map((val, index) => (
                      <option key={index} value={val.id}>
                        {val.company_name}
                      </option>
                    ))}
                </select>
                <IoMdArrowDropdown className="absolute top-[9px] right-1 text-xl text-grey-medium" />
              </div>
              <div className="w-full">
                <h1 className="text-dark-5 text-[13px] font-[500] mb-[7px]">
                  Nama Geografis
                  <span className="text-[#780000] ml-[4px]">*</span>
                </h1>
                <input
                  type={"text"}
                  onChange={(e) => setGeoName(e.target.value)}
                  value={geoname}
                  className="bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none"
                  placeholder="Input Nama Geografis..."
                />
              </div>
              <hr />
              {/* <div className='w-full relative'>
                            <BiSearchAlt2 className='absolute left-3 top-3'/>
                            <input type={'text'} className='bg-white border rounded-[6px] pl-10 text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Search...'/>
                        </div> */}
              <div className="w-ull">
                <MapContainer
                  center={[-4.508742832943697, 121.81246561664125]}
                  zoom={5}
                  style={{ height: "300px", width: "100%" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                  />
                  <MapClickHandler />
                  {markerPosition && (
                    <Marker position={markerPosition} icon={customIcon} />
                  )}
                  {georadius && (
                    <Circle center={markerPosition} radius={georadius} />
                  )}
                </MapContainer>
              </div>
              <div className="w-full">
                <div className="w-full">
                  <h1 className="text-dark-5 text-[13px] font-[500] mb-[7px]">
                    Lokasi Geografis{" "}
                    <span className="text-[#780000] ml-[4px]">*</span>
                  </h1>
                  <input
                    type={"text"}
                    value={markerPosition}
                    disabled
                    className="bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none"
                    placeholder="Lokasi Geografis..."
                  />
                </div>
              </div>
              <div className="w-full">
                <h1 className="text-dark-5 text-[13px] font-[500] mb-[7px]">
                  Radius <span className="text-[#780000] ml-[4px]">*</span>
                </h1>
                <input
                  type={"number"}
                  min={0}
                  max={50}
                  disabled={!markerPosition ? true : false}
                  value={georadius}
                  onChange={(e) => setGeoRadius(e.target.value)}
                  className="bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none"
                  placeholder="Ex : 10 Meter..."
                />
              </div>
              <div className="flex items-center justify-end gap-[12px] mt-5">
                <button
                  onClick={() => { setShowModalAdd(!showModalAdd); resetForm() }}
                  className="bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]"
                >
                  Batal
                </button>
                <button
                  onClick={postGeoData}
                  className="bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]"
                >
                  Simpan
                </button>
              </div>
            </div>
          }
        />
        <Modal
          activeModal={showModalEdit}
          title={"Edit Jarak Presensi"}
          buttonClose={() => { setShowModalEdit(!showModalEdit); resetForm() }}
          width={"850px"}
          content={
            <div className="space-y-6">
              <div className="relative w-full">
                <select
                  onChange={(e) => setPenempatan(e.target.value)}
                  value={penempatan}
                  className="text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none"
                >
                  <option selected disabled value="">
                    Pilih Penempatan
                  </option>
                  {dataPenempatan &&
                    dataPenempatan.map((val, index) => (
                      <option key={index} value={val.id}>
                        {val.company_name}
                      </option>
                    ))}
                </select>
                <IoMdArrowDropdown className="absolute top-[9px] right-1 text-xl text-grey-medium" />
              </div>
              <div className="w-full">
                <h1 className="text-dark-5 text-[13px] font-[500] mb-[7px]">
                  Nama Geografis
                  <span className="text-[#780000] ml-[4px]">*</span>
                </h1>
                <input
                  type={"text"}
                  value={geoname}
                  onChange={(e) => setGeoName(e.target.value)}
                  className="bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none"
                  placeholder="Nama Geografis..."
                />
              </div>
              <hr />
              {/* <div className='w-full relative'>
                            <BiSearchAlt2 className='absolute left-3 top-3'/>
                            <input type={'text'} className='bg-white border rounded-[6px] pl-10 text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Search...'/>
                        </div> */}
              <div className="w-ull">
                <MapContainer
                  center={[-4.508742832943697, 121.81246561664125]}
                  zoom={5}
                  style={{ height: "300px", width: "100%" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                  />
                  <MapClickHandler />
                  {markerPosition && (
                    <Marker position={markerPosition} icon={customIcon} />
                  )}
                  {georadius && (
                    <Circle center={markerPosition} radius={georadius} />
                  )}
                </MapContainer>
              </div>
              <div className="w-full">
                <div className="w-full">
                  <h1 className="text-dark-5 text-[13px] font-[500] mb-[7px]">
                    Lokasi Geografis{" "}
                    <span className="text-[#780000] ml-[4px]">*</span>
                  </h1>
                  <input
                    type={"text"}
                    value={markerPosition}
                    disabled
                    className="bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none"
                    placeholder="Geo Location..."
                  />
                </div>
              </div>
              <div className="w-full">
                <h1 className="text-dark-5 text-[13px] font-[500] mb-[7px]">
                  Radius<span className="text-[#780000] ml-[4px]">*</span>
                </h1>
                <input
                  type={"number"}
                  min={0}
                  max={50}
                  disabled={!markerPosition ? true : false}
                  value={georadius}
                  onChange={(e) => setGeoRadius(e.target.value)}
                  className="bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none"
                  placeholder="Ex : 10 Meter..."
                />
              </div>
              <div className="flex items-center justify-end gap-[12px] mt-5">
                <button
                  onClick={() => { setShowModalEdit(!showModalEdit); resetForm() }}
                  className="bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]"
                >
                  Batal
                </button>
                <button
                  onClick={updateGeoData}
                  className="bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]"
                >
                  Simpan
                </button>
              </div>
            </div>
          }
        />
        <ModalDelete
          activeModal={showModalDelete}
          buttonClose={() => setShowModalDelete(!showModalDelete)}
          submitButton={deleteGeoData}
        />
      </div>
      <div className="rounded-xl overflow-hidden shadow-lg border-gray-400 border">
        <MapContainer
          center={
            geodata.length === 0
              ? [-4.289663763983576, 122.42769997219564]
              : geodata[0]?.geocode
          }
          zoom={geodata.length === 0 ? 6 : 13}
          style={{ height: "400px", width: "100%", zIndex: "10" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="Humanusia Using Leaflet"
          />
          {Object.values(geodata).map((data, index) => {
            const geocodeString = data.geocode;
            const geocodeArray = JSON.parse(geocodeString);
            return (
              <div key={index}>
                <Marker
                  position={
                    data.length === 0
                      ? [-4.289663763983576, 122.42769997219564]
                      : geocodeArray
                  }
                  icon={customIcon}
                >
                  <Popup>{data.geoname}</Popup>
                </Marker>
                <Circle
                  center={
                    data.length === 0
                      ? [-4.289663763983576, 122.42769997219564]
                      : geocodeArray
                  }
                  radius={data.georadius}
                />
              </div>
            );
          })}
        </MapContainer>
      </div>
      <div className="bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]">
        <div className="flex flex-col lg:flex-row items-center justify-end">
          {/* <div>
                    <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border rounded-lg'>
                        <CgTrashEmpty className='text-lg' />
                        <h1>Delete</h1>
                    </button>
                </div> */}
          <div className="flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]">
            <div className="relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]">
              <BiSearch className="absolute left-[12px] text-lg" />
              <input
                type="text"
                placeholder={
                  languages.language === "id" ? "Cari Nama Geografis..." : "Search..."
                }
                onChange={(e) => setSearch(e.target.value)}
                className="outline-none text-[10px] w-full"
              />
            </div>
            <button
              onClick={() => setShowModalAdd(!showModalAdd)}
              className="bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]"
            >
              <AiOutlinePlus className="text-xl text-white" />
              <h1 className="text-dark-9 text-sm font-[500]">
                {languages.language === "id"
                  ? "Tambah Jarak Presensi"
                  : "Add Absence Distance"}
              </h1>
            </button>
          </div>
        </div>
        <div className="mt-[44px] overflow-auto">
          <table className="w-full space-y-[10px]">
            <div className="flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]">
              <div className="flex items-center gap-[15px] min-w-[200px] max-w-[200px]">
                <h1 className="text-dark-5 text-xs font-[600]">
                  {languages.language === "id" ? "Nama Geografis" : "Geo Name"}
                </h1>
              </div>
              <div className="flex items-center gap-[15px] min-w-[350px] max-w-[350px]">
                <h1 className="text-dark-5 text-xs font-[600]">
                  {languages.language === "id"
                    ? "Lokasi Geografis"
                    : "Geo Location"}
                </h1>
              </div>
              <div className="flex items-center gap-[15px] min-w-[100px] max-w-[100px]">
                <h1 className="text-dark-5 text-xs font-[600]">
                  {languages.language === "id" ? "Radius" : "Geo Radius"}
                </h1>
              </div>
              <div className="flex items-center justify-center gap-[15px] w-full">
                <h1 className="text-dark-5 text-xs font-[600]">
                  {languages.language === "id" ? "Aksi" : "Action"}
                </h1>
              </div>
            </div>
            {Object.values(geodata).map((data, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]"
                >
                  <div className="min-w-[200px] max-w-[200px]">
                    <h1 className="text-dark-5 text-xs font-[600] line-clamp-1">
                      {data.geoname}
                    </h1>
                  </div>
                  <div className="min-w-[350px] max-w-[350px]">
                    <h1 className="text-dark-5 text-xs font-[600] line-clamp-1">
                      {data.geocode}
                    </h1>
                  </div>
                  <div className="min-w-[100px] max-w-[100px]">
                    <h1 className="text-dark-5 text-xs font-[600] line-clamp-1">
                      {data.georadius} Meter
                    </h1>
                  </div>
                  <div className="flex items-center justify-center w-full gap-[12px]">
                    <button
                      onClick={() => getGeoDataById(data.id)}
                      className="w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center"
                    >
                      <HiOutlinePencil className="text-[#003049]" />
                    </button>
                    <button
                      onClick={() => deleteGeoDataId(data.id)}
                      className="w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center"
                    >
                      <CgTrashEmpty className="text-[#003049]" />
                    </button>
                  </div>
                </div>
              );
            })}
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          limitData={geodata?.length}
          lengthData={totalCount}
          onPageChange={handlePageChange}
          onPrevChange={handlePrevChange}
          onNextChange={handleNextChange}
        />
      </div>
    </>
  );
}

export default AturLokasi;
