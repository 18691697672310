import React from 'react'

const Lembur = ({ data, month }) => {
    const formatRupiah = (number) => {
        // Menggunakan regex untuk menambahkan separator titik setiap 3 digit
        return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return (
        <table className='w-full space-y-[10px]'>
            <div className='bg-[#EBF7FF] rounded-[3px] border border-[#F1F1F1] p-[9px] flex items-center gap-[20px]'>
                <div className={`flex items-center gap-[11px] min-w-[50px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>No.</h1>
                </div>
                {/* <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>NIP</h1>
                </div> */}
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>NAMA</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>GOLONGAN</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>JML. JAM HARI KERJA</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>JML. JAM HARI LIBUR</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>JML. HARI MAKAN</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>JML. UANG LEMBUR</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>JML. UANG MAKAN LEMBUR</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>JML. UANG LEMBUR & UANG MAKAN LEMBUR</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>POTONGAN PPH</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>JML.BERSIH</h1>
                </div>
            </div>
            <div className='space-y-[10px]'>
                {data && data.map((value, index) => (
                    <div key={index} className='bg-white rounded-[3px] p-[9px] flex items-center gap-[20px]'>
                        <div className='flex items-center gap-[11px] min-w-[50px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{index + 1}</h1>
                        </div>
                        {/* <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.nip}</h1>
                        </div> */}
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.fullname}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.golongan}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.jumlah_jam_hari_kerja}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.jumlah_jam_hari_libur}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.jumlah_jam_hari_libur}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.jumlah_uang_lembur)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.jumlah_uang_makan_lembur)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.jumlah_dari_kolom)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.potongan)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.jumlah_bersih)}</h1>
                        </div>
                    </div>
                ))}
            </div>
        </table>
    )
}

export default Lembur