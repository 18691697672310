import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Api from '../../Api'
import Pagination from '../Pagination'

const TableTraining = ({ startDate, endDate, name, isAsc, exportData, limitData }) => {
    const [data, setData] = useState([])

    const [refresh, setRefresh] = useState(false)
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const getDataPelatihan = async () => {
        try {
            const response = await Api.GetPelatihan(localStorage.getItem('hris-token'), currentPage, limitData, startDate, endDate, name, isAsc)
            setData(response.data.results.data)
            setCurrentPage(response.data.results.currentPage)
            setTotalPages(response.data.results.totalPages)
            setTotalCount(response.data.results.totalCount)
            exportData(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getDataPelatihan()
        setRefresh(false)
    }, [refresh, startDate, endDate, name, isAsc, limitData])
    return (
        <div className='rounded shadow'>
            <div className='overflow-auto'>
                <table className='w-full space-y-[10px] text-dark-5'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nama Pegawai</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nama Pengembangan Kompetensi</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Jalur Pengembangan Kompetensi</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nomor SK/Sertifikat</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal SK/Sertifikat</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal Mulai</h1>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal Selesai</h1>
                        </div>
                    </div>
                    {Object.values(data).map((item, idx) => (
                        <>
                            <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.user?.fullname ?? '-'}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.nama ?? '-'}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.jalur ?? '-'}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.nomor_sk ?? '-'}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.tanggal_sk ?? '-'}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.tanggal_mulai ? moment(item.tanggal_mulai).format('DD/MM/YYYY') : '-'}</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.tanggal_selesai ? moment(item.tanggal_selesai).format('DD/MM/YYYY') : '-'}</h1>
                                </div>
                            </div>
                        </>
                    ))}

                </table>
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                limitData={limitData}
                lengthData={totalCount}
                onPageChange={handlePageChange}
                onPrevChange={handlePrevChange}
                onNextChange={handleNextChange}
            />
        </div>
    )
}

export default TableTraining