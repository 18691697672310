import moment from 'moment'
import React from 'react'
import languages from '../../utils/languages'

const InboxDetailPermission = ({EmployeeName, JobPosition, PermissionReason, StartDate, EndDate, Duration, Address, onClickDecline, onClickApprove, buttonHide}) => {
  return (
    <div>
        <div className='space-y-[40px] w-[full]'>
            <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-full'>
                <div className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 text-[11px] text-dark-5 gap-y-[20px]">
                    <h1 className='font-semibold'>Nama Pegawai</h1>
                    <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {EmployeeName ? EmployeeName : '-'}</h1>
                    <h1 className='font-semibold'>Jabatan</h1>
                    <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {JobPosition? JobPosition : '-'}</h1>
                    <h1 className='font-semibold'>Alasan Izin</h1>
                    <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {PermissionReason ? PermissionReason : '-'}</h1>
                    <h1 className='font-semibold'>Tanggal Mulai</h1>
                    <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {StartDate ? moment(StartDate).format('DD-MM-YYYY') : '-'}</h1>
                    <h1 className='font-semibold'>Tanggal Selesai</h1>
                    <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {EndDate ? moment(EndDate).format('DD-MM-YYYY') : '-'}</h1>
                    <h1 className='font-semibold'>Durasi Izin</h1>
                    <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {Duration ? Duration : '-'}</h1>
                    <h1 className='font-semibold'>Alamat Selama Izin</h1>
                    <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {Address ? Address : '-'}</h1>
                </div>
            </div>
        </div>
        {buttonHide &&
            <div className='flex items-center justify-end gap-[20px] mt-10'>
                <button onClick={onClickDecline} className='bg-[#C1121F] text-dark-9 text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                    Tolak
                </button>
                <button onClick={onClickApprove} className='bg-[#04BB00] text-dark-9 text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                    Setujui
                </button>
            </div>
        }
    </div>
  )
}

export default InboxDetailPermission