import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function BarChart() {
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['This is example for question number one ', 'This is loongg example for question number two ', 'This is little long example for question number three', 'This is longer than previous example for question number three', 'This is longest than previous example for question number Four', 'This is most long than previous example for question number five'],
    },
    colors: ['#003049', '#304676', '#219EBC', '#669BBC', '#ED6F51', '#FF525F'],
  });

  const [series, setSeries] = useState([{ data: [400, 430, 448, 470, 540, 580] }]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
}

export default BarChart;
