import moment from 'moment'
import React, { useState } from 'react'
import { Navbar, Sidebar } from '../../../components'
import { BsCheck } from 'react-icons/bs'
import { BiTime } from 'react-icons/bi'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { IoClose } from 'react-icons/io5'
import { IoMdArrowDropdown } from 'react-icons/io'
import { AiOutlineEye } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const Approval = () => {

    const navigate = useNavigate()
    const approvalStatus = 'Waiting'
    const [documentType, setDocumentType] = useState('Document')

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Approval List'} LinkBack={'/inbox'}/>
                <div className='space-y-[24px] bg-white border px-[16px] py-[30px] rounded-[12px]'>
                    <div className='border-b-2 flex items-center '>
                        <button onClick={ () => setDocumentType('Document') } className={`${documentType === 'Document' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Document</button>
                        <button onClick={ () => setDocumentType('Time') } className={`${documentType === 'Time' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Time Management</button>
                        <button onClick={ () => setDocumentType('Finance') } className={`${documentType === 'Finance' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Finance</button>
                    </div>
                    <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                        <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                            <div className='w-full lg:w-auto'>
                                <h1 className='text-dark-3 text-[16px] font-[500]'>
                                    {
                                        documentType === 'Document' ?
                                        'Document Management System'
                                        : documentType === 'Finance' ?
                                        'Finance'
                                        : documentType === 'Time' ? 
                                        'Time Management'
                                        : null
                                    }
                                </h1>
                                <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                    {
                                        documentType === 'Document' ?
                                        'list of approval in document management system'
                                        : documentType === 'Finance' ?
                                        'list of approval in Finance'
                                        : documentType === 'Time' ?
                                        'list of approval in Time Management'
                                        : null
                                    }
                                </h1>
                            </div>
                        </div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>Employee Name</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>Form Filling Timestamp</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>Type</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>Approved</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>Action</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>Detail</h1>
                                    </div>
                                </div>
                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[300px] max-w-[300px]'>
                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>Muhammad Faizal</h1>
                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] truncate'>ITX</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>{moment().format('DD MMMM YYYY hh:mm')}</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-xs font-[500] truncate'>Overtime</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px] flex items-center justify-center'>
                                        {
                                            approvalStatus === 'Approved' ?
                                            <div className='flex flex-col items-center justify-center space-y-1'>
                                                <div className='bg-[#00AB9A] w-[25px] h-[25px] rounded-full flex items-center justify-center'>
                                                    <BsCheck className='text-white'/>
                                                </div>
                                                <button className='text-dark-5 text-[7px] font-[500] underline underline-offset-1'>Detail</button>
                                            </div>
                                            : approvalStatus === 'Waiting' ?
                                            <div className='flex flex-col items-center justify-center space-y-1'>
                                                <div className='bg-base-yellow w-[25px] h-[25px] rounded-full flex items-center justify-center'>
                                                    <BiTime className='text-white'/>
                                                </div>
                                                <button className='text-dark-5 text-[7px] font-[500] underline underline-offset-1'>Detail</button>
                                            </div>
                                            :
                                            <div className='flex flex-col items-center justify-center space-y-1'>
                                                <div className='bg-[#C1121F] w-[25px] h-[25px] rounded-full flex items-center justify-center'>
                                                    <IoClose className='text-white'/>
                                                </div>
                                                <button className='text-dark-5 text-[7px] font-[500] underline underline-offset-1'>Detail</button>
                                            </div>
                                        }
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px] flex items-center justify-center'>
                                        <button className='p-[10px] bg-[#CECECE78] rounded-[6px] flex items-center justify-between gap-[12px]'>
                                            <h1 className='text-dark-3 text-[12px] font-[500]'>Action</h1>
                                            <IoMdArrowDropdown/>
                                        </button>
                                    </div>
                                    <div className='w-full flex items-center justify-center'>
                                        <button onClick={ () => navigate('/inbox-detail') } className='p-[10px] bg-[#CEDFEA] rounded-[6px] flex items-center justify-between gap-[12px]'>
                                            <AiOutlineEye/>
                                        </button>
                                    </div>
                                </div>
                            </table>
                        </div>
                        <div className='flex items-center justify-between mt-[30px]'>
                            <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                            <div className='flex items-center gap-[8px]'>
                                <MdKeyboardArrowLeft className='text-[#A098AE]'/>
                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                    <h1 className='text-[#780000] text-[10px]'>1</h1>
                                </div>
                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                    <h1 className='text-white text-[10px]'>2</h1>
                                </div>
                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                    <h1 className='text-[#780000] text-[10px]'>3</h1>
                                </div>
                                <MdKeyboardArrowRight className='text-[#A098AE]'/>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default Approval