import React, { useEffect, useState } from 'react'
import { Modal } from '../../../../../components'
import Api from '../../../../../Api'
import moment from 'moment'
import toast from 'react-hot-toast'
import languages from '../../../../../utils/languages'
import { IoMdArrowDropdown } from 'react-icons/io'
import { HiOutlinePencil } from 'react-icons/hi'

const PenilaianRekanKerja = ({ idSkp }) => {
    // console.log("ini idskp", idSkp)
    const [dataPerilaku, setDataPerilaku] = useState([])
    const [penilaiId, setPenilaiId] = useState('')
    const [userId, setUserId] = useState('')
    const [dataEmployee, setDataEmployee] = useState([])
    const [showAddFeedback, setShowAddFeedback] = useState()
    const [feedback, setFeedback] = useState('')
    const [performanceSettingId, setPerformanceSettingId] = useState('')
    const [month, setMonth] = useState(moment().format('M'))

    const [refreshPerilaku, setRefreshPerilaku] = useState(false)

    const getPenilaianPerilakuKerja = async () => {
        try {
            await Api.GetPenilaianPerilakuKerja(localStorage.getItem('hris-token'), userId, penilaiId, month).then((response) => {
                // console.log(response.data.results.data)
                setDataPerilaku(response.data.results.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const postPenilaianPerilakuKerja = async () => {
        try {
            const data = {
                "userId": userId,
                "penilaiId": penilaiId,
                "performanceSettingId": performanceSettingId,
                "feedback": feedback,
                "isPenilaiAtasan": false,
                "bulan": month
            }
            await Api.PostPenilaianPerilakuKerja(localStorage.getItem('hris-token'), data, userId, penilaiId, moment().format('M')).then((response) => {
                setRefreshPerilaku(true)
                toast.success('Berhasil Menambahkan Data')
                setShowAddFeedback(!showAddFeedback)
                setFeedback("")
            }).catch((error) => {
                console.log(error)
                const e = error.response.data
                for (let i = 0; i < e.length; i++) {
                    toast.error(e[i])
                }
            })

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }
    const fetchUser = async () => {
        try {
            await Api.Fetch(localStorage.getItem('hris-token')).then((response) => {
                setPenilaiId(response.data.results.user.id)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getEmployee = async () => {
        try {
            await Api.GetDropdownEmployee(localStorage.getItem('hris-token')).then((response) => {
                // console.log(response.data.data)
                setDataEmployee(response.data.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (val) => {
        setShowAddFeedback(!showAddFeedback); 
        setPerformanceSettingId(val.id); 
        setFeedback(val.feedback)
    }
    useEffect(() => {
        fetchUser()
        getEmployee()
        getPenilaianPerilakuKerja()
        setRefreshPerilaku(false)
    }, [userId, refreshPerilaku, month])
    return (
        <>
            <div className='relative'>
                <Modal
                    activeModal={showAddFeedback}
                    title={'Add Feedback'}
                    buttonClose={() => setShowAddFeedback(!showAddFeedback)}
                    width={'695px'}
                    content={
                        <div className='space-y-6'>

                            <div className='space-y-[5px]'>
                                <h1 className="text-neutral-500 text-[11px] font-medium">Feedback</h1>
                                <div className='w-full'>
                                    <div className="mb-[12px]">
                                        <div className='relative w-full'>
                                            <select onChange={(e) => setFeedback(e.target.value)} value={feedback} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected disabled value="">Select Feedback</option>
                                                <option value="Diatas Ekspektasi">Diatas Ekspektasi</option>
                                                <option value="Sesuai Ekspektasi">Sesuai Ekspektasi</option>
                                                <option value="Dibawah Ekspektasi">Dibawah Eskpektasi</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => setShowAddFeedback(!showAddFeedback)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                                <button onClick={postPenilaianPerilakuKerja} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Simpan</button>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='border rounded-xl py-[24px] px-[23px]'>
                <div className='space-y-[5px]'>
                    <div className='flex justify-between gap-3'>
                        <div className='w-full'>
                            <h1 className="text-neutral-500 text-[11px] font-medium">{languages.language === 'id' ? "Pilih Pegawai" : "Select Employee"}</h1>
                            <div className='relative w-full'>
                                <select onChange={(e) => setUserId(e.target.value)} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                    <option selected disabled value="">{languages.language === 'id' ? "Pilih Pegawai..." : "Select Employee..."}</option>
                                    {dataEmployee && dataEmployee.map((val, index) => {
                                        if (val.id !== penilaiId && val.role === "USER") {
                                            return (
                                                <option key={index} value={val.id}>{val.fullname}</option>
                                            )
                                        }
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className="text-neutral-500 text-[11px] font-medium">Periode</h1>
                            <div className='relative w-full'>
                                <select onChange={(e) => setMonth(e.target.value)} value={month} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                    <option selected disabled value="">Pilih Bulan...</option>
                                    <option value={1}>Januari</option>
                                    <option value={2}>Februari</option>
                                    <option value={3}>Maret</option>
                                    <option value={4}>April</option>
                                    <option value={5}>Mei</option>
                                    <option value={6}>Juni</option>
                                    <option value={7}>Juli</option>
                                    <option value={8}>Agustus</option>
                                    <option value={9}>September</option>
                                    <option value={10}>Oktober</option>
                                    <option value={11}>November</option>
                                    <option value={12}>Desember</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-neutral-500 text-[11px] font-medium'>Tahun</h1>
                            <input type="text" disabled value={moment().format("YYYY")} className='py-[10px] px-[16px] border rounded-md text-gray-500 text-xs w-full' />
                        </div>
                    </div>
                </div>
            </div>
            {dataPerilaku && dataPerilaku.map((evaluation, evaluationIndex) => (
                <div key={evaluationIndex}>
                    <h1 className="text-zinc-700 text-xl font-semibold">{evaluation.evaluation_name}</h1>

                    <div className='mt-[44px] mb-[44px] overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[80px] max-w-[80px]'>
                                    <h1 className='text-grey-thick text-xs font-medium'>No</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[400px] max-w-[400px]'>
                                    <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Berorientasi Pada Pelayanan" : "Berorientasi Pada Pelayanan"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Feedback" : "Feedback"}</h1>
                                </div>
                                <div className='flex items-center justify-center gap-[15px] w-full'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                </div>
                            </div>
                            {evaluation && evaluation.settings.map((setting, settingIndex) => (
                                <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[80px] max-w-[80px]'>
                                        <h1 className='text-grey-thick text-xs font-medium'>{settingIndex + 1}</h1>
                                    </div>
                                    <div className='min-w-[400px] max-w-[400px]'>
                                        <h1 className='text-grey-thick text-xs font-medium'>{setting.setting_name}</h1>
                                        <ul className='text-grey-thick text-xs font-medium list-disc ml-4'>
                                            {setting && setting.points.map((point, pointIndex) => (
                                                <li key={pointIndex}>{point.point_name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className={`${setting.feedback === 'Sesuai Ekspektasi' ? 'bg-[#51A7F5]' : setting.feedback === 'Dibawah Ekspektasi' ? 'bg-[#EA5A54]' : setting.feedback === 'Diatas Ekspektasi' ? 'bg-[#AED96C]' : ''} text-white font-medium text-xs rounded-lg text-center px-[8px] py-[8px] w-fit`}>{setting.feedback}</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <button onClick={() => handleChange(setting)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-[#003049]' />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </table>
                    </div>
                </div>
            ))}
        </>
    )
}

export default PenilaianRekanKerja