import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiSearch } from 'react-icons/bi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { CiShare1 } from 'react-icons/ci'
import { Link, useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import { Navbar } from '../../../../components'
import languages from '../../../../utils/languages'

const Polling = () => {

  const [index, setIndex] = useState(0)
  const [triggerOptionActivePolling, setTriggerOptionActivePolling] = useState(false)
  const [triggerOptionClosedPolling, setTriggerOptionClosedPolling] = useState(false)
  const navigate = useNavigate()
  const [dataActivePolling, setDataActivePolling] = useState('')
  const [dataClosePolling, setDataClosePolling] = useState('')



  const getActivePolling = async () => {
    try {
      const response = await Api.GetActivePolling(localStorage.getItem('hris-token'))
      console.log(response, 'dataActivePolling')
      setDataActivePolling(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getClosePolling = async () => {
    try {
      const response = await Api.GetClosePolling(localStorage.getItem('hris-token'))
      console.log(response, 'dataClosePolling')
      setDataClosePolling(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }





  useEffect(() => {
    getActivePolling()
    getClosePolling()
  }, [])


  return (
    <div>
      <div className='bg-[#F8F9FB] min-h-screen'>
        <div className='flex px-[30px] py-[35px] gap-[30px]'>
          <div className='w-full space-y-[24px]'>
            <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Poling" : "Polling"} LinkBack={'/dashboard'} />
            <div className='space-y-[65px] bg-white px-[55px] py-[43px] rounded-[12px] border'>

              <div className='flex items-center justify-start gap-[24px]'>
                <button onClick={() => setIndex(0)} className={`border border-neutral-400 rounded-lg px-[8px] py-[7px] ${index === 0 ? 'bg-blue-thick text-white' : 'bg-white text-blue-thick'} font-medium`}>{languages.language === 'id' ? "Poling Aktif" : "Active Polling"}</button>
                <button onClick={() => setIndex(1)} className={`border border-neutral-400 rounded-lg px-[8px] py-[7px] ${index === 1 ? 'bg-blue-thick text-white' : 'bg-white text-blue-thick'} font-medium`}>{languages.language === 'id' ? "Poling Ditutup" : "Closed Polling"}</button>
              </div>

              <div>
                <h1 className='mb-[39px] text-[20px] font-semibold text-grey-primer'>{index === 0 ? languages.language === 'id' ? "Poling Aktif" : "Active Polling" : languages.language === 'id' ? "Poling Ditutup" : "Closed Polling"}</h1>
                {index === 0 ? (
                  <>
                    <div className='items-center flex justify-between  mb-[16px]'>
                      <input type="date" className='py-[13px] px-[10px] text-xs rounded-lg bg-grey-scale text-[#8E95A2]' />
                      <div className='flex items-center justify-center gap-[8px]'>
                        <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                          <BiSearch className='absolute left-[12px] text-lg' />
                          <input type="text" placeholder='Search Polling Name' className='outline-none text-[10px] w-full' />
                        </div>
                        {
                          localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ?
                            <Link to={'/dashboard/create-polling'}>
                              <button className='flex gap-[4px] items-center px-[8px] py-[7px] text-[14px] font-medium bg-blue-thick rounded-lg border text-white'>
                                <AiOutlinePlus className='text-lg' />
                                <h1>{languages.language === 'id' ? "Poling Baru" : "New Polling"}</h1>
                              </button>
                            </Link>
                            : null
                        }
                      </div>
                    </div>

                    <div className='border w-full rounded-lg py-[21px] px-[22px] mb-[12px]'>
                      <div className='flex items-center justify-between'>

                        <div className='flex gap-[16px] items-center'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                            <path d="M12.375 19.125C12.375 19.5228 12.217 19.9044 11.9357 20.1857C11.6544 20.467 11.2728 20.625 10.875 20.625C10.4772 20.625 10.0956 20.467 9.81434 20.1857C9.53304 19.9044 9.375 19.5228 9.375 19.125C9.375 18.7272 9.53304 18.3456 9.81434 18.0643C10.0956 17.783 10.4772 17.625 10.875 17.625C11.2728 17.625 11.6544 17.783 11.9357 18.0643C12.217 18.3456 12.375 18.7272 12.375 19.125Z" fill="#003049" />
                            <path d="M8.0625 3.75C6.91875 3.75 5.82185 4.20435 5.0131 5.0131C4.20435 5.82185 3.75 6.91875 3.75 8.0625V24.1875C3.75 25.3312 4.20435 26.4281 5.0131 27.2369C5.82185 28.0456 6.91875 28.5 8.0625 28.5H24.1875C25.3312 28.5 26.4281 28.0456 27.2369 27.2369C28.0456 26.4281 28.5 25.3312 28.5 24.1875V8.0625C28.5 6.91875 28.0456 5.82185 27.2369 5.0131C26.4281 4.20435 25.3312 3.75 24.1875 3.75H8.0625ZM14.25 19.125C14.25 20.0201 13.8944 20.8785 13.2615 21.5115C12.6285 22.1444 11.7701 22.5 10.875 22.5C9.97989 22.5 9.12145 22.1444 8.48851 21.5115C7.85558 20.8785 7.5 20.0201 7.5 19.125C7.5 18.2299 7.85558 17.3715 8.48851 16.7385C9.12145 16.1056 9.97989 15.75 10.875 15.75C11.7701 15.75 12.6285 16.1056 13.2615 16.7385C13.8944 17.3715 14.25 18.2299 14.25 19.125ZM7.5 11.4375C7.5 10.92 7.92 10.5 8.4375 10.5H23.8125C24.0611 10.5 24.2996 10.5988 24.4754 10.7746C24.6512 10.9504 24.75 11.1889 24.75 11.4375C24.75 11.6861 24.6512 11.9246 24.4754 12.1004C24.2996 12.2762 24.0611 12.375 23.8125 12.375H8.4375C7.92 12.375 7.5 11.955 7.5 11.4375ZM16.6875 18.375H23.8125C24.0611 18.375 24.2996 18.4738 24.4754 18.6496C24.6512 18.8254 24.75 19.0639 24.75 19.3125C24.75 19.5611 24.6512 19.7996 24.4754 19.9754C24.2996 20.1512 24.0611 20.25 23.8125 20.25H16.6875C16.4389 20.25 16.2004 20.1512 16.0246 19.9754C15.8488 19.7996 15.75 19.5611 15.75 19.3125C15.75 19.0639 15.8488 18.8254 16.0246 18.6496C16.2004 18.4738 16.4389 18.375 16.6875 18.375Z" fill="#003049" />
                            <path d="M11.4377 31.8747C10.7362 31.8752 10.0452 31.7044 9.42474 31.3771C8.80428 31.0498 8.27313 30.5759 7.87744 29.9967C7.93894 29.999 8.00044 29.9997 8.06269 29.9997H24.1877C25.7293 29.9997 27.2077 29.3873 28.2977 28.2973C29.3878 27.2072 30.0002 25.7288 30.0002 24.1872V8.0622C30.0002 8.0007 29.9987 7.93845 29.9972 7.87695C30.5764 8.27264 31.0503 8.80379 31.3776 9.42425C31.7049 10.0447 31.8757 10.7357 31.8752 11.4372V24.1872C31.8752 28.4322 28.4327 31.8747 24.1877 31.8747H11.4377Z" fill="#003049" />
                          </svg>
                          <div>
                            <h1 className='text-[15px] font-medium text-dark-2'>Selection of outbound places</h1>
                            <h1 className='text-[12px] text-dark-5'>10 Submission</h1>
                          </div>
                        </div>

                        <div className='text-xl gap-[18px] flex font-bold items-center'>
                          <button onClick={() => navigate('/dashboard/polling/polling-form')}> <CiShare1 /> </button>
                          <div onClick={() => setTriggerOptionActivePolling(!triggerOptionActivePolling)} className='relative'>
                            <BsThreeDotsVertical />
                            {triggerOptionActivePolling &&
                              <>
                                <div onClick={() => navigate('/dashboard/polling/detail-polling')} className='border rounded-lg shadow-lg px-[17px] py-[14px] absolute right-0 w-[192px] bg-white text-dark-3 text-[10px] font-medium' style={{ zIndex: '999' }}>
                                  <button to={'detail-polling'} className='w-full text-start mb-[12px]'>{languages.language === 'id' ? "Detail" : "Detail"} </button>
                                  <button className='w-full text-start mb-[12px]'>{languages.language === 'id' ? "Hapus" : "Delete"}</button>
                                  <button className='w-full text-start'>{languages.language === 'id' ? "Salin Tautan" : "Copy Link"}</button>
                                </div>
                              </>}
                          </div>
                        </div>

                      </div>
                    </div>
                  </>
                ) : (
                  <div className='border w-full rounded-lg py-[21px] px-[22px]'>
                    <div className='flex items-center justify-between'>

                      <div className='flex gap-[16px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                          <path d="M12.375 19.125C12.375 19.5228 12.217 19.9044 11.9357 20.1857C11.6544 20.467 11.2728 20.625 10.875 20.625C10.4772 20.625 10.0956 20.467 9.81434 20.1857C9.53304 19.9044 9.375 19.5228 9.375 19.125C9.375 18.7272 9.53304 18.3456 9.81434 18.0643C10.0956 17.783 10.4772 17.625 10.875 17.625C11.2728 17.625 11.6544 17.783 11.9357 18.0643C12.217 18.3456 12.375 18.7272 12.375 19.125Z" fill="#003049" />
                          <path d="M8.0625 3.75C6.91875 3.75 5.82185 4.20435 5.0131 5.0131C4.20435 5.82185 3.75 6.91875 3.75 8.0625V24.1875C3.75 25.3312 4.20435 26.4281 5.0131 27.2369C5.82185 28.0456 6.91875 28.5 8.0625 28.5H24.1875C25.3312 28.5 26.4281 28.0456 27.2369 27.2369C28.0456 26.4281 28.5 25.3312 28.5 24.1875V8.0625C28.5 6.91875 28.0456 5.82185 27.2369 5.0131C26.4281 4.20435 25.3312 3.75 24.1875 3.75H8.0625ZM14.25 19.125C14.25 20.0201 13.8944 20.8785 13.2615 21.5115C12.6285 22.1444 11.7701 22.5 10.875 22.5C9.97989 22.5 9.12145 22.1444 8.48851 21.5115C7.85558 20.8785 7.5 20.0201 7.5 19.125C7.5 18.2299 7.85558 17.3715 8.48851 16.7385C9.12145 16.1056 9.97989 15.75 10.875 15.75C11.7701 15.75 12.6285 16.1056 13.2615 16.7385C13.8944 17.3715 14.25 18.2299 14.25 19.125ZM7.5 11.4375C7.5 10.92 7.92 10.5 8.4375 10.5H23.8125C24.0611 10.5 24.2996 10.5988 24.4754 10.7746C24.6512 10.9504 24.75 11.1889 24.75 11.4375C24.75 11.6861 24.6512 11.9246 24.4754 12.1004C24.2996 12.2762 24.0611 12.375 23.8125 12.375H8.4375C7.92 12.375 7.5 11.955 7.5 11.4375ZM16.6875 18.375H23.8125C24.0611 18.375 24.2996 18.4738 24.4754 18.6496C24.6512 18.8254 24.75 19.0639 24.75 19.3125C24.75 19.5611 24.6512 19.7996 24.4754 19.9754C24.2996 20.1512 24.0611 20.25 23.8125 20.25H16.6875C16.4389 20.25 16.2004 20.1512 16.0246 19.9754C15.8488 19.7996 15.75 19.5611 15.75 19.3125C15.75 19.0639 15.8488 18.8254 16.0246 18.6496C16.2004 18.4738 16.4389 18.375 16.6875 18.375Z" fill="#003049" />
                          <path d="M11.4377 31.8747C10.7362 31.8752 10.0452 31.7044 9.42474 31.3771C8.80428 31.0498 8.27313 30.5759 7.87744 29.9967C7.93894 29.999 8.00044 29.9997 8.06269 29.9997H24.1877C25.7293 29.9997 27.2077 29.3873 28.2977 28.2973C29.3878 27.2072 30.0002 25.7288 30.0002 24.1872V8.0622C30.0002 8.0007 29.9987 7.93845 29.9972 7.87695C30.5764 8.27264 31.0503 8.80379 31.3776 9.42425C31.7049 10.0447 31.8757 10.7357 31.8752 11.4372V24.1872C31.8752 28.4322 28.4327 31.8747 24.1877 31.8747H11.4377Z" fill="#003049" />
                        </svg>
                        <div>
                          <h1 className='text-[15px] font-medium text-dark-2'>Selection of outbound places</h1>
                          <h1 className='text-[12px] text-dark-5'>10 Submission</h1>
                        </div>
                      </div>

                      <div className='text-xl gap-[18px] flex font-bold'>
                        <button onClick={() => navigate('/dashboard/polling/polling-form')}> <CiShare1 /> </button>
                        <div onClick={() => setTriggerOptionClosedPolling(!triggerOptionClosedPolling)} className='relative'>
                          <BsThreeDotsVertical />
                          {triggerOptionClosedPolling &&
                            <>
                              <div className='border rounded-lg shadow-lg px-[17px] py-[15px] absolute right-0 w-[192px] bg-white text-dark-3 text-[10px] font-medium' style={{ zIndex: '999' }}>
                                <button to={'detail-polling'} className='w-full text-start mb-[12px]'>{languages.language === 'id' ? "Detail" : "Detail"} </button>
                                <button className='w-full text-start mb-[12px]'>{languages.language === 'id' ? "Hapus" : "Delete"}</button>
                                <button className='w-full text-start'>{languages.language === 'id' ? "Salin Tautan" : "Copy Link"}</button>
                              </div>
                            </>}
                        </div>
                      </div>

                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Polling