import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar } from '../../../../components'
import languages from '../../../../utils/languages'

const TimeManagementSetting = () => {
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
      <div className='w-full space-y-[24px] overflow-hidden'>
        <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Pengaturan Manajemen Waktu" : "Time Management Setting"} LinkBack={'/dashboard'} />
        <h1 className="text-zinc-800 text-2xl font-semibold">{languages.language === 'id' ? "Pengaturan Manajemen Waktu" : "Time Management Setting"}</h1>
        <div className='space-y-[12px]'>
          <Link to={'/attendance-setting'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
            <h1 className="w-[550px] text-black text-xl font-medium text-start">{languages.language === 'id' ? "Pengaturan Kehadiran" : "Attendance Setting"}</h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
          <Link to={'/time-management/leave-setting'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
            <h1 className="w-[550px] text-black text-xl font-medium text-start">{languages.language === 'id' ? "Pengaturan Cuti" : "Time Off Setting"}</h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
          <Link to={'/official-work-travel'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
            <h1 className="w-[550px] text-black text-xl font-medium text-start">{languages.language === 'id' ? "Pengaturan Perjalanan Dinas" : "Official work Travel"}</h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
          <Link to={'/pengaturan-hari-libur'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
            <h1 className="w-[550px] text-black text-xl font-medium text-start">Pengaturan Hari Libur</h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9.5 6L15.5 12L9.5 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TimeManagementSetting