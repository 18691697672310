import html2pdf from 'html2pdf.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../Api';
import { LogoOmbudsman } from '../assets';
import { AiFillCheckCircle } from 'react-icons/ai';

function ExportCuti() {
    const { id } = useParams()

    const [data, setData] = useState([])
    const [reportTo, setReportTo] = useState([])
    const [pejabatBerwenang, setPejabatBerwenang] = useState([])

    const getDataCutiById = async () => {
        try {
            await Api.getExportLeave(localStorage.getItem('hris-token'), id).then((response) => {
                console.log(response.data.results)
                setData(response.data.results)
                setReportTo(response.data.results.reportTo)
                setPejabatBerwenang(response.data.results.pejabatYangBerwenang)
                setTimeout(() => {
                    exportToPDF()
                }, 2000);
            }).catch((e) => {
                console.log(e)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const exportToPDF = () => {
        console.log("export")
        const content = document.getElementById('cuti')
        const options = {
            margin: 10,
            filename: 'FORM-CUTI.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
        };

        html2pdf().from(content).set(options).save();
        // const report = new jsPDF('portrait','pt', 'a4')
        // report.html(Template).then(()=>{
        //     report.save('report.pdf')
        // })
    };
    useEffect(() => {
        getDataCutiById()
    }, [])
    return (
        <>
            <div id="cuti" className='font-export'>
                {/* Logo */}
                <p className="m-0 text-center leading-normal text-[9px]">
                    <img
                        src={LogoOmbudsman}
                        width={80}
                        height={80}
                        alt=""
                        className="m-0 mx-auto block"
                    />
                </p>
                {/* Nomor Surat */}
                <p className="m-0 text-center leading-normal text-[9px]">
                    <strong className="font-sans">
                        Nomor : KP.11.02/{moment(data.createdAt).format('YYYY')}/{data.nomor}
                    </strong>
                </p>

                <div className='grid grid-cols-2 w-[532.35pt] mx-auto'>
                    <div className="text-left leading-normal text-[9px]">
                        <p className="m-0">
                            <span className="font-sans">Kepada</span>
                            <span className="w-376.52 inline-block">&nbsp;</span>
                        </p>
                        <p className="m-0">
                            <span className="font-sans">Yth.{" "}
                                {pejabatBerwenang && pejabatBerwenang.position_name}
                                {/* {data?.pejabatYangBerwenang && data?.pejabatYangBerwenang.map((val, i) => {
                                    return (val.position_name)
                                })} */}
                            </span>
                        </p>
                        <p className="m-0">
                            <a name="_gjdgxs" />
                            <span className="font-sans">di Tempat</span>
                        </p>
                    </div>
                    <div className='justify-self-end'>
                        <p className="m-0 text-[9px]">
                            {/* <span className="font-sans">{moment(data.createdAt).format('DD-MM-YYYY')}</span> */}
                        </p>
                    </div>
                </div>
                <div className="mb-1">
                    <p className="m-0 text-center leading-normal text-[9px]">
                        <strong className="font-sans text-[9px]">
                            FORMULIR PERMINTAAN DAN PEMBERIAN CUTI
                        </strong>
                    </p>
                </div>

                <div className="">
                    <table className="w-[532.35pt] mx-auto border border-black border-collapse">
                        <tbody>
                            <tr className="h-[14.15pt]">
                                <td colSpan={4} className="border-black px-5 text-base font-bold">
                                    <p className="m-0 text-[9px]">
                                        <strong className="font-sans">I. DATA PEGAWAI</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr className="h-[14.15pt]">
                                <td className="border-black border-x border-y px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">Nama</span>
                                    </p>
                                </td>
                                <td className="border-black border-x border-y px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{data?.user?.user_detail?.gelar_depan === '-' ? "" : data?.user?.user_detail?.gelar_depan} {data.fullname} {data?.user?.user_detail?.gelar_belakang === '-' ? "" : data?.user?.user_detail?.gelar_belakang}</span>
                                    </p>
                                </td>
                                <td className="border-black border-x border-y px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">Jabatan</span>
                                    </p>
                                </td>
                                <td className="border-black border-x border-y px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{data.user?.user_detail.job_position.position_name}</span>
                                    </p>
                                </td>
                            </tr>
                            <tr className="h-[14.15pt]">
                                <td className="border-black border-x border-y px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">NIP</span>
                                    </p>
                                </td>
                                <td className="border-black border-x border-y px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{data.user?.user_detail.employee_id}</span>
                                    </p>
                                </td>
                                <td className="border-black border-x border-y px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">Unit Kerja</span>
                                    </p>
                                </td>
                                <td className="border-black border-x border-y px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{data?.user?.data_unit_kerja?.unitKerja?.name}</span>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="">
                    <table className="w-[532.35pt] mx-auto border border-black border-collapse">
                        <tbody>
                            <tr className="">
                                <td className="border-r border-black px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <strong className="font-sans">II. JENIS CUTI YANG DIAMBIL</strong>
                                    </p>
                                </td>
                                <td className="border-l border-black px-5 pb-2.5 bg-blue-200">
                                    <p className="m-0 text-[9px] font-bold">
                                        <strong className="font-sans">{data?.leavetype?.type_name}</strong>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="">
                    <table className="w-[532.35pt] mx-auto border border-black border-collapse">
                        <tbody>
                            <tr className="">
                                <td className="border border-black px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <strong className="font-sans">III. ALASAN CUTI</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="border-t border-black px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{data.notes}</span>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="">
                    <table className="w-[532.35pt] mx-auto border border-black border-collapse">
                        <tbody>
                            <tr className="">
                                <td colSpan={7} className="border border-black px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <strong className="font-sans">IV. LAMANYA CUTI</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="border-t border-black border-r px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">Selama</span>
                                    </p>
                                </td>
                                <td className="border-t border-black border-l pb-2.5 px-5 text-center">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{data.leave_duration}</span>
                                    </p>
                                </td>
                                <td className="border-t border-black border-r pb-2.5 text-center">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">(hari)*</span>
                                    </p>
                                </td>
                                <td className="border-t border-black border-l border-r pb-2.5 text-center">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">Mulai tanggal</span>
                                    </p>
                                </td>
                                <td className="border-t border-black border-r px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{moment(data.start_date).format('DD-MM-YYYY')}</span>
                                    </p>
                                </td>
                                <td className="border-t border-black border-l border-r px-2.5 pb-2.5 text-center">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">s/d</span>
                                    </p>
                                </td>
                                <td className="border-t border-black border-l px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{moment(data.end_date).format('DD-MM-YYYY')}</span>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="">
                    <table className="w-[532.35pt] mx-auto border border-black border-collapse">
                        <tbody>
                            <tr className="">
                                <td colSpan={5} className="px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <strong className="font-sans">V. CATATAN CUTI</strong>
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td colSpan={5} className="border border-black px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">
                                            Diisi secara otomatis oleh Petugas pengelola cuti pegawai sebelum cuti diajukan kepada Atasan Langsung.
                                        </span>
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td colSpan={3} className="border-black border-t border-r border px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">1. CUTI TAHUNAN</span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">2. CUTI BESAR</span>
                                    </p>
                                </td>
                                <td className="border-black border-t border-l border-r px-5 pb-2.5 text-right">
                                    <p className="m-0 text-[9px]">
                                        {data.sisa_cuti && data.sisa_cuti.map((val, index) => {
                                            if (val.leave_type.type_name === "Cuti Besar") {
                                                return (
                                                    <span className="font-sans">{val.jumlah} Hari</span>
                                                )
                                            }
                                        })}
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="border-black border-t border-r border px-5 pb-2.5 text-center">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">Tahun</span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5 text-center">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">Sisa</span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5 text-center">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">Keterangan</span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">3. CUTI SAKIT</span>
                                    </p>
                                </td>
                                <td className="border-black border-t border-l border-r px-5 pb-2.5 text-right truncate">
                                    <p className="m-0 text-[9px]">
                                        {data.sisa_cuti && data.sisa_cuti.map((val, index) => {
                                            if (val.leave_type.type_name === "Cuti Sakit") {
                                                return (
                                                    <span className="font-sans">{val.jumlah} Hari</span>
                                                )
                                            }
                                        })}
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="border-black border-t border-r border px-5 pb-2.5 text-center">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{moment().format('YYYY') - 1}</span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5 text-right">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">- Hari</span>
                                    </p>
                                </td>
                                <td rowSpan={2} className="border-black border px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">
                                            Hak cuti yang masih tersisa sesudah dikurangi ajuan cuti ini.
                                        </span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">4. CUTI MELAHIRKAN</span>
                                    </p>
                                </td>
                                <td className="border-black border-t border-l border-r px-5 pb-2.5 text-right truncate">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">-</span>
                                        {/* <span className="font-sans">Anak ke- PERSALINAN YANG KE- </span> */}
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="border-black border-t border-r border px-5 pb-2.5 text-center">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">{moment().format('YYYY')}</span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 text-right">
                                    <p className="m-0 text-[9px] truncate pb-2.5">
                                        {data.sisa_cuti && data.sisa_cuti.map((val, index) => {
                                            if (val.leave_type.type_name === "Cuti Tahunan") {
                                                return (
                                                    <span className="font-sans">{val.jumlah} Hari</span>
                                                )
                                            }
                                        })}
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">5. CUTI KARENA ALASAN PENTING</span>
                                    </p>
                                </td>
                                <td colSpan={2} className="border-black border-t border-l border-r px-5 pb-2.5 text-right truncate">
                                    <p className="m-0 text-[9px]">
                                        {data.sisa_cuti && data.sisa_cuti.map((val, index) => {
                                            if (val.leave_type.type_name === "Cuti Karena Alasan Penting") {
                                                return (
                                                    <span className="font-sans">{val.jumlah} Hari</span>
                                                )
                                            }
                                        })}
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="border-black border-t border-r px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">&nbsp;</span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5 text-right">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">&nbsp;</span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">&nbsp;</span>
                                    </p>
                                </td>
                                <td className="border-black border px-5 pb-2.5">
                                    <p className="m-0 text-[9px]">
                                        <span className="font-sans">6. CUTI DI LUAR TANGGUNGAN NEGARA</span>
                                    </p>
                                </td>
                                <td className="border-black border-t border-l border-r px-5 pb-2.5 text-right truncate">
                                    <p className="m-0 text-[9px]">
                                        {data.sisa_cuti && data.sisa_cuti.map((val, index) => {
                                            if (val.leave_type.type_name === "Cuti Diluar Tanggungan Negara") {
                                                return (
                                                    <span className="font-sans">{val.jumlah} Hari</span>
                                                )
                                            }
                                        })}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="">
                    <table
                        className="w-[532.35pt] border border-black border-collapse mx-auto"
                    >
                        <tbody>
                            <tr className="">
                                <td
                                    colSpan={3}
                                    className="border border-solid border-black px-5 pb-2.5 align-middle"
                                >
                                    <p className="m-0 text-[9px] font-bold font-Arial">
                                        VI. ALAMAT SELAMA MENJALANKAN CUTI
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td
                                    rowSpan={2}
                                    className="border-black border-t border-r border border-solid px-5 align-top"
                                >
                                    <p className="m-0 text-[9px] font-Arial">{data.address}</p>
                                </td>
                                <td className="border border-solid border-black px-5 pb-2.5 align-middle">
                                    <p className="m-0 text-[9px] font-Arial">Telp</p>
                                </td>
                                <td
                                    className="border-black border-t border-l border border-solid px-5 pb-2.5 align-middle"
                                >
                                    <p className="m-0 text-[9px] font-Arial">{data?.user?.user_detail?.mobile_phone}</p>
                                </td>
                            </tr>
                            <tr className="">
                                <td
                                    colSpan={2}
                                    className="border-black border-t border-l border-solid px-5 pb-2.5 align-middle"
                                >
                                    <p className="m-0 text-center text-[9px] font-Arial">Hormat saya,</p>
                                    <p className="m-0 text-center text-[9px] font-Arial">&nbsp;</p>
                                    <p className="m-0 text-center text-[9px] font-Arial">&nbsp;</p>
                                    <p className="m-0 text-center text-[9px] font-Arial">&nbsp;</p>
                                    <p className="m-0 text-center text-[9px] font-Arial">
                                        (<u>{data?.user?.user_detail?.gelar_depan === '-' ? "" : data?.user?.user_detail?.gelar_depan} {data.fullname} {data?.user?.user_detail?.gelar_belakang === '-' ? "" : data?.user?.user_detail?.gelar_belakang}</u>)
                                    </p>
                                    <p className="m-0 text-center text-[9px] font-Arial">{data?.user?.user_detail?.employee_id}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="">
                    <table
                        className="w-[532.35pt] border-collapse mx-auto"
                    >
                        <tbody>
                            <tr className="">
                                <td
                                    colSpan={4}
                                    className="border border-solid border-black px-5 pb-2.5 align-middle"
                                >
                                    <p className="m-0 text-[9px] font-bold font-Arial">
                                        VII. PERTIMBANGAN ATASAN LANGSUNG
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td
                                    className="border border-solid border-black px-5 pb-2.5 align-middle"
                                >
                                    <p className="m-0 text-[9px] font-Arial">DISETUJUI</p>
                                </td>
                                <td
                                    className="border border-solid border-black px-10 pb-2.5 align-middle"
                                >
                                    <p className="m-0 text-[9px] font-Arial">DITANGGUHKAN*</p>
                                </td>
                                <td
                                    className="border border-solid border-black px-10 pb-2.5 align-middle"
                                >
                                    <p className="m-0 text-[9px] font-Arial whitespace-nowrap">TIDAK DISETUJUI*</p>
                                </td>
                            </tr>
                            <tr className="">
                                <td
                                    className="border border-solid border-black px-5 align-middle h-[30px]"
                                >
                                    {
                                        data.status === "approved" ?
                                            <p className="m-0 text-[9px] font-Arial"><AiFillCheckCircle className='text-lg text-green-500' /></p>
                                            : null
                                    }
                                </td>
                                <td
                                    className="border border-solid border-black px-5 align-middle h-[30px]"
                                >
                                    {
                                        data.status === "ditangguhkan" ?
                                            <p className="m-0 text-[9px] font-Arial"><AiFillCheckCircle className='text-lg text-green-500' /></p>
                                            : null
                                    }
                                </td>
                                <td
                                    className="border border-solid border-black px-5 align-middle h-[30px]"
                                >
                                    {
                                        data.status === "decline" ?
                                            <p className="m-0 text-[9px] font-Arial"><AiFillCheckCircle className='text-lg text-green-500' /></p>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr className="">
                                <td
                                    className="px-5 py-2.5 align-top"
                                >
                                    <p className="m-0 text-[9px] font-Arial">
                                        Sebelum cuti wajib menyerahkan pekerjaan kepada sejawat, atau
                                        Atasan Langsungnya atau staf/pejabat lain yang ditentukan
                                    </p>
                                </td>
                                <td
                                    colSpan={2}
                                    className="border border-solid border-black px-5 pb-2.5 align-bottom text-center h-[80px]"
                                >
                                    {reportTo &&
                                        <p className="m-0 text-[9px] font-Arial">({reportTo?.gelar_depan === '-' ? "" : reportTo?.gelar_depan} {reportTo?.fullname} {reportTo?.gelar_belakang === '-' ? "" : reportTo?.gelar_belakang})</p>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="">
                    <table
                        className="w-[532.35pt] mx-auto border-collapse"
                    >
                        <tbody>
                            <tr className="">
                                <td
                                    className="border border-solid border-black px-5 pb-2.5 align-middle"
                                >
                                    <p className="m-0 text-[9px] font-bold font-Arial">
                                        VIII. KEPUTUSAN PEJABAT YANG BERWENANG MEMBERIKAN CUTI
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td
                                    className="border border-solid border-black px-5 pb-2.5 align-middle"
                                >
                                    <p className="m-0 text-[9px] font-Arial text-justify">
                                        Pengajuan cuti ini dinyatakan disetujui sesuai pertimbangan Atasan Langsung
                                        setelah ditandatangani secara manual ataupun digital oleh Pejabat Pembina Kepegawaian atau Pejabat lain yang mendapat delegasi kewenangan untuk memberikan cuti.
                                    </p>
                                </td>
                            </tr>
                            <tr className="">
                                <td
                                    className="border border-solid border-black px-5 pb-2.5 align-bottom text-end text-[9px] h-[80px]"
                                >
                                    {pejabatBerwenang ?
                                        <p className="m-0 text-[9px] font-Arial">({pejabatBerwenang?.gelar_depan === '-' ? "" : pejabatBerwenang?.gelar_depan} {pejabatBerwenang.fullname} {pejabatBerwenang?.gelar_belakang === '-' ? "" : pejabatBerwenang?.gelar_belakang})</p>
                                        : <p className="m-0 text-[9px] font-Arial">({reportTo?.gelar_depan === '-' ? "" : reportTo?.gelar_depan} {reportTo?.fullname} {reportTo?.gelar_belakang === '-' ? "" : reportTo?.gelar_belakang})</p>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='mb-2 w-[532.35pt] mx-auto'>
                    <p className="m-0 text-[9px] font-Arial">
                        <span>Catatan:</span>
                    </p>
                    <p className="m-0 text-[9px] font-Arial">
                        Cuti yang telah disetujui harap dikirimkan beserta dokumen pendukungnya
                        (jika ada)
                    </p>
                    <p className="m-0 font-Arial text-[9px]">
                        Jika cuti diubah, ditangguhkan, atau dibatalkan harap diberi catatan dan hubungi{" "}
                        <a href="mailto:kesejahteraan.peg@ombudsman.go.id" className="text-blue-500 no-underline">
                            <u>kesejahteraan.peg@ombudsman.go.id</u>
                        </a>{" "}
                        agar hitungan ini dihapuskan
                    </p>

                </div>
            </div>
        </>
    )
}

export default ExportCuti