import React from 'react'
import { IconDCardDashboard } from '../../assets'

const DCardEmploye = ({ CardName, Value, Img }) => {
  return (
    <div className='border p-5 bg-white rounded-[10px] min-w-[150px] lg:w-full shadow-sm space-y-[10px]'>
      <h1 className='text-dark-5 text-[12px]'>{!CardName ? 'Card Name' : CardName}</h1>
      <div className='flex items-center justify-between gap-2'>
        <h1 className='text-dark-3 text-[24px] font-[600]'>{!Value ? '0' : Value}</h1>
        <div className='hidden lg:flex items-center justify-center w-[40px] h-[40px] rounded-full bg-[#FFEFF2]'>
          <img src={Img} alt='Dashboard' />
        </div>
      </div>
    </div>
  )
}

export default DCardEmploye