import html2pdf from "html2pdf.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../../../../Api";
import { LogoOmbudsman } from "../../../../../assets";

function ExportLaporanKinerjaBulanan() {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [dataLaporan, setDataLaporan] = useState([]);
  const [dataTugasTambahan, setDataTugasTambahan] = useState([]);

  const getExportLaporanBulanan = async () => {
    try {
      await Api.GetExportLaporanBulanan(localStorage.getItem("hris-token"), id)
        .then((response) => {
          setDataLaporan(response.data.results.rencanaHasilKerja);
          setDataTugasTambahan(response.data.results.tugasTambahan);
          setData(response.data.results);
          setTimeout(() => {
            exportToPDF();
          }, 2000);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const exportToPDF = () => {
    console.log("export");
    const content = document.getElementById("laporan");
    const options = {
      margin: 10,
      filename: "Laporan-Kinerja-Bulanan.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(content).set(options).save();
    // const report = new jsPDF('portrait','pt', 'a4')
    // report.html(Template).then(()=>{
    //     report.save('report.pdf')
    // })
  };
  useEffect(() => {
    getExportLaporanBulanan();
  }, []);
  return (
    <div id="laporan" className='font-export'>
      <p
        style={{
          marginBottom: "0pt",
          textAlign: "center",
          lineHeight: "150%",
          fontSize: "10pt",
        }}
      >
        <strong>
          <span style={{ fontFamily: "Arial" }}>
            LAPORAN KINERJA DAN PRESTASI KERJA TERTENTU
          </span>
        </strong>
      </p>
      <p
        style={{
          marginBottom: "0pt",
          textAlign: "center",
          lineHeight: "150%",
          fontSize: "10pt",
        }}
      >
        <strong>
          <span style={{ fontFamily: "Arial" }}>ASISTEN OMBUDSMAN</span>
        </strong>
      </p>
      <p
        style={{
          marginBottom: "0pt",
          textAlign: "center",
          lineHeight: "150%",
          fontSize: "10pt",
        }}
      >
        <strong>
          <span style={{ fontFamily: "Arial" }}>&nbsp;</span>
        </strong>
      </p>
      <p
        style={{
          marginLeft: "28.35pt",
          marginBottom: "0pt",
          lineHeight: "115%",
          fontSize: "10pt",
        }}
      >
        <span style={{ fontFamily: "Arial" }}>Nama</span>
        <span
          style={{
            width: "115.08pt",
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          &nbsp;
        </span>
        <span style={{ fontFamily: "Arial" }}>:</span>
        <span
          style={{
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          {" "}
          {data?.laporan?.user?.user_detail?.gelar_depan === "-"
            ? ""
            : data?.laporan?.user?.user_detail?.gelar_depan}{" "}
          {data?.laporan?.user.fullname}{" "}
          {data?.laporan?.user?.user_detail?.gelar_belakang === "-"
            ? ""
            : data?.laporan?.user?.user_detail?.gelar_belakang}
        </span>
        <u>
          <span
            style={{
              width: "240.95pt",
              fontFamily: '"Lucida Console"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </u>
      </p>
      <p
        style={{
          marginLeft: "28.35pt",
          marginBottom: "0pt",
          lineHeight: "115%",
          fontSize: "10pt",
        }}
      >
        <span style={{ fontFamily: "Arial" }}>NIO/NIP</span>
        <span
          style={{
            width: "104.5pt",
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          &nbsp;
        </span>
        <span style={{ fontFamily: "Arial" }}>:</span>
        <span
          style={{
            width: "11.42pt",
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          {data?.laporan?.user?.user_detail?.employee_id}
        </span>
        <u>
          <span
            style={{
              width: "240.95pt",
              fontFamily: '"Lucida Console"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </u>
      </p>
      <p
        style={{
          marginLeft: "28.35pt",
          marginBottom: "0pt",
          lineHeight: "115%",
          fontSize: "10pt",
        }}
      >
        <span style={{ fontFamily: "Arial" }}>Pangkat / Jenjang Jabatan</span>
        <span
          style={{
            width: "23.33pt",
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          &nbsp;
        </span>
        <span style={{ fontFamily: "Arial" }}>:</span>
        <span
          style={{
            width: "11.42pt",
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          {data?.laporan?.user?.data_golongan?.pangkat_golongan_akhir}
        </span>
      </p>
      <p
        style={{
          marginLeft: "28.35pt",
          marginBottom: "0pt",
          lineHeight: "115%",
          fontSize: "10pt",
        }}
      >
        <span style={{ fontFamily: "Arial" }}>Unit Kerja</span>
        <span
          style={{
            width: "97.84pt",
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          &nbsp;
        </span>
        <span style={{ fontFamily: "Arial" }}>:</span>
        <u>
          <span
            style={{
              width: "240.95pt",
              fontFamily: '"Lucida Console"',
              display: "inline-block",
            }}
          >
            {data?.laporan?.user?.data_unit_kerja?.unitKerja?.name}
          </span>
        </u>
      </p>
      <p
        style={{
          marginLeft: "28.35pt",
          marginBottom: "0pt",
          lineHeight: "115%",
          fontSize: "10pt",
        }}
      >
        <span style={{ fontFamily: "Arial" }}>Bulan</span>
        <span
          style={{
            width: "116.17pt",
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          &nbsp;
        </span>
        <span style={{ fontFamily: "Arial" }}>:</span>
        <span
          style={{
            width: "11.42pt",
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          {data?.laporan?.bulan}
        </span>
        <u>
          <span
            style={{
              width: "240.95pt",
              fontFamily: '"Lucida Console"',
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </u>
      </p>
      <p style={{ marginBottom: "0pt", lineHeight: "115%", fontSize: "10pt" }}>
        <span style={{ fontFamily: "Arial" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0pt",
          textAlign: "center",
          lineHeight: "115%",
          fontSize: "10pt",
        }}
      >
        <span style={{ fontFamily: "Arial" }}>Tabel 1. Kinerja</span>
      </p>
      <table className="w-full border-collapse mb-0 border-t border-l border-r border-black mt-5">
        <tbody>
          <tr>
            <td className="py-2 w-5.62% border-r border-b border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                No
              </p>
            </td>
            <td className="py-2 w-19.34% border-r border-l border-b border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                Tanggal / Bulan
              </p>
            </td>
            <td className="py-2 w-43.46% border-r border-l border-b border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                Uraian Kegiatan
              </p>
            </td>
            <td className="py-2 w-14.5% border-r border-l border-b border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                Waktu
              </p>
            </td>
            <td className="py-2 w-17.08% border-l border-b border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                Hasil
              </p>
            </td>
          </tr>
          <tr>
            <td className="py-2 w-5.62% border-t border-r border-b border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                (1)
              </p>
            </td>
            <td className="py-2 w-19.34% border-t border-l border-r border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                (2)
              </p>
            </td>
            <td className="py-2 w-43.46% border-t border-l border-r border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                (3)
              </p>
            </td>
            <td className="py-2 w-14.5% border-t border-l border-r border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                (4)
              </p>
            </td>
            <td className="py-2 w-17.08% border-t border-l border-b border-black pr-5.03 pl-5.03 align-middle">
              <p className="mb-0 text-center leading-115 text-sm font-bold font-serif">
                (5)
              </p>
            </td>
          </tr>
          {dataLaporan.map((val, index) => (
            <tr key={index} className="h-17">
              <td className="py-2 w-5.62% border-t border-r border-b border-black pr-5.03 pl-5.03 align-middle">
                <p className="mb-0 text-center leading-115 text-sm font-serif">
                  {index + 1}
                </p>
              </td>
              <td className="py-2 w-19.34% border-t border-l border-b border-black pr-5.03 pl-5.03 align-middle">
                <p className="mb-0 text-center leading-115 text-sm font-serif">
                  {val.bukti_dukung_tanggal
                    ? moment(val.bukti_dukung_tanggal).format("DD-MM-YYYY")
                    : ""}
                </p>
              </td>
              <td className="py-2 w-43.46% border-t border-l border-b border-black pr-5.03 pl-5.03 align-middle">
                <p className="mb-0 text-center leading-115 text-sm font-serif">
                  {val.rencana_aksi}
                </p>
              </td>
              <td className="py-2 w-14.5% border-t border-l border-b border-black pr-5.03 pl-5.03 align-middle">
                <p className="mb-0 text-center leading-115 text-sm font-serif">
                  {val.target_waktu}
                </p>
              </td>
              <td className="py-2 w-17.08% border-t border-l border-b border-black pr-5.03 pl-5.03 align-middle">
                <p className="mb-0 text-center leading-115 text-sm font-serif">
                  {val.bukti_dukung}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p
        style={{ marginLeft: "28.35pt", lineHeight: "115%", fontSize: "10pt" }}
      >
        <span style={{ fontFamily: "Arial" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: "0pt",
          textAlign: "center",
          lineHeight: "115%",
          fontSize: "10pt",
        }}
      >
        <span style={{ fontFamily: "Arial" }}>
          Tabel 2. Prestasi Kerja Tertentu
        </span>
      </p>
      <table className="w-full mb-0 border-collapse border-l border-r border-t border-black mt-5">
        <tbody>
          <tr>
            <td className="w-5.62% border-r border-b border-black pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                <span className="font-Arial">No</span>
              </p>
            </td>
            <td className="w-19.34% border-r border-l border-b border-black pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                <span className="font-Arial">Tanggal / Bulan</span>
              </p>
            </td>
            <td className="w-43.46% border-r border-l border-b border-black pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                <span className="font-Arial">Uraian Kegiatan</span>
              </p>
            </td>
            <td className="w-14.5% border-r border-l border-b border-black pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                <span className="font-Arial">Dasar Penugasan</span>
              </p>
            </td>
            <td className="w-17.08% border-l border-b border-black pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                <span className="font-Arial">Hasil</span>
              </p>
            </td>
          </tr>
          <tr>
            <td className="w-5.62% border-t border-r border-b border-black pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                (1)
              </p>
            </td>
            <td className="w-19.34% border-black border-l border-r pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                (2)
              </p>
            </td>
            <td className="w-43.46% border-black border-l border-r pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                (3)
              </p>
            </td>
            <td className="w-14.5% border-black border-l border-r pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                (4)
              </p>
            </td>
            <td className="w-17.08% border-t border-l border-b border-black pr-5.03 pl-5.03 align-middle py-2">
              <p className="mb-0 text-center leading-115 text-10pt font-bold font-serif">
                (5)
              </p>
            </td>
          </tr>
          {dataTugasTambahan.map((val, index) => (
            <tr key={index} className="h-17pt">
              <td className="w-5.62% border-t border-r border-b border-black pr-5.03 pl-5.03 align-middle py-2">
                <p className="mb-0 text-center leading-115 text-10pt font-serif">
                  {index + 1}
                </p>
              </td>
              <td className="w-19.34% border-black border-t border-r border-b pr-5.03 pl-5.03 align-middle py-2">
                <p className="mb-0 text-center leading-115 text-10pt font-serif">
                  {moment(val.start_date).format("DD-MM-YYYY")}
                </p>
              </td>
              <td className="w-43.46% border-black border-t border-r border-b pr-5.03 pl-5.03 align-middle py-2">
                <p className="mb-0 text-center leading-115 text-10pt font-serif">
                  {val.uraian_kegiatan}
                </p>
              </td>
              <td className="w-14.5% border-black border-t border-r border-b pr-5.03 pl-5.03 align-middle py-2">
                <p className="mb-0 text-center leading-115 text-10pt font-serif">
                  {val.dasar_penugasan}
                </p>
              </td>
              <td className="w-17.08% border-t border-l border-b border-black pr-5.03 pl-5.03 align-middle py-2">
                <p className="mb-0 text-center leading-115 text-10pt font-serif">
                  {val.hasil}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p
        style={{ marginLeft: "28.35pt", lineHeight: "115%", fontSize: "10pt" }}
      >
        <span style={{ fontFamily: "Arial" }}>&nbsp;</span>
      </p>
      <p
        style={{ marginLeft: "28.35pt", lineHeight: "115%", fontSize: "10pt" }}
      >
        <span
          style={{
            width: "354.4pt",
            fontFamily: "Arial",
            display: "inline-block",
          }}
        >
          &nbsp;
        </span>
        <span style={{ fontFamily: "Arial" }}>
          Jakarta, {moment(dataLaporan.createdAt).format("DD-MM-YYYY")}
        </span>
      </p>
      <table className="w-full mx-auto mb-0 border-collapse mt-5">
        <tbody>
          <tr className="w-full h-[100px]">
            <td className="w-166.4 text-center pr-5.4 pl-5.4 align-top">
              <p className="mr-0.55 ml-1.65 mb-0 leading-150 text-sm font-serif">
                Atasan Langsung,
              </p>
            </td>
            <td className="w-163.15 text-center pr-5.4 pl-5.4 align-top">
              <p className="mr-0.55 ml-1.65 mb-0 leading-150 text-sm font-serif">
                Asisten yang bersangkutan,
              </p>
            </td>
          </tr>
          <tr className="w-full h-[100px]">
            <td className="w-166.4 pr-5.4 pl-5.4 text-center align-top">
              <p className="mr-0.55 ml-1.65 mb-0 leading-150 text-sm">
                <strong>
                  <span className="font-serif">
                    (
                    {data?.atasanLangsung?.gelar_depan === "-"
                      ? ""
                      : data?.atasanLangsung?.gelar_depan}{" "}
                    {data?.atasanLangsung?.fullname}{" "}
                    {data?.atasanLangsung?.gelar_belakang === "-"
                      ? ""
                      : data?.atasanLangsung?.gelar_belakang}
                    )
                  </span>
                </strong>
              </p>
            </td>
            <td className="w-163.15 pr-5.4 pl-5.4 text-center align-top">
              <p className="mr-0.55 ml-1.65 mb-0 leading-150 text-sm font-serif">
                <strong>
                  <span className="font-serif">
                    (
                    {data?.laporan?.user?.user_detail?.gelar_depan === "-"
                      ? ""
                      : data?.laporan?.user?.user_detail?.gelar_depan}{" "}
                    {data?.laporan?.user.fullname}{" "}
                    {data?.laporan?.user?.user_detail?.gelar_belakang === "-"
                      ? ""
                      : data?.laporan?.user?.user_detail?.gelar_belakang}
                    )
                  </span>
                </strong>
              </p>
            </td>
          </tr>
          <tr className="h-[100px]">
            <td
              colSpan={2}
              className="w-193.35 text-center pr-5.4 pl-5.4 align-top"
            >
              <p className="mr-0.55 ml-1.65 mb-0 leading-150 text-sm font-serif">
                Pengampu,
              </p>
            </td>
          </tr>
          <tr className="h-16.35">
            <td
              colSpan={2}
              className="w-193.35 pr-5.4 pl-5.4 text-center align-top"
            >
              <p className="mr-0.55 ml-1.65 mb-0 leading-150 text-sm font-bold font-serif">
                (
                {data?.pengampu?.gelar_depan === "-"
                  ? ""
                  : data?.pengampu?.gelar_depan}{" "}
                {data?.pengampu?.fullname}{" "}
                {data?.pengampu?.gelar_belakang === "-"
                  ? ""
                  : data?.pengampu?.gelar_belakang}
                )
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <p
        style={{ marginLeft: "28.35pt", lineHeight: "115%", fontSize: "10pt" }}
      >
        <span style={{ fontFamily: "Arial" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginLeft: "28.35pt",
          marginBottom: "0pt",
          lineHeight: "150%",
          fontSize: "10pt",
        }}
      >
        <span style={{ fontFamily: "Arial" }}>Keterangan:</span>
      </p>
      <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          className="ListParagraph"
          style={{
            marginLeft: "44.99pt",
            marginBottom: "0pt",
            lineHeight: "150%",
            paddingLeft: "4.66pt",
            fontFamily: "Arial",
            fontSize: "10pt",
          }}
        >
          Kolom uraian kegiatan Tabel 1. diisi sesuai kegiatan yang dilakukan
          setiap hari kerja;
        </li>
        <li
          className="ListParagraph"
          style={{
            marginLeft: "44.99pt",
            marginBottom: "0pt",
            lineHeight: "150%",
            paddingLeft: "4.66pt",
            fontFamily: "Arial",
            fontSize: "10pt",
          }}
        >
          Pegawai yang tidak masuk kerja tidak diwajibkan mengisi uraian
          kegiatan Tabel 1. pada hari tersebut;
        </li>
        <li
          className="ListParagraph"
          style={{
            marginLeft: "44.99pt",
            marginBottom: "0pt",
            lineHeight: "150%",
            paddingLeft: "4.66pt",
            fontFamily: "Arial",
            fontSize: "10pt",
          }}
        >
          Kolom uraian kegiatan Tabel 1. diisi sesuai dengan uraian tugas pokok
          yang terdapat pada Peraturan Ombudsman Nomor 42 Tahun 2020 Pasal 14
          sesuai jenis kegiatan yang sedang atau telah dilakukan;
        </li>
        <li
          className="ListParagraph"
          style={{
            marginLeft: "44.99pt",
            marginBottom: "0pt",
            lineHeight: "150%",
            paddingLeft: "4.66pt",
            fontFamily: "Arial",
            fontSize: "10pt",
          }}
        >
          Kolom waktu Tabel 1. diisi sesuai dengan durasi pelaksanaan kegiatan;
        </li>
        <li
          className="ListParagraph"
          style={{
            marginLeft: "44.99pt",
            marginBottom: "0pt",
            lineHeight: "150%",
            paddingLeft: "4.66pt",
            fontFamily: "Arial",
            fontSize: "10pt",
          }}
        >
          Kolom hasil Tabel 1. diisi dengan hasil atau proses kegiatan;
        </li>
        <li
          className="ListParagraph"
          style={{
            marginLeft: "44.99pt",
            marginBottom: "0pt",
            lineHeight: "150%",
            paddingLeft: "4.66pt",
            fontFamily: "Arial",
            fontSize: "10pt",
          }}
        >
          Kolom uraian kegiatan Tabel 2. diisi kegiatan selain tugas dan fungsi
          pada unit kerjanya;
        </li>
        <li
          className="ListParagraph"
          style={{
            marginLeft: "44.99pt",
            marginBottom: "0pt",
            lineHeight: "150%",
            paddingLeft: "4.66pt",
            fontFamily: "Arial",
            fontSize: "10pt",
          }}
        >
          Kolom dasar penugasan Tabel 2. diisi dengan nomor penugasan tertulis
          dari Kepala Unit Kerja atau Pimpinan Ombudsman;
        </li>
        <li
          className="ListParagraph"
          style={{
            marginLeft: "44.99pt",
            lineHeight: "150%",
            paddingLeft: "4.66pt",
            fontFamily: "Arial",
            fontSize: "10pt",
          }}
        >
          Kolom hasil Tabel 2. diisi dengan hasil atau proses kegiatan.
        </li>
      </ol>
    </div>
  );
}

export default ExportLaporanKinerjaBulanan;
