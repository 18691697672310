import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { BsArrowLeftShort } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import Api from '../../../Api'
import { Landing1, Logo2 } from '../../../assets'

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')

    const forgotPassword = async () => {
        try {
            await Api.ForgotPassword(email).then((response)=>{
                toast.success("Periksa Email Anda untuk mereset kata sandi!")
                navigate("/")
            }).catch((e)=>{
                // console.log(e.response.data)
                toast.error(e.response.data.message)
            })
        } catch (error) {
            console.log(error)
            toast.error("Invalid email and/or password!!!")
        }
    }

    return (
        <div>
            <div className='lg:grid lg:grid-cols-12'>
                <div className='lg:col-span-7 bg-[#ECEEF6] '>
                    <div className='flex flex-col justify-between h-[100%]'>
                        <div className='px-[85px] mt-[51px]'>
                            <img src={Logo2} className='w-[203px]' alt='Humanusia Logo' />
                        </div>
                        <div className=''>
                            <img src={Landing1} className='' alt='LandingImage' />
                        </div>
                    </div>
                </div>
                <div className='lg:col-span-5 bg-white px-5 lg:px-[82px] py-[170px]'>
                    <h1 className='text-[30px] text-[#2F3044] font-medium'>Lupa Kata Sandi?</h1>
                    <h2 className='text-[#A8A8A8] text-sm'>Masukkan email anda untuk reset password</h2>
                    <div className='mt-3'>
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Email</h1>
                        <input onChange={(e) => setEmail(e.target.value)} type='text' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-2" placeholder='Masukkan email...' />
                        <button onClick={forgotPassword} className='py-[10px] px-[195px] text-white rounded-md bg-[#0E5073] text-[12px] w-full font-semibold'>Kirim</button>
                    </div>

                    <div className='flex justify-center gap-1 mt-3'>
                        <Link to='/' className='flex gap-3'>
                            <BsArrowLeftShort className='text-dark-5 text-2xl' />
                            <h1 className='text-[14px] text-dark-5 underline'>Kembali Ke Halaman Login</h1>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ForgotPassword