import React, { useEffect, useState } from 'react'
import { Modal, Navbar} from '../../../../components'
import { TbFileExport } from 'react-icons/tb'
import { AiOutlinePlus } from 'react-icons/ai'
import { MdImportExport, MdMarkEmailRead} from 'react-icons/md'
import { IoIosShareAlt} from 'react-icons/io'
import Api from '../../../../Api'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { HiOutlinePencil } from 'react-icons/hi'

const RecruitmentStage = () => {
    const [showModalPassingEmail, setShowModalPassingEmail] = useState()
    const [showModalNotPassingEmail, setShowModalNotPassingEmail] = useState()
    const [showModalAddRecStage, setShowModalAddRecStage] = useState()
    const [showModalEditRecStage, setShowModalEditRecStage] = useState()
    const [data, setData] = useState('')
    const [employeeName, setEmployeeName] = useState('')
    const [employeeId, setEmployeeId] = useState('')
    const [employeeStatusRecruitment, setEmployeeStatusRecruitment] = useState(null)
    const navigate = useNavigate()
    const params = useLocation()

    const [stageName, setStageName] = useState('')
    const [status, setStatus] = useState('')
    const [note, setNote] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [idRecruitmentStage, setIdRecruitmentStage] = useState('')
    
    const resetForm = () => {
        setStageName('')
        setNote('')
    }

    console.log(employeeStatusRecruitment)

    const getRecruitmentById = async () => {
        try{
            const response = await Api.GetRecruitmentApplicantById(localStorage.getItem('hris-token'), params.state.applicantId)
            setEmployeeName(response.data.applicant.name)
            setEmployeeId(response.data.applicant.id)
            setEmployeeStatusRecruitment(response.data.applicant.status)
            console.log(response, 'byId')
        } catch (error) {
            console.log(error)
        }
    }


    const getDataRecruitmentStage = async () => {
        try {
            const response = await Api.GetRecruitmentStage(localStorage.getItem('hris-token'), '', '', '')
            setData(response.data)
            console.log(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const createRecruitmentStage = async () => {
        const data = {
            stage_name: stageName,
            note: note,
        }
        try {
            console.log(data)
            setIsLoading(true)
            const response = await Api.PostRecruitmentStage(localStorage.getItem('hris-token'), data)
            setIsLoading(false)
            resetForm()
            setRefresh(true)
            setShowModalAddRecStage(!showModalAddRecStage)
            toast.success('Create Recruitment Stage Success!')
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error(error.message)
        }
    }

    const openEditRecruitmentStage = async (id) => {
        setShowModalEditRecStage(!showModalEditRecStage)
        setIdRecruitmentStage(id)
        try {
            const response = await Api.GetRecruitmentStageById(localStorage.getItem('hris-token'), id)
            setStageName(response.data.stage_name)
            setNote(response.data.note)
            setStatus(response.data.status)
            console.log(response, 'openEdit')
        } catch (error) {
            console.log(error)
        }
    }

    const editRecruitmentStage = async () => {
        const data = {
            stage_name: stageName,
            note: note,
            status: status
        }
        try {
            setIsLoading(true)
            const response = await Api.UpdateRecruitmentStage(localStorage.getItem('hris-token'), data, idRecruitmentStage)
            setIsLoading(false)
            resetForm()
            setRefresh(true)
            setShowModalEditRecStage(!showModalEditRecStage)
            toast.success('Success Update!')
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            console.log(error.message)
        }
    }

    const postEndRecruitment = async (e) => {
        try {
            const data = {
                status: e
            }
            const response = await Api.PostEndRecruitment(localStorage.getItem('hris-token'), data, employeeId )
            setRefresh(true)
            toast.success('Success End Recruitment')
        } catch (error) {
            console.log(error)
        }
    }
    
    useEffect(() => {
        getDataRecruitmentStage()
        getRecruitmentById()
        setRefresh(false)
    }, [refresh])
    

  return (
    <div>
        <Modal 
                activeModal={showModalPassingEmail}
                title={''}
                buttonClose={ () => setShowModalPassingEmail(!showModalPassingEmail)}
                width={'582px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        <MdMarkEmailRead className='text-[#00ED5F] text-7xl text-center'/>
                        <h1 className='text-[#003049] font-semibold text-base'>Share Notification Email</h1>
                        <h2 className='text-dark-5 text-[13px] align-middle'>Recipients will receive notification of passing the recruitment stage. Are you sure you want to share the notification email? This action cannot be undone</h2>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalPassingEmail(!showModalPassingEmail)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Send</button>
                        </div>
                    </div>
                }
        />
        <Modal 
                activeModal={showModalNotPassingEmail}
                title={''}
                buttonClose={ () => setShowModalNotPassingEmail(!showModalNotPassingEmail)}
                width={'582px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        <MdMarkEmailRead className='text-[#C1121F] text-7xl text-center'/>
                        <h1 className='text-[#003049] font-semibold text-base'>Share Notification Email</h1>
                        <h2 className='text-dark-5 text-[13px] align-middle'>Recipients will receive notification of not passing the recruitment stage. Are you sure you want to share the notification email? This action cannot be undone</h2>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalNotPassingEmail(!showModalNotPassingEmail)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Send</button>
                        </div>
                    </div>
                }
        />
        <Modal 
                activeModal={showModalAddRecStage}
                title={'Recruitment Stage'}
                buttonClose={ () => setShowModalAddRecStage(!showModalAddRecStage)}
                width={'582px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        <div className='w-full'>
                                <h1 className='text-[11px] font-medium text-dark-5 mb-[5px]'>Stage Name <span className='text-[#780000]'>*</span></h1>
                            <input type="text" value={stageName} onChange={(e) => setStageName(e.target.value)} className='border rounded-md w-full py-[11px] pl-[15px] pr-[21px] text-[12px] outline-none' placeholder='Stage name...' />
                        </div>
                        <div className='w-full'>
                                <h1 className='text-[11px] font-medium text-dark-5 mb-[5px]'>Note</h1>
                            <textarea rows={'4'} type="text" value={note} onChange={(e) => setNote(e.target.value)} className='border rounded-md w-full py-[11px] pl-[15px] pr-[21px] text-[12px] outline-none appearance-none resize-none' placeholder='Note...' />
                        </div>
  
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setShowModalAddRecStage(!showModalAddRecStage)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={createRecruitmentStage} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Add</button>
                        </div>
                    </div>
                }
        />
        <Modal 
                activeModal={showModalEditRecStage}
                title={'Edit Recruitment Stage'}
                buttonClose={ () => setShowModalEditRecStage(!showModalEditRecStage)}
                width={'582px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        <div className='w-full'>
                                <h1 className='text-[11px] font-medium text-dark-5 mb-[5px]'>Stage Name <span className='text-[#780000]'>*</span></h1>
                            <input type="text" value={stageName} onChange={(e) => setStageName(e.target.value)} className='border rounded-md w-full py-[11px] pl-[15px] pr-[21px] text-[12px] outline-none' placeholder='Stage name...' />
                        </div>
                        <div className='w-full'>
                                <h1 className='text-[11px] font-medium text-dark-5 mb-[5px]'>Note</h1>
                            <textarea rows={'4'} type="text" value={note} onChange={(e) => setNote(e.target.value)} className='border rounded-md w-full py-[11px] pl-[15px] pr-[21px] text-[12px] outline-none appearance-none resize-none' placeholder='Note...' />
                        </div>
                        <div className='flex items-center justify-center gap-[12px]'>
                            <select value={status} onChange={(e) => setStatus(e.target.value)} className='text-dark-3 w-full py-[11px] rounded-[6px] border flex items-center justify-center text-[12px] gap-[10px] px-2'>
                                <option>Action</option>
                                <option value={'Success'}>Success</option>
                                <option value={'Failed'}>Failed</option>
                                <option value={'On Progress'}>Pending</option>
                            </select>
                        </div>
  
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setShowModalEditRecStage(!showModalEditRecStage)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={editRecruitmentStage} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Add</button>
                        </div>
                    </div>
                }
        />
        
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Recruitment Stage'} LinkBack={'/recruitment/entry-application'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div>
                        <h1 className='text-dark-3 text-[20px] font-semibold'>{employeeName}</h1>
                        <p className='text-dark-5 text-[10px]'>List of  stage for employee recruitment </p>
                    </div>
                    <div className="flex gap-2 mt-[31px] relative">
                        <button className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                            <TbFileExport className='text-[#003049]'/>
                            <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                        </button>
                        <div className="absolute right-0">
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <button onClick={ () => setShowModalAddRecStage(!showModalAddRecStage)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Add Stage</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='overflow-auto scrollbar-hide mb-[100px]'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Stage</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Position</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Apply Date</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Phone Number</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Status</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-1/2'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Edit</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-1/2'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Share Email</h1>
                                        <MdImportExport className='text-dark-5 text-xs'/>
                                    </div>
                                </div>
                                {Object.values(data).map((item, idx) => (
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{idx + 1}. {item.stage_name? item.stage_name : '-'}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.recruitment? item.recruitment.position : '-'}</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.apply_date? moment(item.apply_date).format('DD-MM-YYYY') : '-'}</h1>
                                        </div>
                                        <div className=' min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.phone_number? item.phone_number : '-'}</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px] flex items-center justify-center gap-[12px]'>
                                            {item.status === 'Success' ?
                                            <div className=' bg-[#BDFFD778] rounded-[9px] flex items-center text-base-green justify-center p-[10px] text-[12px] font-medium w-[71px]'>
                                                Success
                                            </div>
                                            : item.status === 'Failed' ?
                                            <div className=' bg-[#FFBDBD78] rounded-[9px] flex items-center text-[#AE0000] justify-center p-[10px] text-[12px] font-medium w-[71px]'>
                                                Failed
                                            </div>
                                            : <div className=' bg-[#FFF7B278] rounded-[9px] flex items-center text-[#AE9200] justify-center p-[10px] text-[12px] font-medium w-full'>
                                                On Progress
                                            </div>
                                            }
                                        </div>
                                        <div className='w-1/2 flex items-center justify-center gap-[12px]'>
                                            {item.status === 'On Progress' ? (
                                                <button onClick={() => openEditRecruitmentStage(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]'/>
                                                </button>
                                            ) : (
                                                <button disabled onClick={() => openEditRecruitmentStage(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#77A8C2]'/>
                                                </button> 
                                            )}
                                        </div>
                                        <div className='w-1/2 flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => setShowModalPassingEmail(!showModalPassingEmail)}  className='w-[43px] h-[35px] bg-[#003049] rounded-[9px] flex items-center justify-center'>
                                                    <IoIosShareAlt className='text-white text-2xl'/>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        {employeeStatusRecruitment === null? (
                            <>
                                <hr className='border-dark-5 border-b-1 mb-[17px]'/>
                                <div className='flex gap-[36px]'>
                                    <h1 className='text-[14px] text-dark-5 font-medium'>End Recruitment?</h1>
                                    <div className='flex gap-[19px]'>
                                        <button onClick={() => postEndRecruitment(true)}  className='text-base-green w-[94px] h-[35px] bg-[#BDFFD778] rounded-[6px] flex items-center justify-center text-[12px]'>
                                            Success
                                        </button>
                                        <button onClick={() => postEndRecruitment(false)} className='text-[#AE0000] w-[94px] h-[35px] bg-[#FFBDBD78] rounded-[6px] flex items-center justify-center text-[12px]'>
                                            Failed
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            null
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RecruitmentStage