import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const DCardNews = ({NewsTitle, NewsDate, image, id}) => {

    const navigate = useNavigate()

    return (
        <button onClick={ () => navigate('/news/news-view', {state: {id: id}}) } className='border h-[142px] min-w-[279px] rounded-[10px] bg-cover shadow-sm' style={{ backgroundImage: `url(${image})` }}>
            <div className='h-full flex flex-col justify-end px-[8px] py-[10px] rounded-[10px] bg-gradient-to-r from-[#282828A6] via-[#2F2F2F99] to-transparent'>
                <h1 className='text-dark-9 text-[15px] font-bold text-start'>{!NewsTitle ? 'News Title' : NewsTitle}</h1>
                <h1 className='text-[#A8A8A8] text-[9px] text-start'>{!NewsDate ? moment().format('MMMM DD, YYYY') : NewsDate}</h1>
            </div>
        </button>
    )
}

export default DCardNews