import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiFillCheckCircle, AiFillClockCircle, AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { BiDownload, BiExport } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { FaTimesCircle } from 'react-icons/fa'
import { HiPaperClip } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import Select from 'react-select'
import Api from '../../../../Api'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import imageHandle from '../../../../utils/imageHandle'
import languages from '../../../../utils/languages'

const PresensiManual = () => {
    const [showDetail, setShowDetail] = useState()
    const [showAdd, setShowAdd] = useState()
    const [showModalDelete, setShowModalDelete] = useState()
    const [refresh, setRefresh] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')


    const [userName, setUserName] = useState('')
    const [userId, setUserId] = useState('')
    const FetchUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserName(response.data.results.user.fullname)
            setUserId(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }

    const [date, setDate] = useState('')
    const [time, setTime] = useState('')
    const [jenis, setJenis] = useState('')
    const [alasan, setAlasan] = useState('')
    const [file, setFile] = useState([])

    // Repeater Inputan
    const [searchTerm, setSearchTerm] = useState('');
    const [employeeName, setEmployeeName] = useState([])
    const [idEmployee, setIdEmployee] = useState([])

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    
    const [dataPegawai, setDataPegawai] = useState([])
    const getDataPegawai = async () => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'))
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))

            setDataPegawai(options)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataPegawaiPenempatan, setDataPegawaiPenempatan] = useState([])
    const getDataPegawaiByCompany = async (id) => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'), id)
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))

            setDataPegawaiPenempatan(options)
        } catch (error) {
            console.log(error)
        }
    }

    const getApproval = async () => {
        try {
            await Api.Fetch(localStorage.getItem('hris-token')).then(async (response) => {
                await Api.GetEmployeeReportToUserId(localStorage.getItem('hris-token'), response.data.results.user.id).then((res) => {
                    // console.log("Hak akses", res?.data?.results?.data?.pengaturan_persetujuan)
                    const data = res?.data?.results[0]
                    //izin
                    setIdEmployee({
                        "value": data.id ?? "",
                        "label": data.fullname ?? ""
                    })
                }).catch((err) => {
                    console.log(err)
                })
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleRemoveClickedEmployee = (name) => {
        const index = employeeName.indexOf(name);
        if (index !== -1) {
            const newIdEmployee = [...idEmployee];
            const newEmployeeName = [...employeeName];

            newIdEmployee.splice(index, 1);
            newEmployeeName.splice(index, 1);

            setIdEmployee(newIdEmployee);
            setEmployeeName(newEmployeeName);
        }
    };

    const handleNameClick = (id) => {
        const selectedOption = dataPegawai.find(
            (data) => data.id === id
        );
        if (selectedOption && !idEmployee.includes(id)) {
            setIdEmployee([...idEmployee, selectedOption.id]);
            setEmployeeName([...employeeName, selectedOption.fullname]);
        }
    };
    const handleFileChange = (event) => {
        const maxSize = 2 * 1024 * 1024
        const f = event.target.files[0]
        // console.log(f.size)
        if (f.size > maxSize) {
            toast.error('file tidak boleh lebih dari 2 MB')
        } else {
            const newFiles = Array.from(event.target.files);
            setFile([...file, newFiles]);
        }
    };

    const handleRemoveFile = (index) => {
        const newFiles = [...file];
        newFiles.splice(index, 1);
        setFile(newFiles);
    };

    const resetForm = () => {
        setDate("")
        setTime("")
        setJenis("")
        setAlasan("")
        setIdEmployee([])
        setEmployeeName([])
        setFile([])
    }

    const [data, setData] = useState([])
    const getData = async () => {
        try {
            const response = await Api.GetPresensiManual(localStorage.getItem('hris-token'), currentPage, limit, startDate, endDate)
            // console.log(response.data.results.data)
            setData(response.data.results.data)
            setCurrentPage(response.data.results.currentPage)
            setTotalPages(response.data.results.totalPages)
            setTotalCount(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }
    const [detail, setdetail] = useState([])
    const getDetailData = async (id) => {
        setShowDetail(true)
        try {
            const response = await Api.GetPresensiManualById(localStorage.getItem('hris-token'), id)
            // console.log(response.data.results)
            setdetail(response.data.results)
        } catch (error) {
            console.log(error)
        }
    }
    const [id, setId] = useState('')
    const deleteDataModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
    }
    const deleteData = async () => {
        try {
            await Api.DeletePresensiManual(localStorage.getItem('hris-token'), id).then((res) => {
                setShowModalDelete(!showModalDelete)
                setRefresh(true)
                toast.success('Berhasil Menghapus Data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data!')
        }
    }
    const postData = async () => {
        try {
            const data = {
                userId: userId,
                tanggal: date,
                jam: time,
                jenis: jenis,
                alasan: alasan,
                reportToId: idEmployee.value
            }

            const formData = new FormData();
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            if (file?.length === 0) {
                toast.error("File Bukti Tidak Boleh Kosong")
            } else {
                for (const i in file) {
                    formData.append('bukti', file[i][0]);
                }
                // for (const pair of formData.entries()) {
                //     console.log(pair[0] + ', ' + pair[1]);
                // }
                await Api.PostPresensiManual(localStorage.getItem('hris-token'), formData).then((res) => {
                    resetForm()
                    setShowAdd(!showAdd)
                    toast.success('Berhasil Menambahkan Data!')
                    setRefresh(true)
                }).catch((error) => {
                    console.log(error)
                    toast.error(error.response.data.message)
                })
            }

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data!')
        }
    }
    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    
    useEffect(() => {
        getApproval()
    }, [])
    useEffect(() => {
        FetchUser()
        getDataPegawai()
        getDataPegawaiByCompany()
        getData()
        setRefresh(false)
    }, [refresh, startDate, endDate])
    return (
        <div>
            <Modal
                activeModal={showAdd}
                title={"Tambah Formulir Khilaf Asisten"}
                buttonClose={() => setShowAdd(!showAdd)}
                width={'553px'}
                content={
                    <div className='space-y-[24px] w-[full]'>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input value={userName} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' readOnly />
                            </div>
                        </div>
                        <div className='flex gap-3'>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Tanggal <span className='text-[#780000]'>*</span></h1>
                                <input onChange={(e) => setDate(e.target.value)} value={date} type='date' className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px]  h-[35px] w-full text-[#A8A8A8]' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Jam <span className='text-[#780000]'>*</span></h1>
                                <input onChange={(e) => setTime(e.target.value)} value={time} type='time' className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px]  h-[35px] w-full text-[#A8A8A8]' />
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Jenis <span className='text-[#780000]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={(e) => setJenis(e.target.value)} value={jenis} className='text-grey-medium text-[12px] outline-none font-medium rounded-md w-full border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option selected value={''} className='text-grey-medium font-normal '>Pilih Jenis Presensi</option>
                                    <option value={"MASUK"} className='text-grey-primer'>Presensi Masuk</option>
                                    <option value={"KELUAR"} className='text-grey-primer'>Presensi Keluar</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>Alasan <span className='text-[#780000]'>*</span></h1>
                            <textarea onChange={(e) => setAlasan(e.target.value)} value={alasan} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] text-dark-5 w-full resize-none'></textarea>
                        </div>
                        <div>
                            <h1 className="text-neutral-500 text-[11px] font-medium mb-1">File <span className='text-red-900'>*</span></h1>
                            <label htmlFor='upload-file' className='cursor-pointer'>
                                <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full'>
                                    <div className='flex items-center justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                            <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                        </svg>
                                    </div>
                                    <h1 className='text-red-primer text-[12px] font-medium text-center'>Upload File Disini</h1>
                                    <div className="text-gray-500 text-[8px] text-center">PDF/PNG/JPG/JPEG maks 2 MB</div>
                                </div>
                                <input type='file' id='upload-file' multiple accept="application/pdf,image/png,image/jpeg,image/jpg" onChange={(e) => handleFileChange(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                            </label>
                            {file.length > 0 && (
                                <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                    {file.map((val, index) => (
                                        <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                            <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                                <h1>{val[0]?.name}</h1>
                                                <button
                                                    onClick={() => handleRemoveFile(index)}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                        <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <h1 className='text-[12px] text-dark-5 mb-[5px] font-medium'>{languages.language === 'id' ? "Penandatangan" : "Approval"} <span className='text-[#780000]'>*</span></h1>
                        {localStorage.getItem('hris-role') === 'ADMIN' ?
                            <Select
                                value={idEmployee}
                                onChange={selectedOption => setIdEmployee(selectedOption)}
                                options={dataPegawai}
                            />
                            : <Select
                                value={idEmployee}
                                onChange={selectedOption => setIdEmployee(selectedOption)}
                                options={dataPegawaiPenempatan}
                            />
                        }


                        <div className='relative pb-[37px] pt-[40px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAdd(!showAdd)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                </button>
                                <button onClick={postData} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah" : "Add"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showDetail}
                title={"Detail Formulir Khilaf Asisten"}
                buttonClose={() => setShowDetail(!showDetail)}
                width={'553px'}
                content={
                    <div className='space-y-[40px] w-[full]'>
                        <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-[773px]'>
                            <div className='w-[669px]'>
                                <div className="grid grid-cols-4 text-[11px] text-dark-5 gap-y-[20px] ">
                                    <h1 className='font-semibold'>Nama Pegawai</h1>
                                    <h1 className='font-medium col-span-3'>: {detail?.presensi?.user?.fullname} </h1>
                                    <h1 className='font-semibold'>Tanggal</h1>
                                    <h1 className='font-medium col-span-3'>: {detail?.presensi?.tanggal ? moment(detail?.presensi?.tanggal).format("DD-MM-YYYY") : '-'} </h1>
                                    <h1 className='font-semibold'>Jam Masuk</h1>
                                    <h1 className='font-medium col-span-3'>: {detail?.presensi?.jam ? moment(detail?.presensi?.jam).format("HH:MM") : '-'}</h1>
                                    <h1 className='font-semibold'>Jenis Presensi</h1>
                                    <h1 className='font-medium col-span-3'>: {detail?.presensi?.jenis === "MASUK" ? "Presensi Masuk" : detail?.presensi?.jenis === "KELUAR" ? "Presensi Keluar" : ""}</h1>
                                    <h1 className='font-semibold'>Alasan</h1>
                                    <h1 className='font-medium col-span-3'>: {detail?.presensi?.alasan ? detail?.presensi?.alasan : '-'}</h1>
                                    <h1 className='font-semibold'>Status Persetujuan</h1>
                                    <h1 className='font-medium col-span-3'>: {detail?.presensi?.status === "approved" ? "Disetujui" : detail?.presensi?.status === "decline" ? "Ditolak" : "Pending"}</h1>
                                    <h1 className='font-semibold'>Bukti</h1>

                                    <div>
                                        {detail.files && detail.files.map((file, idx) => (
                                            <div key={idx} className='flex items-center gap-3'>
                                                <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                                <a href={imageHandle(file?.path)} download={file?.originalname} target='_blank' rel='noreferrer' className='text-blue-primer font-semibold text-sm flex gap-2'>
                                                    <BiDownload className='text-xl' />
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowDetail(!showDetail)} className=' bg-[#0E5073] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[white] text-sm font-[500]'>{languages.language === 'id' ? "Tutup" : "Close"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteData}
            />
            <div div className='bg-[#F8F9FB] min-h-screen' >
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={"Formulir Khilaf Asisten"} LinkBack={'/time-management/my-attendance'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className='text-[20px] text-dark-3 font-semibold'>Formulir Khilaf Asisten</h1>
                            <h2 className='text-dark-5 text-[10px]'>Daftar Formulir Khilaf Asisten</h2>
                        </div>

                        <div className="flex flex-wrap justify-between items-center gap-2">
                            <div className='flex flex-wrap items-center gap-2'>
                                <input onChange={(e) => setStartDate(e.target.value)} value={startDate} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                <p>-</p>
                                <input onChange={(e) => setEndDate(e.target.value)} value={endDate} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                            </div>
                            <div>
                                <button onClick={() => setShowAdd(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus className='text-xl text-white' />
                                    <h1 className='text-dark-9 text-sm font-[500]'>Formulir Khilaf Asisten</h1>
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className='overflow-auto'>
                                <table className='w-full space-y-[10px] text-dark-5'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[50px] max-w-[50px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>No.</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Tanggal</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Jam</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Jenis</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Alasan</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Persetujuan</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Actions"}</h1>
                                        </div>
                                    </div>
                                    {data && data.map((val, index) => (
                                        <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[6px] min-w-[50px] max-w-[50px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{index + 1}</h1>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{moment(val.tanggal).format("DD-MM-YYYY")}</h1>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{val.jam ? moment(val.jam).format("HH:MM") : "-"}</h1>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{val.jenis === "MASUK" ? "Presensi Masuk" : val.jenis === "KELUAR" ? "Presensi Keluar" : ""}</h1>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{val.alasan}</h1>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[100px] max-w-[100px]'>
                                                {val.status === 'pending' ?
                                                    <AiFillClockCircle className='text-base-yellow w-[29px] h-[29px]' />
                                                    : val.status === 'approved' ?
                                                        <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]' />
                                                        :
                                                        <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]' />
                                                }
                                            </div>
                                            <div className='flex items-center justify-center gap-[6px] w-full'>
                                                <button
                                                    onClick={() => getDetailData(val.id)}
                                                    className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <AiOutlineEye className='text-[#003049]' />
                                                </button>
                                                <button
                                                    onClick={() => deleteDataModal(val.id)}
                                                    className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]' />
                                                </button>
                                                {val.status === 'approved' ?
                                                    <a
                                                        href={`/export-absen-manual/${val.id}`}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                        className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <BiExport className='text-[#003049]' />
                                                    </a>
                                                    : <button
                                                        disabled
                                                        className='w-[29px] h-[29px] bg-base-outline rounded-[9px] flex items-center justify-center'>
                                                        <BiExport className='text-[#003049]' />
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPage}
                                limitData={limit}
                                lengthData={data?.length}
                                totalPages={totalCount}
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PresensiManual