import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import languages from "../../../../../utils/languages";
import Api from "../../../../../Api";
import { toast } from "react-hot-toast";
import { Modal, ModalDelete } from "../../../../../components";
import InputComponent from "../../../../../components/Input/inputComponent";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import { MultiSelect } from "react-multi-select-component";
import { HiOutlinePencil } from "react-icons/hi";
import WaktuNormal from "./Normal";
import WaktuKhusus from "./Khusus";

function PengaturanWaktu() {
  const [showModalPeriode, setShowModalPeriode] = useState(false);
  const [menu, setMenu] = useState("normal")
  const [detail, setDetail] = useState(false)
  return (
    <>
      <div className="bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px] space-y-5">
        <div className="flex relative gap-5">
          <div className='w-[200px] h-[762px] py-[18px]  shadow rounded-l-xl border-r-6 relative pl-[18px] bg-white'>

            <button onClick={() => { setMenu("normal")}} className={menu === "normal" ? 'flex items-center justify-start bg-[#EFF9FF] w-[150px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[150px] h-[38px] rounded-lg'}>
              <h1 className={menu === "normal" ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                Normal
              </h1>
            </button>

            <button onClick={() => { setMenu("khusus")}} className={menu === "khusus" ? 'flex items-center justify-start bg-[#EFF9FF] w-[150px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[150px] h-[38px] rounded-lg'}>
              <h1 className={menu === "khusus" ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                Khusus
              </h1>

            </button>

          </div>

          <div className="ml-auto w-full p-5 space-y-5 bg-white rounded-r-xl h-[762px] overflow-y-auto border shadow-md scrollbar-default">
            {
              menu === "normal" ? (
                <WaktuNormal />
              )
                : menu === "khusus" ? (
                  <WaktuKhusus />
                ) : null
            }
          </div>
        </div>
      </div >
    </>
  );
}

export default PengaturanWaktu;
