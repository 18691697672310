import React, { useState } from 'react'
import { Navbar } from '../../../../components'
import Pegawai from './Pegawai'
import Absensi from './Absensi'

function ImportData() {
  const [menu, setMenu] = useState('Pegawai')
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
        <div className='w-full space-y-[24px] overflow-hidden'>
          <Navbar SubNavbar={true} NameSubNavbar={'Import Data'} LinkBack={'/dashboard'} />
          <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
            <div className='border-b-2 flex justify-start items-center text-[16px]'>
              <button onClick={() => setMenu('Pegawai')} className={`${menu === 'Pegawai' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Pegawai</button>
              <button onClick={() => setMenu('Absensi')} className={`${menu === 'Absensi' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Absensi</button>
            </div>

            {menu === 'Pegawai' ? (
              <Pegawai />
            ) : menu === 'Absensi' ? (
              <Absensi />
            ) : null}
          </div>
        </div>
    </div>
  )
}

export default ImportData