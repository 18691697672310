import moment from 'moment'
import React from 'react'
import imageHandle from '../../utils/imageHandle'

const InboxDetailWorkTravel = ({EmployeeName, JobPosition,  StartDate, EndDate, Location, TypeName, onClickDecline, onClickApprove, buttonHide, fileDownload}) => {
  return (
    <div>
        <div className='space-y-[40px] w-[full]'>
            <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-full'>
                <div className='w-[669px]'>
                    <div className="grid grid-cols-2 text-[11px] text-dark-5 gap-y-[20px] ">
                        <h1 className='font-semibold'>Employee Name</h1>
                        <h1 className='font-medium'>: {EmployeeName ? EmployeeName : ''} </h1>
                        <h1 className='font-semibold'>Job Position</h1>
                        <h1 className='font-medium'>: {JobPosition? JobPosition : '-'} </h1>
                        <h1 className='font-semibold'>Type Name</h1>
                        <h1 className='font-medium'>: {TypeName? TypeName : '-'} </h1>
                        <h1 className='font-semibold'>Start date</h1>
                        <h1 className='font-medium'>: {StartDate? moment(StartDate).format('DD-MM-YYYY') : '-'}</h1>
                        <h1 className='font-semibold'>End date </h1>
                        <h1 className='font-medium'>: {EndDate? moment(EndDate).format('DD-MM-YYYY') : '-'}</h1>
                        <h1 className='font-semibold'>Location</h1>
                        <h1 className='font-medium'>: {Location? Location : '-'}</h1>
                        <h1 className='font-semibold'>File</h1>
                        <a href={imageHandle(fileDownload)} target='_blank' className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</a>
                    </div>
                </div>
            </div>
        </div>
        {buttonHide &&
            <div className='flex items-center justify-end gap-[20px] mt-[21px]'>
                <button onClick={onClickDecline} className='bg-[#C1121F] text-dark-9 text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                    Decline
                </button>
                <button onClick={onClickApprove} className='bg-[#04BB00] text-dark-9 text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                    Approve
                </button>
            </div>
        }
    </div>
  )
}

export default InboxDetailWorkTravel