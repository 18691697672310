import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { LogoH } from '../../../../assets'
import { Navbar, Sidebar } from '../../../../components'
import { useLocation } from 'react-router-dom'
import Api from '../../../../Api'
import imageHandle from '../../../../utils/imageHandle'
import languages from '../../../../utils/languages'

const NewsView = () => {

    const params = useLocation()
    const [newsDetail, setNewsDetail] = useState('')
    const getData = async() => {
        try {
            const response = await Api.GetNewsDetail(localStorage.getItem('hris-token'), params.state.id)
            setNewsDetail(response.data[0])
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const linkBack = localStorage.getItem('hris-role') === 'ADMIN'? '/news' : '/dashboard'


    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={languages.language == 'id' ?"Detail Berita":"Detail News"} LinkBack={linkBack}/>
                <div className='space-y-[24px]'>
                    <div className='border-b-2 py-2'>
                        <h1 className='text-dark-3 text-[24px] font-bold'>{newsDetail.title}</h1>
                        <h1 className='text-dark-5 text-[12px] font-[500]'>{moment(newsDetail.createdAt).format('DD/MM/YYYY')}</h1>
                    </div>
                    <div className='w-full flex items-center justify-center'>
                        <img src={!newsDetail.image ? LogoH : imageHandle(newsDetail.image)} className='w-[550px] h-[363px] object-cover rounded-[8px] border shadow-lg' alt='Humanusia'/>
                    </div>
                    <h1 className='test-dark-3 text-[13px] text-justify'>{newsDetail.description}</h1>
                </div>
            </div>
        </div>
    )
}

export default NewsView