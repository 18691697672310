import React from 'react'

const InboxDetailLoan = ({EmployeeName, Policy, Amount, UseDate, Notes, Interest, Installment, buttonHide, onClickDecline, onClickApprove, jobPosition}) => {
    return (
        <div className='space-y-2'>
            <div className='bg-[#F8F9FB] space-y-[16px] rounded-[15px] px-[33px] py-[31px]'>
                <div className='border-b-2'>
                    <h1 className='text-dark-2 text-sm font-[500]'>Detail Loan</h1>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>Employee Name</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>{!EmployeeName ? ': Empty' : `: ${EmployeeName}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>Job Position</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>{!jobPosition ? ': Empty' : `: ${jobPosition}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>Loan Policy</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>{!Policy ? ': Empty' : `: ${Policy}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>Use Date</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>{!UseDate ? ': Empty' : `: ${UseDate}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>Interest</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>{!Interest ? ': Empty' : `: ${Interest}%`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>Max Installment</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>{!Installment ? ': Empty' : `: ${Installment} Mounth`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>Amount</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>{!Amount ? ': Rp. 0' : `: Rp. ${Amount}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>Notes</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-dark-5 text-[11px] font-[600]'>{!Notes ? ': Empty' : `: ${Notes}`}</h1>
                    </div>
                </div>
            </div>
            {buttonHide &&
                <div className='flex items-center justify-end gap-[20px]'>
                    <button onClick={onClickDecline} className='bg-[#C1121F] text-dark-9 text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Decline
                    </button>
                    <button onClick={onClickApprove} className='bg-[#04BB00] text-dark-9 text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Approve
                    </button>
                </div>
            }
        </div>   
    )
}

export default InboxDetailLoan