import 'leaflet/dist/leaflet.css'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaMapMarkerAlt, FaRegUser } from 'react-icons/fa'
import { FiEye } from 'react-icons/fi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { Link } from 'react-router-dom'
import Api from "../../../../Api"
import { LogoH } from '../../../../assets'
import { Modal, Navbar, Pagination } from '../../../../components'
import languages from '../../../../utils/languages'

const MyAttendance = () => {
    const [showDetailAttendance, setShowDetailAttendance] = useState()
    const [showLaporan, setShowLaporan] = useState()
    const [dataAttendance, setDataAttendance] = useState('')
    const [refresh, setRefresh] = useState(false)

    const limit = 10
    const [currentPageAttendance, setCurrentPageAttendance] = useState(1)
    const [totalPagesAttendance, setTotalPagesAttendance] = useState('')
    const [detailData, setDetailData] = useState('')


    const handlePageChange = (page) => {
        setCurrentPageAttendance(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPageAttendance === 1) {
            setCurrentPageAttendance(1)
        } else {
            setCurrentPageAttendance(currentPageAttendance - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPageAttendance === totalPagesAttendance) {
            setCurrentPageAttendance(totalPagesAttendance)
        } else {
            setCurrentPageAttendance(currentPageAttendance + 1);
        }
        setRefresh(true)
    };

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const getDataAttendance = async () => {
        try {
            const response = await Api.GetMyAttendance(localStorage.getItem('hris-token'), startDate, endDate, currentPageAttendance, limit)
            setDataAttendance(response.data.attendances)
            setCurrentPageAttendance(response.data.currentPage)
            setTotalPagesAttendance(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }

    const openDetailAttendance = async (data) => {
        setDetailData(data)
        setShowDetailAttendance(!showDetailAttendance)
        // try {
        //     const response = await Api.GetMyAttendanceById(localStorage.getItem('hris-token'), id)
        // } catch (error) {
        //     console.log(error)
        // }
    }
    const duration = (endTime, startTime) => {
        const format = "YYYY-MM-DD HH:mm:ss"; // Include the date in the format as well

        const ms = moment(endTime, format).diff(moment(startTime, format));
        const d = moment.duration(ms).abs(); // Take the absolute value of the duration
        const h = d.hours();
        const m = d.minutes();
        // const s = d.seconds();

        if (h !== 0) {
            return `${h} Jam ${m} Menit`;
        } else {
            return `${m} Menit`;
        }
    };

    const [userId, setUserId] = useState('')
    const [isAsisten, setIsAsisten] = useState("")
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setIsAsisten(response?.data?.results?.user?.user_detail?.job_category?.category_name.toLowerCase() === "asisten" ?? false)
            setUserId(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }

    const [bulan, setBulan] = useState(moment().format("M"))
    const [tahun, setTahun] = useState(moment().format("YYYY"))
    const [dataLaporan, setDataLaporan] = useState([])
    const getDataLaporan = async () => {
        try {
            const response = await Api.GetLaporanPresensi(localStorage.getItem('hris-token'), userId, bulan, tahun)
            setDataLaporan(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDataLaporan()
    }, [bulan, tahun])

    useEffect(() => {
        Fetch()
    }, [])

    useEffect(() => {
        getDataAttendance()
        setRefresh(false)
    }, [startDate, endDate, currentPageAttendance, limit, refresh])

    return (
        <div>
            <Modal
                activeModal={showDetailAttendance}
                title={languages.language === 'id' ? "Detail Kehadiran" : "Detail Attendance"}
                buttonClose={() => setShowDetailAttendance(!showDetailAttendance)}
                width={'715px'}
                content={
                    <div className='space-y-[20px] w-[full]'>
                        <>
                            <div className='bg-[#F8F8F8] w-[623px] rounded-2xl mb-[30px]'>
                                <div className='py-[20px] px-[20px]'>
                                    <h1 className=' text-dark-4 text-[13px] font-semibold mb-[22px]'>{languages.language === 'id' ? "Informasi Kehadiran" : "Attendance Information"}</h1>
                                    <div className='text-[11px] text-dark-5 font-semibold space-y-2'>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-2'>
                                                <h1>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                                            </div>
                                            <div className='col-span-10'>
                                                <h1>: {detailData?.fullname}</h1>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-2'>
                                                <h1>{languages.language === 'id' ? "Durasi" : "Duration"}</h1>
                                            </div>
                                            <div className='col-span-10'>
                                                <h1>: {detailData.time_checkin && detailData.time_checkout ? duration(detailData.time_checkin, detailData.time_checkout) : '-'}</h1>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-2'>
                                                <h1>keterangan</h1>
                                            </div>
                                            <div className='col-span-10'>
                                                <h1>: {detailData.keterangan}</h1>
                                            </div>
                                        </div>
                                        {/* <div className='grid grid-cols-12'>
                                            <div className='col-span-2'>
                                                <h1>Work Shift</h1>
                                            </div>
                                            <div className='col-span-10'>
                                                <h1>: -</h1>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-2'>
                                <div>
                                    <div className='flex text-[14px] text-dark-4 font-semibold gap-[43px] mb-[11px]'>
                                        <h1>{languages.language === 'id' ? "Presensi Masuk" : "Check in"}</h1>
                                        <h2>{detailData.time_checkin ? moment(detailData?.time_checkin).format('hh:mm') : '-'}</h2>
                                    </div>
                                    <div className='flex gap-[23px]'>
                                        <div>
                                            <h1 className='text-dark-4 text-[10px] font-medium mb-[1px]'>Photo</h1>
                                            <div className='w-[77px] h-[76px] bg-dark-9 rounded-[10px] flex items-center justify-center'>
                                                <img src={detailData.image_checkin ? detailData.image_checkin : LogoH} alt='Image_Checkin' />
                                            </div>
                                        </div>
                                        <div>
                                            <h1 className='text-dark-4 text-[10px] font-medium mb-[1px]'>{languages.language === 'id' ? "Lokasi" : "Location"}</h1>
                                            {/* <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 border'>
                                                {detailData.latitude_checkin && detailData.longtitude_checkin ? (
                                                    <MapContainer
                                                        center={[detailData?.latitude_checkin, detailData?.longtitude_checkin]}
                                                        zoom={15}
                                                        style={{ height: '100%', width: '100%' }}
                                                    >
                                                        <TileLayer
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                        />
                                                  
                                                        <Marker icon={customIcon} position={[detailData.latitude_checkin, detailData?.longtitude_checkin]}>

                                                        </Marker>
                                                    </MapContainer>
                                                ) : (
                                                    <h1 className='text-center text-[10px]'>Lokasi Tidak Ditemukan</h1>
                                                )}
                                            </div> */}

                                            <div className='flex gap-[9px]'>
                                                <FaMapMarkerAlt className='text-[#757D8A]' />
                                                <a href={`https://www.google.com/maps/?q=${detailData.latitude_checkin},${detailData.longtitude_checkin}`} target='_blank' rel='noreferrer' className='text-blue-500 text-[11px] font-[500] underline underline-offset-2'>{languages.language === 'id' ? "Lokasi" : "Location"}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex text-[14px] text-dark-4 font-semibold gap-[43px] mb-[11px]'>
                                        <h1>{languages.language === 'id' ? "Presensi Keluar" : "Check Out"}</h1>
                                        <h2>{detailData.time_checkout ? moment(detailData?.time_checkout).format('hh:mm') : '-'}</h2>
                                    </div>
                                    <div className='flex gap-[23px]'>
                                        <div>
                                            <h1 className='text-dark-4 text-[10px] font-medium mb-[1px]'>Photo</h1>
                                            <div className='w-[77px] h-[76px] bg-dark-9 rounded-[10px] flex items-center justify-center'>
                                                <img src={detailData.image_checkout ? detailData.image_checkout : LogoH} alt='Image_Checkout' />
                                            </div>
                                        </div>
                                        <div>
                                            <h1 className='text-dark-4 text-[10px] font-medium mb-[1px]'>{languages.language === 'id' ? "Lokasi" : "Location"}</h1>
                                            {/* <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 text-base border'>
                                                {detailData.latitude_checkout && detailData.longtitude_checkout ? (
                                                    <MapContainer
                                                        center={[detailData?.latitude_checkout, detailData?.longtitude_checkout]}
                                                        zoom={15}
                                                        style={{ height: '100%', width: '100%' }}
                                                    >
                                                        <TileLayer
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                        />
                                                        <Marker icon={customIcon} position={[detailData.latitude_checkout, detailData?.longtitude_checkout]}>

                                                        </Marker>
                                                    </MapContainer>
                                                ) : (
                                                    <h1 className='text-center text-[10px]'>Lokasi Tidak Ditemukan</h1>
                                                )}
                                            </div> */}

                                            <div className='flex gap-[9px]'>
                                                <FaMapMarkerAlt className='text-[#757D8A]' />
                                                <a href={`https://www.google.com/maps/?q=${detailData.latitude_checkout},${detailData.longtitude_checkout}`} target='_blank' rel='noreferrer' className='text-blue-500 text-[11px] font-[500] underline underline-offset-2'>{languages.language === 'id' ? "Lokasi" : "Location"}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                }
            />
            <Modal
                activeModal={showLaporan}
                title={"Laporan"}
                buttonClose={() => setShowLaporan(!showLaporan)}
                width={'715px'}
                content={
                    <div className='space-y-[20px] w-[full]'>
                        <>
                            <div className='space-y-[24px] w-[full]'>
                                <div className='grid grid-cols-2 gap-3'>
                                    <div className='relative'>
                                        <select onChange={(e) => setBulan(e.target.value)} value={bulan} className='text-grey-medium text-[12px] outline-none font-medium rounded-md w-full border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                            <option selected value={''} className='text-grey-medium font-normal '>Pilih Bulan</option>
                                            <option value={'1'}>Januari</option>
                                            <option value={'2'}>Februari</option>
                                            <option value={'3'}>Maret</option>
                                            <option value={'4'}>April</option>
                                            <option value={'5'}>Mei</option>
                                            <option value={'6'}>Juni</option>
                                            <option value={'7'}>Juli</option>
                                            <option value={'8'}>Agustus</option>
                                            <option value={'9'}>September</option>
                                            <option value={'10'}>Oktober</option>
                                            <option value={'11'}>November</option>
                                            <option value={'12'}>Desember</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                    </div>
                                    <div className='relative'>
                                        <select onChange={(e) => setTahun(e.target.value)} value={tahun} className='text-grey-medium text-[12px] outline-none font-medium rounded-md w-full border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                            <option selected value={''} className='text-grey-medium font-normal '>Pilih Tahun</option>
                                            <option value={'2023'}>2023</option>
                                            <option value={'2024'}>2024</option>
                                            <option value={'2025'}>2025</option>
                                            <option value={'2026'}>2026</option>
                                            <option value={'2027'}>2027</option>
                                            <option value={'2028'}>2028</option>
                                            <option value={'2029'}>2029</option>
                                            <option value={'2030'}>2030</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                    </div>
                                </div>
                                <div className='bg-[#F8F8F8] w-[623px] rounded-2xl mb-[30px]'>
                                    <div className='py-[20px] px-[20px]'>
                                        <h1 className=' text-dark-4 text-sm font-semibold mb-[22px]'>Detail Rekap Absen</h1>
                                        <div className='text-sm text-dark-5 space-y-2'>
                                            <div className='grid grid-cols-6'>
                                                <div className='col-span-2'>
                                                    <h1>Total Cuti</h1>
                                                </div>
                                                <div className='col-span-4'>
                                                    <h1>: {dataLaporan?.total_cuti ? dataLaporan?.total_cuti : 0} Hari</h1>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-6'>
                                                <div className='col-span-2'>
                                                    <h1>Total Izin</h1>
                                                </div>
                                                <div className='col-span-4'>
                                                    <h1>: {dataLaporan?.total_izin ? dataLaporan?.total_izin : 0} Hari</h1>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-6'>
                                                <div className='col-span-2'>
                                                    <h1>Total Alfa</h1>
                                                </div>
                                                <div className='col-span-4'>
                                                    <h1>: {dataLaporan?.total_alpha ? dataLaporan?.total_alpha : 0} Hari</h1>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-6'>
                                                <div className='col-span-2'>
                                                    <h1>Total Dinas Luar</h1>
                                                </div>
                                                <div className='col-span-4'>
                                                    <h1>: {dataLaporan?.total_perjalanan_dinas ? dataLaporan?.total_perjalanan_dinas : 0} Hari</h1>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-6'>
                                                <div className='col-span-2'>
                                                    <h1>Total Terlambat</h1>
                                                </div>
                                                <div className='col-span-4'>
                                                    <h1>: {dataLaporan?.total_menit_terlambat ? dataLaporan?.total_menit_terlambat : 0} Menit</h1>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-6'>
                                                <div className='col-span-2'>
                                                    <h1>Total Hari Masuk</h1>
                                                </div>
                                                <div className='col-span-4'>
                                                    <h1>: {dataLaporan?.total_hari_masuk ? dataLaporan?.total_hari_masuk : 0} Hari</h1>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-6'>
                                                <div className='col-span-2'>
                                                    <h1>Total Hari Kerja</h1>
                                                </div>
                                                <div className='col-span-4'>
                                                    <h1>: {dataLaporan?.total_hari_kerja ? dataLaporan?.total_hari_kerja : 0} Hari</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='py-[20px] px-[20px]'>
                                        <h1 className=' text-dark-4 text-sm font-semibold mb-[22px]'>Detail Potongan</h1>
                                        <div className='text-sm text-dark-5 space-y-2'>
                                            <div className='grid grid-cols-6'>
                                                <div className='col-span-2'>
                                                    <h1>Total Potongan Absensi</h1>
                                                </div>
                                                <div className='col-span-4'>
                                                    <h1>: {dataLaporan?.total_potongan_absensi ? dataLaporan?.total_potongan_absensi : 0} %</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                }
            />
            <div div className='bg-[#F8F9FB] min-h-screen' >
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Kehadiran Saya" : "My Attendance"} LinkBack={'/dashboard'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className='text-[20px] text-dark-3 font-semibold'>{languages.language === 'id' ? "Kehadiran Saya" : "My Attendace"}</h1>
                            <h2 className='text-dark-5 text-[10px]'>{languages.language === 'id' ? "Daftar Catatan Kehadiran" : "List of Attendance"}</h2>
                        </div>
                        <div className='flex flex-wrap items-center justify-between gap-2 mt-[31px]'>
                            <div className="flex flex-wrap items-center gap-2 mt-[31px]">
                                <input onChange={(e) => setStartDate(e.target.value)} value={startDate} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                <p>-</p>
                                <input onChange={(e) => setEndDate(e.target.value)} value={endDate} type="date" className='w-full lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                {/* <a href={handleLink(`attendence/export?bulan=${moment(startDate).format("M")}&tahun=${moment(startDate).format("YYYY")}&userId=${userId}`)} target='_blank' rel='noreferrer' className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <TbFileExport className='text-[#003049]' />
                                <h1 className='text-[#003049] text-sm font-[500]'>Ekspor</h1>
                            </a> */}
                            </div>
                            <div className='flex flex-wrap items-center gap-2 mt-[31px]'>
                                <button onClick={() => { setShowLaporan(true); getDataLaporan() }} className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border border-[#8E95A2] rounded-lg text-sm'>
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.6932 5.82143H15.9205V5.21429C15.9205 4.89224 15.7959 4.58338 15.5743 4.35566C15.3527 4.12793 15.0521 4 14.7386 4H10.0114C9.69793 4 9.39733 4.12793 9.17569 4.35566C8.95406 4.58338 8.82955 4.89224 8.82955 5.21429V5.82143H7.05682C6.74338 5.82143 6.44278 5.94936 6.22115 6.17708C5.99951 6.40481 5.875 6.71367 5.875 7.03571V19.7857C5.875 20.1078 5.99951 20.4166 6.22115 20.6443C6.44278 20.8721 6.74338 21 7.05682 21H17.6932C18.0066 21 18.3072 20.8721 18.5289 20.6443C18.7505 20.4166 18.875 20.1078 18.875 19.7857V7.03571C18.875 6.71367 18.7505 6.40481 18.5289 6.17708C18.3072 5.94936 18.0066 5.82143 17.6932 5.82143ZM10.0114 5.21429H14.7386V7.64286H10.0114V5.21429ZM17.6932 19.7857H7.05682V7.03571H8.82955V8.85714H15.9205V7.03571H17.6932V19.7857Z" fill="#113B61" />
                                        <path d="M8.875 14.0472H14.2083V15.3805H8.875V14.0472ZM8.875 10.7139H16.875V12.0472H8.875V10.7139ZM8.875 17.3805H12.2083V18.7139H8.875V17.3805Z" fill="#113B61" />
                                    </svg>
                                    Lihat Laporan
                                </button>
                                {isAsisten ?
                                    <Link to={"/time-management/my-attendance/manual"} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <FaRegUser className='text-xl text-white' />
                                        <h1 className='text-dark-9 text-sm font-[500]'>Formulir Khilaf Asisten</h1>
                                    </Link>
                                    : null
                                }
                            </div>
                        </div>
                        <div>
                            <div className='overflow-auto'>
                                <table className='w-full space-y-[10px] text-dark-5'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Tanggal" : "Date"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Presensi Masuk" : "Check In"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Lokasi" : "Location"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Presensi Keluar" : "Check Out"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Lokasi" : "Location"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Durasi" : "Duration"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Keterangan</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Actions"}</h1>
                                        </div>
                                    </div>
                                    {
                                        dataAttendance && dataAttendance.map((item, index) => (
                                            <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{!item.date ? "-" : moment(item.date).format('DD MMMM YYYY')}</h1>
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{!item.time_checkin ? "-" : moment(item.time_checkin).format('HH:mm')}</h1>
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                                                    <a href={`https://www.google.com/maps/?q=${item.latitude_checkin},${item.longtitude_checkin}`} target='_blank' rel='noreferrer' className='text-blue-500 text-[11px] font-[500] underline underline-offset-2'>{languages.language === 'id' ? "Lokasi" : "Location"}</a>
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{!item.time_checkout ? '-' : moment(item.time_checkout).format('HH:mm')}</h1>
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                                                    <a href={`https://www.google.com/maps/?q=${item.latitude_checkout},${item.longtitude_checkout}`} target='_blank' rel='noreferrer' className='text-blue-500 text-[11px] font-[500] underline underline-offset-2'>{languages.language === 'id' ? "Lokasi" : "Location"}</a>
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{!item.time_checkout ? '-' : duration(item.time_checkin, item.time_checkout)}</h1>
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{item?.keterangan ?? "-"}</h1>
                                                </div>
                                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                                    <button onClick={() => openDetailAttendance(item)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <FiEye className='text-[#003049]' />
                                                    </button>
                                                    {/* {!item.time_checkout ?
                                                        <button onClick={() => CheckOutDataAttendance(item.id)} className=' bg-[#CEDFEA] rounded-[9px] w-[92px] flex items-center justify-center text-[#003049] py-[5px] px-[15px] text-[11px] font-semibold'>
                                                            {languages.language === 'id' ?"Presensi Keluar":"Check Out"}
                                                        </button>
                                                        : null} */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPageAttendance}
                                totalPages={totalPagesAttendance}
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyAttendance