import { saveAs } from 'file-saver'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiFilter, BiPlus } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'
import { CgTrashEmpty } from 'react-icons/cg'
import { FiSearch } from 'react-icons/fi'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { TbFileExport } from 'react-icons/tb'
import { Link, useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import Api from '../../../../Api'
import { LogoH } from '../../../../assets'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import imageHandle from '../../../../utils/imageHandle'
import languages from '../../../../utils/languages'
import moment from 'moment'

const EmployeeList = () => {
    moment.locale('id');
    const navigate = useNavigate()
    const [showFilter, setShowFilter] = useState()
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [employeeData, setEmployeeData] = useState('')
    const [employeeId, setEmployeeId] = useState('')
    const [nameSearch, setNameSearch] = useState('')

    // openIndex
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)

    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handleCollapse = (id) => {
        if (id) {
            setCurrent(id)
            setActive(!Active)
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const [penempatan, setPenempatan] = useState("")
    const [unitKerja, setUnitKerja] = useState("")
    const [kelasJabatan, setKelasJabatan] = useState("")
    const [jenisJabatan, setJenisJabatan] = useState("")
    const [jenisKepegawaian, setJenisKepegawaian] = useState("")
    const [jabatan, setJabatan] = useState("")
    const GetEmployee = async () => {
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), currentPage, limit, nameSearch, penempatan, unitKerja, kelasJabatan, jenisJabatan, jenisKepegawaian, jabatan)
            setEmployeeData(response.data.data.filter(item => item.role === 'USER'))
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)
        } catch (error) {
            console.log(error)
        }
    }

    const [dataPenempatan, setDataPenempatan] = useState([])
    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [dataKelasJabatan, setDataKelasJabatan] = useState([])
    const [dataJenisJabatan, setDataJenisJabatan] = useState([])
    const [dataJenisKepegawaian, setDataJenisKepegawaian] = useState([])
    const [dataJabatan, setDataJabatan] = useState([])
    const GetData = async () => {
        try {
            const res = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            const res2 = await Api.GetUnitKerjaEselon(localStorage.getItem('hris-token'))
            const res3 = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            const res4 = await Api.GetJobLevelDropdown(localStorage.getItem('hris-token'))
            const res5 = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
            const res6 = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'), unitKerja)
            setDataPenempatan(res.data.results.data)
            setDataUnitKerja(res2.data.results.data)
            setDataKelasJabatan(res3.data.results.data)
            setDataJenisJabatan(res4.data.results.data)
            setDataJenisKepegawaian(res5.data.results.data)
            setDataJabatan(res6.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleResetFilter = () => {
        setPenempatan("")
        setUnitKerja("")
        setKelasJabatan("")
        setJenisJabatan("")
        setJenisKepegawaian("")
        setJabatan("")
        setRefresh(true)
        setShowFilter(!showFilter)
        // window.location.reload()
    }

    const DeleteEmployeeModal = async (id) => {
        setShowModalDelete(!showModalDelete)
        setEmployeeId(id)
    }

    const DeleteEmployee = async (id) => {
        try {
            await Api.DeleteEmployee(localStorage.getItem('hris-token'), employeeId).then((val) => {
                setRefresh(true)
                setShowModalDelete(!showModalDelete)
                toast.success('Success Delete')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')

        }
    }

    const toggleHandler = async (id, value) => {
        const updatedEmployees = employeeData.map(employee => {
            if (employee.id === id) {
                // Memperbarui properti isWFH berdasarkan id pegawai yang dipilih
                return { ...employee, user_detail: { isWFH: !employee.user_detail.isWFH } };
            }
            return employee;
        });
        setEmployeeData(updatedEmployees);
        try {
            const data = {
                userId: [id],
                isWFH: !value,
            }
            await Api.DataWFH(localStorage.getItem('hris-token'), data).then((response) => {
                navigate('/employee')
                toast.success('Berhasil Mengubah Data!')
                setRefresh(true)
            }).catch((error) => {
                console.log(error)
                toast.error("Gagal Mengubah Data!")
            })
        } catch (error) {
            console.error('Error updating employee:', error);
            setEmployeeData(prevEmployees => prevEmployees.map(employee => {
                if (employee.id === id) {
                    return { ...employee, user_detail: { isWFH: !employee.user_detail.isWFH } };
                }
                return employee;
            }));
        }
    };

    const [selectedRows, setSelectedRows] = useState([]);
    const handleSelectRow = (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const isSelected = (id) => selectedRows.includes(id);
    const handleSelectAll = () => {
        if (selectedRows.length === employeeData.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(employeeData.map(item => item.id));
        }
    };

    const handleIsWfh = async (value) => {
        try {
            const data = {
                isWFH: value,
                userId: selectedRows
            }
            await Api.DataWFH(localStorage.getItem('hris-token'), data).then((response) => {
                setSelectedRows([])
                toast.success('Berhasil Mengubah Data!')
                setRefresh(true)
            }).catch((error) => {
                console.log(error)
                toast.error("Gagal Mengubah Data!")
            })
        } catch (error) {
            console.error('Error updating employee:', error);
        }
    }
    // Export Employee
    const exportToExcel = async () => {
        // Sample data array
        let data
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), 1, totalCount, nameSearch, penempatan, unitKerja, kelasJabatan, jenisJabatan, jenisKepegawaian, jabatan)
            data = response.data.data.filter(item => item.role === 'USER')
        } catch (error) {
            console.log(error)
        }

        // Define custom headers for each table
        const Headers = ['Employee ID', 'Nama', 'Jenis Kelamin', 'Email', 'Email Pribadi', 'Nomor Handphone', 'Telepon', 'Tanggal Lahir', 'Alamat', 'Provinsi', 'Kabupaten/Kota', 'Kecamatan', 'Kode Pos', 'Status Perkawinan', 'Kedudukan Hukum', 'Tanggal Masuk Ombudsman', 'Kelas Jabatan', 'Jenis Jabatan', 'Jenis Kepegawaian', 'Jabatan'];

        // Create modified data arrays with custom headers
        const jobGrade = data.map(({ fullname, user_detail, email, phone, isVerified }) => ({
            'Employee ID': user_detail?.employee_id || '-',
            'Nama': fullname || '-',
            'Jenis Kelamin': user_detail?.gender || '-',
            'Email': email || '-',
            'Email Pribadi': user_detail?.email_pribadi || '-',
            'Nomor Handphone': user_detail?.mobile_phone || '-',
            'Telepon': phone || '-',
            'Tanggal Lahir': user_detail?.birthdate || '-',
            'Alamat': user_detail?.street || '-',
            'Provinsi': user_detail?.province || '-',
            'Kabupaten/Kota': user_detail?.city || '-',
            'Kecamatan': user_detail?.kecamatan || '-',
            'Kode Pos': user_detail?.postal_code || '-',
            'Status Perkawinan': user_detail?.marital_status || '-',
            'Kedudukan Hukum': user_detail?.kedudukan_hukum || '-',
            'Tanggal Masuk Ombudsman': user_detail?.tanggal_masuk_ombudsman || '-',
            'Kelas Jabatan': user_detail?.job_grade?.job_grade || '-',
            'Jenis Jabatan': user_detail?.job_level?.level_name || '-',
            'Jenis Kepegawaian': user_detail?.job_category?.category_name || '-',
            'Jabatan': user_detail?.job_position?.position_name || '-'
        }));

        // Create a new worksheet for each table
        const worksheetGrade = XLSX.utils.json_to_sheet(jobGrade, { header: Headers });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Pegawai');
        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, `Data Pegawai ${moment().format("DDMMYYYY")}.xlsx`);
    };

    useEffect(() => {
        GetData()
        setRefresh(false)
    }, [refresh, limit])

    useEffect(() => {
        GetEmployee()
        setRefresh(false)
    }, [refresh, nameSearch, limit])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={DeleteEmployee}
            />
            <Modal
                activeModal={showFilter}
                buttonClose={() => setShowFilter(!showFilter)}
                width={'550px'}
                title={
                    <div className='flex gap-[27px] items-center'>
                        <BsFilter className='text-dark-1 text-2xl' />
                        <h1 className='text-dark-1 text-[20px] font-semibold'>Filter</h1>
                    </div>
                }
                content={
                    <div className='space-y-[20px]'>
                        <div className='grid grid-cols-2 gap-3'>
                            <div className='relative'>
                                <select onChange={(e) => setPenempatan(e.target.value)} value={penempatan} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Penempatan</option>
                                    {dataPenempatan && dataPenempatan.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.company_name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='relative'>
                                <select onChange={(e) => setUnitKerja(e.target.value)} value={unitKerja} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Unit Kerja</option>
                                    {dataUnitKerja && dataUnitKerja.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='relative'>
                                <select onChange={(e) => setKelasJabatan(e.target.value)} value={kelasJabatan} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Kelas Jabatan</option>
                                    {dataKelasJabatan && dataKelasJabatan.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.job_grade}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='relative'>
                                <select onChange={(e) => setJenisJabatan(e.target.value)} value={jenisJabatan} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Jenis Jabatan</option>
                                    {dataJenisJabatan && dataJenisJabatan.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.level_name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='relative'>
                                <select onChange={(e) => setJenisKepegawaian(e.target.value)} value={jenisKepegawaian} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Jenis Kepegawaian</option>
                                    {dataJenisKepegawaian && dataJenisKepegawaian.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.category_name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='relative'>
                                <select onChange={(e) => setJabatan(e.target.value)} value={jabatan} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} selected className='text-grey-medium font-normal'>Pilih Jabatan</option>
                                    {dataJabatan && dataJabatan.map((val, index) => (
                                        <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.position_name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                        </div>
                        <div className='flex items-center justify-between'>
                            <button onClick={handleResetFilter} className='py-[10px] px-[15px] text-[#0E5073] text-[14px] font-medium flex justify-center rounded-md items-center border'>Reset Filter</button>
                            <button onClick={() => { GetEmployee(); setShowFilter(false) }} className='py-[10px] px-[15px] bg-[#0E5073] text-white text-[14px] font-medium flex justify-center rounded-md items-center'>Filter</button>
                        </div>
                    </div>
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Pegawai" : "Employee"} LinkBack={'/dashboard'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-grey-primer text-[20px] font-[600]'>
                                {languages.language === 'id' ? "Daftar Pegawai" : "List of Employee"}
                            </h1>
                            <h1 className='text-grey-thick text-[14px] font-[400]'>
                                {languages.language === 'id' ? "Daftar Pegawai di Instansi" : "List of employees in the company"}
                            </h1>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                        <div className='flex items-center gap-1 mt-3 lg:mt-0 w-full lg:w-auto'>
                            <button onClick={() => setShowFilter(!showFilter)} className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <BiFilter className='text-[#0E5073]' />
                                <h1 className='text-[#0E5073] text-sm font-[500]'>Filter</h1>
                            </button>
                            <select onChange={(e) => setLimit(e.target.value)} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                <option value="" selected disabled>Set Limit</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <button onClick={exportToExcel} className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <TbFileExport className='text-[#0E5073]' />
                                <h1 className='text-[#0E5073] text-sm font-[500]'>{languages.language === 'id' ? "Ekspor" : "Export"}</h1>
                            </button>
                            {selectedRows.length > 0 ?
                                <select onChange={(e) => handleIsWfh(e.target.value)} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                    <option value="" selected disabled>Status</option>
                                    <option value="true">WFH</option>
                                    <option value="false">WFO</option>
                                </select>
                                : null
                            }
                            {/* <Link to={'mass-resign'} className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <VscSignOut className='text-[#8E95A2]'/>
                                <h1 className='text-[#8E95A2] text-sm font-[500]'>{languages.language === 'id' ? "Resign Massal" : "Mass Resign"}</h1>
                            </Link> */}
                        </div>
                        <div className='flex flex-col lg:flex-row lg:items-center gap-[13px] mt-3 lg:mt-0 w-full lg:w-auto'>
                            <div className='relative'>
                                <input onChange={(e) => setNameSearch(e.target.value)} placeholder='Cari Nama Pegawai...' className='bg-white border rounded-[6px] text-[#A8A8A8] text-sm pl-10 w-full lg:w-[300px] flex items-center gap-[12px] px-[10px] py-[8px]' />
                                <FiSearch className='text-[#A8A8A8] absolute top-[11px] left-3' />
                            </div>
                            <Link to={'add-employee'} className='bg-[#0E5073] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <BiPlus className='text-white' />
                                <h1 className='text-white text-sm font-[500]'>{languages.language === 'id' ? "Tambah Pegawai" : "Add Employee"}</h1>
                            </Link>
                        </div>
                    </div>
                    <div className='overflow-auto'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.length === employeeData.length}
                                        onChange={handleSelectAll}
                                        className="form-checkbox"
                                    />
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Pegawai" : "Employee"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px] justify-center'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Kelas Jabatan" : "Job Grade"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px] justify-center'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Jenis Jabatan" : "Job Level"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px] justify-center'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Jabatan" : "Job Position"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px] justify-center'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Kedudukan Hukum" : "Employee Status"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px] justify-center'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>Status</h1>
                                </div>
                                <div className='flex items-center gap-[15px] justify-center w-full'>
                                    <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                </div>
                            </div>
                            {employeeData?.length === 0 ?
                                <div>
                                    <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] rounded-t-[3px]`}>
                                        <div className='min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='flex items-center justify-center w-full gap-[12px]'>
                                            <h1 className='text-dark-5 text-xs font-[500] truncate'>-</h1>
                                        </div>
                                    </div>
                                </div>
                                :
                                employeeData.map((data, index) => (
                                    <div key={index}>
                                        <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] rounded-t-[3px]`}>
                                            <div className='min-w-[100px] max-w-[100px]'>
                                                <input
                                                    type="checkbox"
                                                    checked={isSelected(data.id)}
                                                    onChange={() => handleSelectRow(data.id)}
                                                    className="form-checkbox"
                                                />
                                            </div>
                                            <div className='min-w-[300px] max-w-[300px]'>
                                                <div className='flex gap-[15px]'>
                                                    <div className='w-[34px] h-[34px] bg-white border'>
                                                        <img className='w-full h-full' src={data.user_detail?.image ? imageHandle(data.user_detail?.image) : LogoH} alt="Profil" />
                                                    </div>
                                                    <div>
                                                        <button onClick={() => navigate('/employee/detail-employee', { state: { employeeId: data.id } })} className='text-grey-medium text-xs font-[500] truncate hover:underline'>{!data?.fullname ? '-' : data.fullname}</button>
                                                        <h1 className='text-grey-extra-thin text-xs font-[500] truncate'>{!data?.user_detail?.employee_id ? '-' : data?.user_detail?.employee_id}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-dark-5 text-xs font-[500] truncate text-center'>{!data.user_detail?.job_grade ? '-' : data.user_detail.job_grade.job_grade}</h1>
                                            </div>
                                            <div className='min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-dark-5 text-xs font-[500] truncate text-center'>{!data.user_detail?.job_level ? '-' : data.user_detail.job_level.level_name}</h1>
                                            </div>
                                            <div className='min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-dark-5 text-xs font-[500] truncate text-center'>{!data.user_detail?.job_position ? '-' : data.user_detail.job_position.position_name}</h1>
                                            </div>
                                            <div className='min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-dark-5 text-xs font-[500] truncate text-center'>{!data.user_detail?.kedudukan_hukum ? '-' : data.user_detail.kedudukan_hukum}</h1>
                                            </div>
                                            <div className='min-w-[150px] max-w-[150px]'>
                                                <label className="inline-flex gap-2 items-center justify-center cursor-pointer w-full">
                                                    <input
                                                        type="checkbox"
                                                        value=""
                                                        className="sr-only peer"
                                                        checked={data.user_detail.isWFH}
                                                        onChange={() => toggleHandler(data.id, data.user_detail.isWFH)}
                                                    />
                                                    <div className={`relative w-11 h-6 bg-gray-500 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600`}></div>
                                                    <span className="text-dark-5 text-xs font-[500] truncate text-center">{data.user_detail.isWFH ? 'WFH' : 'WFO'}</span>
                                                </label>
                                            </div>
                                            <div className='flex items-center justify-center w-full gap-[12px]'>
                                                <button onClick={() => navigate('/profile', { state: { employeeId: data.id } })} className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                <button onClick={() => DeleteEmployeeModal(data.id)} className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]' />
                                                </button>
                                                <button onClick={() => handleCollapse(data.id)} className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                    {data.id === Current && !Active ?
                                                        <MdKeyboardArrowUp className='text-[#003049]' />
                                                        :
                                                        <MdKeyboardArrowDown className='text-[#003049]' />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden py-2 ${data.id === Current && !Active ? 'h-fit border' : 'h-0 border-0 hidden'}`}>
                                            <div className='px-4 pt-4 pb-1'>
                                                <h1 className='text-dark-5 text-xs font-[500] col-span-6'>Detail Catatan Pegawai</h1>
                                            </div>
                                            <div className='grid grid-cols-12 gap-5 px-4 py-2'>
                                                <h1 className='text-dark-5 text-xs font-[500] col-span-6'>{languages.language === 'id' ? "Informasi Pegawai" : "Detail Employee Information"}</h1>
                                            </div>
                                            <div className='grid grid-cols-12 gap-5 px-4 py-2'>
                                                <div className='flex flex-col gap-3 col-span-2'>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Email</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Unit Kerja</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Penempatan</h1>
                                                </div>
                                                <div className='flex flex-col gap-3 col-span-1'>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                </div>
                                                <div className='flex flex-col gap-3 col-span-3'>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{data?.email}</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{data?.data_unit_kerja?.unitKerja?.name}</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{data?.user_detail?.subsidiary?.company_name}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        lengthData={totalCount}
                        limitData={limit}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmployeeList