import React, { useState } from 'react'
import { BsFillFileEarmarkFill } from 'react-icons/bs'
import { Navbar, Sidebar } from '../../../../../components'
import imageHandle from '../../../../../utils/imageHandle'
import { toast } from 'react-hot-toast'
import Api from '../../../../../Api'
import { useNavigate } from 'react-router-dom'

const JobBulkUpload = () => {
    
    const navigate = useNavigate()
    const [namepath, setNamePath] = useState('')
    const [file, setFile] = useState('')

    const handleChangeFile = (e) => {
        setNamePath(e.target.files[0].name)
        setFile(e.target.files[0])
    }

    const ImportData = async() => {
        const data = {
            file: file
        }
        try {
            const response = await Api.UploadJobManagement(localStorage.getItem('hris-token'), data)
            toast.success('Import Data Job Management Successfully')
            navigate('/job-management')
        } catch (error) {
            console.log(error)
        }
    }
    
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Bulk Upload Job Management'} LinkBack={'/job-management'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] font-[600]'>Bulk Upload</h1>
                        <h1 className='text-dark-5 text-[10px]'>Import a file to update external user data </h1>
                    </div>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] text-[16px] font-[600]'>Step :</h1>
                        <ul className='list-disc text-[#464E5F] text-[12px] list-outside ml-3'>
                            <li>
                                Select the type of work template
                            </li>
                            <li>
                                Download Job tamplate management, <span className='text-[#780000]'><a href={imageHandle('/public/assets/files/import/Import Data Job Management.xlsx')} target='_blank'>Here !!!</a></span>
                            </li>
                            <li>
                                Fill in Job data according to the available template
                            </li>
                            <li>
                                Make sure the data you fill is correct
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] text-[16px] font-[600]'>Upload File <span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <div className='flex items-center gap-[13px]'>
                            <label htmlFor='upload-file' className='cursor-pointer'>
                                <div className='w-[100px] h-[100px] rounded-[12px] bg-dark-9 border border-dashed border-dark-5 flex flex-col items-center justify-center bg-cover relative'>
                                    <BsFillFileEarmarkFill className='text-2xl text-dark-5'/>
                                    <h1 className='text-dark-5 text-[11px] font-semibold mt-[10px]'>Upload File</h1>
                                </div>
                                <input type='file' id='upload-file' accept=".xlsx, .xls" onChange={(e) => handleChangeFile(e)}  className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required/>
                            </label>
                            <div className='space-y-2'>
                                <ul className='list-disc list-inside space-y-2'>
                                    <li className='text-xs text-dark-5 font-[500]'>Size: Max. 10 MB</li>
                                    <li className='text-xs text-dark-5 font-[500]'>Format: Excel</li>
                                </ul>
                                <div className='py-[6px] px-[20px] rounded-full bg-dark-5'>
                                    <h1 className='text-[10px] text-white'>{!namepath ? 'No One Data To Upload' : namepath}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button onClick={ImportData} className='bg-[#0E5073] rounded-[6px] flex items-center gap-[12px] px-[17px] py-[10px]'>
                        <h1 className='text-white text-sm font-[500]'>Upload</h1>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default JobBulkUpload