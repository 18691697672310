import moment from 'moment'
import React from 'react'

const InsentifAsisten = ({data, month}) => {
    const formatRupiah = (number) => {
        // Menggunakan regex untuk menambahkan separator titik setiap 3 digit
        return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  return (
    <table className='w-full space-y-[10px]'>
            <div className='bg-[#EBF7FF] rounded-[3px] border border-[#F1F1F1] p-[9px] flex items-center gap-[20px]'>
                <div className={`flex items-center gap-[11px] min-w-[50px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>No.</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>NIO</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>NAMA</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>JENJANG JABATAN</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>KELAS JABATAN</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>LAPORAN KINERJA</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>INSENTIF KERJA</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>POT RAPEL</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>POTONGAN {moment(month).format("MMMM").toUpperCase()}</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>POTONGAN INSENTIF</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>INSENTIF BRUTO</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>POTONGAN PAJAK</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>POTONGAN IURAN BPJS KETENAGAKERJAAN</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>JUMLAH POTONGAN</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>PAGU DIBAYARKAN</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>NAMA REKENING BNI</h1>
                </div>
                <div className={`flex items-center gap-[11px] min-w-[150px]`}>
                    <h1 className='capitalize text-dark-5 text-[11px] font-semibold'>NOMOR REKENING BNI</h1>
                </div>
            </div>
            <div className='space-y-[10px]'>
                {data && data.map((value, index) => (
                    <div key={index} className='bg-white rounded-[3px] p-[9px] flex items-center gap-[20px]'>
                        <div className='flex items-center gap-[11px] min-w-[50px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{index + 1}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.nip}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.fullname}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.jenjang_jabatan}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.kelas_jabatan}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.laporan_kinerja}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.insentif_kerja)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.pot_rapel}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.potongan}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.potongan_insentif)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.insentif_bruto)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.potongan_pajak)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.potongan_iuran_bpjs)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.jumlah_potongan)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>Rp. {formatRupiah(value.pagu_dibayarkan)}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.nama_rekening}</h1>
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px]'>
                            <h1 className='capitalize text-grey-medium text-[12px] font-medium line-clamp-1'>{value.nomor_rekening}</h1>
                        </div>
                    </div>
                ))}
            </div>
        </table>
  )
}

export default InsentifAsisten