import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai';
import { CgTrashEmpty } from 'react-icons/cg';
import { HiOutlinePencil } from 'react-icons/hi';
import { MultiSelect } from 'react-multi-select-component';
import Api from '../../../../../../Api';
import { Modal, ModalDelete, Pagination } from '../../../../../../components';
import InputComponent from '../../../../../../components/Input/inputComponent';
import DetailKhusus from './Detail';

function WaktuKhusus() {
    moment.locale('id');
    const [showModalPeriode, setShowModalPeriode] = useState(false);
    const [showModalEditPeriode, setShowModalEditPeriode] = useState(false);
    const [detail, setDetail] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const handleInputChange = (inputName, inputValue) => {
        setFormData((prevData) => ({
            ...prevData,
            [inputName]: inputValue,
        }));
    };

    const [formData, setFormData] = useState({
        name: "",
        start_periode: "",
        end_periode: "",
        companyId: ""
    });

    const resetFormData = () => {
        setFormData({
            name: "",
            start_periode: "",
            end_periode: "",
            companyId: ""
        });
    };

    const postPeriode = async () => {
        try {
            // console.log(formData);
            await Api.PostPeriodeKhusus(localStorage.getItem('hris-token'), formData).then((response) => {
                // console.log(response)
                resetFormData()
                setRefresh(true)
                toast.success('Sukses Menambahkan Data')
                setShowModalPeriode(false)
            }).catch((error) => {
                console.log(error)
                toast.error(error.response.data.message)
                // toast.error('Gagal Menambahkan Data')
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    };

    const updatePeriode = async () => {
        try {
            await Api.UpdatePeriodeKhusus(localStorage.getItem('hris-token'), formData, id).then((response) => {
                // console.log(response)
                resetFormData()
                setRefresh(true)
                toast.success('Sukses Edit Data')
                setShowModalEditPeriode(false)
            }).catch((error) => {
                console.log(error)
                toast.error(error.response.data.message)
                // toast.error('Gagal Edit Data')
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Edit Data')
        }
    };

    const [data, setData] = useState([])
    const getDataPeriode = async () => {
        try {
            const response = await Api.GetPeriode(localStorage.getItem('hris-token'))
            // console.log(response.data.results.data)
            setData(response.data.results.data)
            setCurrentPage(response.data.results.currentPage)
            setTotalPages(response.data.results.totalPages)
            setTotalCount(response.data.results.totalCount)
        } catch (error) {
            console.log(error)
        }
    }
    const getDataPeriodeById = async (id) => {
        setShowModalEditPeriode(true)
        try {
            const response = await Api.GetPeriodeById(localStorage.getItem('hris-token'), id)
            setId(id)
            const res = response.data.results.data
            const company = res.companies && res.companies.map(val => (val.id))
            setFormData({
                name: res.name,
                start_periode: res.start_periode,
                end_periode: res.end_periode,
                companyId: company
            });
            const options = res.companies && res.companies.map(val => ({
                "value": val.id,
                "label": val.company_name
            }))
            setSelectedPenempatan(options)
        } catch (error) {
            console.log(error)
        }
    }
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [id, setId] = useState('')

    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setId(id)
        setRefresh(true)
    }

    const deletePeriode = async () => {
        try {
            await Api.DeletePeriode(localStorage.getItem('hris-token'), id).then((response) => {
                setRefresh(true)
                setShowModalDelete(!showModalDelete)
                toast.success('Hapus Data Berhasil')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }

    const [dataPenempatan, setDataPenempatan] = useState([])
    const getSubsidiary = async () => {
        try {
            const response = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            // console.log("subsidiary", response.data.results.data)
            const data = response.data.results.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.company_name
            }))
            setDataPenempatan(options)
        } catch (error) {
            console.log(error)
        }
    }
    const [selectedPenempatan, setSelectedPenempatan] = useState([])
    const handleChangePenempatan = (e) => {
        const data = []
        e && e.map(val => (
            data.push(val.value)
        ))
        handleInputChange("companyId", data)
        setSelectedPenempatan(e)
        // setSelectedAssignTo(data)
    }

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    useEffect(() => {
        getDataPeriode()
        setRefresh(false)
    }, [refresh])

    useEffect(() => {
        getSubsidiary()
    }, [])

    return (
        <>
            <div className="relative">
                <Modal
                    activeModal={showModalPeriode}
                    title={"Tambah Periode Khusus"}
                    buttonClose={() => setShowModalPeriode(!showModalPeriode)}
                    width={'550px'}
                    content={
                        <div className='space-y-6'>
                            <div className="grid grid-cols-2 gap-x-5 gap-y-2 pb-[37px]">
                                <div className="col-span-2">
                                    <InputComponent
                                        disabled={false}
                                        label={"Nama"}
                                        value={formData.name}
                                        onChange={(e) => handleInputChange("name", e)}
                                        required={true}
                                        type={"text"}
                                    />
                                </div>
                                <div className="">
                                    <InputComponent
                                        disabled={false}
                                        label={"Periode Awal"}
                                        value={formData.start_periode}
                                        onChange={(e) => handleInputChange("start_periode", e)}
                                        required={true}
                                        type={"date"}
                                    />
                                </div>
                                <div className="">
                                    <InputComponent
                                        disabled={false}
                                        label={"Periode Akhir"}
                                        value={formData.end_periode}
                                        onChange={(e) => handleInputChange("end_periode", e)}
                                        required={true}
                                        type={"date"}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">
                                        Penempatan <span className="text-red-primer">*</span>
                                    </h2>
                                    {/* <div className="relative">
                                        <select
                                            value={formData.companyId}
                                            onChange={(e) => handleInputChange("companyId", e.target.value)}
                                            className="bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none"
                                        >
                                            <option selected disabled value={""}>
                                                Pilih Penempatan
                                            </option>
                                            {dataPenempatan && dataPenempatan.map((val, index) => (
                                                <option key={index} value={val.id}>{val.company_name}</option>
                                            ))}
                                        </select>
                                        <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                                    </div> */}
                                    <MultiSelect
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={selectedPenempatan}
                                        options={dataPenempatan}
                                        onChange={(e) => handleChangePenempatan(e)}
                                    />
                                </div>
                            </div>

                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => setShowModalPeriode(!showModalPeriode)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                                <button onClick={postPeriode} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Tambah</button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showModalEditPeriode}
                    title={"Edit Periode Khusus"}
                    buttonClose={() => setShowModalEditPeriode(!showModalEditPeriode)}
                    width={'550px'}
                    content={
                        <div className='space-y-6'>
                            <div className="grid grid-cols-2 gap-x-5 gap-y-2 pb-[37px]">
                                <div className="col-span-2">
                                    <InputComponent
                                        disabled={false}
                                        label={"Nama"}
                                        value={formData.name}
                                        onChange={(e) => handleInputChange("name", e)}
                                        required={true}
                                        type={"text"}
                                    />
                                </div>
                                <div className="">
                                    <InputComponent
                                        disabled={false}
                                        label={"Periode Awal"}
                                        value={formData.start_periode}
                                        onChange={(e) => handleInputChange("start_periode", e)}
                                        required={true}
                                        type={"date"}
                                    />
                                </div>
                                <div className="">
                                    <InputComponent
                                        disabled={false}
                                        label={"Periode Akhir"}
                                        value={formData.end_periode}
                                        onChange={(e) => handleInputChange("end_periode", e)}
                                        required={true}
                                        type={"date"}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">
                                        Penempatan <span className="text-red-primer">*</span>
                                    </h2>
                                    {/* <div className="relative">
                                        <select
                                            value={formData.companyId}
                                            onChange={(e) => handleInputChange("companyId", e.target.value)}
                                            className="bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none"
                                        >
                                            <option selected disabled value={""}>
                                                Pilih Penempatan
                                            </option>
                                            {dataPenempatan && dataPenempatan.map((val, index) => (
                                                <option key={index} value={val.id}>{val.company_name}</option>
                                            ))}
                                        </select>
                                        <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                                    </div> */}
                                    <MultiSelect
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={selectedPenempatan}
                                        options={dataPenempatan}
                                        onChange={(e) => handleChangePenempatan(e)}
                                    />
                                </div>
                            </div>

                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => setShowModalEditPeriode(!showModalEditPeriode)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                                <button onClick={updatePeriode} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Simpan</button>
                            </div>
                        </div>
                    }
                />

                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={deletePeriode}
                />
            </div>
            {!detail ?
                <>
                    <div className="w-full flex justify-between items-center">
                        <div className='w-full lg:w-auto mb-[20px]'>
                            <h1 className='text-dark-3 text-[20px] font-[600]'>Pengaturan Waktu</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[400]'>Daftar Jam Kerja Khusus</h1>
                        </div>
                        <button onClick={() => setShowModalPeriode(!showModalPeriode)} className='h-fit bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                            <AiOutlinePlus className='text-xl text-white' />
                            <h1 className='text-dark-9 text-sm font-[500]'>Tambah Periode</h1>
                        </button>
                    </div>
                    <div className="mt-5 overflow-auto">
                        <table className="w-full space-y-[10px]">
                            <div className="flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]">
                                <div className="flex items-center gap-[15px] min-w-[50px] max-w-[50px]">
                                    <h1 className="text-grey-thick text-xs font-medium">No.</h1>
                                </div>
                                <div className="flex items-center gap-[15px] min-w-[200px] max-w-[200px]">
                                    <h1 className="text-grey-thick text-xs font-medium">Nama</h1>
                                </div>
                                <div className="flex items-center gap-[15px] min-w-[150px] max-w-[150px]">
                                    <h1 className="text-grey-thick text-xs font-medium">Periode Awal</h1>
                                </div>
                                <div className="flex items-center gap-[15px] min-w-[150px] max-w-[150px]">
                                    <h1 className="text-grey-thick text-xs font-medium">Periode Akhir</h1>
                                </div>
                                <div className="flex items-center gap-[15px] min-w-[150px] max-w-[150px]">
                                    <h1 className="text-grey-thick text-xs font-medium">Penempatan</h1>
                                </div>
                                <div className="flex items-center gap-[15px]">
                                    <h1 className="text-grey-thick text-xs font-medium">Aksi</h1>
                                </div>
                            </div>
                            {data && data.map((val, index) => (
                                <div key={index} className="flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]">
                                    <div className="min-w-[50px] max-w-[50px]">
                                        <h1 className="text-grey-thick text-xs font-medium ">{index + 1}.</h1>
                                    </div>
                                    <div className="min-w-[200px] max-w-[200px]">
                                        <h1 className="text-grey-thick text-xs font-medium ">{val.name}</h1>
                                    </div>
                                    <div className="min-w-[150px] max-w-[150px]">
                                        <h1 className="text-grey-thick text-xs font-medium ">{moment(val?.start_periode).format("DD MMMM YYYY")}</h1>
                                    </div>
                                    <div className="min-w-[150px] max-w-[150px]">
                                        <h1 className="text-grey-thick text-xs font-medium ">{moment(val?.end_periode).format("DD MMMM YYYY")}</h1>
                                    </div>
                                    <div className="min-w-[150px] max-w-[150px]">
                                        {val?.companies?.length === dataPenempatan?.length ?
                                            <h1 className='text-grey-thick text-xs font-medium truncate'>Seluruh Penempatan</h1>
                                            : val?.companies && val?.companies.map((comp, idx) => (
                                                <h1 className='text-grey-thick text-xs font-medium truncate'>{comp.company_name}{val?.companies?.length - (idx + 1) !== 0 ? "," : ""}</h1>
                                            ))
                                        }
                                    </div>
                                    <div className="flex gap-3">
                                        <button onClick={() => { setDetail(!detail); setId(val.id) }} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <AiOutlineEye className='text-[#003049]' />
                                        </button>
                                        <button onClick={() => getDataPeriodeById(val.id)} className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-[#003049]' />
                                        </button>
                                        <button onClick={() => deleteModal(val.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]' />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        limitData={data?.length}
                        lengthData={totalCount}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </>
                :
                <DetailKhusus idPeriode={id} />
            }
        </>
    )
}

export default WaktuKhusus