import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BiExport } from 'react-icons/bi'
import Api from '../../../../Api'
import { Pagination } from '../../../../components'
import languages from '../../../../utils/languages'

const Cuti = ({ startDate, endDate, exportData, name, isAsc, limitData }) => {
    const [data, setData] = useState([])

    const [refresh, setRefresh] = useState(false)
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const getDataCuti = async () => {
        try {
            const response = await Api.GetCuti(localStorage.getItem('hris-token'), currentPage, limitData, startDate, endDate, name, isAsc)
            setData(response.data.results.leaves)
            setCurrentPage(response.data.results.currentPage)
            setTotalPages(response.data.results.totalPages)
            setTotalCount(response.data.results.totalCount)
            exportData(response.data.results.totalCount)
            // console.log(response.data.results.leaves, 'atendance')
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getDataCuti()
        setRefresh(false)
    }, [refresh, startDate, endDate, name, isAsc, limitData])
    return (
        <>
            <div>
                <div className='overflow-auto'>
                    <table className='w-full space-y-[10px]'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language == 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language == 'id' ? "Tanggal Mulai" : "Start Date"}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language == 'id' ? "Tanggal Selesai" : "End Date"}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language == 'id' ? "Jenis Cuti" : "Type"}</h1>
                            </div>
                            <div className='flex items-center gap-[6px]'>
                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language == 'id' ? "Aksi" : "Action"}</h1>
                            </div>
                        </div>
                        {data && data.map((item, index) => (
                            <div key={index} className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.fullname}</h1>
                                </div>
                                <div className='min-w-[120px] max-w-[120px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(item.start_date).format('DD-MM-YYYY')}</h1>
                                </div>
                                <div className='min-w-[120px] max-w-[120px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(item.end_date).format('DD-MM-YYYY')}</h1>
                                </div>
                                <div className='min-w-[120px] max-w-[120px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item?.leavetype?.type_name}</h1>
                                </div>
                                <div className='w-full flex items-start justify-start gap-[12px]'>
                                    {/* <button onClick={() => openDetailLeave(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <AiOutlineEye className='text-[#003049]' />
                                        </button> */}
                                    {item.status == 'approved' ?
                                        <a
                                            href={`/export-cuti/${item.id}`}
                                            target='_blank'
                                            className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <BiExport className='text-[#003049]' />
                                        </a>
                                        : <button
                                            disabled
                                            className='w-[29px] h-[29px] bg-base-outline rounded-[9px] flex items-center justify-center'>
                                            <BiExport className='text-[#003049]' />
                                        </button>
                                    }

                                </div>
                            </div>
                        ))}
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    limitData={limitData}
                    lengthData={totalCount}
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </>
    )
}

export default Cuti