import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { BiPrinter } from 'react-icons/bi';
import { IconLocFingerprint } from '../assets';

const ExportSka = ({ data, fileExport, onClick }) => {
    const val = data
    let counter = 1;
    const generatePDF = () => {
        const doc = new jsPDF();

        doc.autoTable({
            head: [
                ['FORMULIR SASARAN KERJA'],
                ['ASISTEN OMBUDSMAN RI'],
            ],
            theme: 'plain', // Gunakan 'plain' untuk menghilangkan border default
            styles: {
                fontSize: 12,
                textColor: [0, 0, 0],
                valign: 'middle',
                overflow: 'linebreak',
                lineColor: [0, 0, 0], // Warna border, akan diabaikan karena lineWidth adalah 0
                lineWidth: 0, // Set border width to 0 to make borders invisible
                cellPadding: 1
            },
            headStyles: {
                fontSize: 12,
                fontStyle: 'bold',
                halign: 'center',
                lineWidth: 0 // Pastikan tidak ada border untuk header cells
            },
        });
        // Tambahkan tabel dari elemen HTML
        doc.autoTable({
            html: '#my-table',
            startY: 40, // Mulai tabel di bawah teks
            theme: 'striped',
            styles: {
                fontSize: 9,
                textColor: [0, 0, 0],
                cellPadding: 1,
                lineWidth: 0.2,
                lineColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                overflow: 'linebreak',
                cellPadding: 3,
                halign: 'left',
                valign: 'middle'
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255]
            },
            columnStyles: { 0: { halign: 'center' }, 3: { halign: 'center', } },
            didParseCell: (data) => {
                // data.row.index memberikan indeks baris saat ini
                if (data.row.index === 0 || data.row.index === 6) {
                    // Setel halign untuk baris tertentu
                    data.cell.styles.halign = 'center';
                    data.cell.styles.valign = 'middle';
                }
            },
        });

        doc.autoTable({
            body: [
                ['Pejabat Penilai', 'Asisten Yang Dinilai'],
                ['#', '^'],
                [val?.pegawaiDinilai?.fullname, val?.pejabatPenilai?.fullname],
                [`NIO. ${val?.pegawaiDinilai?.nomor_induk_pegawai}`, `NIO. ${val?.pejabatPenilai?.nomor_induk_pegawai}`]
            ],
            theme: 'plain', // Gunakan 'plain' untuk menghilangkan border default
            styles: {
                fontSize: 9,
                textColor: [0, 0, 0],
                cellPadding: 1,
                lineWidth: 0,
                lineColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                overflow: 'linebreak',
                halign: 'center',
                valign: 'middle',
            },
            pageBreak: 'auto',
            didParseCell: (data) => {
                // data.row.index memberikan indeks baris saat ini
                if (data.row.index === 1) {
                    // Setel halign untuk baris tertentu
                    data.cell.styles.minCellHeight = 35;
                }
            },
        });

        // Add footer
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            if (i === pageCount) {
                doc.setPage(i);
                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();
                doc.setFontSize(9);
                doc.setTextColor(3, 1, 240);
                doc.setFont('times', 'italic');
                doc.text(` Dokumen ini telah ditandatangani secara elektronik menggunakan sertifikat elektronik yang diterbitkan oleh BSrE`, pageWidth / 2, pageHeight - 10, {
                    align: 'center',
                });
            }
        }

        // Simpan PDF
        const pdfOutput = doc.output('blob');
        fileExport(pdfOutput)
        // doc.save('example.pdf')
    };
    const handleButtonClick = () => {
        onClick();
        generatePDF()
    };
    return (
        <div>
            <button
                onClick={handleButtonClick}
                className='px-[8px] py-[7px] w-fit border border-gray-400 rounded-lg text-blue-thick text-[14px]'>
                <img src={IconLocFingerprint} className='h-10 w-auto' />
            </button>
            <table className='hidden' id="my-table">
                <tbody>
                    <tr>
                        <td>NO</td>
                        <td colSpan={2}>I. PEJABAT PENILAI</td>
                        <td>NO</td>
                        <td colSpan={4}>II. ASISTEN YANG DINILAI</td>
                    </tr>
                    <tr>
                        <td>1.</td>
                        <td>Nama</td>
                        <td>{val?.pegawaiDinilai?.fullname}</td>
                        <td>1.</td>
                        <td>Nama</td>
                        <td colSpan={3}>{val?.pejabatPenilai?.fullname}</td>
                    </tr>
                    <tr>
                        <td>2.</td>
                        <td>NIO</td>
                        <td>{val?.pegawaiDinilai?.nomor_induk_pegawai}</td>
                        <td>2.</td>
                        <td>NIO</td>
                        <td colSpan={3}>{val?.pejabatPenilai?.nomor_induk_pegawai}</td>
                    </tr>
                    <tr>
                        <td>3.</td>
                        <td>Pangkat</td>
                        <td>{val?.pegawaiDinilai?.pangkat}</td>
                        <td>3.</td>
                        <td>Pangkat</td>
                        <td colSpan={3}>{val?.pejabatPenilai?.pangkat}</td>
                    </tr>
                    <tr>
                        <td>4.</td>
                        <td>Jabatan</td>
                        <td>{val?.pegawaiDinilai?.job_position}</td>
                        <td>4.</td>
                        <td>Jabatan</td>
                        <td colSpan={3}>{val?.pejabatPenilai?.job_position}</td>
                    </tr>
                    <tr>
                        <td>5.</td>
                        <td>Unit Kerja</td>
                        <td>{val?.pegawaiDinilai?.unit_kerja}</td>
                        <td>5.</td>
                        <td>Unit Kerja</td>
                        <td colSpan={3}>{val?.pejabatPenilai?.unit_kerja}</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>NO</td>
                        <td rowSpan={2} colSpan={2}>III. KEGIATAN TUGAS POKOK JABATAN</td>
                        <td rowSpan={2}></td>
                        <td colSpan={4}>TARGET</td>
                    </tr>
                    <tr>
                        <td>KUANTITAS/<br></br>OUTPUT</td>
                        <td>KUALITAS/<br></br>MUTU</td>
                        <td>WAKTU</td>
                        <td>BIAYA</td>
                    </tr>

                    {val?.rhk && val?.rhk.map((v) => (
                        v?.rencana_aksi && v?.rencana_aksi.map((item, index) => (
                            <tr key={index}>
                                <td>{counter++}</td>
                                <td colSpan={2}>{item?.name}</td>
                                <td></td>
                                <td>{item?.target_kuantitas} Dokumen</td>
                                <td>{item?.target_kualitas}</td>
                                <td>{item?.target_waktu} Bulan</td>
                                <td></td>
                            </tr>
                        ))
                    ))}
                </tbody>
            </table>
            {/* <button onClick={generatePDF} className='px-[8px] py-[7px] border border-gray-400 rounded-lg text-blue-thick text-[14px]'>
                <BiPrinter className='text-lg' />
            </button> */}
        </div>
    );
};

export default ExportSka;
