import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil, HiOutlinePencilAlt } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdImportExport } from 'react-icons/md'
import { MultiSelect } from 'react-multi-select-component'
import Api from '../../../../Api'
import { Modal, ModalDelete, Navbar } from '../../../../components'
import languages from '../../../../utils/languages'

const PayrollSetting = () => {
    const [menu, setMenu] = useState('Take Home Pay')
    const [showAddIncome, setShowAddIncome] = useState()
    const [showEditIncome, setShowEditIncome] = useState()
    const [showAddDeduction, setShowAddDeduction] = useState()
    const [showEditDeduction, setShowEditDeduction] = useState()
    const [showAddBenefit, setShowAddBenefit] = useState()
    const [showEditBenefit, setShowEditBenefit] = useState()
    const [showEditTHR, setShowEditTHR] = useState()
    const [showAddNPP, setShowAddNPP] = useState()
    const [showEditNPP, setShowEditNPP] = useState()
    const [isUnderstand, setIsUnderstand] = useState(false)
    const [isUnderstandInTHR, setIsUnderstandInTHR] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    // State Payroll
    const [selectedAssignTo, setSelectedAssignTo] = useState([])
    const [selected, setSelected] = useState([])
    const [jobGrade, setJobGrade] = useState([])
    const [jobLevel, setJobLevel] = useState([])
    const [jobPosition, setJobPosition] = useState([])
    const [jobCategory, setJobCategory] = useState([])


    const getSelectJobGrade = async () => {
        try {
            const res = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.job_grade
            }))

            setJobGrade(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobCategory = async () => {
        try {
            const res = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.category_name
            }))

            setJobCategory(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobLevel = async () => {
        try {
            const res = await Api.GetJobLevelDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.level_name
            }))

            setJobLevel(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobPosition = async () => {
        try {
            const res = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.position_name
            }))

            setJobPosition(options)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const data = []
        e && e.map(val => (
            data.push(val.value)
        ))
        setSelected(e)
        setSelectedAssignTo(data)
    }

    const [subsidiary, setSubsidiary] = useState([])
    const getSelectSubsidiary = async () => {
        try {
            const res = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            // const options = data && data.map(val => ({
            //     "value": val.id,
            //     "label": val.company_name
            // }))

            // setSubsidiary(options)
            setSubsidiary(data)
        } catch (error) {
            console.log(error)
        }
    }

    const [dataUser, setDataUser] = useState([])

    const getUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setDataUser(response.data.results.user)
            setSubsidiaryJkk(response.data.results.user.companyId)
        } catch (error) {
            console.log(error)
        }
    }

    // Income
    const [showModalDeleteIncome, setShowModalDeleteIncome] = useState(false)
    const [dataIncome, setDataIncome] = useState([])
    const [idIncome, setIdIncome] = useState('')
    const [titleIncome, setTitleIncome] = useState('')
    const [amountIncome, setAmountIncome] = useState('')
    const [paymentTypeIncome, setPaymentTypeIncome] = useState('')
    const [taxIncome, setTaxIncome] = useState('')
    const [assignTo, setAssignTo] = useState('')

    const getIncome = async () => {
        try {
            await Api.GetIncome(localStorage.getItem('hris-token'))
                .then((response) => {
                    // console.log(response)
                    setDataIncome(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    // const e = error.response.data.errors.error.details
                    // for(let i=0; i<e.length; i++){
                    //     toast.error(error.response.data.errors.error.details[i].message)
                    // }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const getIncomeById = async (id) => {
        try {
            await Api.GetIncomeById(localStorage.getItem('hris-token'), id)
                .then((response) => {
                    const value = response.data[0]
                    setIdIncome(value?.id)
                    setTitleIncome(value?.title)
                    setAmountIncome(value?.amount)
                    setPaymentTypeIncome(value?.payment_type)
                    setTaxIncome(value?.tax)
                    const assign_to = Object.keys(value.assign_to);
                    if (assign_to[0] === "ALL") {
                        setAssignTo(assign_to[0])
                    } else if (assign_to[0] === "job_category") {
                        setAssignTo(assign_to[0])
                        let options = {}
                        if (assign_to.length > 1) {
                            options = value.assign_to.job_category && value.assign_to.job_category.map(val => ({
                                "value": val.id,
                                "label": val.name
                            }))
                        } else {
                            options = [{
                                "value": value.assign_to.job_category.id,
                                "label": value.assign_to.job_category.name
                            }]
                        }
                        setSelected(options)
                    } else if (assign_to[0] === "job_position") {
                        setAssignTo(assign_to[0])
                        let options = {}
                        if (assign_to.length > 1) {
                            options = value.assign_to.job_position && value.assign_to.job_position.map(val => ({
                                "value": val.id,
                                "label": val.name
                            }))
                        } else {
                            options = [{
                                "value": value.assign_to.job_position.id,
                                "label": value.assign_to.job_position.name
                            }]
                        }
                        setSelected(options)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    // const e = error.response.data.errors.error.details
                    // for(let i=0; i<e.length; i++){
                    //     toast.error(error.response.data.errors.error.details[i].message)
                    // }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const deleteModalIncome = async (id) => {
        setShowModalDeleteIncome(!showModalDeleteIncome)
        setIdIncome(id)
    }
    const deleteIncome = async () => {
        try {
            await Api.DeleteIncome(localStorage.getItem('hris-token'), idIncome)
            setShowModalDeleteIncome(!showModalDeleteIncome)
            // setRefresh(true)
            getIncome()
            toast.success('Berhasil Menghapus Data!')
        } catch (error) {
            toast.error('Gagal Menghapus Data!')
        }
    }
    const resetFormIncome = () => {
        setTitleIncome("")
        setAmountIncome("")
        setPaymentTypeIncome("")
        setTaxIncome("")
        setAssignTo("")
    }
    const postIncome = async () => {
        try {
            var dataAssigTo = {}
            if (assignTo === "ALL") {
                dataAssigTo.ALL = true
            } else if (assignTo === "job_grade") {
                dataAssigTo.job_grade = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssigTo.job_position = selectedAssignTo
            } else if (assignTo === "job_level") {
                dataAssigTo.job_level = selectedAssignTo
            }
            const data = {
                "title": titleIncome,
                "amount": amountIncome,
                "payment_type": paymentTypeIncome, // Enum ( monthly, weekly, daily, onceTime )
                "tax": taxIncome, // Enum ( Taxable, Non Taxable )
                "assign_to": dataAssigTo
            }
            // console.log(data)
            await Api.PostIncome(localStorage.getItem('hris-token'), data).then((response) => {
                setShowAddIncome(!showAddIncome)
                getIncome()
                resetFormIncome()
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const editIncome = async () => {
        try {
            let dataAssignTo = {}
            if (assignTo === "ALL") {
                dataAssignTo.ALL = true
            } else if (assignTo === "job_category") {
                dataAssignTo.job_category = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssignTo.job_position = selectedAssignTo
            }
            const data = {
                "title": titleIncome,
                "amount": amountIncome,
                "payment_type": paymentTypeIncome, // Enum ( monthly, weekly, daily, onceTime )
                "tax": taxIncome, // Enum ( Taxable, Non Taxable )
                "assign_to": dataAssignTo
            }
            await Api.UpdateIncome(localStorage.getItem('hris-token'), data, idIncome).then((response) => {
                setShowEditIncome(!showEditIncome)
                getIncome()
                resetFormIncome()
                toast.success('Berhasil Mengubah Data!')
            }).catch((error) => {
                console.log(error?.response?.data?.message)
                toast.error(error?.response?.data?.message)
                // const e = error.response.data.errors.error.details
                // for (let i = 0; i < e.length; i++) {
                //     toast.error(error.response.data.errors.error.details[i].message)
                // }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const handleEditIncome = (id) => {
        setShowEditIncome(true)
        getIncomeById(id)
    }

    // Deduction
    const [showModalDeleteDeduction, setShowModalDeleteDeduction] = useState(false)
    const [dataDeduction, setDataDeduction] = useState([])
    const [idDeduction, setIdDeduction] = useState('')
    const [titleDeduction, setTitleDeduction] = useState('')
    const [amountDeduction, setAmountDeduction] = useState('')
    const [paymentTypeDeduction, setPaymentTypeDeduction] = useState('')
    const [taxDeduction, setTaxDeduction] = useState('')

    const getDeduction = async () => {
        try {
            await Api.GetDeduction(localStorage.getItem('hris-token'))
                .then((response) => {
                    // console.log(response)
                    setDataDeduction(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    // const e = error.response.data.errors.error.details
                    // for(let i=0; i<e.length; i++){
                    //     toast.error(error.response.data.errors.error.details[i].message)
                    // }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const getDeductionById = async (id) => {
        try {
            await Api.GetDeductionById(localStorage.getItem('hris-token'), id)
                .then((response) => {
                    console.log(response.data[0])
                    const value = response.data[0]
                    setIdDeduction(value?.id)
                    setTitleDeduction(value?.title)
                    setAmountDeduction(value?.amount)
                    setPaymentTypeDeduction(value?.payment_type)
                    setTaxDeduction(value?.tax)
                    const assign_to = Object.keys(value.assign_to);
                    if (assign_to[0] === "ALL") {
                        setAssignTo(assign_to[0])
                    } else if (assign_to[0] === "job_category") {
                        setAssignTo(assign_to[0])
                        let options = {}
                        if (assign_to.length > 1) {
                            options = value.assign_to.job_category && value.assign_to.job_category.map(val => ({
                                "value": val.id,
                                "label": val.name
                            }))
                        } else {
                            options = [{
                                "value": value.assign_to.job_category.id,
                                "label": value.assign_to.job_category.name
                            }]
                        }
                        setSelected(options)
                    } else if (assign_to[0] === "job_position") {
                        setAssignTo(assign_to[0])
                        let options = {}
                        if (assign_to.length > 1) {
                            options = value.assign_to.job_position && value.assign_to.job_position.map(val => ({
                                "value": val.id,
                                "label": val.name
                            }))
                        } else {
                            options = [{
                                "value": value.assign_to.job_position.id,
                                "label": value.assign_to.job_position.name
                            }]
                        }
                        setSelected(options)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    // const e = error.response.data.errors.error.details
                    // for(let i=0; i<e.length; i++){
                    //     toast.error(error.response.data.errors.error.details[i].message)
                    // }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const deleteModalDeduction = async (id) => {
        setShowModalDeleteDeduction(!showModalDeleteDeduction)
        setIdDeduction(id)
    }
    const deleteDeduction = async () => {
        try {
            await Api.DeleteDeduction(localStorage.getItem('hris-token'), idDeduction)
            setShowModalDeleteDeduction(!showModalDeleteDeduction)
            // setRefresh(true)
            getDeduction()
            toast.success('Berhasil Menghapus Data!')
        } catch (error) {
            toast.error('Gagal Menghapus Data!')
        }
    }
    const resetFormDeduction = () => {
        setTitleDeduction("")
        setAmountDeduction("")
        setPaymentTypeDeduction("")
        setTaxDeduction("")
        setAssignTo("")
    }
    const postDeduction = async () => {
        try {
            var dataAssigTo = {}
            if (assignTo === "ALL") {
                dataAssigTo.ALL = true
            } else if (assignTo === "job_grade") {
                dataAssigTo.job_grade = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssigTo.job_position = selectedAssignTo
            } else if (assignTo === "job_level") {
                dataAssigTo.job_level = selectedAssignTo
            }
            const data = {
                "title": titleDeduction,
                "amount": amountDeduction,
                "payment_type": paymentTypeDeduction,
                "tax": taxDeduction,
                "assign_to": dataAssigTo
            }
            // console.log(data)
            await Api.PostDeduction(localStorage.getItem('hris-token'), data).then((response) => {
                setShowAddDeduction(!showAddDeduction)
                getDeduction()
                resetFormDeduction()
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const editDeduction = async () => {
        try {
            let dataAssignTo = {}
            if (assignTo === "ALL") {
                dataAssignTo.ALL = true
            } else if (assignTo === "job_category") {
                dataAssignTo.job_category = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssignTo.job_position = selectedAssignTo
            }
            const data = {
                "title": titleDeduction,
                "amount": amountDeduction,
                "payment_type": paymentTypeDeduction,
                "tax": taxDeduction,
                "assign_to": dataAssignTo
            }
            console.log(data)
            await Api.UpdateDeduction(localStorage.getItem('hris-token'), data, idDeduction).then((response) => {
                setShowEditDeduction(!showEditDeduction)
                getDeduction()
                resetFormDeduction()
                toast.success('Berhasil Mengubah Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const handleEditDeduction = (id) => {
        setShowEditDeduction(true)
        getDeductionById(id)
    }

    // Benefit
    const [showModalDeleteBenefit, setShowModalDeleteBenefit] = useState(false)
    const [dataBenefit, setDataBenefit] = useState([])
    const [idBenefit, setIdBenefit] = useState('')
    const [titleBenefit, setTitleBenefit] = useState('')
    const [amountBenefit, setAmountBenefit] = useState('')
    const [paymentTypeBenefit, setPaymentTypeBenefit] = useState('')
    const [taxBenefit, setTaxBenefit] = useState('')

    const getBenefit = async () => {
        try {
            await Api.GetBenefit(localStorage.getItem('hris-token'))
                .then((response) => {
                    // console.log(response)
                    setDataBenefit(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    // const e = error.response.data.errors.error.details
                    // for(let i=0; i<e.length; i++){
                    //     toast.error(error.response.data.errors.error.details[i].message)
                    // }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const deleteModalBenefit = async (id) => {
        setShowModalDeleteBenefit(!showModalDeleteBenefit)
        setIdBenefit(id)
    }
    const deleteBenefit = async () => {
        try {
            await Api.DeleteBenefit(localStorage.getItem('hris-token'), idBenefit)
            setShowModalDeleteBenefit(!showModalDeleteBenefit)
            // setRefresh(true)
            getBenefit()
            toast.success('Berhasil Menghapus Data!')
        } catch (error) {
            toast.error('Gagal Menghapus Data!')
        }
    }
    const resetFormBenefit = () => {
        setTitleBenefit("")
        setAmountBenefit("")
        setPaymentTypeBenefit("")
        setTaxBenefit("")
        setAssignTo("")
    }
    const postBenefit = async () => {
        try {
            var dataAssigTo = {}
            if (assignTo === "ALL") {
                dataAssigTo.ALL = true
            } else if (assignTo === "job_grade") {
                dataAssigTo.job_grade = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssigTo.job_position = selectedAssignTo
            } else if (assignTo === "job_level") {
                dataAssigTo.job_level = selectedAssignTo
            }
            const data = {
                "title": titleBenefit,
                "amount": amountBenefit,
                "payment_type": paymentTypeBenefit, // Enum ( monthly, weekly, daily, onceTime )
                "tax": taxBenefit, // Enum ( Taxable, Non Taxable )
                "assign_to": dataAssigTo
            }
            // console.log(data)
            await Api.PostBenefit(localStorage.getItem('hris-token'), data).then((response) => {
                setShowAddBenefit(!showAddBenefit)
                getBenefit()
                resetFormBenefit()
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    // JKK
    const [showModalDeleteJKK, setShowModalDeleteJKK] = useState(false)
    const [dataJKK, setDataJKK] = useState([])
    const [idJKK, setIdJKK] = useState('')
    const [titleNpp, setTitleNpp] = useState('')
    const [nppNumber, setNppNumber] = useState('')
    const [subsidiaryJkk, setSubsidiaryJkk] = useState('')
    const [jkk, setJkk] = useState('')

    const getJKK = async () => {
        try {
            await Api.GetJKK(localStorage.getItem('hris-token'))
                .then((response) => {
                    // console.log(response.data)
                    setDataJKK(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    // const e = error.response.data.errors.error.details
                    // for(let i=0; i<e.length; i++){
                    //     toast.error(error.response.data.errors.error.details[i].message)
                    // }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const openEditJKK = async (id) => {
        setIdJKK(id)
        setShowEditNPP(!showEditNPP)
        try {
            const response = await Api.GetJKKById(localStorage.getItem('hris-token'), id)
            setIdJKK(response.data[0].id)
            setTitleNpp(response.data[0].title)
            setNppNumber(response.data[0].npp_number)
            setJkk(response.data[0].jkk)
            setSubsidiaryJkk(response.data[0].subsidiaryId)
        } catch (error) {
            console.log(error)
        }
    }
    const deleteModalJKK = async (id) => {
        setShowModalDeleteJKK(!showModalDeleteJKK)
        setIdJKK(id)
    }
    const deleteJKK = async () => {
        try {
            await Api.DeleteJKK(localStorage.getItem('hris-token'), idJKK)
            setShowModalDeleteJKK(!showModalDeleteJKK)
            // setRefresh(true)
            getJKK()
            toast.success('Berhasil Menghapus Data!')
        } catch (error) {
            toast.error('Gagal Menghapus Data!')
        }
    }
    const postJKK = async () => {
        try {
            const data = {
                "title": titleNpp,
                "npp_number": nppNumber,
                "subsidiaryId": subsidiaryJkk,
                "jkk": jkk // Enum ( 0.24, 0.54, 0.89, 1.27, 1.74 )
            }
            // console.log(data)
            await Api.PostJKK(localStorage.getItem('hris-token'), data).then((response) => {
                getJKK()
                setIsUnderstand(false)
                setShowAddNPP(!showAddNPP)
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const updateJKK = async () => {
        try {
            const data = {
                "title": titleNpp,
                "npp_number": nppNumber,
                "subsidiaryId": subsidiaryJkk,
                "jkk": jkk // Enum ( 0.24, 0.54, 0.89, 1.27, 1.74 )
            }
            await Api.UpdateJKK(localStorage.getItem('hris-token'), data, idJKK).then((response) => {
                getJKK()
                setIsUnderstand(false)
                setShowEditNPP(!showEditNPP)
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getIncome()
        getDeduction()
        getBenefit()
        getJKK()
        getUser()
        getSelectJobGrade()
        getSelectJobLevel()
        getSelectJobPosition()
        getSelectJobCategory()
    }, [])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showEditTHR}
                title={!isUnderstandInTHR ? 'Formula for calculating Employee THR' : 'Add THR'}
                buttonClose={() => setShowEditTHR(!showEditTHR)}
                width={'553px'}
                content={
                    <div className='space-y-[20px] w-[full]'>
                        {!isUnderstandInTHR ? (
                            <>
                                <h1 className=" text-neutral-500 text-sm font-normal">In general, the formula for calculating THR holiday allowances is </h1>
                                <div className="w-[449px] h-[87px] bg-gray-200 rounded-[3px]" />
                                <h1 className="w-[460px] text-neutral-500 text-sm font-normal">This formula also applies to calculating THR for employees of any status, including how to calculate THR for contract employees.<br /></h1>
                                <div className='flex items-center justify-end'>
                                    <button onClick={() => setIsUnderstandInTHR(true)} className='px-[30px] py-2.5 bg-sky-900 rounded-md text-white text-sm font-medium'>
                                        Okay, I Understand
                                    </button>
                                </div>
                            </>

                        ) : (
                            <>
                                content dalam thr
                            </>
                        )}


                    </div>
                }
            />
            <Modal
                activeModal={showAddNPP}
                title={!isUnderstand ? 'JKK BPJS Ketenagakerjaan' : 'Tambah NPP BPJS Ketenagakerjaan'}
                buttonClose={() => setShowAddNPP(!showAddNPP)}
                width={'553px'}
                content={
                    <div className='space-y-[20px] w-[full]'>
                        {!isUnderstand ? (
                            <>
                                <div className="w-[473px] text-zinc-700 text-sm font-normal ">
                                    {languages.language === 'id' ?
                                        "BPJS Ketenagakerjaan memiliki beberapa jenis program, salah satunya adalah JKK. Program JKK (Jaminan Kecelakaan Kerja) merupakan program yang memberikan perlindungan terhadap risiko kecelakaan yang terjadi dalam hubungan kerja."
                                        : "BPJS Ketenagakerjaan has several types of programs, one of which is JKK. The JKK (Jaminan Kecelakaan Kerja) program is a program that provides protection against the risk of accidents that occur in work relationships."}
                                </div>
                                <div className="text-zinc-700 text-sm font-semibold ">
                                    {languages.language === 'id' ?
                                        <ol className="list-decimal list-inside">
                                            <li>Tingkat risiko sangat rendah yaitu 0,24% dari gaji per bulan.</li>
                                            <li>Tingkat risiko rendah sebesar 0,54% dari gaji per bulan.</li>
                                            <li>Tingkat risiko sedang adalah 0,89% dari gaji per bulan.</li>
                                            <li>Tingkat risiko tinggi adalah 1,27% dari gaji per bulan.</li>
                                            <li>Tingkat risiko yang sangat tinggi adalah 1,74% dari gaji per bulan.</li>
                                        </ol>
                                        : "1. Very low risk level of 0.24% of wages per month.<br />2. Low risk level of 0.54% of wages per month.<br />3. Medium risk level is 0.89% of wages per month.<br />4. High risk level is 1.27% of wages per month.<br />5. Very high risk level is  1.74% of wages per month."}</div>

                                <div className='flex justify-end'>
                                    <button onClick={() => setIsUnderstand(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-fit'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Oke, saya mengerti" : "Okay, I Understand"}</h1>
                                    </button>
                                </div>
                            </>

                        ) : (
                            <>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama NPP" : "NPP Name"} <span className='text-red-primer'>*</span></h1>
                                    <input type="text" onChange={(e) => setTitleNpp(e.target.value)} className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder={languages.language === 'id' ? "Input Nama NPP" : "Input NPP name..."} />
                                </div>

                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nomor NPP" : "NPP Number"} <span className='text-red-primer'>*</span></h1>
                                    <input type="text" onChange={(e) => setNppNumber(e.target.value)} className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder={languages.language === 'id' ? "Input Nomor NPP" : "Input NPP number..."} />
                                </div>

                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Kantor Penempatan" : "Subsidiary"} <span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        {dataUser.role === "SUBSIDIARY" ?
                                            <input disabled value={(dataUser.fullname)} type='text' className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick w-full text-[12px] mb-[20.5px] bg-gray-200' />
                                            :
                                            <div className='relative'>
                                                <select onChange={(e) => setSubsidiaryJkk(e.target.value)} value={subsidiaryJkk} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected disabled value={''}>Pilih Penempatan...</option>
                                                    {subsidiary && subsidiary.map((item, idx) => {
                                                        return (
                                                            <option data-id={item.id} key={idx} value={item.id}>{item.company_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                            </div>
                                        }
                                        {/* <div className='relative w-full'>
                                            <select onChange={(e) => setSubsidiaryJkk(e.target.value)} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected disabled value="">{languages.language === 'id' ? "Pilih Kantor Cabang" : "Select company subsidiary"}</option>
                                                {subsidiary && subsidiary.map((val, index) => {
                                                    return (
                                                        <option value={val.id}>{val.company_name}</option>
                                                    )
                                                })}
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div> */}
                                    </div>
                                </div>

                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>JKK <span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-full'>
                                            <select onChange={(e) => setJkk(e.target.value)} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected disabled value="">{languages.language === 'id' ? "Pilih Kategori JKK" : "Select JJK"}</option>
                                                <option value="0.24">0,24% = {languages.language === 'id' ? "Tingkat risiko yang sangat rendah" : "Very low level of risk"}</option>
                                                <option value="0.54">0,54% = {languages.language === 'id' ? "Tingkat risiko rendah" : "Low level of risk"}</option>
                                                <option value="0.89">0,89% = {languages.language === 'id' ? "Tingkat risiko sedang" : "Medium risk level"}</option>
                                                <option value="1.27">1,27%  = {languages.language === 'id' ? "Tingkat risiko tinggi" : "High risk level"}</option>
                                                <option value="1.74">1,74% = {languages.language === 'id' ? "Tingkat risiko yang sangat tinggi" : "Very high risk level"}</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div>
                                </div>

                                <div className='relative pb-[37px]'>
                                    <div className="flex gap-[10px] absolute right-0">
                                        <button onClick={() => setShowAddNPP(!showAddNPP)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                            <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                        </button>
                                        <button onClick={postJKK} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                            <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}


                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDeleteJKK}
                buttonClose={() => setShowModalDeleteJKK(!showModalDeleteJKK)}
                submitButton={deleteJKK}
            />
            <Modal
                activeModal={showEditNPP}
                title={!isUnderstand ? 'JKK BPJS Ketenagakerjaan' : 'Edit NPP BPJS Ketenagakerjaan'}
                buttonClose={() => setShowEditNPP(!showEditNPP)}
                width={'553px'}
                content={
                    <div className='space-y-[20px] w-[full]'>
                        {!isUnderstand ? (
                            <>
                                <div className="w-[473px] text-zinc-700 text-sm font-normal ">
                                    {languages.language === 'id' ?
                                        "BPJS Ketenagakerjaan memiliki beberapa jenis program, salah satunya adalah JKK. Program JKK (Jaminan Kecelakaan Kerja) merupakan program yang memberikan perlindungan terhadap risiko kecelakaan yang terjadi dalam hubungan kerja."
                                        : "BPJS Ketenagakerjaan has several types of programs, one of which is JKK. The JKK (Jaminan Kecelakaan Kerja) program is a program that provides protection against the risk of accidents that occur in work relationships."}
                                </div>
                                <div className="text-zinc-700 text-sm font-semibold ">
                                    {languages.language === 'id' ?
                                        <ol className="list-decimal list-inside">
                                            <li>Tingkat risiko sangat rendah yaitu 0,24% dari gaji per bulan.</li>
                                            <li>Tingkat risiko rendah sebesar 0,54% dari gaji per bulan.</li>
                                            <li>Tingkat risiko sedang adalah 0,89% dari gaji per bulan.</li>
                                            <li>Tingkat risiko tinggi adalah 1,27% dari gaji per bulan.</li>
                                            <li>Tingkat risiko yang sangat tinggi adalah 1,74% dari gaji per bulan.</li>
                                        </ol>
                                        : "1. Very low risk level of 0.24% of wages per month.<br />2. Low risk level of 0.54% of wages per month.<br />3. Medium risk level is 0.89% of wages per month.<br />4. High risk level is 1.27% of wages per month.<br />5. Very high risk level is  1.74% of wages per month."}</div>

                                <div className='flex justify-end'>
                                    <button onClick={() => setIsUnderstand(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-fit'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Oke, saya mengerti" : "Okay, I Understand"}</h1>
                                    </button>
                                </div>
                            </>

                        ) : (
                            <>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama NPP" : "NPP Name"} <span className='text-red-primer'>*</span></h1>
                                    <input type="text" onChange={(e) => setTitleNpp(e.target.value)} value={titleNpp} className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder={languages.language === 'id' ? "Input Nama NPP" : "Input NPP name..."} />
                                </div>

                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nomor NPP" : "NPP Number"} <span className='text-red-primer'>*</span></h1>
                                    <input type="text" onChange={(e) => setNppNumber(e.target.value)} value={nppNumber} className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder={languages.language === 'id' ? "Input Nomor NPP" : "Input NPP number..."} />
                                </div>

                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Kantor Penempatan" : "Subsidiary"} <span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        {dataUser.role === "SUBSIDIARY" ?
                                            <input disabled value={(dataUser.fullname)} type='text' className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick w-full text-[12px] mb-[20.5px] bg-gray-200' />
                                            :
                                            <div className='relative'>
                                                <select onChange={(e) => setSubsidiaryJkk(e.target.value)} value={subsidiaryJkk} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected disabled value={''}>Pilih Penempatan...</option>
                                                    {subsidiary && subsidiary.map((item, idx) => {
                                                        return (
                                                            <option key={idx} value={item.id}>{item.company_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                            </div>
                                        }
                                        {/* <div className='relative w-full'>
                                            <select onChange={(e) => setSubsidiaryJkk(e.target.value)} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected disabled value="">{languages.language === 'id' ? "Pilih Kantor Cabang" : "Select company subsidiary"}</option>
                                                {subsidiary && subsidiary.map((val, index) => {
                                                    return (
                                                        <option value={val.id}>{val.company_name}</option>
                                                    )
                                                })}
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div> */}
                                    </div>
                                </div>

                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>JJK <span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-full'>
                                            <select onChange={(e) => setJkk(e.target.value)} value={jkk} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected disabled value="">{languages.language === 'id' ? "Pilih Kategori JKK" : "Select JJK"}</option>
                                                <option value="0.24">0,24% = {languages.language === 'id' ? "Tingkat risiko yang sangat rendah" : "Very low level of risk"}</option>
                                                <option value="0.54">0,54% = {languages.language === 'id' ? "Tingkat risiko rendah" : "Low level of risk"}</option>
                                                <option value="0.89">0,89% = {languages.language === 'id' ? "Tingkat risiko sedang" : "Medium risk level"}</option>
                                                <option value="1.27">1,27%  = {languages.language === 'id' ? "Tingkat risiko tinggi" : "High risk level"}</option>
                                                <option value="1.74">1,74% = {languages.language === 'id' ? "Tingkat risiko yang sangat tinggi" : "Very high risk level"}</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div>
                                </div>

                                <div className='relative pb-[37px]'>
                                    <div className="flex gap-[10px] absolute right-0">
                                        <button onClick={() => setShowEditNPP(!showEditNPP)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                            <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                        </button>
                                        <button onClick={() => updateJKK()} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                            <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}


                    </div>
                }
            />
            <Modal
                activeModal={showAddIncome}
                title={languages.language === 'id' ? "Tambah Pemasukan" : "Add Income"}
                buttonClose={() => { setShowAddIncome(!showAddIncome); resetFormIncome() }}
                width={'553px'}
                content={
                    <div className='space-y-[25px] w-[full]'>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Pemasukan" : "Incomes Name"} <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setTitleIncome(e.target.value)} value={titleIncome} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nama Pemasukan...' />
                        </div>

                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah" : "Amount"}<span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setAmountIncome(e.target.value)} value={amountIncome} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                            {/* <input
                                type="text"
                                value={value}
                                onChange={handleChangeRupiah}
                                placeholder="Masukkan angka..."
                                className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]'
                            /> */}
                        </div>

                        <div className='flex gap-[20px] w-full items-center'>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Tipe Pembayaran" : "Payment Type"}<span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="paymentTypeIncome" onChange={(e) => setPaymentTypeIncome(e.target.value)} value={paymentTypeIncome} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Tipe Pembayaran" : "Select payment type"}</option>
                                            <option value="monthly">{languages.language === 'id' ? "Bulanan" : "Monthly"}</option>
                                            <option value="weekly">{languages.language === 'id' ? "Mingguan" : "Weekly"}</option>
                                            <option value="daily">{languages.language === 'id' ? "Harian" : "Daily"}</option>
                                            <option value="onceTime">{languages.language === 'id' ? "Satu Kali" : "One Time"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Pajak" : "Tax"}<span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="" onChange={(e) => setTaxIncome(e.target.value)} value={taxIncome} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Pajak" : "Select Tax"}</option>
                                            <option value="Taxable">{languages.language === 'id' ? "Kena Pajak" : "Taxable"}</option>
                                            <option value="Non Taxable">{languages.language === 'id' ? "Tidak Kena Pajak" : "Non Taxable"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>{languages.language === 'id' ? "Ditujukan Kepada" : "Assign To"}<span className='text-red-primer'>*</span></h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "ALL"} id="allIncome" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="allIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                </div>
                                {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="searchIncome" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="searchIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_grade"} id="jobGradeIncome" name="radio-option" value='job_grade' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobGradeIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Kelas Jabatan" : "Based on Job Grade"}</label>
                                </div>
                                {
                                    assignTo === "job_grade" ?
                                        <MultiSelect
                                            options={jobGrade}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_level"} id="jobLevelIncome" name="radio-option" value='job_level' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobLevelIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jenis Jabatan" : "Based on Job Level"}</label>
                                </div>
                                {
                                    assignTo === "job_level" ?
                                        <MultiSelect
                                            options={jobLevel}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_position"} id="jobPositionIncome" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobPositionIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                </div>
                                {
                                    assignTo === "job_position" ?
                                        <MultiSelect
                                            options={jobPosition}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowAddIncome(!showAddIncome); resetFormIncome() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                </button>
                                <button onClick={postIncome} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Simpan" : "Add"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showEditIncome}
                title={languages.language === 'id' ? "Edit Pemasukan" : "Edit Income"}
                buttonClose={() => setShowEditIncome(!showEditIncome)}
                width={'553px'}
                content={
                    <div className='space-y-[25px] w-[full]'>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Pemasukan" : "Incomes Name"} <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setTitleIncome(e.target.value)} value={titleIncome} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nama Pemasukan...' />
                        </div>

                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah" : "Amount"}<span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setAmountIncome(e.target.value)} value={amountIncome} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                            {/* <input
                                type="text"
                                value={value}
                                onChange={handleChangeRupiah}
                                placeholder="Masukkan angka..."
                                className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]'
                            /> */}
                        </div>

                        <div className='flex gap-[20px] w-full items-center'>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Tipe Pembayaran" : "Payment Type"}<span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="paymentTypeIncome" onChange={(e) => setPaymentTypeIncome(e.target.value)} value={paymentTypeIncome} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Tipe Pembayaran" : "Select payment type"}</option>
                                            <option value="monthly">{languages.language === 'id' ? "Bulanan" : "Monthly"}</option>
                                            <option value="weekly">{languages.language === 'id' ? "Mingguan" : "Weekly"}</option>
                                            <option value="daily">{languages.language === 'id' ? "Harian" : "Daily"}</option>
                                            <option value="onceTime">{languages.language === 'id' ? "Satu Kali" : "One Time"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Pajak" : "Tax"}<span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="" onChange={(e) => setTaxIncome(e.target.value)} value={taxIncome} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Pajak" : "Select Tax"}</option>
                                            <option value="Taxable">{languages.language === 'id' ? "Kena Pajak" : "Taxable"}</option>
                                            <option value="Non Taxable">{languages.language === 'id' ? "Tidak Kena Pajak" : "Non Taxable"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "ALL"} id="allEdit" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="allEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                </div>
                                {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="search" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="search" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}

                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_category"} id="jenisKepegawaianEdit" name="radio-option" value='job_category' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jenisKepegawaianEdit" className='text-grey-thick font-medium text-xs'>Berdasarkan Jenis Kepegawaian</label>
                                </div>
                                {
                                    assignTo === "job_category" ?
                                        <MultiSelect
                                            options={jobCategory}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_position"} id="jabatanEdit" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jabatanEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                </div>
                                {
                                    assignTo === "job_position" ?
                                        <MultiSelect
                                            options={jobPosition}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowEditIncome(!showEditIncome); resetFormIncome() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                </button>
                                <button onClick={editIncome} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Simpan" : "Add"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDeleteIncome}
                buttonClose={() => setShowModalDeleteIncome(!showModalDeleteIncome)}
                submitButton={deleteIncome}
            />
            <Modal
                activeModal={showAddDeduction}
                title={languages.language === 'id' ? "Tambah Pengurangan" : "Add Deduction"}
                buttonClose={() => { setShowAddDeduction(!showAddDeduction); resetFormDeduction() }}
                width={'553px'}
                content={
                    <div className='space-y-[25px] w-[full]'>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Pengurangan" : "Deduction Name"} <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setTitleDeduction(e.target.value)} value={titleDeduction} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nama Potongan...' />
                        </div>

                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah" : "Amount"} <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setAmountDeduction(e.target.value)} value={amountDeduction} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                        </div>

                        <div className='flex gap-[20px] w-full items-center'>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Tipe Pembayaran" : "Payment Type"} <span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="paymentTypeDeduction" onChange={(e) => setPaymentTypeDeduction(e.target.value)} value={paymentTypeDeduction} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Tipe Pembayaran" : "Select payment type"}</option>
                                            <option value="monthly">{languages.language === 'id' ? "Bulanan" : "Monthly"}</option>
                                            <option value="weekly">{languages.language === 'id' ? "Mingguan" : "Weekly"}</option>
                                            <option value="daily">{languages.language === 'id' ? "Harian" : "Daily"}</option>
                                            <option value="onceTime">{languages.language === 'id' ? "Satu Kali" : "One Time"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Pajak" : "Tax"}<span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="" onChange={(e) => setTaxDeduction(e.target.value)} value={taxDeduction} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Pajak" : "Select Tax"}</option>
                                            <option value="Taxable">{languages.language === 'id' ? "Kena Pajak" : "Taxable"}</option>
                                            <option value="Non Taxable">{languages.language === 'id' ? "Tidak Kena Pajak" : "Non Taxable"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "ALL"} id="allDeduction" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="allDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                </div>
                                {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="searchDeduction" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="searchDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_grade"} id="jobGradeDeduction" name="radio-option" value='job_grade' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobGradeDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Kelas Jabatan" : "Based on Job Grade"}</label>
                                </div>
                                {
                                    assignTo === "job_grade" ?
                                        <MultiSelect
                                            options={jobGrade}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_level"} id="jobLevelDeduction" name="radio-option" value='job_level' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobLevelDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jenis Jabatan" : "Based on Job Level"}</label>
                                </div>
                                {
                                    assignTo === "job_level" ?
                                        <MultiSelect
                                            options={jobLevel}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_position"} id="jobPositionDeduction" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobPositionDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                </div>
                                {
                                    assignTo === "job_position" ?
                                        <MultiSelect
                                            options={jobPosition}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowAddDeduction(!showAddDeduction); resetFormDeduction() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={postDeduction} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDeleteDeduction}
                buttonClose={() => setShowModalDeleteDeduction(!showModalDeleteDeduction)}
                submitButton={deleteDeduction}
            />
            <Modal
                activeModal={showEditDeduction}
                title={'Edit Deduction'}
                buttonClose={() => setShowEditDeduction(!showEditDeduction)}
                width={'553px'}
                content={
                    <div className='space-y-[25px] w-[full]'>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Pengurangan" : "Deduction Name"} <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setTitleDeduction(e.target.value)} value={titleDeduction} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nama Potongan...' />
                        </div>

                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah" : "Amount"} <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setAmountDeduction(e.target.value)} value={amountDeduction} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                        </div>

                        <div className='flex gap-[20px] w-full items-center'>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Tipe Pembayaran" : "Payment Type"} <span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="paymentTypeDeduction" onChange={(e) => setPaymentTypeDeduction(e.target.value)} value={paymentTypeDeduction} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Tipe Pembayaran" : "Select payment type"}</option>
                                            <option value="monthly">{languages.language === 'id' ? "Bulanan" : "Monthly"}</option>
                                            <option value="weekly">{languages.language === 'id' ? "Mingguan" : "Weekly"}</option>
                                            <option value="daily">{languages.language === 'id' ? "Harian" : "Daily"}</option>
                                            <option value="onceTime">{languages.language === 'id' ? "Satu Kali" : "One Time"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Pajak" : "Tax"}<span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="" onChange={(e) => setTaxDeduction(e.target.value)} value={taxDeduction} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Pajak" : "Select Tax"}</option>
                                            <option value="Taxable">{languages.language === 'id' ? "Kena Pajak" : "Taxable"}</option>
                                            <option value="Non Taxable">{languages.language === 'id' ? "Tidak Kena Pajak" : "Non Taxable"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "ALL"} id="editAllDeduction" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label for="editAllDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                </div>
                                {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="searchDeduction" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="searchDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}

                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_category"} id="jenisKepegawaianEditDeduction" name="radio-option" value='job_category' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jenisKepegawaianEditDeduction" className='text-grey-thick font-medium text-xs'>Berdasarkan Jenis Kepegawaian</label>
                                </div>
                                {
                                    assignTo === "job_category" ?
                                        <MultiSelect
                                            options={jobCategory}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_position"} id="jabatanEditDeduction" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jabatanEditDeduction" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                </div>
                                {
                                    assignTo === "job_position" ?
                                        <MultiSelect
                                            options={jobPosition}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowEditDeduction(!showEditDeduction); resetFormDeduction() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={editDeduction} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showAddBenefit}
                title={languages.language === 'id' ? "Tambah Benefit" : "Add Benefit"}
                buttonClose={() => { setShowAddBenefit(!showAddBenefit); resetFormBenefit() }}
                width={'553px'}
                content={
                    <div className='space-y-[25px] w-[full]'>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Nama Benefit" : "Benefit Name"}<span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setTitleBenefit(e.target.value)} value={titleBenefit} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nama Benefit...' />
                        </div>

                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Jumlah" : "Amount"} <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setAmountBenefit(e.target.value)} value={amountBenefit} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                        </div>

                        <div className='flex gap-[20px] w-full items-center'>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Tipe Pembayaran" : "Payment Type"}<span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="paymentTypeBenefit" onChange={(e) => setPaymentTypeBenefit(e.target.value)} value={paymentTypeBenefit} id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Tipe Pembayaran" : "Select payment type"}</option>
                                            <option value="monthly">{languages.language === 'id' ? "Bulanan" : "Monthly"}</option>
                                            <option value="weekly">{languages.language === 'id' ? "Mingguan" : "Weekly"}</option>
                                            <option value="daily">{languages.language === 'id' ? "Harian" : "Daily"}</option>
                                            <option value="onceTime">{languages.language === 'id' ? "Satu Kali" : "One Time"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Pajak" : "Tax"} <span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="" onChange={(e) => setTaxBenefit(e.target.value)} value={taxBenefit} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">{languages.language === 'id' ? "Pilih Pajak" : "Select Tax"}</option>
                                            <option value="Taxable">{languages.language === 'id' ? "Kena Pajak" : "Taxable"}</option>
                                            <option value="Non Taxable">{languages.language === 'id' ? "Tidak Kena Pajak" : "Non Taxable"}</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "ALL"} id="allBenefit" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="allBenefit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                </div>
                                {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="searchBenefit" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="searchBenefit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_grade"} id="jobGradeBenefit" name="radio-option" value='job_grade' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobGradeBenefit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Kelas Jabatan" : "Based on Job Grade"}</label>
                                </div>
                                {
                                    assignTo === "job_grade" ?
                                        <MultiSelect
                                            options={jobGrade}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_level"} id="jobLevelBenefit" name="radio-option" value='job_level' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobLevelBenefit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jenis Jabatan" : "Based on Job Level"}</label>
                                </div>
                                {
                                    assignTo === "job_level" ?
                                        <MultiSelect
                                            options={jobLevel}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_position"} id="jobPositionBenefit" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobPositionBenefit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                </div>
                                {
                                    assignTo === "job_position" ?
                                        <MultiSelect
                                            options={jobPosition}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowAddBenefit(!showAddBenefit); resetFormBenefit() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={postBenefit} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDeleteBenefit}
                buttonClose={() => setShowModalDeleteBenefit(!showModalDeleteBenefit)}
                submitButton={deleteBenefit}
            />
            <Modal
                activeModal={showEditBenefit}
                title={'Edit Benefit'}
                buttonClose={() => setShowEditBenefit(!showEditBenefit)}
                width={'553px'}
                content={
                    <div className='space-y-[25px] w-[full]'>
                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>Benefit Name <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setTitleIncome(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Benfit Name...' />
                        </div>

                        <div className='w-full'>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>Amount <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setAmountIncome(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-dark-4 text-[12px]' placeholder='Nominal...' />
                        </div>

                        <div className='flex gap-[20px] w-full items-center'>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>Payment Type <span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">Select payment type</option>
                                            <option value="Monthly">Monthly</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Daily">Daily</option>
                                            <option value="One Time">One Time</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>Tax <span className='text-red-primer'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[233px]'>
                                        <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">Select Tax</option>
                                            <option value="Taxable">Taxable</option>
                                            <option value="Non Taxable">Non Taxable</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Assign To <span className='text-red-primer'>*</span></h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="radio1" name="radio-option" value="" />
                                    <label className='text-grey-thick font-medium text-xs' htmlFor="radio1">All Employee</label>
                                </div>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="radio2" name="radio-option" value="" />
                                    <label className='text-grey-thick font-medium text-xs' htmlFor="radio2">Search Employee</label>
                                </div>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="radio3" name="radio-option" value="" />
                                    <label className='text-grey-thick font-medium text-xs' htmlFor="radio3">Based on Job Grade </label>
                                </div>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="radio4" name="radio-option" value="" />
                                    <label className='text-grey-thick font-medium text-xs' htmlFor="radio4">Based on Job Level  </label>
                                </div>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="radio5" name="radio-option" value="" />
                                    <label className='text-grey-thick font-medium text-xs' htmlFor="radio5">Based on Job Position </label>
                                </div>
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddIncome(!showAddIncome)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Pengaturan Penggajian" : "Payroll Setting"} LinkBack={'/dashboard'} />
                <h1 className="text-zinc-800 text-xl font-semibold">{languages.language === 'id' ? "Pengaturan Penggajian" : "Payroll Setting"}</h1>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='border-b-2 flex flex-wrap items-center text-[16px]'>
                        <button onClick={() => setMenu('Take Home Pay')} className={`${menu === 'Take Home Pay' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Take Home Pay</button>
                        {/* <button onClick={ () => setMenu('Allowance') } className={`${menu === 'Allowance' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Allowance</button> */}
                        <button onClick={() => setMenu('THR')} className={`${menu === 'THR' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>THR</button>
                        <button onClick={() => setMenu('PPH 21')} className={`${menu === 'PPH 21' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>PPH 21</button>
                    </div>
                    {menu === 'Take Home Pay' ? (
                        <>
                            <h1 className="text-black text-xl font-semibold">{languages.language === 'id' ? "Pengaturan Prorate" : "Prorate Setting"}</h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[26px]'>
                                    <input type="radio" id="radio1" name="radio-option" value="" />
                                    <label className='text-grey-thick text-[16px]' htmlFor="radio1">{languages.language === 'id' ? "Berdasarkan Hari Kerja" : "Based on working days"}</label>
                                </div>
                                <div className='flex items-center gap-[26px]'>
                                    <input type="radio" id="radio2" name="radio-option" value="" />
                                    <label className='text-grey-thick text-[16px]' htmlFor="radio2">{languages.language === 'id' ? "Berdasarkan Waktu Kerja" : "Based on working time"}</label>
                                </div>
                            </div>
                            <hr />
                            <h1 className="text-black text-xl font-semibold">{languages.language === 'id' ? "Tanggal Penggajian" : "Payroll Date"}</h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[26px]'>
                                    <input type="date" />
                                </div>
                            </div>
                            <hr />
                            <h1 className="text-black text-xl font-semibold">{languages.language === 'id' ? "Komponen Penggajian" : "Payroll Component"}</h1>

                            <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3 items-center justify-center'>
                                <div className='bg-gray-200 px-[20px] py-[20px] rounded-[10px] h-96'>
                                    <div className='flex items-center justify-center p-[10px] bg-[#219EBC] text-[16px] text-white rounded-lg mb-[20px]'>{languages.language === 'id' ? "Pemasukan" : "Income"}</div>
                                    <div className='space-y-[10px] mb-[20px] h-5/6 overflow-y-auto flex flex-col justify-start'>
                                        {
                                            dataIncome && dataIncome.map((data, index) => {
                                                return (
                                                    <>
                                                        <div key={index} className='py-[8px] px-[10px] text-[11px] text-zinc-700 rounded-md border border-black w-full flex justify-between items-center'>
                                                            <h1>{data.title}</h1>
                                                            <button onClick={() => handleEditIncome(data.id)}>
                                                                <HiOutlinePencilAlt className='text-[#003049] text-base' />
                                                            </button>
                                                            {/* <button onClick={() => deleteModalIncome(data.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M5.80764 5.80666C5.8657 5.74846 5.93467 5.70228 6.0106 5.67077C6.08653 5.63926 6.16793 5.62305 6.25014 5.62305C6.33235 5.62305 6.41375 5.63926 6.48968 5.67077C6.56561 5.70228 6.63458 5.74846 6.69264 5.80666L10.0001 9.11541L13.3076 5.80666C13.3658 5.74855 13.4347 5.70246 13.5107 5.67101C13.5866 5.63956 13.668 5.62337 13.7501 5.62337C13.8323 5.62337 13.9137 5.63956 13.9896 5.67101C14.0655 5.70246 14.1345 5.74855 14.1926 5.80666C14.2508 5.86477 14.2968 5.93376 14.3283 6.00968C14.3597 6.08561 14.3759 6.16698 14.3759 6.24916C14.3759 6.33134 14.3597 6.41272 14.3283 6.48864C14.2968 6.56457 14.2508 6.63355 14.1926 6.69166L10.8839 9.99916L14.1926 13.3067C14.2508 13.3648 14.2968 13.4338 14.3283 13.5097C14.3597 13.5856 14.3759 13.667 14.3759 13.7492C14.3759 13.8313 14.3597 13.9127 14.3283 13.9886C14.2968 14.0646 14.2508 14.1336 14.1926 14.1917C14.1345 14.2498 14.0655 14.2959 13.9896 14.3273C13.9137 14.3588 13.8323 14.375 13.7501 14.375C13.668 14.375 13.5866 14.3588 13.5107 14.3273C13.4347 14.2959 13.3658 14.2498 13.3076 14.1917L10.0001 10.8829L6.69264 14.1917C6.63453 14.2498 6.56554 14.2959 6.48962 14.3273C6.4137 14.3588 6.33232 14.375 6.25014 14.375C6.16796 14.375 6.08659 14.3588 6.01066 14.3273C5.93474 14.2959 5.86575 14.2498 5.80764 14.1917C5.74953 14.1336 5.70343 14.0646 5.67199 13.9886C5.64054 13.9127 5.62435 13.8313 5.62435 13.7492C5.62435 13.667 5.64054 13.5856 5.67199 13.5097C5.70343 13.4338 5.74953 13.3648 5.80764 13.3067L9.11639 9.99916L5.80764 6.69166C5.74944 6.63361 5.70326 6.56464 5.67175 6.48871C5.64024 6.41277 5.62402 6.33137 5.62402 6.24916C5.62402 6.16695 5.64024 6.08555 5.67175 6.00962C5.70326 5.93369 5.74944 5.86472 5.80764 5.80666Z" fill="#C1121F" />
                                                                </svg>
                                                            </button> */}
                                                        </div>
                                                    </>
                                                )
                                            }
                                            )}
                                    </div>

                                    <div className='flex items-center justify-center'>
                                        {/* <button
                                            onClick={() => {
                                                setShowAddIncome(!showAddIncome)
                                                getSelectJobGrade()
                                                getSelectJobLevel()
                                                getSelectJobPosition()
                                            }}
                                            className='bg-white py-2 px-4 rounded-[10px] flex items-center  gap-[16px]'>
                                            <AiOutlinePlus className='text-xl text-[#333333]' />
                                            <h1 className='text-[#333333] text-sm font-[500]'>{languages.language === 'id' ? "Tambah Pemasukan" : "Add Income"}</h1>
                                        </button> */}
                                    </div>
                                </div>

                                <div className='bg-gray-200 px-[20px] py-[20px] rounded-[10px] h-96'>
                                    <div className='flex items-center justify-center p-[10px] bg-[#219EBC] text-[16px] text-white rounded-lg mb-[20px]'>{languages.language === 'id' ? "Pengurangan" : "Deduction"}</div>
                                    <div className='space-y-[10px] mb-[20px] h-5/6 overflow-y-auto flex flex-col justify-start'>
                                        {
                                            dataDeduction && dataDeduction.map((data, index) => {
                                                return (
                                                    <>
                                                        <div key={index} className='py-[8px] px-[10px] text-[11px] text-zinc-700 rounded-md border border-black w-full flex justify-between items-center'>
                                                            <h1>{data.title}</h1>
                                                            <button onClick={() => handleEditDeduction(data.id)}>
                                                                <HiOutlinePencilAlt className='text-[#003049] text-base' />
                                                            </button>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            )}
                                    </div>

                                    <div className='flex items-center justify-center'>
                                        {/* <button
                                            onClick={() => {
                                                setShowAddDeduction(!showAddDeduction)
                                                getSelectJobGrade()
                                                getSelectJobLevel()
                                                getSelectJobPosition()
                                            }}
                                            className='bg-white py-2 px-4 rounded-[10px] flex items-center  gap-[16px]'>
                                            <AiOutlinePlus className='text-xl text-[#333333]' />
                                            <h1 className='text-[#333333] text-sm font-[500]'>{languages.language === 'id' ? "Tambah Pengurangan" : "Add Deduction"}</h1>
                                        </button> */}
                                    </div>
                                </div>

                                <div className='bg-gray-200 px-[20px] py-[20px] rounded-[10px] h-96'>
                                    <div className='flex items-center justify-center p-[10px] bg-[#219EBC] text-[16px] text-white rounded-lg mb-[20px]'>{languages.language === 'id' ? "Benefit" : "Benefits"}</div>
                                    <div className='space-y-[10px] mb-[20px] h-[200px] overflow-y-auto flex flex-col justify-start'>
                                        {
                                            dataBenefit && dataBenefit.map((data, index) => {
                                                return (
                                                    <>
                                                        <div key={index} className='py-[8px] px-[10px] text-[11px] text-zinc-700 rounded-md border border-black w-fit flex items-center'>
                                                            <h1>{data.title}</h1>
                                                            <button onClick={() => deleteModalBenefit(data.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M5.80764 5.80666C5.8657 5.74846 5.93467 5.70228 6.0106 5.67077C6.08653 5.63926 6.16793 5.62305 6.25014 5.62305C6.33235 5.62305 6.41375 5.63926 6.48968 5.67077C6.56561 5.70228 6.63458 5.74846 6.69264 5.80666L10.0001 9.11541L13.3076 5.80666C13.3658 5.74855 13.4347 5.70246 13.5107 5.67101C13.5866 5.63956 13.668 5.62337 13.7501 5.62337C13.8323 5.62337 13.9137 5.63956 13.9896 5.67101C14.0655 5.70246 14.1345 5.74855 14.1926 5.80666C14.2508 5.86477 14.2968 5.93376 14.3283 6.00968C14.3597 6.08561 14.3759 6.16698 14.3759 6.24916C14.3759 6.33134 14.3597 6.41272 14.3283 6.48864C14.2968 6.56457 14.2508 6.63355 14.1926 6.69166L10.8839 9.99916L14.1926 13.3067C14.2508 13.3648 14.2968 13.4338 14.3283 13.5097C14.3597 13.5856 14.3759 13.667 14.3759 13.7492C14.3759 13.8313 14.3597 13.9127 14.3283 13.9886C14.2968 14.0646 14.2508 14.1336 14.1926 14.1917C14.1345 14.2498 14.0655 14.2959 13.9896 14.3273C13.9137 14.3588 13.8323 14.375 13.7501 14.375C13.668 14.375 13.5866 14.3588 13.5107 14.3273C13.4347 14.2959 13.3658 14.2498 13.3076 14.1917L10.0001 10.8829L6.69264 14.1917C6.63453 14.2498 6.56554 14.2959 6.48962 14.3273C6.4137 14.3588 6.33232 14.375 6.25014 14.375C6.16796 14.375 6.08659 14.3588 6.01066 14.3273C5.93474 14.2959 5.86575 14.2498 5.80764 14.1917C5.74953 14.1336 5.70343 14.0646 5.67199 13.9886C5.64054 13.9127 5.62435 13.8313 5.62435 13.7492C5.62435 13.667 5.64054 13.5856 5.67199 13.5097C5.70343 13.4338 5.74953 13.3648 5.80764 13.3067L9.11639 9.99916L5.80764 6.69166C5.74944 6.63361 5.70326 6.56464 5.67175 6.48871C5.64024 6.41277 5.62402 6.33137 5.62402 6.24916C5.62402 6.16695 5.64024 6.08555 5.67175 6.00962C5.70326 5.93369 5.74944 5.86472 5.80764 5.80666Z" fill="#C1121F" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            )}
                                    </div>

                                    <div className='flex items-center justify-center'>
                                        {/* <button
                                            onClick={() => {
                                                setShowAddBenefit(!showAddBenefit)
                                                getSelectJobGrade()
                                                getSelectJobLevel()
                                                getSelectJobPosition()
                                            }}
                                            className='bg-white py-2 px-4 rounded-[10px] flex items-center  gap-[16px]'>
                                            <AiOutlinePlus className='text-xl text-[#333333]' />
                                            <h1 className='text-[#333333] text-sm font-[500]'>{languages.language === 'id' ? "Tambah Benefit" : "Add Benefits"}</h1>
                                        </button> */}
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <h1 className="text-black text-xl font-semibold">{languages.language === 'id' ? "Pengaturan BPJS" : "BPJS Setting"}</h1>
                            <div className='flex gap-[21px]'>
                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Presentase BPJS Ketenagakerjaan<span className='text-red-primer'>*</span></h2>
                                    <input type="number" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick w-full text-[12px] mb-[20.5px]' placeholder='0' />
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Presentase BPJS Ketenagakerjaan<span className='text-red-primer'>*</span></h2>
                                    <input type="number" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick w-full text-[12px] mb-[20.5px]' placeholder='0' />
                                </div>
                            </div>
                            <div className='border rounded-xl pt-[39px] px-[26px]'>
                                <div className='flex items-center justify-between mb-[30px]'>
                                    <div className='flex gap-[10px] items-center'>
                                        <div className="text-zinc-800 text-base font-semibold">NPP BPJS Ketenagakerjaan</div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M7.535 5.625L7.085 6.085C6.725 6.445 6.5 6.75 6.5 7.5H5.5V7.25C5.5 6.695 5.725 6.195 6.085 5.835L6.705 5.205C6.89 5.025 7 4.775 7 4.5C7 4.23478 6.89464 3.98043 6.70711 3.79289C6.51957 3.60536 6.26522 3.5 6 3.5C5.73478 3.5 5.48043 3.60536 5.29289 3.79289C5.10536 3.98043 5 4.23478 5 4.5H4C4 3.96957 4.21071 3.46086 4.58579 3.08579C4.96086 2.71071 5.46957 2.5 6 2.5C6.53043 2.5 7.03914 2.71071 7.41421 3.08579C7.78929 3.46086 8 3.96957 8 4.5C7.99928 4.92162 7.83217 5.32591 7.535 5.625ZM6.5 9.5H5.5V8.5H6.5M6 1C5.34339 1 4.69321 1.12933 4.08658 1.3806C3.47995 1.63188 2.92876 2.00017 2.46447 2.46447C1.52678 3.40215 1 4.67392 1 6C1 7.32608 1.52678 8.59785 2.46447 9.53553C2.92876 9.99983 3.47995 10.3681 4.08658 10.6194C4.69321 10.8707 5.34339 11 6 11C7.32608 11 8.59785 10.4732 9.53553 9.53553C10.4732 8.59785 11 7.32608 11 6C11 3.235 8.75 1 6 1Z" fill="black" />
                                        </svg>
                                    </div>
                                    <button
                                        onClick={() => {
                                            setShowAddNPP(!showAddNPP);
                                            getSelectSubsidiary();
                                        }}
                                        className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus className='text-xl text-white' />
                                        <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah NPP" : "Add NPP"}</h1>
                                    </button>
                                </div>
                                <div>
                                    <div className='overflow-auto scrollbar-hide'>
                                        <table className='w-full space-y-[10px]'>
                                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nama NPP" : "NPP Name"}</h1>
                                                    <MdImportExport className='text-dark-5 text-xs' />
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nomor NPP" : "NPP Number"}</h1>
                                                    <MdImportExport className='text-dark-5 text-xs' />
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Kantor Cabang" : "Branch"}</h1>
                                                    <MdImportExport className='text-dark-5 text-xs' />
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[100px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>JKK</h1>
                                                    <MdImportExport className='text-dark-5 text-xs' />
                                                </div>

                                                <div className='flex items-center justify-center gap-[6px] w-1/2'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                                    <MdImportExport className='text-dark-5 text-xs' />
                                                </div>
                                            </div>
                                            {
                                                dataJKK && dataJKK.map((data, index) => {
                                                    return (
                                                        <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                            <div className='min-w-[200px] max-w-[200px]'>
                                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.title}</h1>
                                                            </div>
                                                            <div className='min-w-[200px] max-w-[200px]'>
                                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.npp_number}</h1>
                                                            </div>
                                                            <div className='min-w-[200px] max-w-[200px]'>
                                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.subsidiary.company_name}</h1>
                                                            </div>
                                                            <div className='min-w-[100px] max-w-[200px]'>
                                                                <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.jkk}</h1>
                                                            </div>
                                                            <div className='w-1/2 flex items-center justify-center gap-[12px]'>
                                                                <button onClick={() => { openEditJKK(data.id); getSelectSubsidiary(); }} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                    <HiOutlinePencil className='text-[#003049]' />
                                                                </button>
                                                                <button onClick={() => deleteModalJKK(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                    <CgTrashEmpty className='text-[#003049]' />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </table>
                                    </div>
                                    {/* <Pagination
                                        currentPage={currentPage} 
                                        totalPages={totalPages} 
                                        onPageChange={handlePageChange}
                                        onPrevChange={handlePrevChange}
                                        onNextChange={handleNextChange}
                                    /> */}
                                </div>
                            </div>

                            <hr />
                            <h1 className="text-black text-xl font-semibold">{languages.language === 'id' ? "Pemotongan" : "Cut Off"}</h1>
                            <div>
                                <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">{languages.language === 'id' ? "Konfigurasi Pajak" : "SalaryTax Setting"}<span className='text-[#780000] font-medium'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[337px]'>
                                        <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[19px]  appearance-none'>
                                            <option selected disabled value="">{languages.language === 'id' ? "Pilih Konfigurasi Pajak" : "Select Employee Salary Tax Setting"}</option>
                                            <option value="Gross">Gross</option>
                                            <option value="Gross Up">Gross Up</option>
                                            <option value="Netto">Netto</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-end pb-[60px]'>
                                <button className=' px-[30px] py-[10px] bg-blue-primer rounded-lg text-white'>Simpan</button>
                            </div>
                        </>
                    ) : menu === 'THR' ? (
                        <>
                            <div>
                                <h1 className="text-zinc-800 text-xl font-semibold">THR</h1>
                                <h1 className="text-gray-500 text-xs font-normal">{languages.language === 'id' ? "Pengaturan Tunjangan Hari Raya" : "THR Setting"}</h1>
                            </div>
                            <div className='flex gap-[53px] items-start'>
                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Prorata " : "Prorate "}<span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-[337px]'>
                                            <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected value="">{languages.language === 'id' ? "Pilih Prorata" : "Select prorate"}</option>
                                                <option value="Daily">Berdasarkan Hari Kerja</option>
                                                <option value="Monthly">Berdasarkan Waktu Kerja</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>{languages.language === 'id' ? "Pegawai baru akan mendapat THR saat berapa bulan kerja " : "New Employee can get THR after "}<span className='text-red-primer'>*</span></h1>
                                    <div className='flex items-center gap-[16px]'>
                                        <input type='number' min={0} max={12} className='rounded-md border py-[8px] px-[13px] w-[72px] text-sm outline-none' placeholder='0' />
                                        <h1 className="text-zinc-700 text-sm font-medium">{languages.language === 'id' ? "Bulan" : "Month"}</h1>
                                    </div>
                                </div>
                            </div>

                            <div className='space-y-[12px] pb-[31px]'>
                                <h1 className='text-neutral-500 text-[11px] font-medium '>{languages.language === 'id' ? "Komponen THR" : "THR Component "}<span className='text-red-primer'>*</span></h1>
                                <div className='flex items-center gap-[13px] font-medium'>
                                    <input onChange={() => { setIsChecked(false) }} type="radio" id="radio1" name="radio-option" value="" />
                                    <label className='text-neutral-500 text-[11px] font-medium' htmlFor="radio1">Default</label>
                                </div>
                                <div className='flex items-center gap-[13px] font-medium'>
                                    <input onChange={() => setIsChecked(!isChecked)} type="radio" id="radio2" name="radio-option" value="" />
                                    <label className='text-neutral-500 text-[11px] font-medium' htmlFor="radio2">{languages.language === 'id' ? "Kustom Komponen THR " : "Custom THR Component"}</label>
                                </div>
                                {isChecked && (
                                    <div className='flex items-center gap-[11px]'>
                                        <div className="">
                                            <div className='relative w-[337px]'>
                                                <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-zinc-600 w-full py-[10px] px-[8px] appearance-none'>
                                                    <option selected value="">{languages.language === 'id' ? "Pilih Komponen" : "Select Component"}</option>
                                                    <option value="option 1">option 1</option>
                                                    <option value="option 2">option 2</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                            </div>
                                        </div>
                                        <button onClick={() => setShowEditTHR(!showEditTHR)} className='w-[35px] h-[35px] rounded-md flex items-center justify-center bg-blue-primer text-white'>
                                            <AiOutlinePlus className='text-xl' />
                                        </button>

                                    </div>
                                )}
                            </div>

                            <button className="w-[86px] h-[38px] px-[30px] py-2.5 bg-blue-primer rounded-md justify-center items-center gap-2.5 inline-flex">
                                <div className="text-center text-white text-sm font-medium">{languages.language === 'id' ? "Simpan" : "Save"}</div>
                            </button>
                        </>
                    ) : menu === 'PPH 21' ? (
                        <>
                            <h1 className="text-zinc-800 text-[15px] font-semibold">{languages.language === 'id' ? "Pengaturan PTKP" : "PTKP Setting"}</h1>

                            <div className='space-y-[16px] w-4/12 pb-[5px]'>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">{languages.language === 'id' ? "Wajib Pajak Orang Pribadi" : "Individual Taxpayer "}<span className='text-red-primer'>*</span></h1>
                                    <input type="number" className='px-[8px] outline-none py-[10px] border rounded-md text-xs w-full' placeholder='Rp' />
                                </div>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">{languages.language === 'id' ? "Tambahan Wajib Pajak Orang Pribadi" : "Additional Individual Taxpayer "}<span className='text-red-primer'>*</span></h1>
                                    <input type="number" className='px-[8px] outline-none py-[10px] border rounded-md text-xs w-full' placeholder='Rp' />
                                    <div className="text-stone-300 text-[9px] font-medium italic tracking-tight">({languages.language === 'id' ? "Tambahan bagi istri yang penghasilannya digabungkan dengan penghasilan suami" : "Additional for the wife whose income is combined with the husband's"})</div>
                                </div>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">{languages.language === 'id' ? "Tambahan Wajib Pajak Sudah Menikah" : "Additional Married Taxpayers "}<span className='text-red-primer'>*</span></h1>
                                    <input type="number" className='px-[8px] outline-none py-[10px] border rounded-md text-xs w-full' placeholder='Rp' />
                                </div>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">{languages.language === 'id' ? "Tambahan untuk tanggungan" : "Additional for dependents "}<span className='text-red-primer'>*</span></h1>
                                    <input type="number" className='px-[8px] outline-none py-[10px] border rounded-md text-xs w-full' placeholder='Rp' />
                                </div>
                            </div>

                            <hr className='border-[1px] border-dark-7' />

                            <h1 className="text-zinc-800 text-[15px] font-semibold">{languages.language === 'id' ? "Pengaturan PTKP " : "PKP Setting "}</h1>

                            <div className='space-y-[18px] pb-[5px]'>
                                <div className='flex gap-[11px] text-neutral-500 items-center'>
                                    <h1 className=" text-xs">{languages.language === 'id' ? "Jika Pendapatan" : "If Income"}</h1>
                                    <input type="number" placeholder='Rp' className='text-sm border rounded-md py-[6px] px-[10px] w-[177px] outline-none' />
                                    <h1 className=" text-sm">-</h1>
                                    <input type="number" placeholder='Rp' className='text-sm border rounded-md py-[6px] px-[10px] w-[177px] outline-none' />
                                    <h1 className=" text-sm">=</h1>
                                    <h1 className="text-neutral-500 text-sm font-normal">{languages.language === 'id' ? "Tarif" : "Rates"}</h1>
                                    <div className='flex items-center border rounded-md py-[6px] w-[66px] px-[10px]'>
                                        <input type="text" className='text-sm outline-none appearance-none w-1/2' />
                                        <h1 className="text-neutral-500 text-sm font-medium">%</h1>
                                    </div>
                                    <button className='w-[32px] h-[32px] flex items-center justify-center bg-[#FFE1E1] text-[#780000] rounded-md'>
                                        <CgTrashEmpty className='text-md' />
                                    </button>
                                </div>
                                <div className='flex gap-[11px] text-neutral-500 items-center'>
                                    <h1 className=" text-xs">{languages.language === 'id' ? "Jika Pendapatan" : "If Income"}</h1>
                                    <input type="number" placeholder='Rp' className='text-sm border rounded-md py-[6px] px-[10px] w-[177px] outline-none' />
                                    <h1 className=" text-sm">-</h1>
                                    <input type="number" placeholder='Rp' className='text-sm border rounded-md py-[6px] px-[10px] w-[177px] outline-none' />
                                    <h1 className=" text-sm">=</h1>
                                    <h1 className="text-neutral-500 text-sm font-normal">{languages.language === 'id' ? "Tarif" : "Rates"}</h1>
                                    <div className='flex items-center border rounded-md py-[6px] w-[66px] px-[10px]'>
                                        <input type="text" className='text-sm outline-none appearance-none w-1/2' />
                                        <h1 className="text-neutral-500 text-sm font-medium">%</h1>
                                    </div>
                                    <button className='w-[32px] h-[32px] flex items-center justify-center bg-[#FFE1E1] text-[#780000] rounded-md'>
                                        <CgTrashEmpty className='text-md' />
                                    </button>
                                </div>
                            </div>

                            <button className='bg-[#E1F4FF] py-[5px] px-[12px] rounded-md flex items-center gap-[5px]'>
                                <AiOutlinePlus className='text-lg text-[#219EBC]' />
                                <h1 className='text-[#219EBC] text-xs font-[500]'>{languages.language === 'id' ? "Tambah" : "Add"}</h1>
                            </button>

                            <hr className='border-[1px] border-dark-7 ' />

                            <div className='flex items-center justify-start pb-[41px] pt-[30px]'>
                                <button className=' px-[30px] py-[10px] bg-blue-primer rounded-lg text-white'>{languages.language === 'id' ? "Simpan" : "Save"}</button>
                            </div>
                        </>
                    ) : null}


                </div>
            </div>
        </div >
    )
}

export default PayrollSetting