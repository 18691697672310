import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HelmetReact = ({description, title}) => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content={description} />
                <meta name="title" content={title} />
            </Helmet>
        </HelmetProvider>
    )
}

export default HelmetReact