import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Navbar } from '../../../../../components'
import RealisasiBuktiDukung from '../../DetailLaporanKinerja/RealisasiBuktiDukung'
import LaporanKinerjaSaya from '../../DetailLaporanKinerja/LaporanKinerjaSaya'
import PenilaianRekanKerja from '../../DetailLaporanKinerja/PenilaianRekanKerja'

const DetailLaporanKinerjaSaya = () => {
    const params = useLocation()
    const idSkp = params?.state?.id
    const [menu, setMenu] = useState('Realisasi & Bukti Dukung')
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Detail Laporan Kinerja'} LinkBack={'/laporan-kinerja'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='border-b-2 flex justify-beetween items-center text-[16px]'>
                        <button onClick={() => setMenu('Realisasi & Bukti Dukung')} className={`${menu === 'Realisasi & Bukti Dukung' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px] w-full`}>Realisasi & Bukti Dukung</button>
                        <button onClick={() => setMenu('Laporan Kinerja Saya')} className={`${menu === 'Laporan Kinerja Saya' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px] w-full`}>Laporan Kinerja Saya</button>
                        <button onClick={() => setMenu('Penilaian Rekan Kerja')} className={`${menu === 'Penilaian Rekan Kerja' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px] w-full`}>Penilaian Rekan Kerja</button>
                    </div>

                    {menu === 'Realisasi & Bukti Dukung' ? (
                        <RealisasiBuktiDukung idSkp={idSkp} />
                    ) : menu === 'Laporan Kinerja Saya' ? (
                        <LaporanKinerjaSaya idSkp={idSkp} />
                    ) : menu === 'Penilaian Rekan Kerja' ? (
                        <PenilaianRekanKerja idSkp={idSkp} />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default DetailLaporanKinerjaSaya