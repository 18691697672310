import React from "react";
import languages from "../../../../../utils/languages";

const PollingStepper = ({ step, onPrevious, onNext, isFirst, isLast, currentStep, totalStep, question }) => {

  const handlePrevious = () => {
    onPrevious();
  };

  const handleNext = () => {
    onNext();
  };
  const steps = Array.from({ length: totalStep }, (_, index) => index);

  return (
    <div>
      <h1 className="text-cyan-900 text-[10px] font-medium mb-[3px]">{currentStep + 1} / {totalStep}</h1>
      <div className="flex  mb-[62px] rounded-xl z-10">
        {step.map((idx) => (
          <div key={idx}
            className={`w-full h-2  ${
              idx <= currentStep
                ? "bg-cyan-900" // Warna latar belakang untuk langkah yang telah dilewati atau yang aktif
                : "bg-gray-300" // Warna latar belakang untuk langkah yang belum dilewati
            }`}
          ></div>
        ))}
     </div>
      <h1 className="text-center text-zinc-700 text-xl font-semibold mb-[41px]">{question}</h1>
      {step.map((item, idx) => (
        <div key={idx} className="mb-[16px] flex justify-center items-center">
          <button className="bg-white rounded-lg border border-gray-300 w-[550px] flex items-center justify-center py-[25px]">
            <p>{item.text}</p>
          </button>
        </div>
      ))}
      <div className="flex items-center justify-end gap-[24px] pt-[139px]">
        <button onClick={handlePrevious} disabled={isFirst} className="rounded-md px-[25px] py-[10px] text-blue-thick bg-[#EBEBEB] font-medium">
          {languages.language == 'id' ?"Kembali":"Previous"}
        </button>
        <button onClick={handleNext} disabled={isLast} className="rounded-md px-[30px] py-[10px] text-white bg-blue-thick font-medium">
          {languages.language == 'id' ?"Selanjutnya":"Next"}
        </button>
      </div>
    </div>
  );
};

export default PollingStepper;
