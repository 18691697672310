import React, { useEffect, useState } from 'react'
import { Modal, Navbar, Sidebar } from '../../../../components'
import { AiOutlinePlus } from 'react-icons/ai'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import moment from 'moment'
import { CgTrashEmpty } from 'react-icons/cg'

const EditRecruitment = () => {
    const navigate = useNavigate()
    const params = useLocation()
    const [showModalSpesificQualification, setShowModalSpesificQualification] = useState()

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [position, setPosition] = useState('')
    const [placement, setPlacement] = useState('')
    const [jobDescription, setJobDescription] = useState('')
    const [qualification, setQualification] = useState('')
    const [location, setLocation] = useState('')
    const [type, setType] = useState('')
    const [publishDate, setPublishDate] = useState('')
    const [expiredDate, setExpiredDate] = useState('')
    const [ageRange, setAgeRange] = useState('')
    const [gender, setGender] = useState('')
    const [education, setEducation] = useState('')
    const [experience, setExperience] = useState('')
    const [skill, setSkill] = useState('')
    const [priority, setPriority] = useState('')

    const GetRecruitmentById = async() => {
        try {
            const response = await Api.GetRecruitmentById(localStorage.getItem('hris-token'), params.state.recruitmenId)
            console.log(response, 'getById')
            setTitle(response.data.recruitment.title)
            setDescription(response.data.recruitment.description)
            setPosition(response.data.recruitment.position)
            setPlacement(response.data.recruitment.placement)
            setJobDescription(response.data.recruitment.jobDescription)
            setQualification(response.data.recruitment.qualification)
            setLocation(response.data.recruitment.location)
            setType(response.data.recruitment.type)
            setPublishDate(response.data.recruitment.publishDate)
            setExpiredDate(response.data.recruitment.expiredDate)
            setAgeRange(response.data.recruitment.spesific_recruitment[0].ageRange)
            setGender(response.data.recruitment.spesific_recruitment[0].gender)
            setEducation(response.data.recruitment.spesific_recruitment[0].education)
            setExperience(response.data.recruitment.spesific_recruitment[0].experience)
            setSkill(response.data.recruitment.spesific_recruitment[0].skill)
            setPriority(response.data.recruitment.spesific_recruitment[0].priority)
        } catch (error) {
            console.log(error)
        }
    }

    function checkEmptyStrings(data) {
        function isEmptyString(str) {
            return str.trim() === '';
        }
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                if (isEmptyString(value)) {
                    toast.error('Data cannot be empty')
                    return;
                }
            }
        }
    }

    const UpdateRecruitment = async() => {    
        try {
            const data = {
                title: title,
                description: description,
                position: position,
                placement: placement,
                jobDescription: jobDescription,
                qualification: qualification,
                location: location,
                type: type,
                publishDate: publishDate,
                expiredDate: expiredDate,
                ageRange: ageRange,
                gender: gender,
                education: education,
                experience: experience,
                skill: skill,
                priority: priority
            }
            checkEmptyStrings(data)
            const response = await Api.UpdateRecruitment(localStorage.getItem('hris-token'), data, params.state.recruitmenId)
            console.log(response)
            toast.success('Success Update')
            navigate('/recruitment')
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = () => {
        setShowModalSpesificQualification(!showModalSpesificQualification)
        toast.success('Success Add Spesific Qualification')
    }

    useEffect(() => {
        GetRecruitmentById()
    }, [])

    return (
        <div>
            <Modal 
                activeModal={showModalSpesificQualification}
                title={'Spesific Qualification '}
                buttonClose={ () => setShowModalSpesificQualification(!showModalSpesificQualification)}
                width={'715px'}
                content= {
                    <div className='space-y-[20px] w-full'>
                        <div className='w-full mb-[30px]'>
                            <h1 className='text-dark-5 text-[13px] font-[400] mb-[7px]'>Age Range <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='flex items-center gap-[12px]'>
                                <div className='border w-full rounded-md py-[10px] pl-[15px] pr-2'>
                                    <input type="text" onChange={ (e) => setAgeRange(e.target.value)} placeholder='Age Range' className='placeholder:text-[12px] text-[12px] appearance-none outline-none w-full' />
                                </div>
                            </div>
                        </div>
                        <div className='w-full mb-[30px]'>
                            <h1 className='text-dark-5 text-[13px] font-[400] mb-[7px]'>Gender<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select value={gender} onChange={ (e) => setGender(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''} disabled>Select Gender Qualification </option>
                                    <option value={'man'}>Man</option>
                                    <option value={'woman'}>Woman</option>
                                    <option value={'both'}>Both of Them</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='w-full mb-[30px]'>
                            <h1 className='text-dark-5 text-[13px] font-[400] mb-[7px]'>Education <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select value={education} onChange={ (e) => setEducation(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''} disabled>Select Education Qualification </option>
                                    <option value={'SMA/SMK'}>SMA/SMK Sederajat</option>
                                    <option value={'S1'}>S1</option>
                                    <option value={'S2'}>S2</option>
                                    <option value={'S3'}>S3</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='w-full mb-[30px]'>
                            <h1 className='text-dark-5 text-[13px] font-[400] mb-[7px]'>Experience <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select value={experience} onChange={ (e) => setExperience(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''} disabled>Select Experience Time</option>
                                    <option value={'No Experience Yet'}>No Experience Yet</option>
                                    <option value={'1 Year'}>1 Year</option>
                                    <option value={'2 Years'}>2 Years</option>
                                    <option value={'3 Years'}>3 Years</option>
                                    <option value={'4 Years'}>4 Years</option>
                                    <option value={'5 Years'}>5 Years</option>
                                    <option value={'> 5 Years'}>&#62; 5 Years</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='w-full mb-[30px]'>
                            <h1 className='text-dark-5 text-[13px] font-[400] mb-[7px]'>Skill Qualification <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='flex items-center gap-[12px]'>
                                <div className='border w-full rounded-md py-[10px] pl-[15px] pr-2'>
                                    <input value={skill} type="text" onChange={ (e) => setSkill(e.target.value)} placeholder='Skill Qualification' className='placeholder:text-[12px] text-[12px] appearance-none outline-none w-full' />
                                </div>
                            </div>
                        </div>
                        <div className='w-full mb-[30px]'>
                            <h1 className='text-dark-5 text-[13px] font-[400] mb-[7px]'>Priority Option <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select value={priority} onChange={ (e) => setPriority(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select the priority option</option>
                                    <option value={'Age'}>Age</option>
                                    <option value={'Gender'}>Gender</option>
                                    <option value={'Education'}>Education</option>
                                    <option value={'Experience'}>Experience</option>
                                    <option value={'Skill'}>Skill </option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>

                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalSpesificQualification(!showModalSpesificQualification)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={handleSubmit} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                        </div>
                    </div>
                }
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[41px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Edit Recruitment'} LinkBack={'/recruitment'}/>
                    <div className=''>
                        <h1 className='text-[20px] font-semibold text-dark-3'>Edit Recruitment</h1>
                        <h2 className='text-[10px] text-dark-5'>Make sure the data filled in matches the vacancies opened</h2>
                    </div>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <div className='mb-[30px]'>
                                <h1 className='text-[12px] text-grey-thick font-medium mb-[9px]'>Recruitment Position <span className='text-red-primer'>*</span></h1>
                                <input value={title} type="text" onChange={ (e) => setTitle(e.target.value)} className='py-[10px] pl-[15px] pr-2 text-[12px] border rounded-md w-full' placeholder='ex: Designer' />
                            </div>
                            <div className='flex w-full gap-[21px]'>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>Employee Status <span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-full'>
                                            <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected value="">Select employee status</option>
                                                <option value="Option 1">Option 1</option>
                                                <option value="Option 2">Option 2</option>
                                                <option value="Option 3">Option 3</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[5px]'>Placement Subsidiary <span className='text-red-primer'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-full'>
                                            <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected value="">Select Subsidiary</option>
                                                <option value="Option 1">Option 1</option>
                                                <option value="Option 2">Option 2</option>
                                                <option value="Option 3">Option 3</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-[30px]'>
                                <h1 className='text-[12px] text-grey-thick font-medium mb-[9px]'>Job Description <span className='text-red-primer'>*</span></h1>
                                <textarea value={jobDescription}  onChange={ (e) => setJobDescription(e.target.value)} className='py-[10px] pl-[15px] pr-2 text-[12px] border rounded-md w-full resize-none' rows={'8'} placeholder='Job position description ...' />
                            </div>
                            <div className='mb-[15px]'>
                                <h1 className='text-[12px] text-grey-thick font-medium mb-[9px]'>General Qualification <span className='text-red-primer'>*</span></h1>
                                <textarea value={qualification}  onChange={ (e) => setQualification(e.target.value)} className='py-[10px] pl-[15px] pr-2 text-[12px] border rounded-md w-full resize-none' rows={'8'} placeholder='General Qualification ...' />
                            </div>

                            <button onClick={ () => setShowModalSpesificQualification(!showModalSpesificQualification)} className='flex items-center gap-[13px] text-blue-primer font-medium mb-[34px]'>
                                <AiOutlinePlus/>
                                <h1 className='text-[12px]'>Add Specific Qualification </h1>
                            </button>

                            <div className='flex gap-[39px]'>
                                <div className='text-[12px] w-[452px] h-[35px]'>
                                    <h1 className='text-grey-thick font-medium'>Publish Date <span className='text-red-primer'>*</span></h1>
                                    <input value={moment(publishDate).format('YYYY-MM-DD')} type="date" onChange={ (e) => setPublishDate(e.target.value)} className='py-[10px] pl-[17.7px] pr-[19px] border rounded-md w-[452px]' />
                                </div>
                                <div className='text-[12px] w-[452px] h-[35px]'>
                                    <h1 className='text-grey-thick font-medium'>Expired Date <span className='text-red-primer'>*</span></h1>
                                    <input value={moment(expiredDate).format('YYYY-MM-DD')} type="date" onChange={ (e) => setExpiredDate(e.target.value)} className='py-[10px] pl-[17.7px] pr-[19px] border rounded-md w-[452px]' />
                                </div>
                            </div>

                        </div>

                        <div className="text-black text-base font-medium pt-[20px]">Recruitment Stage</div>
                        <div>
                            <h1 className='text-gray-500 text-xs font-medium mb-[9px]'>Stage Name</h1>
                            <div className='flex gap-[14px] mb-[6px]'>
                                <input type="text" onChange={ (e) => setTitle(e.target.value)} className='py-[10px] pl-[15px] pr-2 text-[12px] border rounded-md w-[453px]' placeholder='ex: Designer' />
                                <div className='py-[5px] px-[12px] bg-[#FFE1E1] text-[#780000] rounded-md flex items-center justify-center'>
                                    <CgTrashEmpty className='text-xl' />
                                </div>
                            </div>
                            <button className='py-[7px] px-[8px] flex gap-[4px] text-blue-thick font-medium bg-indigo-200 rounded-lg'>
                                <AiOutlinePlus />
                                <h1 className='text-xs'>Add Stage</h1>
                            </button>
                        </div>
                        
                    </div>
                    <div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => navigate('/recruitment')} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={UpdateRecruitment} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditRecruitment