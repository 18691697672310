import React, { useState } from 'react'
import { Navbar } from '../../../components'
import LaporanKinerjaBawahan from './LaporanKinerjaBawahan'
import LaporanKinerjaSaya from './LaporanKinerjaSaya'
import SasaranKinerjaBawahan from './SasaranKinerjaBawahan'

const LaporanKinerjaPegawai = () => {
    // const params = useLocation()
    // const idSkp = params?.state?.id
    const [menu, setMenu] = useState('Laporan Kinerja Saya')
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Laporan Kinerja'} LinkBack={'/dashboard'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='border-b-2 flex justify-start items-center text-[16px]'>
                        <button onClick={() => setMenu('Laporan Kinerja Saya')} className={`${menu === 'Laporan Kinerja Saya' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Laporan Kinerja Saya</button>
                        <button onClick={() => setMenu('Sasaran Kinerja Bawahan')} className={`${menu === 'Sasaran Kinerja Bawahan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Sasaran Kinerja Bawahan</button>
                        <button onClick={() => setMenu('Laporan Kinerja Bawahan')} className={`${menu === 'Laporan Kinerja Bawahan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Laporan Kinerja Bawahan</button>
                    </div>

                    {menu === 'Laporan Kinerja Saya' ? (
                        <LaporanKinerjaSaya />
                    ) : menu === 'Sasaran Kinerja Bawahan' ? (
                        <SasaranKinerjaBawahan />
                    ) : menu === 'Laporan Kinerja Bawahan' ? (
                        <LaporanKinerjaBawahan />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default LaporanKinerjaPegawai