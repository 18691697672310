import React, { useState } from 'react'
import { Navbar } from '../../../../components'
import Cuti from './Cuti'
import Izin from './Izin'
import Kehadiran from './Kehadiran'
import PerjalananDinas from './PerjalananDinas'

const AbsensiBawahan = () => {
    const [menu, setMenu] = useState('kehadiran')

    return (
        <div>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Absensi Bawahan'} LinkBack={'/dashboard'} />
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border w-full'>
                        <div className='border-b-2 flex flex-wrap items-center text-[16px]'>
                            <button onClick={() => setMenu('kehadiran')} className={`${menu === 'kehadiran' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Kehadiran</button>
                            <button onClick={() => setMenu('cuti')} className={`${menu === 'cuti' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Cuti</button>
                            <button onClick={() => setMenu('izin')} className={`${menu === 'izin' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Izin</button>
                            <button onClick={() => setMenu('perjalananDinas')} className={`${menu === 'perjalananDinas' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} py-[10px] px-[80px]`}>Perjalanan Dinas</button>
                        </div>

                        {
                            menu === 'kehadiran' ? (
                                <Kehadiran />
                            ) : menu === 'cuti' ? (
                                <Cuti />
                            ) : menu === 'izin' ? (
                                <Izin />
                            ) : menu === 'perjalananDinas' ? (
                                <PerjalananDinas />
                            ) : null}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AbsensiBawahan