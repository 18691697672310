import moment from 'moment'
import React from 'react'
import { BiDownload } from 'react-icons/bi'
import { HiPaperClip } from 'react-icons/hi'
import imageHandle from '../../utils/imageHandle'

const InboxDetailPresensiManual = ({ detail, onClickDecline, onClickApprove, buttonHide }) => {
    return (
        <div>
            <div className='space-y-[40px] w-[full]'>
                <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-full'>
                    <div className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 text-[11px] text-dark-5 gap-y-[20px]">
                        <h1 className='font-semibold'>Nama Pegawai</h1>
                        <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {detail?.presensi?.user?.fullname} </h1>
                        <h1 className='font-semibold'>Tanggal</h1>
                        <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {detail?.presensi?.tanggal ? moment(detail?.presensi?.tanggal).format("DD-MM-YYYY") : '-'} </h1>
                        <h1 className='font-semibold'>Jam Masuk</h1>
                        <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {detail?.presensi?.jam ? moment(detail?.presensi?.jam).format("HH:MM") : '-'}</h1>
                        <h1 className='font-semibold'>Jenis Presensi</h1>
                        <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {detail?.presensi?.jenis === "MASUK" ? "Presensi Masuk" : detail?.presensi?.jenis === "KELUAR" ? "Presensi Keluar" : ""}</h1>
                        <h1 className='font-semibold'>Alasan</h1>
                        <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {detail?.presensi?.alasan ? detail?.presensi?.alasan : '-'}</h1>
                        <h1 className='font-semibold'>Status Persetujuan</h1>
                        <h1 className='font-medium lg:col-span-5 md:col-span-3'>: {detail?.presensi?.status ? detail?.presensi?.status === "approved" ? "Disetujui" : detail?.presensi?.status === "decline" ? "Ditolak" : "-" : '-'}</h1>
                        <h1 className='font-semibold'>Bukti</h1>

                        <div className='lg:col-span-5 md:col-span-3 col-span-2'>
                            {detail.files && detail.files.map((file, idx) => (
                                <div key={idx} className='flex items-center gap-3'>
                                    <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                    <a href={imageHandle(file?.path)} download={file?.originalname} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2'>
                                        <BiDownload className='text-xl' />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {buttonHide &&
                <div className='flex items-center justify-end gap-[20px] mt-10'>
                    <button onClick={onClickDecline} className='bg-[#C1121F] text-dark-9 text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Tolak
                    </button>
                    <button onClick={onClickApprove} className='bg-[#04BB00] text-dark-9 text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Setujui
                    </button>
                </div>
            }
        </div>
    )
}

export default InboxDetailPresensiManual