import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiPlus } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import Api from '../../../../../Api'
import { ModalDelete, Navbar, Pagination } from '../../../../../components'
import languages from '../../../../../utils/languages'
import { IoMdArrowDropdown } from 'react-icons/io'

const Subsidiary = () => {
    const [dataSubsidiary, setDataSubsidiary] = useState('')
    const [refresh, setRefresh] = useState('')
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalData, setTotalData] = useState('')

    const navigate = useNavigate()

    // openIndex
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)

    const handleCollapse = (id) => {
        if (id) {
            setCurrent(id)
            setActive(!Active)
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const getSubsidiary = async () => {
        try {
            const response = await Api.GetSubsidiary(localStorage.getItem('hris-token'), currentPage, limit)
            // console.log(response.data)
            setDataSubsidiary(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalData(response.data.total)
            // console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    // const getCompanyById = async () => {
    //     try {
    //         const response = await Api.GetCompanyById(localStorage.getItem('hris-token'), companyId)
    //         setCompanyName
    //     } catch (error) {
    //         console.log(response)
    //     }
    // }

    const deleteSubsidiary = async () => {
        try {
            await Api.DeleteSubsidiary(localStorage.getItem('hris-token'), idSubsidiary).then((res) => {
                setShowModalDelete(!showModalDelete)
                setRefresh(true)
                toast.success('Berhasil menghapus data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data gagal dihapus!')
        }
    }

    const [showModalDelete, setShowModalDelete] = useState()
    const [idSubsidiary, setIdSubsidiary] = useState('')

    const deleteSubsidiaryModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setIdSubsidiary(id)
        setRefresh(true)
    }

    useEffect(() => {
        getSubsidiary()
        setRefresh(false)
    }, [refresh, limit])


    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteSubsidiary}
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Penempatan" : "Subsidiary"} LinkBack={'/dashboard'} />
                <div>
                    {/* <div className='py-[10px]'>
                            <h1 className='text-xl font-semibold text-grey-primer'>Nama Perusahaan</h1>
                        </div> */}
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-b-[12px] rounded-tr-[12px] border'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-dark-3 text-[16px] font-[500]'>
                                {languages.language === 'id' ? "Penempatan" : "Subsidiary"}
                            </h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                {languages.language === 'id' ? "Daftar Penempatan" : "list of subsidiary"}
                            </h1>
                        </div>
                        <div className='flex flex-col lg:flex-row justify-between mb-[36px]'>
                            <div className='relative w-fit'>
                                <select onChange={(e) => setLimit(e.target.value)} value={limit} className='text-[#003049] text-sm outline-none font-medium rounded-md w-[100px] border border-gray-300 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option value={""} className='text-grey-medium font-normal'>Limit</option>
                                    <option value={10} className='text-grey-medium font-normal '>10</option>
                                    <option value={25} className='text-grey-medium font-normal '>25</option>
                                    <option value={50} className='text-grey-medium font-normal '>50</option>
                                    <option value={100} className='text-grey-medium font-normal '>100</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <div className='flex items-center gap-[13px] mt-3 lg:mt-0 w-full lg:w-auto'>
                                <Link to={'/subsidiary/add-subsidiary'} className='bg-[#0E5073] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <BiPlus className='text-white' />
                                    <h1 className='text-white text-sm font-[500]'>{languages.language === 'id' ? "Tambah Penempatan" : "Add Subsidiary"}</h1>
                                </Link>
                            </div>
                        </div>
                        <div className='overflow-auto'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Nama Penempatan" : "Subsidiary Name"}</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px] border'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>Zona Waktu</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Nomor Registrasi" : "Registration Number"}</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Status" : "Status"}</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] justify-center w-full'>
                                        <h1 className='text-dark-5 text-xs font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                    </div>
                                </div>
                                <div>
                                    {Object.values(dataSubsidiary).map((item, idx) => (
                                        <div key={idx}>
                                            <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] rounded-[3px]`}>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-xs font-[500] truncate'>{item.company_name ?? '-'}</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-xs font-[500] truncate'>{item.timezone ?? "-"}</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-xs text-center font-[500] truncate'>{item.registration_number ?? '-'}</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-xs text-center font-[500] truncate'>{item.statusCompany ? "Aktif" : "Tidak Aktif"}</h1>
                                                </div>
                                                <div className='flex items-center justify-center w-full gap-[12px]'>
                                                    {/* <button onClick={() => navigate('/subsidiary/detail-subsidiary', { state: { subsidiaryId: item.id } })} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <AiOutlineEye className='text-[#003049]' />
                                                        </button> */}
                                                    <button onClick={() => navigate('/subsidiary/edit-subsidiary', { state: { subsidiaryId: item.id } })} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]' />
                                                    </button>
                                                    <button onClick={() => deleteSubsidiaryModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]' />
                                                    </button>
                                                    <button onClick={() => handleCollapse(item.id)} className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                        {item.id === Current && !Active ?
                                                            <MdKeyboardArrowUp className='text-[#003049]' />
                                                            :
                                                            <MdKeyboardArrowDown className='text-[#003049]' />
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${item.id === Current && !Active ? 'h-fit border' : 'h-0 border-0'}`}>
                                                <div className='grid grid-cols-12 gap-5 px-4 py-4'>
                                                    <div className='flex flex-col gap-3 col-span-2'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "ID Tax" : "Tax ID"}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "Nomor Telepon" : "Phone Number"}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "Fax" : "Fax"}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "Email" : "Email"}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "Alamat" : "Address"}</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-1'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-3'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.tax_id ?? '-'}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.phone ?? '-'}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.fax ?? '-'}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item?.user[0]?.email ?? '-'}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.address ?? '-'}</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-2'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "Ksbupaten/Kota" : "City"}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "Provinsi" : "Province"}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "Kode Pos" : "Postal Code"}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "Negara" : "Country"}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>{languages.language === 'id' ? "Catatan" : "Notes"}</h1>
                                                    </div>
                                                    <div className='flex flex-col gap-3 col-span-1'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    </div>

                                                    <div className='flex flex-col gap-3 col-span-3'>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.city ?? '-'}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.province ?? '-'}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.postal_code ?? '-'}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.country ?? '-'}</h1>
                                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-4'>{item.notes ?? '-'}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            lengthData={totalData}
                            limitData={dataSubsidiary?.length}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subsidiary