import { saveAs } from 'file-saver'
import L from "leaflet"
import React, { useEffect, useState } from 'react'
import { default as Chart, default as ReactApexChart } from 'react-apexcharts'
import { toast } from 'react-hot-toast'
import { AiFillClockCircle, AiOutlinePlus } from 'react-icons/ai'
import { FaFileExport, FaInfoCircle, FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import { Link } from 'react-router-dom'
import * as XLSX from 'xlsx'
import Api from '../../../Api'
import { ExampleNews, IconDCardDashboard, LogoH } from '../../../assets'
import { DCardEmploye, DCardNews, Modal, Navbar } from '../../../components'
import SearchComponent from '../../../components/Input/searchComponent'
import TablePenghargaan from '../../../components/Table/TablePenghargaan'
import TableTraining from '../../../components/Table/TableTraining'
import TableTugasBelajar from '../../../components/Table/TableTugasBelajar'
import imageHandle from '../../../utils/imageHandle'
import languages from '../../../utils/languages'
import Cuti from '../Time Management/AbsensiBawahan/Cuti'
import Izin from '../Time Management/AbsensiBawahan/Izin'
import Kehadiran from '../Time Management/AbsensiBawahan/Kehadiran'
import PerjalananDinas from '../Time Management/AbsensiBawahan/PerjalananDinas'
import Select from 'react-select'
import { selectStyle } from '../../../utils/styles/select'
import { BiFilter } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'
import moment from 'moment'

const Dashboard = () => {
    const tz = moment.tz.guess(true);

    const [dataNews, setDataNews] = useState('')
    const [getStatistict, setGetStatistict] = useState('')
    const [getBirthdate, setGetBirthdate] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [modalAddAnnouncement, setModalAddAnnouncement] = useState()
    const [isWFH, setIsWFH] = useState(false)

    const [atasan, setAtasan] = useState('')
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setAtasan(response.data.results.user.isAtasan)
            setIsWFH(response.data.results.user.user_detail.isWFH)
        } catch (error) {
            console.log(error)
        }
    }


    const getData = async () => {
        try {
            const resNews = await Api.GetNews(localStorage.getItem('hris-token'), '', '', '')
            // const resForum = await Api.GetForum(localStorage.getItem('hris-token'))
            const resStat = await Api.GetStatistict(localStorage.getItem('hris-token'))
            const resBirth = await Api.GetBirthdateByMonth(localStorage.getItem('hris-token'))
            setGetBirthdate(resBirth.data.results.data)
            setGetStatistict(resStat.data)
            // setGetForum(resForum.data.data)
            setDataNews(resNews.data.news)
            // setGetNewEmployee(resNewEmp.data)
            // setAppliedEmployee(resAppliedEmployee.data.entryAplicant)
        } catch (error) {
            console.log(error)
        }
    }
    const [chartDataJenisPegawai, setChartDataJenisPegawai] = useState({
        options: {
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 280
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
        series: []
    });
    const [chartDataAbsensi, setChartDataAbsensi] = useState({
        options: {
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 280
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
        series: []
    });
    const [chartDataAbsensiPegawai, setChartDataAbsensiPegawai] = useState({
        options: {
            labels: [],
            chart: {
                type: 'pie',
            },
            legend: {
                position: 'bottom'
            }
        },
        series: []
    });
    const [dataAbsensi, SetDataAbsensi] = useState({

        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%'
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            xaxis: {
                categories: [],
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'right',
                offsetX: 0,
                offsetY: 50
            },
        },


    })
    const [dataAbsensi2, SetDataAbsensi2] = useState({

        series: [{
            name: '',
            data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65, 35]
        }],
        options: {
            annotations: {
                points: [{
                    x: 'tanggal',
                    seriesIndex: 0,
                    label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                            color: '#fff',
                            background: '#775DD0',
                        },
                        text: 'Tangggal',
                    }
                }]
            },
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false // Menyembunyikan toolbar
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    columnWidth: '50%',
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                labels: {
                    rotate: -45
                },
                categories: ['Apples', 'Oranges', 'Strawberries', 'Pineapples', 'Mangoes', 'Bananas',
                    'Blackberries', 'Pears', 'Watermelons', 'Cherries', 'Pomegranates', 'Tangerines', 'Papayas'
                ],
                tickPlacement: 'on'
            },
            yaxis: {
                title: {
                    text: '',
                },
            },
            fill: [
                {
                    type: 'solid', // Menggunakan warna solid
                    colors: ['#780000'], // Mengatur warna bar menjadi #780000
                },
                {
                    type: 'solid', // Menggunakan warna solid
                    colors: ['#790000'], // Mengatur warna bar menjadi #780000
                },
                {
                    type: 'solid', // Menggunakan warna solid
                    colors: ['#760000'], // Mengatur warna bar menjadi #780000
                },
                {
                    type: 'solid', // Menggunakan warna solid
                    colors: ['#770000'], // Mengatur warna bar menjadi #780000
                },
            ]
        }
    })
    const getStatistictJenisKepegawaian = async () => {
        try {
            const response = await Api.GetStatistictJenisKepegawaian(localStorage.getItem('hris-token'))
            const res = response.data.results.data
            const labels = res.labels;
            const series = res.series;
            setChartDataJenisPegawai({
                options: {
                    ...chartDataJenisPegawai.options,
                    labels: labels
                },
                series: series
            });
        } catch (error) {
            console.log(error)
        }
    }
    const getStatistictRekapAbsen = async () => {
        try {
            const response = await Api.GetStatistictRekapAbsen(localStorage.getItem('hris-token'))
            const res = response.data.results.data
            const labels = res.labels;
            const series = res.series;
            setChartDataAbsensi({
                options: {
                    ...chartDataAbsensi.options,
                    labels: labels
                },
                series: series
            });
            setChartDataAbsensiPegawai({
                options: {
                    ...chartDataAbsensi.options,
                    labels: labels
                },
                series: series
            });
            SetDataAbsensi({
                options: {
                    ...dataAbsensi.options, // Keep the existing options
                    xaxis: {
                        ...dataAbsensi.options.xaxis, // Keep the existing xaxis options
                        categories: ['21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02', '21/02',]
                    }
                },
                series: [
                    {
                        name: 'PRODUCT A',
                        data: [44, 55, 41, 67, 22, 43, 21, 49]
                    },
                    {
                        name: 'PRODUCT B',
                        data: [13, 23, 20, 8, 13, 27, 33, 12]
                    },
                    {
                        name: 'PRODUCT C',
                        data: [11, 17, 15, 15, 21, 14, 15, 13]
                    },
                    {
                        name: 'PRODUCT C',
                        data: [11, 17, 15, 15, 21, 14, 15, 13]
                    }
                ]
            });
            SetDataAbsensi2({
                series: [
                    {
                        name: 'Kehadiran',
                        data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65, 35]
                    },
                    {
                        name: 'Cuti',
                        data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65, 35]
                    },
                    {
                        name: 'Izin',
                        data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65, 35]
                    },
                    {
                        name: 'Perjalanan Dinas',
                        data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65, 35]
                    },
                ],
                options: {
                    ...dataAbsensi2.options, // Spread the existing options
                    xaxis: {
                        ...dataAbsensi2.options.xaxis, // Spread the existing xaxis options
                        categories: ['2011', '2011', '2011', '2011', '2012', '2012', '2012', '2012', '2013', '2013', '2013', '2013', '2014']
                    }
                }
            });

        } catch (error) {
            console.log(error)
        }
    }
    const admin = localStorage.getItem('hris-role') === 'ADMIN'
    const subsidiary = localStorage.getItem('hris-role') === 'SUBSIDIARY'

    const [geodata, setGeoData] = useState([])
    const getGeoData = async () => {
        try {
            const response = await Api.GetGeoLocationByUser(localStorage.getItem("hris-token"))
            setGeoData(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const [dataPresensi, setDataPresensi] = useState([])
    const getDataPresensi = async () => {
        try {
            const response = await Api.GetMyAttendanceRecord(localStorage.getItem('hris-token'))
            setDataPresensi(response.data.attendances[0] ? response.data.attendances[0] : [])
        } catch (error) {
            console.log(error)
        }
    }

    const [lat, setLat] = useState()
    const [long, setLong] = useState()

    // Fungsi isInRadius
    function isInRadius(lat1, lon1, lat2, lon2, radiusInMeters) {
        // Konversi derajat ke radian
        const toRadians = degree => degree * (Math.PI / 180);

        // Menghitung jarak Haversine
        const haversine = (a, b) => {
            const dLat = toRadians(b.lat - a.lat);
            const dLon = toRadians(b.lon - a.lon);
            const hav =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRadians(a.lat)) *
                Math.cos(toRadians(b.lat)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(hav), Math.sqrt(1 - hav));
            const radius = 6371000; // Radius bumi dalam meter
            return radius * c;
        };

        // Titik koordinat lokasi 1
        const point1 = { lat: lat1, lon: lon1 };

        // Titik koordinat lokasi 2
        const point2 = { lat: lat2, lon: lon2 };

        // Menghitung jarak antara kedua titik dalam meter
        const distance = haversine(point1, point2);

        // Memeriksa apakah jarak kurang dari atau sama dengan radius yang ditentukan
        return distance <= radiusInMeters;
    }
    const [modalCheckIn, setModalCheckIn] = useState(false)
    const [modalCheckOut, setModalCheckOut] = useState(false)

    const CheckInDataAttendance = async () => {
        setModalCheckIn(false)
        let resultRadius = false
        if (isWFH) {
            resultRadius = true
        } else {
            geodata.forEach((data) => {
                const geocodeArray = JSON.parse(data.geocode);
                const georadius = data.georadius;
                const result = isInRadius(geocodeArray[0], geocodeArray[1], lat, long, georadius);

                if (result) {
                    resultRadius = true;
                    return;
                }
            });
        }
        if (!resultRadius) {
            toast.error('Anda diluar radius!')
        } else {
            try {
                const data = {
                    note: "ok",
                    longitude_checkin: JSON.stringify(long),
                    latitude_checkin: JSON.stringify(lat),
                    isWFH: isWFH,
                    timezone: tz,
                }
                await Api.PostMyAttendanceCheckIn(localStorage.getItem('hris-token'), data).then((res) => {
                    toast.success('Presensi Masuk Berhasil')
                    setRefresh(true)
                }).catch((err) => {
                    console.log(err)
                    toast.error(err?.response?.data?.message)
                })
            } catch (error) {
                console.log(error)
                toast.error("Presensi Masuk Gagal")
                toast.error(error.response.data.message)
            }
        }
    }

    const CheckOutDataAttendance = async () => {
        setModalCheckOut(false)
        let resultRadius = false
        geodata.forEach((data) => {
            const geocodeString = data.geocode;
            const geocodeArray = JSON.parse(geocodeString);
            const georadius = data.georadius
            const result = isInRadius(geocodeArray[0], geocodeArray[1], lat, long, georadius);
            if (result) {
                resultRadius = result
            }
        });
        if (isWFH) {
            resultRadius = true
        }
        if (!resultRadius) {
            toast.error('Anda diluar radius!')
        } else {
            try {
                const data = {
                    longitude_checkout: JSON.stringify(long),
                    latitude_checkout: JSON.stringify(lat),
                    isWFH: isWFH,
                    timezone: tz,
                }
                await Api.PostMyAttendanceCheckOut(localStorage.getItem('hris-token'), data).then((res) => {
                    setRefresh(true)
                    toast.success('Presensi Keluar Berhasil')
                }).catch((err) => {
                    console.log(err)
                    toast.error(err?.response?.data?.message)
                })
            } catch (error) {
                console.log(error)
                toast.error('Presensi Keluar Gagal')
                toast.error(error.response.data.message)
            }
        }
    }

    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [unitKerja, setUnitKerja] = useState("")
    const [selected, setSelected] = useState("")

    const getUnitKerja = async () => {
        try {
            await Api.GetUnitKerjaEselon(localStorage.getItem('hris-token')).then((response) => {
                const data = response?.data?.results?.data
                const options = data && data.map(val => ({
                    "value": val.name,
                    "label": val.name
                }))
                setDataUnitKerja(options)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleCahnge = (selectedOption) => {
        const data = []
        selectedOption && selectedOption.map(val => (
            data.push(val.value)
        ))
        setSelected(selectedOption)
        setUnitKerja(data)
    }

    const [grafikKinerjaSaya, setGrafikKinerjaSaya] = useState({
        series: [],
        options: {
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#e7e7e7",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2
                        }
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: -2,
                            fontSize: '22px'
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    shadeIntensity: 0.4,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 53, 91]
                },
            },
            labels: ['Results'],
            colors: ['#E00101', '#FF5D3C', '#F44336', '#E91E63'],
        }
    })
    const [detailKinerjaSaya, setDetailKinerjaSaya] = useState([])

    const getKinerjaSaya = async () => {
        try {
            await Api.GetWidgetKinerjaSaya(localStorage.getItem('hris-token')).then((response) => {
                setGrafikKinerjaSaya(prevState => ({
                    ...prevState,
                    series: [response?.data?.results?.persentase_kinerja]
                }));
                setDetailKinerjaSaya(response?.data?.results?.detail)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const today = moment().format('YYYY-MM-DD');

    const [menu, setMenu] = useState('kehadiran')
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
    const [name, setName] = useState("")
    const [isAscending, setIsAscending] = useState(true)
    const [limitData, setLimitData] = useState(10)

    const [totalDataExport, setTotalDataExport] = useState("")
    const handleLimit = (limit) => {
        setTotalDataExport(limit)
    };
    // Start Export Data Kehadiran
    const exportKehadiran = async () => {
        const data = {
            "page": 1,
            "limit": totalDataExport,
            "start_date": startDate,
            "end_date": endDate,
            "isAsc": isAscending,
            "search": name,
            "unitKerjaEselon": filter
        }
        await Api.GetKehadiran(localStorage.getItem('hris-token'), data).then((response) => {
            const body = response.data.results.data;

            // Define custom headers for each table
            const Headers = ['NIP', 'Nama Pegawai', 'Tanggal', 'Presensi Masuk', 'Lokasi Presensi Masuk', 'Presensi Keluar', 'Lokasi Presensi Keluar', 'Durasi', 'Potongan (%)', 'Status', 'Keterangan'];

            // Create modified data arrays with custom headers
            const data = body.map(({ nip, fullname, date, time_checkin, time_checkout, latitude_checkin, longtitude_checkin, latitude_checkout, longtitude_checkout, status, potongan, keterangan }) => ({
                'NIP': nip ?? '-',
                'Nama Pegawai': fullname ?? '-',
                'Tanggal': moment(date).format('DD/MM/YYYY'),
                'Presensi Masuk': time_checkin ? moment(time_checkin).format('HH:mm:ss') : '-',
                'Lokasi Presensi Masuk': latitude_checkin && longtitude_checkin ? latitude_checkin + ',' + longtitude_checkin : '-',
                'Presensi Keluar': time_checkout ? moment(time_checkout).format('HH:mm:ss') : '-',
                'Lokasi Presensi Keluar': latitude_checkout && longtitude_checkout ? latitude_checkout + ',' + longtitude_checkout : '-',
                'Durasi': time_checkin && time_checkout ? duration(time_checkin, time_checkout) : '-',
                'Potongan (%)': potongan,
                'Status': status || status !== "" ? status.map((val) => `${val}, `).join('') : '-',
                'Keterangan': keterangan
            }));

            // Create a new worksheet for each table
            const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Add the worksheets to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Absensi');
            // Generate Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            // Convert buffer to Blob
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Save the Excel file using FileSaver.js
            saveAs(excelBlob, 'Rekap Absensi.xlsx');
        }).catch((err) => {
            console.log(err)
        })
    };
    const duration = (endTime, startTime) => {
        const format = "YYYY-MM-DD HH:mm:ss"; // Include the date in the format as well

        const ms = moment(endTime, format).diff(moment(startTime, format));
        const d = moment.duration(ms).abs(); // Take the absolute value of the duration
        const h = d.hours();
        const m = d.minutes();
        // const s = d.seconds();

        if (h !== 0) {
            return `${h} Jam ${m} Menit`;
        } else {
            return `${m} Menit`;
        }
    };
    // End Export Data Kehadiran

    // Start Export Data Izin
    const exportIzin = async () => {
        // Sample data array
        await Api.GetIzin(localStorage.getItem('hris-token'), 1, totalDataExport, startDate, endDate, name, isAscending).then((response) => {
            const body = response.data.results.permissions;

            // Define custom headers for each table
            const Headers = ['Nama Pegawai', 'Jabatan', 'Tanggal Pengisian', 'Tanggal Izin', 'Alasan', 'Alamat Selama Izin'];

            // Create modified data arrays with custom headers
            const data = body.map(({ user, dateof_filling, start_date, end_date, duration, permission_reason, alamat_selama_izin }) => ({
                'Nama Pegawai': user ? user.fullname : '-',
                'Jabatan': user?.user_detail ? user?.user_detail?.job_position?.position_name : '',
                'Tanggal Pengisian': moment(dateof_filling).format('DD MMMM YYYY'),
                'Tanggal Izin': start_date && end_date ? `${moment(start_date).format('DD MMMM YYYY')} s/d ${moment(end_date).format('DD MMMM YYYY')}` : '-',
                'Alasan': permission_reason ? permission_reason : '-',
                'Alamat Selama Izin': alamat_selama_izin ? alamat_selama_izin : '-'
            }));

            // Create a new worksheet for each table
            const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Add the worksheets to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap izin');
            // Generate Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            // Convert buffer to Blob
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Save the Excel file using FileSaver.js
            saveAs(excelBlob, 'Rekap Izin.xlsx');
        }).catch((err) => {
            console.log(err)
        })
    };
    // End Export Data Izin

    // Start Export Data Cuti
    const exportCuti = async () => {
        // Sample data array
        await Api.GetCuti(localStorage.getItem('hris-token'), 1, totalDataExport, startDate, endDate, name, isAscending).then((response) => {
            const body = response.data.results.leaves;

            // Define custom headers for each table
            const Headers = ['Nama Pegawai', 'Tanggal Mulai', 'Tanggal Selesai', 'Jenis Cuti'];

            // Create modified data arrays with custom headers
            const data = body.map(({ user, start_date, end_date, leavetype }) => ({
                'Nama Pegawai': user ? user.fullname : '-',
                'Tanggal Mulai': moment(start_date).format('DD-MM-YYYY'),
                'Tanggal Selesai': moment(end_date).format('DD-MM-YYYY'),
                'Jenis Cuti': leavetype?.type_name
            }));

            // Create a new worksheet for each table
            const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Add the worksheets to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Cuti');
            // Generate Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            // Convert buffer to Blob
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Save the Excel file using FileSaver.js
            saveAs(excelBlob, 'Rekap Cuti.xlsx');
        }).catch((err) => {
            console.log(err)
        })
    };
    // End Export Data Izin

    // Start Export Data PerjalananDinas
    const exportPerjalananDinas = async () => {
        // Sample data array
        await Api.GetPerjalananDinas(localStorage.getItem('hris-token'), 1, totalDataExport, startDate, endDate, name, isAscending).then((response) => {
            const body = response.data.results.workTravels;

            // Define custom headers for each table
            const Headers = ['Nama Pegawai', 'Jabatan', 'Jenis Perjalanan', 'Durasi', 'Tanggal Mulai', 'Tanggal Selesai', 'Lokasi', 'Lampiran'];

            // Create modified data arrays with custom headers
            const data = body.map(({ user, office_work_travel, duration, start_date, end_date, location, file }) => ({
                'Nama Pegawai': user ? user.fullname : '-',
                'Jabatan': user?.user_detail ? user?.user_detail?.job_position?.position_name : '',
                'Jenis Perjalanan': office_work_travel?.type_name ?? '',
                'Durasi': duration,
                'Tanggal Mulai': moment(start_date).format("DD-MM-YYYY"),
                'Tanggal Selesai': moment(end_date).format("DD-MM-YYYY"),
                'Lokasi': location,
                'Lampiran': imageHandle(file)
            }));

            // Create a new worksheet for each table
            const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Add the worksheets to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap PerjalananDinas');
            // Generate Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            // Convert buffer to Blob
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Save the Excel file using FileSaver.js
            saveAs(excelBlob, 'Rekap PerjalananDinas.xlsx');
        }).catch((err) => {
            console.log(err)
        })
    };
    // End Export Data PerjalananDinas

    // Start Export Data Pelatihan
    const exportPelatihan = async () => {
        // Sample data array
        await Api.GetPelatihan(localStorage.getItem('hris-token'), 1, totalDataExport, startDate, endDate, name, isAscending).then((response) => {
            const body = response.data.results.data;

            // Define custom headers for each table
            const Headers = ['Nama Pegawai', 'Nama Pengembangan Kompetensi', 'Jalur Pengembangan Kompetensi', 'Nomor SK/Sertifikat', 'Tanggal SK/Sertifikat', 'Tanggal Mulai', 'Tanggal Selesai'];

            // Create modified data arrays with custom headers
            const data = body.map(({ user, nama, jalur, nomor_sk, tanggal_sk, tanggal_mulai, tanggal_selesai }) => ({
                'Nama Pegawai': user ? user.fullname : '-',
                'Nama Pengembangan Kompetensi': nama ?? '',
                'Jalur Pengembangan Kompetensi': jalur ?? '',
                'Nomor SK/Sertifikat': nomor_sk ?? '',
                'Tanggal SK/Sertifikat': tanggal_sk ?? '',
                'Tanggal Mulai': moment(tanggal_mulai).format("DD-MM-YYYY"),
                'Tanggal Selesai': moment(tanggal_selesai).format("DD-MM-YYYY")
            }));

            // Create a new worksheet for each table
            const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Add the worksheets to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Pelatihan');
            // Generate Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            // Convert buffer to Blob
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Save the Excel file using FileSaver.js
            saveAs(excelBlob, 'Rekap Pelatihan.xlsx');
        }).catch((err) => {
            console.log(err)
        })
    };
    // End Export Data Pelatihan

    // Start Export Data Penghargaan
    const exportPenghargaan = async () => {
        // Sample data array
        await Api.GetPenghargaanDashboard(localStorage.getItem('hris-token'), 1, totalDataExport, startDate, endDate, name, isAscending).then((response) => {
            const body = response.data.results.data;

            // Define custom headers for each table
            const Headers = ['Nama Pegawai', 'Tahun Menerima Penghargaan', 'Nama Penghargaan', 'Lembaga Pemberi Penghargaan', 'Nomor SK/Sertifikat', 'Tanggal SK/Sertifikat'];

            // Create modified data arrays with custom headers
            const data = body.map(({ user, tahun, nama, lembaga, nomor_sk, tanggal_sk }) => ({
                'Nama Pegawai': user ? user.fullname : '-',
                'Tahun Menerima Penghargaan': tahun ?? '',
                'Nama Penghargaan': nama ?? '',
                'Lembaga Pemberi Penghargaan': lembaga ?? '',
                'Nomor SK/Sertifikat': nomor_sk ?? '',
                'Tanggal SK/Sertifikat': moment(tanggal_sk).format("DD-MM-YYYY")
            }));

            // Create a new worksheet for each table
            const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Add the worksheets to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Penghargaan');
            // Generate Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            // Convert buffer to Blob
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Save the Excel file using FileSaver.js
            saveAs(excelBlob, 'Rekap Penghargaan.xlsx');
        }).catch((err) => {
            console.log(err)
        })
    };
    // End Export Data Penghargaan

    // Start Export Data TugasBelajar
    const exportTugasBelajar = async () => {
        // Sample data array
        await Api.GetTugasBelajarDashboard(localStorage.getItem('hris-token'), 1, totalDataExport, startDate, endDate, name, isAscending).then((response) => {
            const body = response.data.results.data;

            // Define custom headers for each table
            const Headers = ['Nama Pegawai', 'Jenis Tugas Belajar', 'Jenjang Pendidikan Yang Ditempuh', 'Nomor SK Tugas Belajar', 'Tanggal SK Tugas Belajar', 'Pejabat Yang Menetapkan', 'Tanggal Mulai Tugas Belajar', 'Tanggal Selesai Tugas Belajar', 'Sumber Pembiayaan Pendidikan'];

            // Create modified data arrays with custom headers
            const data = body.map(({ user, jenis, jenjang_pendidikan, nomor_sk, tanggal_sk, pejabatYangMenetapkan, tanggal_mulai, tanggal_selesai, sumber_pembiayaan }) => ({
                'Nama Pegawai': user ? user.fullname : '-',
                'Jenis Tugas Belajar': jenis ?? '',
                'Jenjang Pendidikan Yang Ditempuh': jenjang_pendidikan ?? '',
                'Nomor SK Tugas Belajar': nomor_sk ?? '',
                'Tanggal SK Tugas Belajar': moment(tanggal_sk).format("DD-MM-YYYY"),
                'Pejabat Yang Menetapkan': pejabatYangMenetapkan?.fullname,
                'Tanggal Mulai Tugas Belajar': moment(tanggal_mulai).format("DD-MM-YYYY"),
                'Tanggal Selesai Tugas Belajar': moment(tanggal_selesai).format("DD-MM-YYYY"),
                'Sumber Pembiayaan Pendidikan': sumber_pembiayaan ?? "-",
            }));

            // Create a new worksheet for each table
            const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Add the worksheets to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Tugas Belajar');
            // Generate Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            // Convert buffer to Blob
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Save the Excel file using FileSaver.js
            saveAs(excelBlob, 'Rekap TugasBelajar.xlsx');
        }).catch((err) => {
            console.log(err)
        })
    };
    // End Export Data TugasBelajar

    const handleSearch = (e) => {
        setName(e)
    }

    const [showDetailKinerja, setShowDetailKinerja] = useState(false)


    const [showFilter, setShowFilter] = useState(false)
    const [filter, setFilter] = useState([])

    useEffect(() => {
        getData()
        getStatistictJenisKepegawaian()
        getStatistictRekapAbsen()
        getUnitKerja()
        getKinerjaSaya()
        setRefresh(false)
    }, [refresh])

    const [position, setPosition] = useState(null)

    const requestLocation = (type) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLat(latitude)
                    setLong(longitude)
                    setPosition([latitude, longitude])
                    if (type === "checkIn") {
                        setModalCheckIn(!modalCheckIn)
                    } else if (type === "checkOut") {
                        setModalCheckOut(!modalCheckOut)
                    }
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        toast.error('Akses lokasi diperlukan untuk melanjutkan. Silakan izinkan akses lokasi.');
                    } else {
                        toast.error(error.message);
                    }
                }
            );

        } else {
            toast.error('Geolocation tidak didukung pada browser ini.');
        }
    }
    useEffect(() => {
        Fetch()
        getDataPresensi()
        getGeoData()
        setRefresh(false)
    }, [refresh])
    return (
        <div className='relative'>
            <Modal
                activeModal={modalAddAnnouncement}
                title={'Create Announcement'}
                buttonClose={() => setModalAddAnnouncement(!modalAddAnnouncement)}
                width={'8150px'}
                content={
                    <div className='space-y-6'>
                        {/* <TinyMce
                        getContentTinyMce={getContentTinyMce}
                        setDataTinyMce={announcement}
                        /> */}
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setModalAddAnnouncement(!modalAddAnnouncement)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={modalCheckIn}
                title={'Presensi Masuk'}
                buttonClose={() => setModalCheckIn(false)}
                width={'450px'}
                content={
                    <div className='space-y-6'>
                        <div>
                            {position ? (
                                <MapContainer center={position} zoom={18} style={{ height: "150px", width: "100%" }}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                    />
                                    <Marker position={position}
                                        icon={L.icon({
                                            iconUrl: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 8 8'%3E%3Cpath fill='%23000' d='M4 0C2.34 0 1 1.34 1 3c0 2 3 5 3 5s3-3 3-5c0-1.66-1.34-3-3-3m0 1a2 2 0 0 1 2 2c0 1.11-.89 2-2 2a2 2 0 1 1 0-4'/%3E%3C/svg%3E",
                                            iconSize: [24, 32],
                                        })}>
                                    </Marker>
                                </MapContainer>
                            ) : (
                                <div className="text-center space-y-2">
                                    <div role="status">
                                        <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin dark:text-red-thick fill-blue-medium" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    <p>Memuat Lokasi...</p>
                                </div>
                            )}
                        </div>
                        <div className="text-center text-sky-950 text-base font-semibold">“Sekarang Pukul {moment().format('HH:mm')}, Apakah anda yakin untuk <span className='font-bold italic'>Presensi Masuk</span>”</div>

                        <div className="text-center text-neutral-500 text-[13px]">Tindakan ini tidak dapat dibatalkan</div>


                        <div className='flex items-center justify-center gap-[12px] mt-5'>
                            <button onClick={() => setModalCheckIn(false)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                            <button onClick={CheckInDataAttendance} className='bg-[#0E5073] text-white text-sm rounded-[6px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Ya, Saya Yakin" : "Yes, Im Sure"}</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={modalCheckOut}
                title={'Presensi Keluar'}
                buttonClose={() => setModalCheckOut(false)}
                width={'450px'}
                content={
                    <div className='space-y-6'>
                        <div>
                            {position ? (
                                <MapContainer center={position} zoom={18} style={{ height: "150px", width: "100%" }}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                                    />
                                    <Marker position={position}
                                        icon={L.icon({
                                            iconUrl: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 8 8'%3E%3Cpath fill='%23000' d='M4 0C2.34 0 1 1.34 1 3c0 2 3 5 3 5s3-3 3-5c0-1.66-1.34-3-3-3m0 1a2 2 0 0 1 2 2c0 1.11-.89 2-2 2a2 2 0 1 1 0-4'/%3E%3C/svg%3E",
                                            iconSize: [24, 32],
                                        })}>
                                    </Marker>
                                </MapContainer>
                            ) : (
                                <div className="text-center space-y-2">
                                    <div role="status">
                                        <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin dark:text-red-thick fill-blue-medium" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    <p>Memuat Lokasi...</p>
                                </div>
                            )}
                        </div>
                        <div className="text-center text-sky-950 text-base font-semibold">“Sekarang Pukul {moment().format('HH:mm')}, Apakah anda yakin untuk <span className='font-bold italic'>Presensi Keluar</span>”</div>

                        <div className="text-center text-neutral-500 text-[13px]">Tindakan ini tidak dapat dibatalkan</div>


                        <div className='flex items-center justify-center gap-[12px] mt-5'>
                            <button onClick={() => setModalCheckOut(false)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                            <button onClick={CheckOutDataAttendance} className='bg-[#0E5073] text-white text-sm rounded-[6px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Ya, Saya Yakin" : "Yes, Im Sure"}</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showDetailKinerja}
                title={'Detail Laporan Kinerja'}
                buttonClose={() => setShowDetailKinerja(false)}
                width={'1000px'}
                content={
                    <div className='space-y-6 overflow-scroll h-80'>
                        <table className='relative w-full space-y-[10px]'>
                            <thead className='sticky top-0'>
                                <tr className='bg-[#EBF7FF] rounded-[3px]'>
                                    <th className='border py-3 px-5 min-w-[250px] max-w-[250px]'>
                                        <p className='text-grey-thick text-xs font-medium'>RHK</p>
                                    </th>
                                    <th className='border py-3 px-5 min-w-[200px] max-w-[200px]'>
                                        <p className='text-grey-thick text-xs font-medium'>Target Kuantitas Rencana Aksi</p>
                                    </th>
                                    <th className='border py-3 px-5 min-w-[200px] max-w-[200px]'>
                                        <p className='text-grey-thick text-xs font-medium'>Realisasi Kuantitas Rencana Aksi</p>
                                    </th>
                                    <th className='border py-3 px-5 min-w-[100px] max-w-[100px]'>
                                        <p className='text-grey-thick text-xs font-medium'>%</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {detailKinerjaSaya && detailKinerjaSaya.map((val, index) => (
                                    <tr key={index} className='bg-[#F9F9F9] rounded-[3px]'>
                                        <td className='border py-3 px-5 min-w-[250px] max-w-[250px]'>
                                            <p className='text-grey-thick text-xs font-medium'>{val?.name}</p>
                                        </td>
                                        <td className='border py-3 px-5 min-w-[200px] max-w-[200px]'>
                                            <p className='text-grey-thick text-xs font-medium'>{val?.target_kuantitas}</p>
                                        </td>
                                        <td className='border py-3 px-5 min-w-[200px] max-w-[200px]'>
                                            <p className='text-grey-thick text-xs font-medium'>{val?.realisasi_kuantitas}</p>
                                        </td>
                                        <td className='border py-3 px-5 min-w-[100px] max-w-[100px]'>
                                            <p className='text-grey-thick text-xs font-medium truncate text-center'>{val?.persentase} %</p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            />
            <Modal
                activeModal={showFilter}
                buttonClose={() => setShowFilter(false)}
                width={'550px'}
                title={
                    <div className='flex gap-[27px] items-center'>
                        <BsFilter className='text-dark-1 text-2xl' />
                        <h1 className='text-dark-1 text-[20px] font-semibold'>Filter</h1>
                    </div>
                }
                content={
                    <div className='h-72 flex flex-col justify-between items-end'>
                        <div className='w-full h-full overflow-auto'>
                            <label className='w-full text-grey-thick text-[12px] font-medium mb-[9px]'>Unit Kerja <span className='text-red-primer'> *</span></label>
                            <Select
                                isMulti
                                value={selected}
                                styles={selectStyle}
                                onChange={data => handleCahnge(data)}
                                options={dataUnitKerja}
                            />
                        </div>
                        <div>
                            <button onClick={() => { setShowFilter(false); setFilter(unitKerja) }} className='py-[10px] px-[15px] bg-blue-thick text-white text-[14px] font-medium flex justify-center rounded-md items-center'>Filter</button>
                        </div>
                    </div>
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden '>
                <Navbar />
                {admin || subsidiary ?
                    <>
                        <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-center gap-5'>
                            <DCardEmploye
                                CardName={"Total Pegawai"}
                                Value={getStatistict.total_employee}
                                Img={IconDCardDashboard}
                            />
                            <DCardEmploye
                                CardName={"Penempatan"}
                                Value={getStatistict.total_penempatan}
                                Img={IconDCardDashboard}
                            />
                            <DCardEmploye
                                CardName={"Unit Kerja"}
                                Value={getStatistict.total_unit_kerja}
                                Img={IconDCardDashboard}
                            />
                        </div>
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-5'>
                            <div className='bg-white border shadow-sm rounded-[12px] p-5 space-y-3 overflow-x-auto'>
                                <h1 className='text-dark-3 font-semibold'>Jenis Kepegawaian</h1>
                                <div className="mixed-chart">
                                    <Chart options={chartDataJenisPegawai.options} series={chartDataJenisPegawai.series} type="pie" width="450" />
                                </div>
                            </div>
                            <div className='bg-white border shadow-sm rounded-[12px] p-5 space-y-3 overflow-x-auto'>
                                <h1 className='text-dark-3 font-semibold'>Rekap Kehadiran & Absensi</h1>
                                <div className="mixed-chart">
                                    <Chart options={chartDataAbsensi.options} series={chartDataAbsensi.series} type="pie" width="430" />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div div className='flex flex-wrap items-center gap-3 '>
                            <div className='lg:w-[230px] md:w-[200px] w-full border p-5 bg-white rounded-[10px] shadow-sm space-y-[10px]'>
                                <button onClick={() => requestLocation("checkIn")} className='w-full space-y-5'>
                                    <div className='text-start'>
                                        <p className='font-semibold'>Presensi Masuk</p>

                                    </div>
                                    <div className='flex items-center gap-3 w-full'>
                                        {/* {dataPresensi.date === today && dataPresensi.time_checkin != null ?
                                            <p className='text-xs text-green-700'> ✓ Sudah Presensi</p>
                                            : <p className='text-xs text-red-700'>✕ Belum ada presensi masuk</p>
                                        } */}
                                        <div className='w-1/2 flex gap-2'>
                                            <AiFillClockCircle className='text-base-yellow w-[20px] h-[20px]' />
                                            {dataPresensi.date === today && dataPresensi.time_checkin ?
                                                <p className='text-sm'>{moment(dataPresensi.time_checkin).format("HH:mm")}</p>
                                                : <p>--:--</p>
                                            }
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className='lg:w-[230px] md:w-[200px] w-full border p-5 bg-white rounded-[10px] shadow-sm space-y-[10px]'>
                                <button onClick={() => requestLocation("checkOut")} className='w-full space-y-5'>
                                    <div className='text-start'>
                                        <p className='font-semibold'>Presensi Keluar</p>

                                    </div>
                                    <div className='flex items-center gap-3 w-full'>
                                        {/* {dataPresensi.date === today && dataPresensi.time_checkout ?
                                            <p className='text-xs text-green-700'> ✓ Sudah Presensi</p>
                                            : <p className='text-xs text-red-700'>✕ Belum ada presensi keluar</p>
                                        } */}
                                        <div className='w-1/2 flex gap-2'>
                                            <AiFillClockCircle className='text-base-yellow w-[20px] h-[20px]' />
                                            {dataPresensi.date === today && dataPresensi.time_checkout ?
                                                <p className='text-sm'>{moment(dataPresensi.time_checkout).format("HH:mm")}</p>
                                                : <p>--:--</p>
                                            }
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className='lg:w-[230px] md:w-[200px] w-full'>
                                <DCardEmploye
                                    CardName={"Sisa Hak Cuti"}
                                    Value={getStatistict.sisa_cuti ? getStatistict.sisa_cuti : 0}
                                    Img={IconDCardDashboard}
                                />
                            </div>
                            <div className='lg:w-[230px] md:w-[200px] w-full'>
                                <DCardEmploye
                                    CardName={"Total Jam Pelatihan"}
                                    Value={getStatistict.total_pelatihan ? getStatistict.total_pelatihan : 0}
                                    Img={IconDCardDashboard}
                                />
                            </div>
                        </div>
                    </>
                }

                {!admin && !subsidiary ?
                    <div className={`grid ${atasan ? "md:grid-cols-2" : "md:grid-cols-2"} grid-cols-1 gap-5`}>
                        {atasan ?
                            <div className='bg-white border shadow-sm rounded-[12px] p-5 space-y-3 overflow-x-auto'>
                                <div className='flex justify-between items-center'>
                                    <h1 className='text-dark-3 font-semibold'>Rekap Absen</h1>
                                    <div>
                                        <Link to={"/time-management/rekap-absen"}>
                                            <FaInfoCircle className='text-dark-3' />
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <Chart options={chartDataAbsensiPegawai.options} series={chartDataAbsensiPegawai.series} type="pie" height={250} />
                                </div>
                            </div>
                            : null
                        }
                        {/* <div className='flex flex-col items-center bg-white border shadow-sm rounded-[12px] p-5 overflow-x-auto scrollbar-hide'>
                            <div className='flex justify-around items-center gap-2 w-full mb-5'>
                                <h1 className='text-dark-3 font-semibold'>Kinerja</h1>
                                <div className='relative'>
                                    <select className='text-[#003049] text-sm outline-none font-medium rounded-md w-[150px] border border-gray-300 py-2 pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} className='text-grey-medium font-normal'>Unit Kerja</option>
                                        {dataUnitKerja && dataUnitKerja.map((val, index) => (
                                            <option key={index} value={val?.name} className='text-grey-medium font-normal '>{val?.name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                            </div>
                            <div className=''>
                                <ReactApexChart options={dataTest.options} series={dataTest.series} type="radialBar" height={300} />
                            </div>
                            <div className='pl-16 -mt-10 z-10'>
                                <button onClick={() => setShowDetailKinerja(true)}>
                                    <FaInfoCircle className='text-sm' />
                                </button>
                            </div>
                        </div> */}
                        <div className='flex flex-col items-center bg-white border shadow-sm rounded-[12px] p-5 overflow-x-auto scrollbar-hide'>
                            <div className='flex justify-around items-center w-full mb-5'>
                                <h1 className='text-dark-3 font-semibold'>Kinerja Saya</h1>
                            </div>
                            <div className=''>
                                <ReactApexChart options={grafikKinerjaSaya.options} series={grafikKinerjaSaya.series} type="radialBar" height={300} />
                            </div>
                            <div className='pl-16 -mt-10 z-10'>
                                <button onClick={() => setShowDetailKinerja(true)}>
                                    <FaInfoCircle className='text-sm' />
                                </button>
                            </div>
                        </div>
                    </div>
                    : null
                }
                <div className='bg-white border shadow-sm rounded-[12px] p-[21px]'>
                    <div className='flex justify-between'>
                        <h3 className='text-dark-3 font-semibold'>Rekap Data</h3>
                    </div>
                    {/* <div id="chart">
                                <ReactApexChart options={dataAbsensi2.options} series={dataAbsensi2.series} type="bar" height={350} />
                            </div> */}
                    <div className='space-y-[24px] w-full'>
                        <div className="flex flex-wrap justify-between items-center gap-2 mt-[31px]">
                            <div className='flex flex-wrap gap-2'>
                                <div className='relative'>
                                    <select defaultValue={"kehadiran"} onChange={(e) => setMenu(e.target.value)} className='text-[#003049] text-sm outline-none font-medium rounded-md w-[150px] border border-gray-300 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value="kehadiran" >Kehadiran</option>
                                        <option value="cuti">Cuti</option>
                                        <option value="izin">Izin</option>
                                        <option value="perjalananDinas">Perjalanan Dinas</option>
                                        <option value="pelatihan">Pelatihan</option>
                                        <option value="penghargaan">Penghargaan</option>
                                        <option value="tugasBelajar">Tugas Belajar</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                                {menu === "kehadiran" ?
                                    <button onClick={() => setShowFilter(true)} className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                        <BiFilter className='text-dark-5 text-2xl' />
                                        Filter
                                    </button>
                                    : null
                                }
                                <input onChange={(e) => setStartDate(e.target.value)} value={startDate} type="date" className='lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                <input onChange={(e) => setEndDate(e.target.value)} value={endDate} type="date" className='lg:w-[121px] p-[10px] rounded-md border border-gray-300 text-[#003049] text-[12px]' />
                                <div className='relative'>
                                    <select onChange={(e) => setLimitData(e.target.value)} value={limitData} className='text-[#003049] text-sm outline-none font-medium rounded-md w-[100px] border border-gray-300 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                        <option value={""} className='text-grey-medium font-normal'>Limit</option>
                                        <option value={10} className='text-grey-medium font-normal '>10</option>
                                        <option value={25} className='text-grey-medium font-normal '>25</option>
                                        <option value={50} className='text-grey-medium font-normal '>50</option>
                                        <option value={100} className='text-grey-medium font-normal '>100</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                                <button onClick={() => setIsAscending(!isAscending)} className='px-[10px] rounded-md border border-gray-300'>
                                    {isAscending === true ?
                                        <FaSortAlphaDown className='text-dark-5 text-2xl' />
                                        : <FaSortAlphaUp className='text-dark-5 text-2xl' />
                                    }
                                </button>
                                <button onClick={() => menu === "kehadiran" ? exportKehadiran() : menu === "izin" ? exportIzin() : menu === "cuti" ? exportCuti() : menu === "perjalananDinas" ? exportPerjalananDinas() : menu === "pelatihan" ? exportPelatihan() : menu === "penghargaan" ? exportPenghargaan() : menu === "tugasBelajar" ? exportTugasBelajar() : ''} className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <FaFileExport className='text-dark-5 text-2xl' />
                                </button>
                            </div>
                            {/* <div className='relative'>
                                <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                                <input onChange={(e) => handleInputChange(e)} placeholder='Cari Nama Pegawai...' className='text-[#A8A8A8] text-sm font-[500] pl-12 border rounded-md py-2 w-full lg:w-[300px]' />
                            </div> */}
                            <SearchComponent onChange={handleSearch} width={200} textSize={12} placeholder={"Cari Nama Pegawai..."} />
                        </div>
                        {/* <div className='border-b-2 flex flex-wrap items-center text-[16px]'>
                                    <button onClick={() => setMenu('kehadiran')} className={`${menu === 'kehadiran' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] lg:w-1/4 md:w-1/2 sm:w-full`}>Kehadiran</button>
                                    <button onClick={() => setMenu('cuti')} className={`${menu === 'cuti' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] lg:w-1/4 md:w-1/2 sm:w-full`}>Cuti</button>
                                    <button onClick={() => setMenu('izin')} className={`${menu === 'izin' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] lg:w-1/4 md:w-1/2 sm:w-full`}>Izin</button>
                                    <button onClick={() => setMenu('perjalananDinas')} className={`${menu === 'perjalananDinas' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} py-[10px] lg:w-1/4 md:w-1/2 sm:w-full`}>Perjalanan Dinas</button>
                                </div> */}

                        {
                            menu === 'kehadiran' ? (
                                <Kehadiran startDate={startDate} endDate={endDate} name={name} isAsc={isAscending} unitKerja={filter} exportData={handleLimit} fresh={refresh} limitData={limitData} />
                            ) : menu === 'cuti' ? (
                                <Cuti startDate={startDate} endDate={endDate} name={name} isAsc={isAscending} exportData={handleLimit} limitData={limitData} />
                            ) : menu === 'izin' ? (
                                <Izin startDate={startDate} endDate={endDate} name={name} isAsc={isAscending} exportData={handleLimit} limitData={limitData} />
                            ) : menu === 'perjalananDinas' ? (
                                <PerjalananDinas startDate={startDate} endDate={endDate} name={name} isAsc={isAscending} exportData={handleLimit} limitData={limitData} />
                            ) : menu === 'pelatihan' ? (
                                <TableTraining startDate={startDate} endDate={endDate} name={name} isAsc={isAscending} exportData={handleLimit} limitData={limitData} />
                            ) : menu === 'penghargaan' ? (
                                <TablePenghargaan startDate={startDate} endDate={endDate} name={name} isAsc={isAscending} exportData={handleLimit} limitData={limitData} />
                            ) : menu === 'tugasBelajar' ? (
                                <TableTugasBelajar startDate={startDate} endDate={endDate} name={name} isAsc={isAscending} exportData={handleLimit} limitData={limitData} />
                            ) : null
                        }

                    </div>
                </div>
                <div className='flex flex-col lg:grid grid-cols-12 gap-[20px] pb-10'>
                    {/* <div className='col-span-9 space-y-[24px] order-2 lg:order-1'>
                        {
                            localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ?
                                <div className='bg-white border shadow-sm rounded-[12px] py-[23px] px-[21px] flex items-center gap-[20px]'>
                                    <img src={image ? imageHandle(image) : LogoH} className='w-[34px] h-[34px] rounded-full shadow-sm border object-cover' alt='Humanusia Logo' />
                                    <input onChange={(e) => setTextForum(e.target.value)} placeholder={languages.language === 'id' ? "Apa yang akan kamu posting..." : "What do you want to post..."} className='text-xs bg-white rounded-[6px] px-[17px] border w-full py-[10px]' />
                                    <button
                                        // onClick={postForum} 
                                        className='py-[7px] px-[15px] rounded-[6px] bg-[#003049] flex items-center gap-[10px]'>
                                        <IoSend className='text-white' />
                                        <h1 className='hidden lg:block text-white text-[13px] font-semibold'>{languages.language === 'id' ? "Kirim" : "Send"}</h1>
                                    </button>
                                </div>
                                : null
                        }

                        <div className='flex border rounded-xl w-full justify-around'>
                            <Link to={'history-announcement'} className='flex gap-[10px] items-center justify-center py-[11px] text-zinc-700 text-sm font-medium'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.8857 4.08203V7.08203H21.8857C22.151 7.08203 22.4053 7.18739 22.5928 7.37492C22.7804 7.56246 22.8857 7.81681 22.8857 8.08203V18.082C22.8857 18.8777 22.5697 19.6407 22.0071 20.2034C21.4445 20.766 20.6814 21.082 19.8857 21.082H5.88574C5.09009 21.082 4.32703 20.766 3.76442 20.2034C3.20181 19.6407 2.88574 18.8777 2.88574 18.082V4.08203C2.88574 3.81681 2.9911 3.56246 3.17864 3.37492C3.36617 3.18739 3.62053 3.08203 3.88574 3.08203H17.8857C18.151 3.08203 18.4053 3.18739 18.5928 3.37492C18.7804 3.56246 18.8857 3.81681 18.8857 4.08203ZM20.8857 18.082C20.8857 18.3472 20.7804 18.6016 20.5928 18.7891C20.4053 18.9767 20.151 19.082 19.8857 19.082C19.6205 19.082 19.3662 18.9767 19.1786 18.7891C18.9911 18.6016 18.8857 18.3472 18.8857 18.082V9.08203H20.8857V18.082ZM6.88574 8.08203C6.88574 7.81681 6.9911 7.56246 7.17864 7.37492C7.36617 7.18739 7.62053 7.08203 7.88574 7.08203H13.8857C14.151 7.08203 14.4053 7.18739 14.5928 7.37492C14.7804 7.56246 14.8857 7.81681 14.8857 8.08203C14.8857 8.34725 14.7804 8.6016 14.5928 8.78914C14.4053 8.97667 14.151 9.08203 13.8857 9.08203H7.88574C7.62053 9.08203 7.36617 8.97667 7.17864 8.78914C6.9911 8.6016 6.88574 8.34725 6.88574 8.08203ZM8.88574 12.082C8.88574 11.8168 8.9911 11.5625 9.17864 11.3749C9.36617 11.1874 9.62053 11.082 9.88574 11.082H13.8857C14.151 11.082 14.4053 11.1874 14.5928 11.3749C14.7804 11.5625 14.8857 11.8168 14.8857 12.082C14.8857 12.3472 14.7804 12.6016 14.5928 12.7891C14.4053 12.9767 14.151 13.082 13.8857 13.082H9.88574C9.62053 13.082 9.36617 12.9767 9.17864 12.7891C8.9911 12.6016 8.88574 12.3472 8.88574 12.082Z" fill="#C1121F" />
                                </svg>
                                <span>{languages.language === 'id' ? "Riwayat Pengumuman" : "History Announcement"}</span>
                            </Link>
                            <Link to={'polling'} className='flex gap-[10px] items-center justify-center py-[11px] border-l px-[147px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M22.1276 14.5537L22.1486 14.6157L22.1716 14.7157L22.1816 14.8157V21.3316C22.1815 21.5132 22.1156 21.6885 21.9961 21.8252C21.8766 21.9618 21.7115 22.0504 21.5316 22.0746L21.4316 22.0816H2.93262C2.75138 22.0816 2.57628 22.016 2.43969 21.8969C2.3031 21.7778 2.21427 21.6132 2.18962 21.4336L2.18262 21.3316V14.8317L2.18462 14.7797L2.19462 14.6937C2.20477 14.6411 2.22052 14.5898 2.24162 14.5407L5.00162 8.52165C5.05368 8.40787 5.13338 8.30892 5.23346 8.23381C5.33354 8.15871 5.45082 8.10984 5.57462 8.09165L5.68262 8.08465L8.22262 8.08365L7.43262 9.45365L7.36562 9.58365H6.16262L4.10062 14.0817H20.2526L18.2256 9.73565L19.0876 8.23865C19.1546 8.28865 19.2126 8.35165 19.2596 8.42265L19.3126 8.51765L22.1276 14.5537ZM13.5476 2.13765L13.6396 2.18265L18.8276 5.18565C19.1556 5.37565 19.2856 5.77665 19.1466 6.11865L19.1016 6.21065L16.2946 11.0817H17.4326C17.624 11.0797 17.8089 11.151 17.9494 11.2809C18.0899 11.4108 18.1755 11.5896 18.1885 11.7805C18.2015 11.9715 18.1411 12.1602 18.0195 12.308C17.898 12.4558 17.7245 12.5516 17.5346 12.5757L17.4326 12.5827L15.4306 12.5817V12.5847H11.3516L11.3486 12.5817H6.93262C6.74378 12.5799 6.56256 12.5069 6.42516 12.3774C6.28775 12.2479 6.20427 12.0712 6.19139 11.8828C6.17851 11.6944 6.23718 11.5081 6.35568 11.3611C6.47417 11.214 6.64378 11.1171 6.83062 11.0897L6.93262 11.0827L8.75562 11.0817L8.57362 10.9767C8.41716 10.8855 8.29885 10.7409 8.24044 10.5695C8.18202 10.3981 8.18741 10.2114 8.25562 10.0437L8.29962 9.95165L12.6166 2.45565C12.8066 2.12665 13.2066 1.99565 13.5476 2.13565V2.13765ZM13.5376 3.85765L9.97162 10.0517L11.7496 11.0817H14.5666L17.4316 6.10865L13.5396 3.85565L13.5376 3.85765Z" fill="#003049" />
                                </svg>
                                <div className="text-zinc-700 text-sm font-medium">{languages.language === 'id' ? "Poling" : "Polling"}</div>
                            </Link>
                        </div>

                        <div className='space-y-[12px]'>
                            {Object.values(dataAnnouncement).map((item, idx) => (
                                <div className='py-[28px] px-[36px] border rounded-xl shadow'>
                                    <div className='flex items-center justify-between mb-[16px]'>
                                        <div className='flex items-center gap-[20px] w-full'>
                                            <img className="w-[38.24px] h-[41.13px] rounded-full shadow" src={item.user.user_detail.image ? imageHandle(item.user.user_detail.image) : LogoH} />
                                            <div className='flex items-center justify-between w-full'>
                                                <div>
                                                    <h1 className="text-zinc-700 text-sm font-semibold">{item.user.fullname ?? '-'}</h1>
                                                    <h1 className="text-gray-500 text-xs font-semibold">{item.user.user_detail.job_position ? item.user.user_detail.job_position.position_name : '-'}</h1>
                                                </div>
                                                <h1 className="text-gray-400 text-[11px] font-light">{moment(item.createdAt).format('DD-MM-YYYY')} ; {moment(item.createdAt).format('HH:mm')}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-zinc-700 text-sm font-normal" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                </div>
                            ))}
                        </div>

                        {seeMoreForum === false ?
                            Object.values(getForum.slice(0, 5)).map((data, index) => {
                                return (
                                    <div key={index} className='bg-white border shadow-sm rounded-[12px] py-[23px] px-[21px] space-y-[14px]'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex items-center gap-[17px]'>
                                                <img src={data.user.user_detail.image ? imageHandle(data.user.user_detail.image) : LogoH} className='w-[34px] h-[34px] rounded-full shadow-sm border object-cover' alt='Humanusia Logo' />
                                                <div className='space-y-1'>
                                                    <h1 className='text-[#333333] text-sm font-semibold'>{data?.user?.fullname}</h1>
                                                    <h1 className='text-[#767676] text-xs font-semibold'>{data?.user?.role}</h1>
                                                </div>
                                            </div>
                                            <h1 className='text-[#767676] text-[9px] font-[300]'>{moment().format('DD MMMM YYYY hh.mm A')}</h1>
                                        </div>
                                        <h1 className='text-dark-5 text-sm'>{data.text}</h1>
                                    </div>
                                )
                            })

                            :
                            Object.values(getForum).map((data, index) => {
                                return (
                                    <div key={index} className='bg-white border shadow-sm rounded-[12px] py-[23px] px-[21px] space-y-[14px]'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex items-center gap-[17px]'>
                                                <img src={data.user.user_detail.image ? imageHandle(data.user.user_detail.image) : LogoH} className='w-[34px] h-[34px] rounded-full shadow-sm border object-cover' alt='Humanusia Logo' />
                                                <div className='space-y-1'>
                                                    <h1 className='text-[#333333] text-sm font-semibold'>{data?.user?.fullname}</h1>
                                                    <h1 className='text-[#767676] text-xs font-semibold'>{data?.user?.role}</h1>
                                                </div>
                                            </div>
                                            <h1 className='text-[#767676] text-[9px] font-[300]'>{moment().format('DD MMMM YYYY hh.mm A')}</h1>
                                        </div>
                                        <h1 className='text-dark-5 text-sm'>{data.text}</h1>
                                    </div>
                                )
                            })
                        }
                        <div className='flex justify-center items-center px-3'>
                            <button className='text-[#A8A8A8] text-[16px] font-[500] hover:text-[#780000]' onClick={() => setSeeMoreForum(!seeMoreForum)}>{seeMoreForum === false && getForum.length < 6 ? '' : seeMoreForum === false && getForum.length > 5 ? 'Load More...' : 'Show Less...'}</button>
                        </div>
                    </div> */}
                    <div className='col-span-9 bg-white border shadow-sm rounded-[12px] p-[21px] h-[300px]'>
                        <div className='space-y-[24px] '>
                            <div className='flex items-center justify-between'>
                                <h1 className='text-dark-3 font-[600]'>{languages.language === 'id' ? "Berita Ombudsman" : "Company News"}</h1>
                                {localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ?
                                    <Link to={'/news'} className='text-[#A8A8A8] text-[13px] font-[500] hover:text-[#780000]'>{languages.language === 'id' ? "Lihat Semua" : "See More"}</Link>
                                    : null
                                }
                            </div>
                            {dataNews.length === 0 ?
                                <div className='h-52 flex flex-col items-center justify-center border rounded-[12px] gap-5'>
                                    <h1 className='text-dark-5 text-sm'>Belum ada berita</h1>
                                    {
                                        localStorage.getItem('hris-role') === 'ADMIN' || localStorage.getItem('hris-role') === 'SUBSIDIARY' ?
                                            <Link to={"/news/news-create"} className='py-[7px] px-[15px] rounded-[6px] bg-[#003049] flex items-center gap-[10px]'>
                                                <AiOutlinePlus className='text-white' />
                                                <h1 className='text-white text-[13px] font-semibold'>{languages.language === 'id' ? "Buat Berita" : "Create News"}</h1>
                                            </Link>
                                            : null
                                    }
                                </div>
                                :
                                <div className='flex items-center gap-[15px] overflow-auto scrollbar-hide'>
                                    {Object.values(dataNews).map((data, index) => {
                                        return (
                                            <DCardNews
                                                key={index}
                                                id={data.id}
                                                NewsTitle={data.title}
                                                NewsDate={moment(data.createdAt).format('MMMM DD, YYYY')}
                                                image={!data.image ? ExampleNews : imageHandle(data.image)}
                                            />
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                    <div className='col-span-3 order-1 lg:order-2'>
                        <div className='bg-white border shadow-sm rounded-[12px] p-[21px] h-[300px]'>
                            <h1 className='text-dark-3 text-[13px] font-semibold'>{languages.language === 'id' ? "Siapa yang Ulang Tahun Bulan Ini?" : "Who’s Birthday In this Month"}</h1>
                            <div className='overflow-y-auto h-[200px]'>
                                {Object.values(getBirthdate).map((data, index) => (
                                    <div key={index} className='mt-[16px] space-y-[17px]'>
                                        {/* <h1 className='text-[#A8A8A8] text-[10px] font-[500] uppercase'>{moment(data.user_detail.birthdate).format('MMMM')}</h1> */}
                                        <div className='flex items-center gap-[14px]'>
                                            <img src={data.user_detail.image ? imageHandle(data.user_detail.image) : LogoH} className='w-[33px] h-[33px] rounded-[3px] shadow-sm' alt='Humanusia Logo' />
                                            <div className='space-y-1'>
                                                <h1 className='text-dark-5 text-xs font-[500]'>{data.fullname}</h1>
                                                <h1 className='text-dark-7 text-[10px] font-[500]'>{moment(data?.user_detail?.birthdate).format("DD/MM")}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div >
    )
}

export default Dashboard