import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineSearch } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import languages from '../../../../utils/languages'

const UserManagement = () => {
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [dataUser, setDataUser] = useState('')
    const [getIdUser, setGetIdUser] = useState('')

    const limit = 10

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [name, setName] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const getDataUser = async () => {
        try {
            const response = await Api.GetUserManagement(localStorage.getItem('hris-token'), currentPage, limit, name)
            setDataUser(response.data.results.data)
            setTotalPages(response.data.results.totalPages)
        } catch (error) {

        }
    }

    const [role, setRole] = useState('')
    const [employeeName, setEmployeeName] = useState('')
    const [isVerified, setIsVerified] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [email, setEmail] = useState('')
    const [subsidiary, setSubsidiary] = useState('')

    const openModalUpdateDataUser = async (id) => {
        setShowModalEdit(!showModalEdit)
        setGetIdUser(id)
        try {
            const response = await Api.GetUserManagementById(localStorage.getItem('hris-token'), id)
            setRole(response.data.results.data.role)
            setEmployeeName(response.data.results.data.fullname)
            setIsVerified(response.data.results.data.isVerified)
            setEmail(response.data.results.data.email)
            setSubsidiary(response.data.results.data.user_detail.subsidiary.id)
        } catch (error) {
            console.log(error)
        }
    }

    const resetForm = () => {
        setRole("")
        setEmployeeName("")
        setIsVerified("")
        setEmail("")
        setSubsidiary("")
        setPassword("")
        setConfirmPassword("")
    }
    const updateDataUser = async () => {
        if (password !== confirmPassword) {
            toast.error("Periksa Kembali Kata Sandi")
        } else {
            try {
                const data = {
                    ...(role && { role: role }),
                    ...(isVerified && { isVerified: isVerified === 'true' ? true : isVerified === 'false' ? false : isVerified }),
                    ...(employeeName && { fullname: employeeName }),
                    ...(email && { email: email }),
                    ...(password && { password: password }),
                    ...(subsidiary && { subsidiary: subsidiary })
                }
                // const data = {
                //     role: role,
                //     isVerified: isVerified === 'true' ? true : false,
                //     fullname: employeeName,
                //     email: email,
                //     password: password,
                //     subsidiary: subsidiary
                // }
                await Api.UpdateUserManagement(localStorage.getItem('hris-token'), getIdUser, data).then((response) => {
                    setRefresh(true)
                    setShowModalEdit(!showModalEdit)
                    resetForm()
                    toast.success("Data Berhasil Diubah")
                }).catch((error) => {
                    console.log(error.response.data.errors.error.details)
                    const e = error.response.data.errors.error.details
                    for (let i = 0; i < e.length; i++) {
                        toast.error(e[i].message)
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const deleteDataUserModal = async (id) => {
        setShowModalDelete(!showModalDelete)
        setGetIdUser(id)
    }

    const deleteDataUser = async (id) => {
        try {
            await Api.DeleteUserManagement(localStorage.getItem('hris-token'), getIdUser).then((res) => {
                setRefresh(true)
                setShowModalDelete(!showModalDelete)
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDataUser()
        setRefresh(false)
    }, [refresh, name])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModalEdit}
                title={'Edit Pengguna'}
                buttonClose={() => setShowModalEdit(!showModalEdit)}
                width={'550px'}
                content={
                    <div className='space-y-[25px]'>
                        <div className='flex items-center gap-[20px]'>
                            {/* <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ?"Role Pengguna":"User Role"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={ (e) => setRole(e.target.value) } value={role} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>{languages.language === 'id' ?"Pilih Role Pengguna":"Select User Role"}</option>
                                        <option value={'USER'}>User</option>
                                        <option value={'LEADER'}>Leader</option>
                                        <option value={'ADMIN'}>Admin</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div> */}
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setEmployeeName(e.target.value)} value={employeeName} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' />
                            </div>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Status<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setIsVerified(e.target.value)} value={isVerified} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>{languages.language === 'id' ? "Pilih Status" : "Select Status"}</option>
                                        <option value={"true"}>{languages.language === 'id' ? "Aktif" : "Enable"}</option>
                                        <option value={"false"}>{languages.language === 'id' ? "Tidak Aktif" : "Disable"}</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Email<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setEmail(e.target.value)} value={email} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' />
                            </div>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Kata Sandi" : "Password"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setPassword(e.target.value)} type='password' value={password} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>{languages.language === 'id' ? "Konfirmasi Kata Sandi" : "Confirm Password"}<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input type='password' onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' />
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setShowModalEdit(!showModalEdit)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                            <button onClick={updateDataUser} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Simpan" : "Submit"}</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteDataUser}
            />
            <div className='w-full space-y-[24px] overflow-hidde'>
                <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Pengguna" : "User Management"} LinkBack={'/dashboard'} />
                <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                    <div className='flex flex-col lg:flex-row items-center justify-between'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-dark-3 text-[16px] font-[500]'>{languages.language === 'id' ? "Pengguna" : "Users"}</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>{languages.language === 'id' ? "Daftar Pengguna" : "list of users"}</h1>
                        </div>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <div className='relative'>
                                <AiOutlineSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                                <input onChange={(e) => setName(e.target.value)} placeholder='Cari Nama Pegawai...' className='text-[#A8A8A8] text-sm font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]' />
                            </div>
                            {/* <button onClick={ () => setShowModalAdd(!showModalAdd)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white'/>
                                <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ?"Tambah Pengguna":"Add User"}</h1>
                            </button> */}
                        </div>
                    </div>
                    <div className='mt-[44px] overflow-auto'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>No</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Email</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Role" : "User Role"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Status</h1>
                                </div>
                                <div className='flex items-center justify-center gap-[15px] w-full'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                </div>
                            </div>
                            {dataUser.length === 0 ?
                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[50px] max-w-[50px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[100px] max-w-[100px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[300px] max-w-[300px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[100px] max-w-[100px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[5px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                </div>
                                :
                                Object.values(dataUser).map((data, index) => {
                                    return (
                                        <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[50px] max-w-[50px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{index + 1}</h1>
                                            </div>
                                            <div className='min-w-[250px] max-w-[250px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data.email}</h1>
                                            </div>
                                            <div className='min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data.role}</h1>
                                            </div>
                                            <div className='min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data.fullname}</h1>
                                            </div>
                                            <div className='min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{!data.isVerified ? 'Tidak Aktif' : 'Aktif'}</h1>
                                            </div>
                                            <div className='w-full flex items-center justify-center gap-[5px]'>
                                                <button onClick={() => openModalUpdateDataUser(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                                <button onClick={() => deleteDataUserModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]' />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        limitData={dataUser?.length}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserManagement