import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { Modal, ModalDelete, Pagination } from '../../../../../components'
import Api from '../../../../../Api'
import toast from 'react-hot-toast'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'

const Penghargaan = ({ refreshPage, UserId }) => {
    const [showAddPenghargaan, setShowAddPenghargaan] = useState(false)
    const [showEditPenghargaan, setShowEditPenghargaan] = useState(false)
    const [showDeletePenghargaan, setShowDeletePenghargaan] = useState(false)
    const [idPenghargaan, setIdPenghargaan] = useState('')
    const [dataPenghargaan, setDataPenghargaan] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [formData, setFormData] = useState({
        "userId": UserId,
        "tahun": "",
        "nama": "",
        "lembaga": "",
        "nomor_sk": "",
        "tanggal_sk": ""
    })
    const resetForm = () => {
        setFormData({
            "userId": UserId,
            "tahun": "",
            "nama": "",
            "lembaga": "",
            "nomor_sk": "",
            "tanggal_sk": ""
        })
    }

    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)

    const getDataPenghargaan = async () => {
        try {
            const response = await Api.GetPenghargaan(localStorage.getItem('hris-token'), UserId, currentPage, limit)
            setCurrentPage(response.data.results.currentPage)
            setTotalPages(response.data.results.totalPages)
            setDataPenghargaan(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const openEditPenghargaan = async (id) => {
        setIdPenghargaan(id)
        setShowEditPenghargaan(!showEditPenghargaan)
        try {
            const response = await Api.GetPenghargaanById(localStorage.getItem('hris-token'), id)
            const data = response.data.results.data
            console.log(data)
            setFormData({
                ...formData,
                "tahun": data?.tahun,
                "nama": data?.nama,
                "lembaga": data?.lembaga,
                "nomor_sk": data?.nomor_sk,
                "tanggal_sk": data?.tanggal_sk
            })
        } catch (error) {
            console.log(error)
        }
    }

    const createPenghargaan = async () => {
        try {
            if (!isValidYear(formData.tahun)) {
                toast.error("Tahun Tidak Valid")
            } else {
                await Api.CreatePengahargaan(localStorage.getItem('hris-token'), formData).then((response) => {
                    setRefresh(true)
                    toast.success("Data Berhasil Ditambahkan!")
                    resetForm()
                    setShowAddPenghargaan(!showAddPenghargaan)
                }).catch((error) => {
                    error?.response?.data?.map((val) => (
                        toast.error(val)
                    ))
                })
            }
        } catch (error) {
            toast.error('Gagal Menambahkan Data!')
        }
    }

    const editPenghargaan = async () => {
        try {
            if (!isValidYear(formData.tahun)) {
                toast.error("Tahun Tidak Valid")
            } else {
                await Api.EditPengahargaan(localStorage.getItem('hris-token'), formData, idPenghargaan).then((response) => {
                    setRefresh(true)
                    toast.success("Data Berhasil Ditambahkan!")
                    resetForm()
                    setShowEditPenghargaan(!showEditPenghargaan)
                }).catch((error) => {
                    toast.error("Data Gagal Ditambahkan!")
                    error?.response?.data?.map((val) => (
                        toast.error(val)
                    ))
                })
            }
        } catch (error) {
            toast.error('Gagal Menambahkan Data!')
        }
    }
    const deleteModal = (id) => {
        setShowDeletePenghargaan(!showDeletePenghargaan)
        setIdPenghargaan(id)
    }

    const deletePenghargaan = async () => {
        try {
            const response = await Api.DeletePengahargaan(localStorage.getItem('hris-token'), idPenghargaan)
            console.log(response)
            setShowDeletePenghargaan(!showDeletePenghargaan)
            setRefresh(true)
            toast.success('Berhasil Dihapus')
        } catch (error) {
            console.log(error)
            toast.error('Gagal Dihapus')
        }
    }

    // Pagination
    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    function isValidYear(year) {
        // Check if the year is between 1900 and 2100
        if (year >= 1900 && year <= 2100) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        getDataPenghargaan()
        setRefresh(false)
    }, [refresh])
    return (
        <>
            <div>
                <Modal
                    activeModal={showAddPenghargaan}
                    title={'Tambah Penghargaan'}
                    buttonClose={() => { setShowAddPenghargaan(!showAddPenghargaan); resetForm() }}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tahun Menerima Penghargaan <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tahun} onChange={(e) => setFormData({ ...formData, tahun: e.target.value })} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tahun Menerima Penghargaan...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nama Penghargaan <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nama} onChange={(e) => setFormData({ ...formData, nama: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nama Penghargaan...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Lembaga Pemberi Penghargaan <span className='text-red-primer'>*</span></h1>
                                <input value={formData.lembaga} onChange={(e) => setFormData({ ...formData, lembaga: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Lembaga Pemberi Penghargaan...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nomor SK/Sertifikat <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nomor_sk} onChange={(e) => setFormData({ ...formData, nomor_sk: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nomor SK/Sertifikat...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal SK/Sertifikat <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_sk} onChange={(e) => setFormData({ ...formData, tanggal_sk: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal SK/Sertifikat...' />
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end">
                                <button onClick={() => { setShowAddPenghargaan(!showAddPenghargaan); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={createPenghargaan} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditPenghargaan}
                    title={'Edit Penghargaan'}
                    buttonClose={() => { setShowEditPenghargaan(!showEditPenghargaan); resetForm() }}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tahun Menerima Penghargaan <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tahun} onChange={(e) => setFormData({ ...formData, tahun: e.target.value })} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tahun Menerima Penghargaan...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nama Penghargaan <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nama} onChange={(e) => setFormData({ ...formData, nama: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nama Penghargaan...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Lembaga Pemberi Penghargaan <span className='text-red-primer'>*</span></h1>
                                <input value={formData.lembaga} onChange={(e) => setFormData({ ...formData, lembaga: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Lembaga Pemberi Penghargaan...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nomor SK/Sertifikat <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nomor_sk} onChange={(e) => setFormData({ ...formData, nomor_sk: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nomor SK/Sertifikat...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal SK/Sertifikat <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_sk} onChange={(e) => setFormData({ ...formData, tanggal_sk: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal SK/Sertifikat...' />
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end">
                                <button onClick={() => { setShowEditPenghargaan(!showEditPenghargaan); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={editPenghargaan} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showDeletePenghargaan}
                    buttonClose={() => setShowDeletePenghargaan(!showDeletePenghargaan)}
                    submitButton={deletePenghargaan}
                />
            </div>
            <div className='px-[19px] py-[19px] space-y-[20px]'>
                <div className='flex relative'>
                    <div>
                        <h1 className='text-base-black text-[20px] font-medium'>Penghargaan</h1>
                        <h2 className='text-dark-5 text-[10px]'>Daftar Penghargaan</h2>
                    </div>
                    <div className='absolute right-0'>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <button onClick={() => setShowAddPenghargaan(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 dark:place text-sm font-[500]'>Tambah Penghargaan</h1>
                            </button>
                        </div>
                    </div>
                </div>
                <hr className='border border-grey-extra-thin' />
                <div className='rounded shadow'>
                    <div className='px-2 py-4'>
                        <div className='overflow-auto'>
                            <table className='w-full space-y-[10px] text-dark-5'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tahun Menerima Penghargaan</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nama Penghargaan</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Lembaga Pemberi Penghargaan</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nomor SK/Sertifikat</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal SK/Sertifikat</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                                    </div>
                                </div>
                                {dataPenghargaan && dataPenghargaan.map((val, index) => (
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.tahun ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.nama ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.lembaga ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.nomor_sk ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.tanggal_sk ?? "-"}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => openEditPenghargaan(val.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]' />
                                            </button>
                                            <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty onClick={() => deleteModal(val.id)} className='text-[#003049]' />
                                            </button>
                                            {/* <button onClick={() => handleCollapse(val.id)} className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                {item.id === Current && !Active ?
                                                    <MdKeyboardArrowUp className='text-[#003049]' />
                                                    :
                                                    <MdKeyboardArrowDown className='text-[#003049]' />
                                                }
                                            </button> */}
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            limitData={dataPenghargaan?.length}
                            lengthData={totalCount}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Penghargaan