import React, { useState } from 'react'
import { Modal, ModalDelete, Navbar, Sidebar } from '../../../../components'
import { AiOutlinePlus } from 'react-icons/ai'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import Reimbursement from './components/Reimbursement'
import CashAdvance from './components/CashAdvance'
import Loan from './components/Loan'
import Api from '../../../../Api'
import { toast } from 'react-hot-toast'

const FinanceSetting = () => {
    const [index, setIndex] = useState(0)
    const [refresh, setRefresh] = useState(false)

    // Reimbursment Modal
    const [showModalAddReimbursement, setShowModalAddReimbursement] = useState()
    const [showModalEditReimbursement, setShowModalEditReimbursement] = useState()
    const [showModalDeleteReimbursement, setShowModalDeleteReimbursement] = useState()

    // Cash Advance Modal
    const [showModalAddCashAdvance, setShowModalAddCashAdvance] = useState()
    const [showModalEditCashAdvance, setShowModalEditCashAdvance] = useState()
    const [showModalDeleteCashAdvance, setShowModalDeleteCashAdvance] = useState()

    // Loan Modal
    const [showModalAddLoan, setShowModalAddLoan] = useState()
    const [showModalEditLoan, setShowModalEditLoan] = useState()
    const [showModalDeleteLoan, setShowModalDeleteLoan] = useState()

    // Reimbursment State
    const [reimbursmentId, setReimbursmentId] = useState('')
    const [title, setTitle] = useState('')
    const [limitAmount, setLimitAmount] = useState('')
    const [minNextClaim, setMinNextClaim] = useState('')
    const [assignTo, setAssignTo] = useState('all')

    // Cash Adavance State
    const [cashAdvanceId, setCashAdvanceId] = useState('')
    const [titleCA, setTitleCA] = useState('')
    const [limitAmountCA, setLimitAmountCA] = useState('')
    const [settlementDue, setSettlementDue] = useState('')
    const [assignToCA, setAssignToCA] = useState('all')
    
    // Loan State
    const [loanId, setLoanId] = useState('')
    const [titleLoan, setTitleLoan] = useState('')
    const [maxInstallment, setMaxInstallment] = useState('')
    const [interest, setInterest] = useState('')

    // Reimbursment Data

    const resetForm = () => {
        setTitle('')
        setLimitAmount('')
        setMinNextClaim('')
        setTitleCA('')
        setLimitAmountCA('')
        setSettlementDue('')
        setTitleLoan('')
        setMaxInstallment('')
        setInterest('')
    }
    const PostDataReimbursment = async() => {
        try {
            const data = {
                name: title,
                limit_amount: limitAmount,
                min_next_claim: minNextClaim,
                assign_to: assignTo
            }
            const response = await Api.CreateFinanceReimbursmentSetting(localStorage.getItem('hris-token'), data)
            resetForm()
            setRefresh(true)
            setShowModalAddReimbursement(!showModalAddReimbursement)
            toast.success('Success create reimbursement policy!')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const UpdateDataReimbursmentOpen = async(id) => {
        setShowModalEditReimbursement(!showModalEditReimbursement)
        setReimbursmentId(id)
        try {
            const response = await Api.GetFinanceReimbursmentSettingById(localStorage.getItem('hris-token'), id)
            setTitle(response.data[0].name)
            setLimitAmount(response.data[0].limit_amount)
            setMinNextClaim(response.data[0].min_next_claim)
            setAssignTo(response.data[0].assign_to)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateDataReimbursment = async() => {
        try {
            const data = {
                name: title,
                limit_amount: limitAmount,
                min_next_claim: minNextClaim,
                assign_to: assignTo
            }
            const response = await Api.UpdateFinanceReimbursmentSetting(localStorage.getItem('hris-token'), data, reimbursmentId)
            resetForm()
            setRefresh(true)
            setShowModalEditReimbursement(!showModalEditReimbursement)
            toast.success('Success update reimbursement policy!')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const DeleteDataReimbursmentModal = async(id) => {
        setShowModalDeleteReimbursement(!showModalDeleteReimbursement)
        setReimbursmentId(id)
    }

    const DeleteDataReimbursment = async(id) => {
        try {
            const response = await Api.DeleteFinanceReimbursmentSetting(localStorage.getItem('hris-token'), reimbursmentId)
            setRefresh(true)
            setShowModalDeleteReimbursement(!showModalDeleteReimbursement)
            toast.success('Success delete reimbursement policy!')
        } catch (error) {
            console.log(error)
            toast.error('Delete failed!')
        }
    }
    
    // Cash Advance Data
    const PostDataCashAdvance = async() => {
        try {
            const data = {
                name: titleCA,
                limit_amount: limitAmountCA,
                settlement_due: settlementDue,
                assign_to: assignToCA
            }
            const response = await Api.CreateFinanceCashAdvanceSetting(localStorage.getItem('hris-token'), data)
            resetForm()
            setRefresh(true)
            setShowModalAddCashAdvance(!showModalAddCashAdvance)
            toast.success('Success create cash advance policy!')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const UpdateDataCashAdvanceOpen = async(id) => {
        setShowModalEditCashAdvance(!showModalEditCashAdvance)
        setCashAdvanceId(id)
        try {
            const response = await Api.GetFinanceCashAdvanceSettingById(localStorage.getItem('hris-token'), id)
            setTitleCA(response.data[0].name)
            setLimitAmountCA(response.data[0].limit_amount)
            setSettlementDue(response.data[0].settlement_due)
            setAssignToCA(response.data[0].assign_to)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateDataCashAdvance = async() => {
        try {
            const data = {
                name: titleCA,
                limit_amount: limitAmountCA,
                settlement_due: settlementDue,
                assign_to: assignToCA
            }
            const response = await Api.UpdateFinanceCashAdvanceSetting(localStorage.getItem('hris-token'), data, cashAdvanceId)
            resetForm()
            setRefresh(true)
            setShowModalEditCashAdvance(!showModalEditCashAdvance)
            toast.success('Success update cash advance policy!')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const DeleteDataCashAdvanceModal = async(id) => {
        setShowModalDeleteCashAdvance(!showModalDeleteCashAdvance)
        setCashAdvanceId(id)
    }

    const DeleteDataCashAdvance = async(id) => {
        try {
            const response = await Api.DeleteFinanceCashAdvanceSetting(localStorage.getItem('hris-token'), cashAdvanceId)
            setRefresh(true)
            setShowModalDeleteCashAdvance(!showModalDeleteCashAdvance)
            toast.success('Success delete cash advance policy!')
        } catch (error) {
            console.log(error)
            toast.error('Delete failed!')
        }
    }
    
    // Loan Data
    const PostDataLoan = async() => {
        try {
            const data = {
                name: titleLoan,
                max_installment: maxInstallment,
                interest: interest,
            }
            const response = await Api.CreateFinanceLoanSetting(localStorage.getItem('hris-token'), data)
            resetForm()
            setRefresh(true)
            setShowModalAddLoan(!showModalAddLoan)
            toast.success('Success create loan policy!')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const UpdateDataLoanOpen = async(id) => {
        setShowModalEditLoan(!showModalEditLoan)
        setLoanId(id)
        try {
            const response = await Api.GetFinanceLoanSettingById(localStorage.getItem('hris-token'), id)
            setTitleLoan(response.data[0].name)
            setMaxInstallment(response.data[0].max_installment)
            setInterest(response.data[0].interest)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateDataLoan = async() => {
        try {
            const data = {
                name: titleLoan,
                max_installment: maxInstallment,
                interest: interest,
            }
            const response = await Api.UpdateFinanceLoanSetting(localStorage.getItem('hris-token'), data, loanId)
            resetForm()
            setRefresh(true)
            setShowModalEditLoan(!showModalEditLoan)
            toast.success('Success update cash advance policy!')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const DeleteDataLoanModal = async(id) => {
        setShowModalDeleteLoan(!showModalDeleteLoan)
        setLoanId(id)
    }

    const DeleteDataLoan = async(id) => {
        try {
            const response = await Api.DeleteFinanceLoanSetting(localStorage.getItem('hris-token'), loanId)
            setRefresh(true)
            setShowModalDeleteLoan(!showModalDeleteLoan)
            toast.success('Success delete cash advance policy!')
        } catch (error) {
            console.log(error)
            toast.error('Delete failed!')
        }
    }

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            {/* Reikbursment */}
            <Modal 
                activeModal={showModalAddReimbursement}
                title={'Add Policy Reimbursment'}
                buttonClose={ () => setShowModalAddReimbursement(!showModalAddReimbursement)}
                width={'715px'}
                content={
                    <>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Policy name <span className='text-[#780000]'>*</span></h1>
                                <input type="text" onChange={ (e) => setTitle(e.target.value) } value={title} className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Input policy of cash advance'/>
                        </div>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Limit Amount<span className='text-[#780000]'>*</span></h1>
                            <input type="number" onChange={ (e) => setLimitAmount(e.target.value) } value={limitAmount} className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Rp'/>
                        </div>
                        <div className='w-[170px] mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Min Next Claim</h1>
                            <div className='flex'>
                                <input type="number" onChange={ (e) => setMinNextClaim(e.target.value) } value={minNextClaim} className='rounded-l-lg w-full h-[35px] border-l border-y py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='0'/>
                                <div className='border rounded-r-lg flex item-center justify-center bg-[#F1F1F1] text-[12px] text-dark-5 w-[76px] h-[35px] py-[9px] px-[23px]'>
                                    <p>Days</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='text-dark-5 text-[11px] font-medium'>
                            <h1 className='mb-5'>Assign to</h1>
                            
                            <div class="flex items-center mb-5">
                                <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-1" class="ml-2 text-dark-5 text-[11px] font-medium">All Employee</label>
                            </div>
                            <div class="flex items-center mb-5">
                                <input checked id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-2" class="ml-2 text-dark-5 text-[11px] font-medium">Search by Employeee</label>
                            </div>
                            <div class="flex items-center mb-5">
                                <input checked id="default-radio-3" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-3" class="ml-2 text-dark-5 text-[11px] font-medium">Select Filter</label>
                            </div>
                        </div> */}
                        <div className="mt-[55px] ml-[420px]">
                            <button onClick={ () => setShowModalAddReimbursement(!showModalAddReimbursement)} className="w-[100px] h-[38px] bg-[#ECECEC] rounded-lg font-medium text-[14px] mr-[10px]">Cancel</button>
                            <button onClick={PostDataReimbursment} className="w-[100px] h-[38px] text-white bg-[#0E5073] rounded-lg font-medium text-[14px]">Save</button>
                        </div>
                    </>
                }
            />
            <Modal 
                activeModal={showModalEditReimbursement}
                title={'Edit Policy Reimbursment'}
                buttonClose={ () => setShowModalEditReimbursement(!showModalEditReimbursement)}
                width={'715px'}
                content={
                    <>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Policy name <span className='text-[#780000]'>*</span></h1>
                            <input type="text" value={title} onChange={ (e) => setTitle(e.target.value) } className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Input policy of cash advance'/>
                        </div>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Limit Amount<span className='text-[#780000]'>*</span></h1>
                            <input type="number" value={limitAmount} onChange={ (e) => setLimitAmount(e.target.value) } className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Rp'/>
                        </div>
                        <div className='w-[170px] mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Min Next Claim</h1>
                            <div className='flex'>
                                <input type="number" value={minNextClaim} onChange={ (e) => setMinNextClaim(e.target.value) } className='rounded-l-lg w-full h-[35px] border-l border-y py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='0'/>
                                <div className='border rounded-r-lg flex item-center justify-center bg-[#F1F1F1] text-[12px] text-dark-5 w-[76px] h-[35px] py-[9px] px-[23px]'>
                                    <p>Days</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='text-dark-5 text-[11px] font-medium'>
                            <h1 className='mb-5'>Assign to</h1>
                            
                            <div class="flex items-center mb-5">
                                <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-1" class="ml-2 text-dark-5 text-[11px] font-medium">All Employee</label>
                            </div>
                            <div class="flex items-center mb-5">
                                <input checked id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-2" class="ml-2 text-dark-5 text-[11px] font-medium">Search by Employeee</label>
                            </div>
                            <div class="flex items-center mb-5">
                                <input checked id="default-radio-3" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-3" class="ml-2 text-dark-5 text-[11px] font-medium">Select Filter</label>
                            </div>
                        </div> */}
                        <div className="mt-[55px] ml-[420px]">
                            <button onClick={ () => setShowModalEditReimbursement(!showModalEditReimbursement)} className="w-[100px] h-[38px] bg-[#ECECEC] rounded-lg font-medium text-[14px] mr-[10px]">Cancel</button>
                            <button onClick={UpdateDataReimbursment} className="w-[100px] h-[38px] text-white bg-[#0E5073] rounded-lg font-medium text-[14px]">Save</button>
                        </div>
                    </>
                }
            />
            <ModalDelete
                activeModal={showModalDeleteReimbursement}
                buttonClose={() => setShowModalDeleteReimbursement(!showModalDeleteReimbursement)}
                submitButton={DeleteDataReimbursment}
            />

            {/* Cash Advance */}
            <Modal 
                activeModal={showModalAddCashAdvance}
                title={'Add Policy Cash Advance'}
                buttonClose={ () => setShowModalAddCashAdvance(!showModalAddCashAdvance)}
                width={'715px'}
                content={
                    <>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Policy name <span className='text-[#780000]'>*</span></h1>
                            <input type="text" onChange={ (e) => setTitleCA(e.target.value) } value={titleCA} className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Input policy of cash advance'/>
                        </div>
                        <div className='w-[170px] mb-[19px]'>
                            <h1 className='text-dark-5 text-[8px] font-medium mb-[5px]'>Setlement due<span className='text-[#780000]'>*</span></h1>
                            <div className='flex'>
                                <input type="number" onChange={ (e) => setSettlementDue(e.target.value) } value={settlementDue} className='rounded-l-lg w-full h-[35px] border-l border-y py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='0'/>
                                <div className='border rounded-r-lg flex item-center justify-center bg-[#F1F1F1] text-[12px] text-dark-5 w-[76px] h-[35px] py-[9px] px-[23px]'>
                                    <p>Days</p>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Limit Amount</h1>
                            <input type="number" onChange={ (e) => setLimitAmountCA(e.target.value) } value={limitAmountCA} className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Rp'/>
                        </div>
                        {/* <div className='text-dark-5 text-[11px] font-medium'>
                            <h1 className='mb-5'>Assign to</h1>
                            
                            <div class="flex items-center mb-5">
                                <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-1" class="ml-2 text-dark-5 text-[11px] font-medium">All Employee</label>
                            </div>
                            <div class="flex items-center mb-5">
                                <input checked id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-2" class="ml-2 text-dark-5 text-[11px] font-medium">Search by Employeee</label>
                            </div>
                            <div class="flex items-center mb-5">
                                <input checked id="default-radio-3" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-3" class="ml-2 text-dark-5 text-[11px] font-medium">Select Filter</label>
                            </div>

                        </div> */}
                        <div className="mt-[55px] ml-[420px]">
                            <button onClick={ () => setShowModalAddCashAdvance(!showModalAddCashAdvance)} className="w-[100px] h-[38px] bg-[#ECECEC] rounded-lg font-medium text-[14px] mr-[10px]">Cancel</button>
                            <button onClick={PostDataCashAdvance} className="w-[100px] h-[38px] text-white bg-[#0E5073] rounded-lg font-medium text-[14px]">Save</button>
                        </div>
                    </>
                    
                }
            />
            <Modal 
                activeModal={showModalEditCashAdvance}
                title={'Edit Policy Cash Advance'}
                buttonClose={ () => setShowModalEditCashAdvance(!showModalEditCashAdvance)}
                width={'715px'}
                content={
                    <>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Policy name <span className='text-[#780000]'>*</span></h1>
                                <input type="text" value={titleCA} onChange={(e) => setTitleCA(e.target.value)} className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Input policy of cash advance'/>
                        </div>
                        <div className='w-[170px] mb-[19px]'>
                            <h1 className='text-dark-5 text-[8px] font-medium mb-[5px]'>Setlement due<span className='text-[#780000]'>*</span></h1>
                            <div className='flex'>
                                <input type="number" value={settlementDue} onChange={(e) => setSettlementDue(e.target.value)} className='rounded-l-lg w-full h-[35px] border-l border-y py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='0'/>
                                <div className='border rounded-r-lg flex item-center justify-center bg-[#F1F1F1] text-[12px] text-dark-5 w-[76px] h-[35px] py-[9px] px-[23px]'>
                                    <p>Days</p>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Limit Amount</h1>
                            <input type="number" value={limitAmountCA} onChange={(e) => setLimitAmountCA(e.target.value)} className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Rp'/>
                        </div>
                        {/* <div className='text-dark-5 text-[11px] font-medium'>
                            <h1 className='mb-5'>Assign to</h1>
                            
                            <div class="flex items-center mb-5">
                                <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-1" class="ml-2 text-dark-5 text-[11px] font-medium">All Employee</label>
                            </div>
                            <div class="flex items-center mb-5">
                                <input checked id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-2" class="ml-2 text-dark-5 text-[11px] font-medium">Search by Employeee</label>
                            </div>
                            <div class="flex items-center mb-5">
                                <input checked id="default-radio-3" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                <label for="default-radio-3" class="ml-2 text-dark-5 text-[11px] font-medium">Select Filter</label>
                            </div>

                        </div> */}
                        <div className="mt-[55px] ml-[420px]">
                            <button onClick={ () => setShowModalEditCashAdvance(!showModalEditCashAdvance)} className="w-[100px] h-[38px] bg-[#ECECEC] rounded-lg font-medium text-[14px] mr-[10px]">Cancel</button>
                            <button onClick={UpdateDataCashAdvance} className="w-[100px] h-[38px] text-white bg-[#0E5073] rounded-lg font-medium text-[14px]">Save</button>
                        </div>
                    </>
                    
                }
            />
            <ModalDelete
                activeModal={showModalDeleteCashAdvance}
                buttonClose={() => setShowModalDeleteCashAdvance(!showModalDeleteCashAdvance)}
                submitButton={DeleteDataCashAdvance}
            />

            {/* Loan */}
            <Modal 
                activeModal={showModalAddLoan}
                title={'Add Policy Loan'}
                buttonClose={ () => setShowModalAddLoan(!showModalAddLoan)}
                width={'715px'}
                content={
                    <>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Policy name <span className='text-[#780000]'>*</span></h1>
                                <input type="text" onChange={(e)=>setTitleLoan(e.target.value)} value={titleLoan} className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Input policy of cash advance'/>
                        </div>
                        <div className='w-full gap-[27.26px] mb-[19px] flex'>
                            <div>
                                <h1 className='text-dark-5 text-[8px] font-medium mb-[5px]'>Max Installment<span className='text-[#780000]'>*</span></h1>
                                <div className='flex'>
                                    <input type="number" onChange={(e)=>setMaxInstallment(e.target.value)} value={maxInstallment} className='rounded-l-lg w-full h-[35px] border-l border-y py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='0'/>
                                    <div className='border rounded-r-lg flex item-center justify-center bg-[#F1F1F1] text-[12px] text-dark-5 w-[76px] h-[35px] py-[9px] px-[23px]'>
                                        <p>Month</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-dark-5 text-[8px] font-medium mb-[5px]'>Interest<span className='text-[#780000]'>*</span></h1>
                                <div className='flex'>
                                    <input type="number" onChange={(e)=>setInterest(e.target.value)} value={interest} className='rounded-l-lg w-full h-[35px] border-l border-y py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='0'/>
                                    <div className='border rounded-r-lg flex item-center justify-center bg-[#F1F1F1] text-[12px] text-dark-5 w-[76px] h-[35px] py-[9px] px-[23px]'>
                                        <p>%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[55px] ml-[420px]">
                            <button onClick={ () => setShowModalAddLoan(!showModalAddLoan)} className="w-[100px] h-[38px] bg-[#ECECEC] rounded-lg font-medium text-[14px] mr-[10px]">Cancel</button>
                            <button onClick={PostDataLoan} className="w-[100px] h-[38px] text-white bg-[#0E5073] rounded-lg font-medium text-[14px]">Save</button>
                        </div>
                    </>
                    
                }
            />
            <Modal 
                activeModal={showModalEditLoan}
                title={'Edit Policy Loan'}
                buttonClose={ () => setShowModalEditLoan(!showModalEditLoan)}
                width={'715px'}
                content={
                    <>
                        <div className='w-full mb-[19px]'>
                            <h1 className='text-dark-5 text-[11px] font-medium mb-[5px]'>Policy name <span className='text-[#780000]'>*</span></h1>
                                <input type="text" value={titleLoan} onChange={(e) => setTitleLoan(e.target.value)} className='w-full h-[35px] border rounded-lg py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='Input policy of cash advance'/>
                        </div>
                        <div className='w-full gap-[27.26px] mb-[19px] flex'>
                            <div>
                                <h1 className='text-dark-5 text-[8px] font-medium mb-[5px]'>Max Installment<span className='text-[#780000]'>*</span></h1>
                                <div className='flex'>
                                    <input type="number" value={maxInstallment} onChange={(e) => setMaxInstallment(e.target.value)} className='rounded-l-lg w-full h-[35px] border-l border-y py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='0'/>
                                    <div className='border rounded-r-lg flex item-center justify-center bg-[#F1F1F1] text-[12px] text-dark-5 w-[76px] h-[35px] py-[9px] px-[23px]'>
                                        <p>Moth</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-dark-5 text-[8px] font-medium mb-[5px]'>Interest<span className='text-[#780000]'>*</span></h1>
                                <div className='flex'>
                                    <input type="number" value={interest} onChange={(e) => setInterest(e.target.value)} className='rounded-l-lg w-full h-[35px] border-l border-y py-[10px] pl-[11.23px] placeholder:text-[12px] text-xs' placeholder='0'/>
                                    <div className='border rounded-r-lg flex item-center justify-center bg-[#F1F1F1] text-[12px] text-dark-5 w-[76px] h-[35px] py-[9px] px-[23px]'>
                                        <p>%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[55px] ml-[420px]">
                            <button onClick={ () => setShowModalEditLoan(!showModalEditLoan)} className="w-[100px] h-[38px] bg-[#ECECEC] rounded-lg font-medium text-[14px] mr-[10px]">Cancel</button>
                            <button onClick={UpdateDataLoan} className="w-[100px] h-[38px] text-white bg-[#0E5073] rounded-lg font-medium text-[14px]">Save</button>
                        </div>
                    </>
                    
                }
            />
            <ModalDelete
                activeModal={showModalDeleteLoan}
                buttonClose={() => setShowModalDeleteLoan(!showModalDeleteLoan)}
                submitButton={DeleteDataLoan}
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Finance Setting'} LinkBack={'/finance'}/>
                <div>
                    <h1 className='text-[16px]'>Finance Setting</h1>
                    <p className='text-[8px] text-dark-5'>Setting Menu for Finance</p>
                </div>
                <div className='space-y-[24px] rounded-[12px] border'>
                    <div className="flex">
                        <div className='w-[267px] h-[762px] py-[18px] shadow-l rounded-l-xl border-r-6 relative pl-[18px] bg-white'>
                            <button onClick={() => setIndex(0)} className={ index === 0 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={ index === 0 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>Reimbursement</h1>  
                            </button>
                            <button onClick={() => setIndex(1)} className={ index === 1 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={ index === 1 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>Cash Advance</h1>  
                            </button>
                            <button onClick={() => setIndex(2)} className={ index === 2 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={ index === 2 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>Loan</h1>  
                            </button>
                        </div>
                        <div className="ml-auto w-9/12 p-5 space-y-5 bg-white">
                            {
                                index === 0 ? 
                                    <Reimbursement
                                        modalAddReimbursment={ () => setShowModalAddReimbursement(!showModalAddReimbursement) }
                                        modalEditReimbursment={UpdateDataReimbursmentOpen }
                                        modalDeleteReimbursment={DeleteDataReimbursmentModal}
                                        refresh={refresh}
                                    /> 
                                : index === 1 ? 
                                    <CashAdvance
                                        modalAddCashAdvance={ () => setShowModalAddCashAdvance(!showModalAddCashAdvance) }
                                        modalEditCashAdvance={UpdateDataCashAdvanceOpen}
                                        modalDeleteCashAdvance={DeleteDataCashAdvanceModal}
                                        refresh={refresh}
                                    /> 
                                : index === 2 ? 
                                    <Loan 
                                        modalAddLoan={ () => setShowModalAddLoan(!showModalAddLoan) }
                                        modalEditLoan={UpdateDataLoanOpen}
                                        modalDeleteLoan={DeleteDataLoanModal}
                                        refresh={refresh}
                                    />
                                : null 
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinanceSetting