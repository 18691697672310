import React, { useEffect, useState } from 'react'
import Api from '../../../../../Api'
import { Navbar } from '../../../../../components'
import OrganizationTree from './OrganizationTree'

const Structure = () => {
    const [data, setData] = useState([])
    const getHierarchy = async () => {
        try {
            const response = await Api.GetHierarchy(localStorage.getItem('hris-token'))
            setData(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getHierarchy()
    }, [])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={"Struktur Organisasi"} LinkBack={'/dashboard'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className="flex justify-start items-center">
                        <OrganizationTree data={data} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Structure