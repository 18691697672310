import React from 'react'
import { Logo, Landing1 } from '../../../assets'
import { Link } from 'react-router-dom'
const Register = () => {
    return (
        <div>
            <div className='lg:grid lg:grid-cols-12'>
                <div className='lg:col-span-7 bg-[#ECEEF6] '>
                    <div className='flex flex-col justify-between h-[100%]'>
                        <div className='px-[85px] mt-[51px]'>
                            <img src={Logo} className='w-[203px]' alt='Humanusia Logo' />
                        </div>
                        <div className=''>
                            <img src={Landing1} className='' alt='LandingImage' />
                        </div>
                    </div>
                </div>
                <div className='lg:col-span-5 bg-white px-5 lg:px-[79px] py-[38px]'>
                    <h1 className='text-[30px] text-[#2F3044] font-medium'>Create New Account</h1>
                    <h2 className='text-[#A8A8A8] text-[12px] mb-[34px]'>Feel the form to get new account access</h2>
                    
                    <div className=''>
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Fullname</h1>
                        <input type='text' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-[15px]" placeholder='Enter your fullname (ex: John Doe)...' />
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Job position</h1>
                        <input type='text' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-[15px]" placeholder='Enter your Job position (ex: Manager)...' />
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Email</h1>
                        <input type='email' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-[15px]" placeholder='Enter your email...' />
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Phone Number</h1>
                        <input type='text' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-[15px]" placeholder='Enter your phone number...' />
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Company Name</h1>
                        <input type='text' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-[15px]" placeholder='Enter your company name (ex: PT Maju Makmur)...' />
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Number of Employee</h1>
                        <input type='number' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-[15px]" placeholder='0' />
                        <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Company Address</h1>
                        <textarea type='text' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-[30px]" placeholder='Your company address...'></textarea>
                    {/* <div className='flex justify-end'> */}
                        <button className='py-[10px] px-[195px] text-white rounded-md bg-[#0E5073] text-[12px] w-full font-semibold mb-[14px]'>Register</button>
                        <div className='flex gap-1'>
                            <h1 className='text-[14px] text-dark-5'>Already have an Account?  </h1>
                            <Link to='/login'>
                                <h1 className='text-[14px] text-[#219EBC] font-semibold'>Login</h1>
                            </Link>
                        </div>
                    {/* </div> */}
                </div>
            </div>
            </div>
        </div >
    )
}

export default Register