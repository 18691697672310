import React from 'react';

const InputComponent = ({ label, value, onChange, required, type, disabled, placeholder, maxLength, min, max }) => {
  return (
    <div className='flex-row space-y-1'>
      <label className='w-full text-grey-thick text-[12px] font-medium mb-1 line-clamp-1'>{label} {required ? <span className='text-red-primer'> *</span> : ''}</label>
      {disabled === true ?
        <input
          type={type}
          value={value}
          disabled
          className='bg-base-outline w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
        />
        :
        <input
          type={type}
          value={value}
          placeholder={placeholder ?? ""}
          maxLength={maxLength ?? ""}
          min={min ?? ""}
          max={max ?? ""}
          onChange={(e) => onChange(e.target.value)}
          className='w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]'
        />
      }
    </div>
  );
};

export default InputComponent;
