import React, { useState } from 'react'
import { Navbar } from '../../../../../components'
import Account from './account'
import General from './general'

const GeneralInformation = () => {
    const [menu, setMenu] = useState('general')
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Informasi Umum'} LinkBack={'/dashboard'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='border-b-2 flex flex-wrap items-center text-[16px]'>
                        <button onClick={() => setMenu('general')} className={`${menu === 'general' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Umum</button>
                        <button onClick={() => setMenu('account')} className={`${menu === 'account' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[20px]`}>Akun</button>
                    </div>

                    {menu === 'general' ? (
                        <General />
                    ) : menu === 'account' ? (
                        <Account />
                    ) : null}
                </div>
            </div >
        </div >
    )
}

export default GeneralInformation