import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import { IconInsertPhoto } from '../../../../assets'
import { Navbar } from '../../../../components'
import InputComponent from '../../../../components/Input/inputComponent'
import InputOption from '../../../../components/Input/inputOption'

const AddEmployee = () => {
    const navigate = useNavigate()

    const [index, setIndex] = useState(0)
    //State Upload Sign
    const [namepath, setNamePath] = useState('')
    const [viewImage, setViewImage] = useState('')
    const [namepathContract, setNamePathContract] = useState('')
    const [namepathUnggahSPKTenagaPendukung, setNamePathUnggahSPKTenagaPendukung] = useState('')
    const [namepathUnggahSKPangkatGolonganTerakhir, setNamePathUnggahSKPangkatGolonganTerakhir] = useState('')
    const [tanggalKenaikanGajiBerkalaBerikutnya, setTanggalKenaikanGajiBerkalaBerikutnya] = useState('');
    const [fileTanggalKenaikanGajiBerkalaPath, setFileTanggalKenaikanGajiBerkalaPath] = useState('');

    const createEmployee = async () => {
        // console.log(formData2)
        const errors = [];
        if (!formData2.basic_salary || !formData2.basic_salary.trim()) { errors.push("Gaji Pokok Wajib Diisi!") }
        if (!formData2.bank_name || !formData2.bank_name.trim()) { errors.push("Nama Bank Wajib Diisi!") }
        if (!formData2.account_name || !formData2.account_name.trim()) { errors.push("Nama Pemilik Rekening Wajib Diisi!") }
        if (!formData2.account_number || !formData2.account_number.trim()) { errors.push("Nomor Rekening Wajib Diisi!") }
        if (!income[0]?.value.trim() || income?.length < 1) { errors.push("Pemasukan Wajib Diisi!") }
        if (!deduction[0]?.value.trim() || deduction?.length < 1) { errors.push("Potongan Wajib Diisi!") }
        if (errors.length > 0) {
            errors.map((err) => {
                toast.error(err)
            })
        } else {
            setIsLoading(true)
            const data = new FormData();

            // formData to data
            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    data.append(key, formData[key]);
                }
            }
            // formData1 to data
            for (const key in formData1) {
                if (formData1.hasOwnProperty(key)) {
                    data.append(key, formData1[key]);
                }
            }
            // formData2 to data
            for (const key in formData2) {
                if (formData2.hasOwnProperty(key)) {
                    data.append(key, formData2[key]);
                }
            }

            // repeater income to data
            if (income[0]?.value.trim() && income?.length > 0) {
                for (const i in income) {
                    data.append('income[]', income[i].value);
                }
            } else {
                toast.error("Pemasukan Tidak Boleh Kosong")
            }
            // repeater deduction to data
            if (deduction[0]?.value.trim() && deduction?.length > 0) {
                for (const i in deduction) {
                    data.append('deduction[]', deduction[i].value);
                }
            } else {
                toast.error("Potongan Tidak Boleh Kosong")
            }
            // for (const pair of data.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            try {
                await Api.CreateEmployee2(localStorage.getItem('hris-token'), data).then((response) => {
                    // console.log(response)
                    setIsLoading(false)
                    navigate('/employee')
                    toast.success('Berhasil Menambahkan Data!')
                }).catch((error) => {
                    console.log(error)
                    setIsLoading(false)
                    toast.error("Gagal Menambahkan Data!")
                    const e = error.response.data.errors.error.details
                    for (let i = 0; i < e?.length; i++) {
                        toast.error(error.response.data.errors.error.details[i].message)
                    }
                })
            } catch (error) {
                console.log(error)
                toast.error("Gagal Menambahkan Data!")
            }
        }
    }

    // repeater income
    const [income, setIncome] = useState([{ id: 1, value: '' }]);

    const handleAddIncome = () => {
        const newId = income?.length + 1;
        setIncome([...income, { id: newId, value: '' }]);
    };

    const handleRemoveIncome = (incomeId) => {
        const updatedIncome = income.filter((income) => income.id !== incomeId);
        setIncome(updatedIncome);
    };

    const handleChangeIncome = (itemId, selectedValue) => {
        const updatedIncome = income.map((item) =>
            item.id === itemId ? { ...item, value: selectedValue } : item
        );
        setIncome(updatedIncome);
    };

    // repeater Deduction
    const [deduction, setDeduction] = useState([{ id: 1, value: '' }]);

    const handleAddDeduction = () => {
        const newId = deduction?.length + 1;
        setDeduction([...deduction, { id: newId, value: '' }]);
    };

    const handleRemoveDeduction = (deductionId) => {
        const updatedDeduction = deduction.filter((deduction) => deduction.id !== deductionId);
        setDeduction(updatedDeduction);
    };

    const handleChangeDeduction = (itemId, selectedValue) => {
        const updatedDeduction = deduction.map((item) =>
            item.id === itemId ? { ...item, value: selectedValue } : item
        );
        setDeduction(updatedDeduction);
    };

    // Handle Sign
    const handleChangeFileSign = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('gambar tidak boleh lebih dari 10 MB')
            // setFileSign(null)
            setFormData({ ...formData, sign: "" })
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat jpg, jpeg, atau png')
        } else {
            setNamePath(e.target.files[0].name)
            setFormData({ ...formData, sign: e.target.files[0] })
        }
    }

    // Handle File Tanggal Kenaikan Gaji Berkala
    const handleChangeFileTanggalKenaikanGajiBerkala = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('gambar tidak boleh lebih dari 10 MB')
            setFormData2({ ...formData2, file_tanggal_kenaikan_gaji_berkala: "" })
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat jpg, jpeg, atau png')
        } else {
            setFileTanggalKenaikanGajiBerkalaPath(e.target.files[0].name)
            setFormData2({ ...formData2, file_tanggal_kenaikan_gaji_berkala: e.target.files[0] })
        }
    }

    // Handle File Contract
    const handleChangeFileContract = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['pdf'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('file tidak boleh lebih dari 10 MB')
            setFormData1({ ...formData1, file_contract: "" })
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat .pdf')
        } else {
            setNamePathContract(e.target.files[0].name)
            setFormData1({ ...formData1, file_contract: e.target.files[0] })
        }
    }
    // Handle File Tenaga Pendukung
    const handleChangeFileUnggahSPKTenagaPendukung = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['pdf'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('file tidak boleh lebih dari 10 MB')
            setFormData1({ ...formData1, file_spk_tenaga_pendukung: "" })
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat .pdf')
        } else {
            setNamePathUnggahSPKTenagaPendukung(e.target.files[0].name)
            setFormData1({ ...formData1, file_spk_tenaga_pendukung: e.target.files[0] })
        }
    }
    // Handle File Tenaga Pendukung
    const handleChangeFileUnggahSKPangkatGolonganTerakhir = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['pdf'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('file tidak boleh lebih dari 10 MB')
            setFormData1({ ...formData1, file_sk_pangkat_golongan_terakhir: "" })
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat .pdf')
        } else {
            setNamePathUnggahSKPangkatGolonganTerakhir(e.target.files[0].name)
            setFormData1({ ...formData1, file_sk_pangkat_golongan_terakhir: e.target.files[0] })
        }
    }
    //Handle Image
    const handleViewImage = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('gambar harus < 2MB')
            // setUploadImage(null)
            setFormData({ ...formData, image: null })
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus jpg, jpeg, atau png')
        } else {
            setViewImage(URL.createObjectURL(e.target.files[0]))
            setFormData({ ...formData, image: e.target.files[0] })
            // const reader = new FileReader()
            //     reader.readAsDataURL(file)
            //     reader.onload = () => {
            //     setUploadImage(reader.result)
            // };
            // setUploadImage(e.target.files[0])
        }
    }

    const generateDate = (e) => {
        handleChange("tanggal_kenaikan_gaji_berkala", e)
        const date = moment(e);
        const newDate = date.add(5, 'years').format('YYYY-MM-DD');
        setTanggalKenaikanGajiBerkalaBerikutnya(newDate)
    };
    const [dataJobGrade, setDataJobGrade] = useState([])
    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [dataUnitKerjaAtasan, setDataUnitKerjaAtasan] = useState([])
    const [dataUnitKerjaInduk, setDataUnitKerjaInduk] = useState([])
    const [dataJobPosition, setDataJobPosition] = useState([])
    const [dataJobCategory, setDataJobCategory] = useState([])
    const [dataJobLevel, setDataJobLevel] = useState([])
    const [dataSubsidiary, setDataSubsidiary] = useState([])
    const [dataPtkpStatus, setDataPtkpStatus] = useState([])
    const [dataIncome, setDataIncome] = useState([])
    const [dataDeduction, setDataDeduction] = useState([])
    const [dataJkk, setDataJkk] = useState([])

    const getJobGrade = async () => {
        try {
            const response = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            setDataJobGrade(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getJobLevel = async () => {
        try {
            const response = await Api.GetJobLevelDropdown(localStorage.getItem('hris-token'))
            setDataJobLevel(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getJobPosition = async () => {
        try {
            const response = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'), formData1.unit_kerja)
            const data = response.data.results.data
            setDataJobPosition(data)
        } catch (error) {
            console.log(error)
        }
    }
    const getJobCategory = async () => {
        try {
            const response = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
            setDataJobCategory(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getDataPtkpStatus = async () => {
        try {
            const response = await Api.GetPtkpStatus(localStorage.getItem('hris-token'))
            setDataPtkpStatus(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getDataIncome = async () => {
        try {
            const response = await Api.GetIncome(localStorage.getItem('hris-token'))
            setDataIncome(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getDataDeduction = async () => {
        try {
            const response = await Api.GetDeduction(localStorage.getItem('hris-token'))
            setDataDeduction(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getDataJkk = async () => {
        try {
            const response = await Api.GetJKK(localStorage.getItem('hris-token'))
            setDataJkk(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getPenempatan = async () => {
        try {
            const response = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            const data = response.data.results.data
            const formattedData = data.map(item => ({ value: item.id, label: item.company_name }));
            setDataSubsidiary(formattedData)
        } catch (error) {
            console.log(error)
        }
    }
    const getUnitKerja = async () => {
        try {
            const response = await Api.GetUnitKerjaDropdown(localStorage.getItem('hris-token'), formData1.subsidiary)
            const item = response.data.results
            const formattedData = item.map(item => ({ value: item.id, label: item.name }));
            setDataUnitKerja(formattedData)
        } catch (error) {
            console.log(error)
        }
    }
    const [subSidiaryAtasanLangsung, setSubsidiaryAtasanLangsung] = useState([])
    const getUnitKerjaAtasan = async () => {
        try {
            const response = await Api.GetUnitKerjaDropdown(localStorage.getItem('hris-token'), subSidiaryAtasanLangsung)
            const item = response.data.results
            const formattedData = item.map(item => ({ value: item.id, label: item.name }));
            setDataUnitKerjaAtasan(formattedData)
        } catch (error) {
            console.log(error)
        }
    }
    const [subSidiaryInduk, setSubsidiaryInduk] = useState([])
    const getUnitKerjaInduk = async () => {
        try {
            const response = await Api.GetUnitKerjaDropdown(localStorage.getItem('hris-token'), subSidiaryInduk)
            const item = response.data.results
            const formattedData = item.map(item => ({ value: item.id, label: item.name }));
            setDataUnitKerjaInduk(formattedData)
        } catch (error) {
            console.log(error)
        }
    }

    const [confirmPassword, setConfirmPassword] = useState("")
    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (password.length < minLength) {
            return 'Kata Sandi harus terdiri dari minimal 8 karakter'
        } else if (!hasUpperCase) {
            return 'Kata Sandi harus mengandung setidaknya satu huruf besar'
        } else if (!hasLowerCase) {
            return 'Kata Sandi harus mengandung setidaknya satu huruf kecil'
        } else if (!hasNumber) {
            return 'Kata Sandi harus mengandung setidaknya satu angka'
        } else if (!hasSpecialChar) {
            return 'Kata Sandi harus mengandung setidaknya satu karakter spesial'
        } else {
            return ''
        }
    };

    const [formData, setFormData] = useState({
        "image": "",
        "fullname": "",
        "gelar_depan": "",
        "gelar_belakang": "",
        "nomor_kk": "",
        "id_number": "",
        "tempat_lahir": "",
        "birthdate": "",
        "agama": "",
        "marital_status": "",
        "gender": "",
        "sign": "",
        "street": "",
        "province": "",
        "city": "",
        "kecamatan": "",
        "postal_code": "",

        "email_pribadi": "",
        "email_instansi": "",
        "mobile_phone": "",
        "phone": "",
        "email": "",
        "password": "",
        "isVerified": "",
    })
    const [formData1, setFormData1] = useState({
        "employee_id": "",
        "pnsId": "",
        "tanggal_masuk_ombudsman": "",

        "subsidiary": "",
        "unit_kerja": "",
        "unit_kerja_atasan_langsung": "",
        "unit_kerja_induk": "",

        "job_grade": "",
        "job_level": "",
        "job_position": "",
        "job_category": "",

        "tmt_jabatan": "",
        "kedudukan_hukum": "",
        "batas_usia_pensiun": "",
        "contract_start_date": "",
        "contract_end_date": "",
        "file_contract": "",

        "status_calon": "",
        "nomor_sk_casn": "",
        "tanggal_sk_casn": "",
        "tmt_casn": "",
        "nomor_sk_asn": "",
        "tanggal_sk_asn": "",
        "tmt_asn": "",
        "nomor_sk_calon_asisten": "",
        "tanggal_sk_calon_asisten": "",
        "tmt_calon_asisten": "",
        "nomor_sk_asisten": "",
        "tanggal_sk_asisten": "",
        "tmt_asisten": "",
        "nomor_sk_anggota_ombudsman": "",
        "tanggal_sk_anggota_ombudsman": "",
        "tmt_anggota_ombudsman": "",
        "nomor_sk_kepala_perwakilan_periode_1": "",
        "tanggal_sk_kepala_perwakilan_periode_1": "",
        "tmt_kepala_perwakilan_periode_1": "",
        "nomor_sk_kepala_perwakilan_periode_2": "",
        "tanggal_sk_kepala_perwakilan_periode_2": "",
        "tmt_kepala_perwakilan_periode_2": "",
        "nomor_spk_tenaga_pendukung": "",
        "tanggal_mulai_spk_tenaga_pendukung": "",
        "tanggal_akhir_spk_tenaga_pendukung": "",
        "file_spk_tenaga_pendukung": "",
        "pangkat_golongan_awal": "",
        "tmt_pangkat_golongan_awal": "",
        "pangkat_golongan_akhir": "",
        "tmt_pangkat_golongan_akhir": "",
        "file_sk_pangkat_golongan_terakhir": "",

        "pendidikan_tertinggi": "",
        "program_studi": "",
        "institusi": "",
        "tahun_lulus": "",
        "rumpun_pendidikan": "",

        "kontak_keluarga": "",
        "nama_keluarga": "",
        "nomor_hp_keluarga": "",
        "address": "",

    })
    const [formData2, setFormData2] = useState({
        "basic_salary": "",
        "tanggal_kenaikan_gaji_berkala": "",
        "file_tanggal_kenaikan_gaji_berkala": "",

        "bank_name": "",
        "account_name": "",
        "account_number": "",

        "nama_kppn": "",
        "npwp": "",
        "ptkp_status": "",
        "tax_configuration": "",

        "npp_bpjs_ketenagakerjaan": "",
        "bpjs_kesehatan": "",
        "bpjs_ketenagakerjaan": "",
        "nomor_taspen": "",
        "nomor_jasindo": "",
    })

    const tanggalMasukOmbudsmanMoment = moment(formData1.tanggal_masuk_ombudsman, 'YYYY-MM-DD');
    const hariIni = moment();
    // Hitung perbedaan dalam tahun, bulan, dan hari
    const tanggalMasukOmbudsmanYear = hariIni.diff(tanggalMasukOmbudsmanMoment, 'years');
    tanggalMasukOmbudsmanMoment.add(tanggalMasukOmbudsmanYear, 'years'); // Majukan tanggal masuk sebanyak tahun yang telah dihitung
    const tanggalMasukOmbudsmanMonth = hariIni.diff(tanggalMasukOmbudsmanMoment, 'months');

    const handleChange = (name, value) => {
        if (index === 0) {
            setFormData({
                ...formData,
                [name]: value,
            })
        } else if (index === 1) {
            setFormData1({
                ...formData1,
                [name]: value,
            })
        } else if (index === 2) {
            setFormData2({
                ...formData2,
                [name]: value,
            })
        }

    };

    const handleNext = (step) => {
        // console.log(formData1)
        if (index === 0) {
            const errors = [];
            const validate_password = validatePassword(formData?.password)
            if (!formData.image) { errors.push("Foto Wajib Diisi!") }
            else if (!formData.fullname || !formData?.fullname?.trim()) { errors.push("Nama Lengkap Wajib Diisi!") }
            else if (!formData.nomor_kk || !formData?.nomor_kk?.trim()) { errors.push("Nomor Kartu Keluarga Wajib Diisi!") }
            else if (!formData.id_number || !formData?.id_number?.trim()) { errors.push("Nomor ID (KTP) Wajib Diisi!") }
            else if (!formData.tempat_lahir || !formData?.tempat_lahir?.trim()) { errors.push("Tempat Lahir Wajib Diisi!") }
            else if (!formData.birthdate || !formData?.birthdate?.trim()) { errors.push("Tanggal Lahir Wajib Diisi!") }
            else if (!formData.agama || !formData?.agama?.trim()) { errors.push("Agama Wajib Diisi!") }
            else if (!formData.marital_status || !formData?.marital_status?.trim()) { errors.push("Status Perkawinan Wajib Diisi!") }
            else if (!formData.gender || !formData?.gender?.trim()) { errors.push("Jenis Kelamin Wajib Diisi!") }
            else if (!formData.street || !formData?.street?.trim()) { errors.push("Alamat Wajib Diisi!") }
            else if (!formData.province || !formData?.province?.trim()) { errors.push("Provinsi Wajib Diisi!") }
            else if (!formData.city || !formData?.city?.trim()) { errors.push("Kabupaten/Kota Wajib Diisi!") }
            else if (!formData.kecamatan || !formData?.kecamatan?.trim()) { errors.push("Kecamatan Wajib Diisi!") }
            else if (!formData.postal_code || !formData?.postal_code?.trim()) { errors.push("Kode Pos Wajib Diisi!") }
            else if (!formData.email_pribadi || !formData?.email_pribadi?.trim()) { errors.push("Email Pribadi Wajib Diisi!") }
            else if (!formData.email_instansi || !formData?.email_instansi?.trim()) { errors.push("Email Instansi Wajib Diisi!") }
            else if (!formData.mobile_phone || !formData?.mobile_phone?.trim()) { errors.push("Nomor Handphone Wajib Diisi!") }
            else if (!formData.email || !formData?.email?.trim()) { errors.push("Email Wajib Diisi!") }
            else if (!formData.password || !formData?.password?.trim()) {
                errors.push("Kata Sandi Wajib Diisi!")
            } else if (formData.password !== confirmPassword) {
                errors.push("Konfirmasi Kata Sandi Tidak Sesuai!")
            } else if (validate_password !== "") {
                errors.push(validate_password)
            }
            else if (!formData.isVerified || !formData?.isVerified?.trim()) { errors.push("Status Akun Wajib Diisi!") }

            if (errors.length > 0) {
                errors.map((err) => {
                    toast.error(err)
                })
            } else {
                setIndex(step)
            }
        } else if (index === 1) {
            const errors = [];
            if (!formData1.employee_id || !formData1.employee_id.trim()) { errors.push("Nomor ID Pegawai/Nomor Induk Pegawai Wajib Diisi!") }
            if (!formData1.tanggal_masuk_ombudsman || !formData1?.tanggal_masuk_ombudsman?.trim()) { errors.push("Tanggal Masuk Ombudsman Wajib Diisi!") }
            if (!formData1.subsidiary || !formData1?.subsidiary?.trim()) { errors.push("Penempatan Unit Kerja Wajib Diisi!") }
            if (!formData1.unit_kerja || !formData1?.unit_kerja?.trim()) { errors.push("Unit Kerja Wajib Diisi!") }
            if (!formData1.job_category || !formData1?.job_category?.trim()) { errors.push("Jenis Kepegawaian Wajib Diisi!") }
            if (!formData1.job_position || !formData1?.job_position?.trim()) { errors.push("Jabatan Wajib Diisi!") }
            if (!formData1.tmt_jabatan || !formData1?.tmt_jabatan?.trim()) { errors.push("TMT Jabatan Wajib Diisi!") }
            if (!formData1.kedudukan_hukum || !formData1?.kedudukan_hukum?.trim()) { errors.push("Status Kepegawaian/Kedudukan Hukum Wajib Diisi!") }
            if (!formData1.pendidikan_tertinggi || !formData1?.pendidikan_tertinggi?.trim()) { errors.push("Tingkat Pendidikan Tertinggi Wajib Diisi!") }
            if (!formData1.program_studi || !formData1?.program_studi?.trim()) { errors.push("Nama Program Studi Wajib Diisi!") }
            if (!formData1.institusi || !formData1?.institusi?.trim()) { errors.push("Nama Institusi Pendidikan Wajib Diisi!") }
            if (!formData1.tahun_lulus || !formData1?.tahun_lulus?.trim()) { errors.push("Tahun Lulus Wajib Diisi!") }
            if (errors.length > 0) {
                errors.map((err) => {
                    toast.error(err)
                })
            } else {
                setIndex(step)
            }
        }
    }
    useEffect(() => {
        getJobGrade()
        getJobLevel()
        getJobCategory()
        getDataPtkpStatus()
        getDataIncome()
        getDataDeduction()
        getDataJkk()
        getPenempatan()
    }, [index])

    useEffect(() => {
        getPenempatan()
        getUnitKerja()
        getJobPosition()
        getUnitKerjaAtasan()
        getUnitKerjaInduk()
    }, [formData1.subsidiary, formData1.unit_kerja, subSidiaryAtasanLangsung, subSidiaryInduk])
    const [isLoading, setIsLoading] = useState(false)
    if (isLoading) {
        return (
            <div className='bg-[#F8F9FB] min-h-screen content-center w-full text-center pt-52 flex-row justify-center items-center'>
                <div className="text-center space-y-2">
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin dark:text-red-thick fill-blue-medium" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p>Memuat Data...</p>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Tambah Pegawai'} LinkBack={'/employee'} />
                    <h1 className="text-zinc-800 text-xl font-semibold">Tambah Pegawai</h1>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>

                        <div className='flex px-3 justify-around items-center'>
                            <div className='flex gap-[16px] items-center '>
                                <div className={`w-[42px] h-[42px] rounded-full flex items-center justify-center text-white text-xl font-semibold ${index === 0 ? 'bg-red-thick' : 'bg-base-green'} `}>1</div>
                                <h1 className={`${index === 0 ? 'text-red-900' : 'text-base-green'} text-base font-semibold`}>Personal</h1>
                            </div>
                            <hr className={`${index === 0 ? 'border-grey-extra-thin border-dashed' : 'border-base-green border'} border-2  w-[158px]`} />
                            <div className='flex gap-[16px] items-center'>
                                <div className={`w-[42px] h-[42px] rounded-full flex items-center justify-center text-white text-xl font-semibold ${index === 1 ? 'bg-red-thick' : index === 2 ? 'bg-base-green' : 'bg-grey-extra-thin'}`}>2</div>
                                <h1 className={`${index === 1 ? 'text-red-900' : index === 2 ? 'text-base-green' : 'text-grey-extra-thin'} text-base font-semibold`}>Data Pegawai</h1>
                            </div>
                            <hr className={`${index === 1 ? 'border-grey-extra-thin border-dashed' : index === 2 ? 'border-base-green border' : 'border-grey-extra-thin border-dashed'} border-2 border-grey-extra-thin w-[158px]  `} />
                            <div className='flex gap-[16px] items-center'>
                                <div className={`w-[42px] h-[42px] rounded-full flex items-center justify-center text-white text-xl font-semibold ${index === 2 ? 'bg-red-thick' : 'bg-grey-extra-thin'} `}>3</div>
                                <h1 className={`${index === 2 ? 'text-red-900' : 'text-gray-400'} text-base font-semibold`}>Gaji</h1>
                            </div>
                        </div>

                        {index === 0 ? (
                            <div className='space-y-[20px]'>
                                <h1 className="text-black text-xl font-medium mb-[28px]">Data Personal</h1>

                                <div className='space-y-[20px]'>

                                    <div className='flex gap-[14px] mb-[28px]'>
                                        <h1 className='text-gray-500 text-[11px] font-medium'>Foto <span className='text-red-primer'>*</span></h1>
                                        <label htmlFor='upload-image'>
                                            <div className='w-[87px] h-[87px] rounded-full bg-[#D9D9D9] bg-cover shadow-md border' style={{ backgroundImage: `url(${viewImage})` }}>
                                                {!viewImage &&
                                                    <div className='flex flex-col justify-center items-center space-y-3 h-full'>
                                                        <img src={IconInsertPhoto} alt='Insert Humanusia' className='object-contain' />
                                                    </div>
                                                }
                                            </div>
                                            <input type='file' className='hidden' id='upload-image' accept=".jpeg, .jpg, .png" onChange={(e) => handleViewImage(e)} />
                                        </label>
                                    </div>

                                    <div>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nama Lengkap <span className='text-red-primer'>*</span></h2>
                                        <input type="text" onChange={(e) => handleChange("fullname", e.target.value)} value={formData.fullname} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nama Lengkap...' />
                                    </div>
                                    <div className='flex items-center gap-[20px]'>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px] w-full'>Gelar Depan</h2>
                                            <input type="text" onChange={(e) => handleChange("gelar_depan", e.target.value)} value={formData.gelar_depan} className='border pr-2 rounded-md py-[10px] px-[20px] w-full text-grey-thick text-[12px]' placeholder='Masukkan Gelar Depan...' />
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px] w-full'>Gelar Belakang</h2>
                                            <input type="text" onChange={(e) => handleChange("gelar_belakang", e.target.value)} value={formData.gelar_belakang} className='border pr-2 rounded-md py-[10px] px-[20px] w-full text-grey-thick text-[12px]' placeholder='Masukkan Gelar Belakang...' />
                                        </div>
                                    </div>

                                    <div className='flex items-center gap-[20px]'>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor Kartu Keluarga <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("nomor_kk", e.target.value)} value={formData.nomor_kk} type="number" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor Kartu Keluarga...' />
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor ID (KTP) <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("id_number", e.target.value)} value={formData.id_number} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor ID...' />
                                        </div>
                                    </div>

                                    <div className='flex items-center gap-[20px]'>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tempat Lahir <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("tempat_lahir", e.target.value)} value={formData.tempat_lahir} type="text" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='Masukkan Tempat Lahir...' />
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal Lahir <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("birthdate", e.target.value)} value={formData.birthdate} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='Masukkan Tanggal Lahir...' />
                                        </div>
                                    </div>

                                    <div className='flex gap-[20px] mb-[12px] w-full'>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Agama <span className='text-red-primer'>*</span></h2>
                                            <div className='relative'>
                                                <select onChange={(e) => handleChange("agama", e.target.value)} value={formData.agama} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                    <option selected disabled value={''}>Pilih Agama</option>
                                                    <option value={'Islam'}>Islam</option>
                                                    <option value={'Kristen'}>Kristen</option>
                                                    <option value={'Katolik'}>Katolik</option>
                                                    <option value={'Hindu'}>Hindu</option>
                                                    <option value={'Budha'}>Budha</option>
                                                    <option value={'Khonghucu'}>Khonghucu</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Status Perkawinan <span className='text-red-primer'>*</span></h2>
                                            <div className='relative'>
                                                <select onChange={(e) => handleChange("marital_status", e.target.value)} value={formData.marital_status} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                    <option selected disabled value={''}>Pilih Status Perkawinan</option>
                                                    <option value={'Belum Kawin'}>Belum Kawin</option>
                                                    <option value={'Kawin'}>Kawin</option>
                                                    <option value={'Cerai Hidup'}>Cerai Hidup</option>
                                                    <option value={'Cerai Mati'}>Cerai Mati</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='text-grey-thick text-[11px] font-medium'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-2'>Jenis Kelamin <span className='text-red-primer'>*</span></h2>
                                        <div className="flex gap-[28px]">
                                            <div className="flex items-center mb-5">
                                                <input onChange={(e) => handleChange("gender", e.target.value)} checked={formData.gender === "Laki-Laki"} id="default-radio-1" type="radio" value="Laki-Laki" name="default-radio" className="w-4 h-4 bg-gray-100 border-gray-300" />
                                                <label htmlFor="default-radio-1" className="ml-[21.13px] text-grey-thick text-[12px] font-medium">Laki-Laki</label>
                                            </div>
                                            <div className="flex items-center mb-5">
                                                <input onChange={(e) => handleChange("gender", e.target.value)} checked={formData.gender === "Perempuan"} id="default-radio-2" type="radio" value="Perempuan" name="default-radio" className="w-4 h-4 bg-gray-100 border-gray-300" />
                                                <label htmlFor="default-radio-2" className="ml-[21.13px] text-grey-thick text-[12px] font-medium">Perempuan</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mb-[28px]'>
                                        <h1 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanda Tangan</h1>
                                        <div className='flex items-center gap-[13px]'>
                                            <label htmlFor='upload-file' className='cursor-pointer'>
                                                <div className='flex w-[473px] h-[35px] bg-gray-300 rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                                    <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                                        <div className="text-center text-sky-950 text-[9px] font-medium">Cari</div>
                                                    </div>
                                                    <h1 className='text-zinc-700 text-xs font-medium'>{namepath ? namepath : 'Tidak ada file yang dipilih'}</h1>
                                                    <input type='file' id='upload-file' accept=".jpeg, .jpg, .png" onChange={(e) => handleChangeFileSign(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                                </div>
                                            </label>
                                        </div>
                                        <h1 className=" text-red-600 text-[8px] font-normal">* Maks 500 KB</h1>
                                    </div>
                                </div>

                                <div className='space-y-[20px]'>
                                    <h1 className="text-black text-xl font-medium mb-[24px]">Detail Kontak</h1>

                                    <div className='flex gap-[20px] item-center w-full'>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Alamat <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("street", e.target.value)} value={formData.street} type="text" placeholder='Masukkan Alamat...' className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' />
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Provinsi <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("province", e.target.value)} value={formData.province} type={'text'} placeholder='Masukkan Provinsi...' className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} />
                                        </div>
                                    </div>

                                    <div className="flex gap-[22px]">
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kabupaten/Kota <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("city", e.target.value)} value={formData.city} type={'text'} placeholder='Masukkan Kabupaten/Kota...' className={`bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} />
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kecamatan <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("kecamatan", e.target.value)} value={formData.kecamatan} type="text" placeholder='Masukkan Kecamatan...' className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' />
                                        </div>
                                    </div>

                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kode Pos <span className='text-red-primer'>*</span></h2>
                                        <input onChange={(e) => handleChange("postal_code", e.target.value)} value={formData.postal_code} type="number" placeholder='Masukkan Kode Pos...' className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' />
                                    </div>

                                    <div className="flex gap-[20px] items-center w-full">
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Email Pribadi <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("email_pribadi", e.target.value)} value={formData.email_pribadi} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Email Pribadi...' />
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Email Instansi <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("email_instansi", e.target.value)} value={formData.email_instansi} type="text" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Email Instansi...' />
                                        </div>
                                    </div>

                                    <div className="flex gap-[20px] items-center w-full">
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor Handphone <span className='text-red-primer'>*</span></h2>
                                            <input onChange={(e) => handleChange("mobile_phone", e.target.value)} value={formData.mobile_phone} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor Handphone...' />
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Telepone</h2>
                                            <input onChange={(e) => handleChange("phone", e.target.value)} value={formData.phone} type="number" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Telepone...' />
                                        </div>
                                    </div>

                                    <hr className='border-base-outline mb-[29.5px] border-2' />


                                    <div className="text-black text-xl font-medium mb-[24px]">Detail Akun</div>

                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Email <span className='text-red-primer'>*</span></h2>
                                        <input onChange={(e) => handleChange("email", e.target.value)} value={formData.email} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Input Email...' />
                                    </div>

                                    <div className='flex items-center gap-[20px]'>
                                        <div className="flex gap-[22px] w-full">
                                            <div className='w-full'>
                                                <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kata Sandi <span className='text-red-primer'>*</span></h2>
                                                <input onChange={(e) => handleChange("password", e.target.value)} value={formData.password} type="password" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Kata Sandi...' />
                                            </div>
                                        </div>
                                        <div className="flex gap-[22px] w-full">
                                            <div className='w-full'>
                                                <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Konfirmasi Kata Sandi <span className='text-red-primer'>*</span></h2>
                                                <input onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} type='password' className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Konfirmasi Kata Sandi...' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Status Akun <span className='text-red-primer'>*</span></h2>
                                        <div className='relative w-full'>
                                            <select onChange={(e) => handleChange("isVerified", e.target.value)} value={formData.isVerified} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Pilih Status Akun....</option>
                                                <option value={true}>Aktif</option>
                                                <option value={false}>Tidak Aktif</option>

                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                </div>

                                <div className='flex gap-2 justify-end'>
                                    <button
                                        onClick={() => {
                                            handleNext(1)
                                            // console.log("next")
                                        }}
                                        className='bg-[#0E5073] text-[14px] font-medium py-[10px] px-[25px] text-white rounded-md'>Selanjutnya</button>
                                </div>
                            </div>
                        ) : index === 1 ? (
                            <div className='space-y-[20px]'>
                                <h1 className="text-black text-xl font-medium mb-[28px]">Data Pegawai</h1>

                                <div className='flex items-center gap-[20px]'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor ID Pegawai/Nomor Induk Pegawai <span className='text-red-primer'>*</span></h2>
                                        <input type="number" onChange={(e) => handleChange("employee_id", e.target.value)} value={formData1.employee_id} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor ID Pegawai......' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>PNS ID</h2>
                                        <input type="number" onChange={(e) => handleChange("pnsId", e.target.value)} value={formData1.pnsId} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan PNS ID......' />
                                    </div>
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal Masuk Ombudsman <span className='text-red-primer'>*</span></h2>
                                        <input onChange={(e) => handleChange("tanggal_masuk_ombudsman", e.target.value)} value={formData1.tanggal_masuk_ombudsman} type='date' className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Lama Masuk Ombudsman <span className='text-red-primer'>*</span></h2>
                                        <div className='flex items-center gap-[7px]'>
                                            <div className='flex items-center w-full mb-[20px]'>
                                                <input type="text" disabled value={isNaN(tanggalMasukOmbudsmanYear) ? "-" : tanggalMasukOmbudsmanYear} className='border-l border-t border-b pr-2 rounded-l-md py-[10px] px-[20px] text-grey-thick w-full h-[35px] text-[12px]' placeholder='Jumlah Tahun...' />
                                                <div className='bg-[#F1F1F1] border-r border-t border-b rounded-r-md py-[10px] px-[20px] text-grey-thick h-[35px] text-[12px]'>
                                                    <h2>Tahun</h2>
                                                </div>
                                            </div>
                                            <div className='flex items-center w-full mb-[20px]'>
                                                <input type="text" disabled value={isNaN(tanggalMasukOmbudsmanMonth) ? "-" : tanggalMasukOmbudsmanMonth} className='border-l border-t border-b pr-2 rounded-l-md py-[10px] px-[20px] text-grey-thick w-full h-[35px] text-[12px]' placeholder='Jumlah Bulan...' />
                                                <div className='bg-[#F1F1F1] border-r border-t border-b rounded-r-md py-[10px] px-[20px] text-grey-thick h-[35px] text-[12px]'>
                                                    <h2>Bulan</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border-2' />
                                <h1 className="text-zinc-800 text-xl font-semibold">Data Pekerjaan</h1>

                                <div className='grid grid-cols-2 gap-[21px] items-end'>
                                    <div className='w-full'>
                                        <InputOption data={dataSubsidiary} label={'Penempatan Unit Kerja'} value={formData1.subsidiary} onChange={(e) => handleChange("subsidiary", e)} required={true} />
                                    </div>
                                    <div className='w-full'>
                                        <InputOption data={dataUnitKerja} label={'Unit Kerja'} value={formData1.unit_kerja} onChange={(e) => handleChange("unit_kerja", e)} required={true} />
                                    </div>
                                    <div className='w-full'>
                                        <InputOption data={dataSubsidiary} label={'Penempatan Unit Kerja Atasan Langsung'} value={subSidiaryAtasanLangsung} onChange={(e) => setSubsidiaryAtasanLangsung(e)} required={true} />
                                    </div>
                                    <div className='w-full'>
                                        <InputOption data={dataUnitKerjaAtasan} label={'Unit Kerja Atasan Langsung'} value={formData1.unit_kerja_atasan_langsung} onChange={(e) => handleChange("unit_kerja_atasan_langsung", e)} required={true} />
                                    </div>
                                    <div className='w-full'>
                                        <InputOption data={dataSubsidiary} label={'Penempatan Unit Kerja Eselon II/ Keasistenan Utama/ Perwakilan'} value={subSidiaryInduk} onChange={(e) => setSubsidiaryInduk(e)} required={true} />
                                    </div>
                                    <div className='w-full'>
                                        <InputOption data={dataUnitKerjaInduk} label={'Unit Kerja Eselon II/ Keasistenan Utama/ Perwakilan'} value={formData1.unit_kerja_induk} onChange={(e) => handleChange("unit_kerja_induk", e)} required={true} />
                                    </div>
                                    <div className='w-full'>
                                        <InputComponent label={'Instansi Induk '} value={'Ombudsman'} disabled={true} type={"text"} />
                                    </div>
                                </div>
                                <div className='flex gap-[20px] mb-[21.5px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kelas Jabatan<span className='text-red-primer'>*</span></h2>
                                        <div className='relative'>
                                            <select onChange={(e) => handleChange("job_grade", e.target.value)} value={formData1.job_grade} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Pilih Kelas Jabatan...</option>
                                                {dataJobGrade.map((item, idx) => {
                                                    return (
                                                        <option key={idx} value={item.id}>{item.job_grade}</option>
                                                    )
                                                })}
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Jenis Jabatan<span className='text-red-primer'>*</span></h2>
                                        <div className='relative w-full'>
                                            <select onChange={(e) => handleChange("job_level", e.target.value)} value={formData1.job_level} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Pilih Jenis Jabatan...</option>
                                                {dataJobLevel.map((item, idx) => {
                                                    return (
                                                        <option key={idx} value={item.id}>{item.level_name}</option>
                                                    )
                                                })}
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex gap-[20px] mb-[21.5px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Jenis Kepegawaian<span className='text-red-primer'>*</span></h2>
                                        <div className='relative w-full'>
                                            <select onChange={(e) => handleChange("job_category", e.target.value)} value={formData1.job_category} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Select Jenis Kepegawaian...</option>
                                                {dataJobCategory.map((item, idx) => {
                                                    return (
                                                        <option key={idx} value={item.id}>{item.category_name}</option>
                                                    )
                                                })}
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Jabatan<span className='text-red-primer'>*</span></h2>
                                        <div className='relative w-full'>
                                            <select onChange={(e) => handleChange("job_position", e.target.value)} value={formData1.job_position} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Select Jabatan...</option>
                                                {dataJobPosition.map((item, idx) => {
                                                    return (
                                                        <option key={idx} value={item.id}>{item.position_name}</option>
                                                    )
                                                })}
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT Jabatan <span className='text-red-primer'>*</span></h2>
                                    <input onChange={(e) => handleChange("tmt_jabatan", e.target.value)} value={formData1.tmt_jabatan} type='date' className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                </div>

                                <div className='w-full mb-[20px]'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Status Pegawai/ Kedudukan Hukum <span className='text-red-primer'>*</span></h2>
                                    <div className='relative w-full'>
                                        <select onChange={(e) => handleChange("kedudukan_hukum", e.target.value)} value={formData1.kedudukan_hukum} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Pilih Status Pegawai...</option>
                                            <option value={'Aktif'}>Aktif</option>
                                            <option value={'Tidak Aktif'}>Tidak Aktif</option>
                                            <option value={'Cuti di Luar Tanggungan Negara'}>Cuti di Luar Tanggungan Negara</option>
                                            <option value={'Tugas Belajar'}>Tugas Belajar</option>
                                            <option value={'Pindah Keluar Instansi'}>Pindah Keluar Instansi</option>
                                            <option value={'Pensiun'}>Pensiun</option>
                                            <option value={'Meninggal Dunia'}>Meninggal Dunia</option>
                                            <option value={'Mengundurkan Diri'}>Mengundurkan Diri</option>
                                            <option value={'Tenaga Pendukung Kontrak'}>Tenaga Pendukung Kontrak</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Batas Usia Pensiun</h2>
                                    <input onChange={(e) => handleChange("batas_usia_pensiun", e.target.value)} value={formData1.batas_usia_pensiun} type='number' placeholder='Masukkan batas usia pensiun' className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal Mulai Kontrak</h2>
                                        <input onChange={(e) => handleChange("contract_start_date", e.target.value)} value={formData1.contract_start_date} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal Berakhir Kontrak</h2>
                                        <input onChange={(e) => handleChange("contract_end_date", e.target.value)} value={formData1.contract_end_date} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <div className='mb-[28px]'>
                                    <h1 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Lampiran Kontrak</h1>
                                    <div className='flex items-center gap-[13px]'>
                                        <label htmlFor='upload-file-FileContract' className='cursor-pointer'>
                                            <div className='flex w-[473px] h-[35px]  rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                                <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                                    <div className="text-center text-sky-950 text-[9px] font-medium">Cari</div>
                                                </div>
                                                <h1 className='text-zinc-700 text-xs font-medium'>{namepathContract ? namepathContract : 'No File Selected'}</h1>
                                                <input type='file' id='upload-file-FileContract' accept=".pdf"
                                                    onChange={(e) => handleChangeFileContract(e)}
                                                    className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                            </div>
                                        </label>
                                    </div>
                                    <h1 className=" text-red-600 text-[8px] font-normal">* Maks 500 KB</h1>
                                </div>

                                <h1 className="text-zinc-800 text-xl font-semibold">Data Calon</h1>

                                <div className='w-full mb-[20px]'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Status Calon</h2>
                                    <div className='relative w-full'>
                                        <select onChange={(e) => handleChange("status_calon", e.target.value)} value={formData1.status_calon} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Pilih Status Calon...</option>
                                            <option value={'CPNS'}>CPNS</option>
                                            <option value={'PNS'}>PNS</option>
                                            <option value={'Calon Asisten'}>Calon Asisten</option>
                                            <option value={'Asisten'}>Asisten</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor SK CASN</h2>
                                    <input type="number" onChange={(e) => handleChange("nomor_sk_casn", e.target.value)} value={formData1.nomor_sk_casn} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor SK CASN...' />
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal SK CASN</h2>
                                        <input onChange={(e) => handleChange("tanggal_sk_casn", e.target.value)} value={formData1.tanggal_sk_casn} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT CASN</h2>
                                        <input onChange={(e) => handleChange("tmt_casn", e.target.value)} value={formData1.tmt_casn} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border-2' />

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor SK ASN</h2>
                                    <input type="number" onChange={(e) => handleChange("nomor_sk_asn", e.target.value)} value={formData1.nomor_sk_asn} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor SK ASN...' />
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal SK ASN</h2>
                                        <input onChange={(e) => handleChange("tanggal_sk_asn", e.target.value)} value={formData1.tanggal_sk_asn} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT ASN</h2>
                                        <input onChange={(e) => handleChange("tmt_asn", e.target.value)} value={formData1.tmt_asn} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border-2' />

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor SK Calon Asisten</h2>
                                    <input type="number" onChange={(e) => handleChange("nomor_sk_calon_asisten", e.target.value)} value={formData1.nomor_sk_calon_asisten} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor SK Calon Asisten...' />
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal SK Calon Asisten</h2>
                                        <input onChange={(e) => handleChange("tanggal_sk_calon_asisten", e.target.value)} value={formData1.tanggal_sk_calon_asisten} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT Calon Asisten</h2>
                                        <input onChange={(e) => handleChange("tmt_calon_asisten", e.target.value)} value={formData1.tmt_calon_asisten} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border-2' />

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor SK Asisten</h2>
                                    <input type="number" onChange={(e) => handleChange("nomor_sk_asisten", e.target.value)} value={formData1.nomor_sk_asisten} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor SK Asisten...' />
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal SK Asisten</h2>
                                        <input onChange={(e) => handleChange("tanggal_sk_asisten", e.target.value)} value={formData1.tanggal_sk_asisten} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT Asisten</h2>
                                        <input onChange={(e) => handleChange("tmt_asisten", e.target.value)} value={formData1.tmt_asisten} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border-2' />

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor SK Anggota Ombudsman</h2>
                                    <input type="number" onChange={(e) => handleChange("nomor_sk_anggota_ombudsman", e.target.value)} value={formData1.nomor_sk_anggota_ombudsman} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor SK Anggota Ombudsman...' />
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal SK Anggota Ombudsman</h2>
                                        <input onChange={(e) => handleChange("tanggal_sk_anggota_ombudsman", e.target.value)} value={formData1.tanggal_sk_anggota_ombudsman} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT Anggota Ombudsman</h2>
                                        <input onChange={(e) => handleChange("tmt_anggota_ombudsman", e.target.value)} value={formData1.tmt_anggota_ombudsman} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border-2' />

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor SK Kepala Perwakilan Periode-1</h2>
                                    <input type="number" onChange={(e) => handleChange("nomor_sk_kepala_perwakilan_periode_1", e.target.value)} value={formData1.nomor_sk_kepala_perwakilan_periode_1} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor SK Kepala Perwakilan Periode-1...' />
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal SK Kepala Perwakilan Periode-1</h2>
                                        <input onChange={(e) => handleChange("tanggal_sk_kepala_perwakilan_periode_1", e.target.value)} value={formData1.tanggal_sk_kepala_perwakilan_periode_1} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT Kepala Perwakilan Periode-1</h2>
                                        <input onChange={(e) => handleChange("tmt_kepala_perwakilan_periode_1", e.target.value)} value={formData1.tmt_kepala_perwakilan_periode_1} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border-2' />

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor SK Kepala Perwakilan Periode-2</h2>
                                    <input type="number" onChange={(e) => handleChange("nomor_sk_kepala_perwakilan_periode_2", e.target.value)} value={formData1.nomor_sk_kepala_perwakilan_periode_2} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor SK Kepala Perwakilan Periode-2...' />
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal SK Kepala Perwakilan Periode-2</h2>
                                        <input onChange={(e) => handleChange("tanggal_sk_kepala_perwakilan_periode_2", e.target.value)} value={formData1.tanggal_sk_kepala_perwakilan_periode_2} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT Kepala Perwakilan Periode-2</h2>
                                        <input onChange={(e) => handleChange("tmt_kepala_perwakilan_periode_2", e.target.value)} value={formData1.tmt_kepala_perwakilan_periode_2} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border-2' />

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor SPK Tenaga Pendukung</h2>
                                    <input type="number" onChange={(e) => handleChange("nomor_spk_tenaga_pendukung", e.target.value)} value={formData1.nomor_spk_tenaga_pendukung} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Nomor SPK Tenaga Pendukung...' />
                                </div>

                                <div className='flex gap-[20px] w-full'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal Mulai SPK Tenaga Pendukung</h2>
                                        <input onChange={(e) => handleChange("tanggal_mulai_spk_tenaga_pendukung", e.target.value)} value={formData1.tanggal_mulai_spk_tenaga_pendukung} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal Akhir SPK Tenaga Pendukung</h2>
                                        <input onChange={(e) => handleChange("tanggal_akhir_spk_tenaga_pendukung", e.target.value)} value={formData1.tanggal_akhir_spk_tenaga_pendukung} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <div className='mb-[28px]'>
                                    <h1 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Unggah SPK Tenaga Pendukung</h1>
                                    <div className='flex items-center gap-[13px]'>
                                        <label htmlFor='upload-file-SPKTenagaPendukung' className='cursor-pointer'>
                                            <div className='flex w-[473px] h-[35px]  rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                                <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                                    <div className="text-center text-sky-950 text-[9px] font-medium">Cari</div>
                                                </div>
                                                <h1 className='text-zinc-700 text-xs font-medium'>{namepathUnggahSPKTenagaPendukung ? namepathUnggahSPKTenagaPendukung : 'No File Selected'}</h1>
                                                <input type='file' id='upload-file-SPKTenagaPendukung' accept=".pdf"
                                                    onChange={(e) => handleChangeFileUnggahSPKTenagaPendukung(e)}
                                                    className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                            </div>
                                        </label>
                                    </div>
                                    <h1 className=" text-red-600 text-[8px] font-normal">* Maks 500 KB</h1>
                                </div>

                                <h1 className="text-zinc-800 text-xl font-semibold">Data Golongan</h1>

                                <div className='flex items-center gap-[20px]'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Pangkat Golongan Awal</h2>
                                        <div className='relative w-full'>
                                            <select onChange={(e) => handleChange("pangkat_golongan_awal", e.target.value)} value={formData1.pangkat_golongan_awal} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Pilih Pangkat Golongan Awal...</option>
                                                <option value={'I / a'}>I / a</option>
                                                <option value={'I / b'}>I / b</option>
                                                <option value={'I / c'}>I / c</option>
                                                <option value={'I / d'}>I / d</option>
                                                <option value={'II / a'}>II / a</option>
                                                <option value={'II / b'}>II / b</option>
                                                <option value={'II / c'}>II / c</option>
                                                <option value={'II / d'}>II / d</option>
                                                <option value={'III / a'}>III / a</option>
                                                <option value={'III / b'}>III / b</option>
                                                <option value={'III / c'}>III / c</option>
                                                <option value={'III / d'}>III / d</option>
                                                <option value={'IV / a'}>IV / a</option>
                                                <option value={'IV / b'}>IV / b</option>
                                                <option value={'IV / c'}>IV / c</option>
                                                <option value={'IV / d'}>IV / d</option>
                                                <option value={'Pemula V'}>Pemula V</option>
                                                <option value={'Terampil VII'}>Terampil VII</option>
                                                <option value={'Mahir IX'}>Mahir IX</option>
                                                <option value={'Penyelia XI'}>Penyelia XI</option>
                                                <option value={'Ahli Pertama IX'}>Ahli Pertama IX</option>
                                                <option value={'Ahli Muda XI'}>Ahli Muda XI</option>
                                                <option value={'Ahli Madya XIII'}>Ahli Madya XIII</option>
                                                <option value={'Ahli Utama XVI'}>Ahli Utama XVI</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT Pangkat Golongan Awal</h2>
                                        <input onChange={(e) => handleChange("tmt_pangkat_golongan_awal", e.target.value)} value={formData1.tmt_pangkat_golongan_awal} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <div className='flex items-center gap-[20px]'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Pangkat Golongan Akhir</h2>
                                        <div className='relative w-full'>
                                            <select onChange={(e) => handleChange("pangkat_golongan_akhir", e.target.value)} value={formData1.pangkat_golongan_akhir} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Pilih Pangkat Golongan Akhir...</option>
                                                <option selected disabled value={''}>Pilih Pangkat Golongan Awal...</option>
                                                <option value={'I / a'}>I / a</option>
                                                <option value={'I / b'}>I / b</option>
                                                <option value={'I / c'}>I / c</option>
                                                <option value={'I / d'}>I / d</option>
                                                <option value={'II / a'}>II / a</option>
                                                <option value={'II / b'}>II / b</option>
                                                <option value={'II / c'}>II / c</option>
                                                <option value={'II / d'}>II / d</option>
                                                <option value={'III / a'}>III / a</option>
                                                <option value={'III / b'}>III / b</option>
                                                <option value={'III / c'}>III / c</option>
                                                <option value={'III / d'}>III / d</option>
                                                <option value={'IV / a'}>IV / a</option>
                                                <option value={'IV / b'}>IV / b</option>
                                                <option value={'IV / c'}>IV / c</option>
                                                <option value={'IV / d'}>IV / d</option>
                                                <option value={'Pemula V'}>Pemula V</option>
                                                <option value={'Terampil VII'}>Terampil VII</option>
                                                <option value={'Mahir IX'}>Mahir IX</option>
                                                <option value={'Penyelia XI'}>Penyelia XI</option>
                                                <option value={'Ahli Pertama IX'}>Ahli Pertama IX</option>
                                                <option value={'Ahli Muda XI'}>Ahli Muda XI</option>
                                                <option value={'Ahli Madya XIII'}>Ahli Madya XIII</option>
                                                <option value={'Ahli Utama XVI'}>Ahli Utama XVI</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>TMT Pangkat Golongan Akhir</h2>
                                        <input onChange={(e) => handleChange("tmt_pangkat_golongan_akhir", e.target.value)} value={formData1.tmt_pangkat_golongan_akhir} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' />
                                    </div>
                                </div>

                                <div>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Masa Kerja Golongan Akhir</h2>
                                    <div className='flex items-center gap-[30px]'>
                                        <div className='flex items-center w-[233px] mb-[20px]'>
                                            <input type="text" disabled value={''} className='border-l border-t border-b pr-2 rounded-l-md py-[10px] px-[20px] text-grey-thick w-[233px] h-[35px] text-[12px]' placeholder='Jumlah Tahun...' />
                                            <div className='bg-[#F1F1F1] border-r border-t border-b rounded-r-md py-[10px] px-[20px] text-grey-thick h-[35px] text-[12px]'>
                                                <h2>Tahun</h2>
                                            </div>
                                        </div>
                                        <div className='flex items-center w-[233px] mb-[20px]'>
                                            <input type="text" disabled value={''} className='border-l border-t border-b pr-2 rounded-l-md py-[10px] px-[20px] text-grey-thick w-[233px] h-[35px] text-[12px]' placeholder='Jumlah Bulan...' />
                                            <div className='bg-[#F1F1F1] border-r border-t border-b rounded-r-md py-[10px] px-[20px] text-grey-thick h-[35px] text-[12px]'>
                                                <h2>Bulan</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mb-[28px]'>
                                    <h1 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Unggah SK Pangkat Golongan Terakhir</h1>
                                    <div className='flex items-center gap-[13px]'>
                                        <label htmlFor='upload-file-SKPangkatGolonganTerakhir' className='cursor-pointer'>
                                            <div className='flex w-[473px] h-[35px]  rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                                <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                                    <div className="text-center text-sky-950 text-[9px] font-medium">Cari</div>
                                                </div>
                                                <h1 className='text-zinc-700 text-xs font-medium'>{namepathUnggahSKPangkatGolonganTerakhir ? namepathUnggahSKPangkatGolonganTerakhir : 'No File Selected'}</h1>
                                                <input type='file' id='upload-file-SKPangkatGolonganTerakhir' accept=".pdf"
                                                    onChange={(e) => handleChangeFileUnggahSKPangkatGolonganTerakhir(e)}
                                                    className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                            </div>
                                        </label>
                                    </div>
                                    <h1 className=" text-red-600 text-[8px] font-normal">* Maks 500 KB</h1>
                                </div>

                                <h1 className="text-zinc-800 text-xl font-semibold">Data Pendidikan</h1>

                                <div className='flex items-center gap-[20px]'>
                                    <div className='w-full mb-[20px]'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tingkat Pendidikan Tertinggi <span className='text-red-primer'>*</span></h2>
                                        <div className='relative w-full'>
                                            <select onChange={(e) => handleChange("pendidikan_tertinggi", e.target.value)} value={formData1.pendidikan_tertinggi} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Pilih Tingkat Pendidikan Tertinggi...</option>
                                                <option value={'SD'}>SD</option>
                                                <option value={'SMP'}>SMP</option>
                                                <option value={'SMA/SMK'}>SMA/SMK</option>
                                                <option value={'D1'}>D1</option>
                                                <option value={'D2'}>D2</option>
                                                <option value={'D3'}>D3</option>
                                                <option value={'D4'}>D4</option>
                                                <option value={'S1'}>S1</option>
                                                <option value={'S2'}>S2</option>
                                                <option value={'S3'}>S3</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-full mb-[20px]'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nama Program Studi <span className='text-red-primer'>*</span></h2>
                                        <input type="text" onChange={(e) => handleChange("program_studi", e.target.value)} value={formData1.program_studi} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Nama Program Studi...' />
                                    </div>
                                </div>

                                <div className='flex items-center gap-[20px]'>
                                    <div className='w-full mb-[20px]'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nama Institusi Pendidikan <span className='text-red-primer'>*</span></h2>
                                        <input type="text" onChange={(e) => handleChange("institusi", e.target.value)} value={formData1.institusi} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Nama Institusi Pendidikan...' />
                                    </div>
                                    <div className='w-full mb-[20px]'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tahun Lulus <span className='text-red-primer'>*</span></h2>
                                        <input type="number" onChange={(e) => handleChange("tahun_lulus", e.target.value)} value={formData1.tahun_lulus} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Tahun Lulus...' />
                                    </div>
                                </div>

                                <div className='w-full mb-[20px]'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Rumpun Pendidikan <span className='text-red-primer'>*</span></h2>
                                    <div className='relative w-full'>
                                        <select onChange={(e) => handleChange("rumpun_pendidikan", e.target.value)} value={formData1.rumpun_pendidikan} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Pilih Rumpun Pendidikan...</option>
                                            <option value={'Agama (Keyakinan tentang Ketuhanan serta teks suci agama)'}>Agama (Keyakinan tentang Ketuhanan serta teks suci agama)</option>
                                            <option value={'Humaniora (Nilai kemanusiaan dan pemikiran manusia)'}>Humaniora (Nilai kemanusiaan dan pemikiran manusia)</option>
                                            <option value={'Sosial (Hubungan antar manusia dan fenomena masyarakat)'}>Sosial (Hubungan antar manusia dan fenomena masyarakat)</option>
                                            <option value={'Alam (Alam semesta)'}>Alam (Alam semesta)</option>
                                            <option value={'Formal (Sistem formal teoritis)'}>Formal (Sistem formal teoritis)</option>
                                            <option value={'Terapan (Aplikasi ilmu bagi kehidupan manusia)'}>Terapan (Aplikasi ilmu bagi kehidupan manusia)</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>

                                <h1 className="text-black text-xl font-medium mb-[28px]">Kontak Darurat</h1>

                                <div className='flex items-center gap-[20px]'>
                                    <div className='w-full mb-[20px]'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Kontak Keluarga</h2>
                                        <div className='relative w-full'>
                                            <select onChange={(e) => handleChange("kontak_keluarga", e.target.value)} value={formData1.kontak_keluarga} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Pilih Kontak Keluarga...</option>
                                                <option value={'Ayah'}>Ayah</option>
                                                <option value={'Ibu'}>Ibu</option>
                                                <option value={'Suami'}>Suami</option>
                                                <option value={'Istri'}>Istri</option>
                                                <option value={'Anak'}>Anak</option>
                                                <option value={'Saudara'}>Saudara</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-full mb-[20px]'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nama Keluarga</h2>
                                        <input type="text" onChange={(e) => handleChange("nama_keluarga", e.target.value)} value={formData1.nama_keluarga} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Nama Keluarga...' />
                                    </div>
                                </div>

                                <div className='w-full mb-[20px]'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Alamat Domisili Keluarga</h2>
                                    <textarea rows={4} type="text" onChange={(e) => handleChange("address", e.target.value)} value={formData1.address} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Alamat Domisili Keluarga...'></textarea>
                                </div>

                                <div className='w-full mb-[20px]'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor HP Keluarga</h2>
                                    <input type="number" onChange={(e) => handleChange("nomor_hp_keluarga", e.target.value)} value={formData1.nomor_hp_keluarga} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick  w-[473px] h-[35px] text-[12px]' placeholder='Nomor HP Keluarga...' />
                                </div>

                                <div className='flex gap-2 justify-end'>
                                    <button onClick={() => setIndex(0)} className='text-[14px] font-medium py-[10px] px-[25px] text-[#0E5073] rounded-md border flex items-center justify-center gap-2 bg-grey-scale'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                            <path d="M6.00016 1L1.3335 5.66667L6.00016 10.3333" stroke="#113B61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M1.3335 5.66797H12.0002" stroke="#113B61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Kembali
                                    </button>
                                    <button
                                        onClick={() => {
                                            handleNext(2)
                                        }}
                                        className='bg-[#0E5073] text-[14px] font-medium py-[10px] px-[25px] text-white rounded-md'>Selanjutnya</button>
                                </div>

                            </div>
                        ) : index === 2 ? (
                            <div className='space-y-[20px]'>
                                <h1 className="text-black text-xl font-medium mb-[28px]">Gaji</h1>
                                <div>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Gaji Pokok <span className='text-red-primer'>*</span></h2>
                                    <input type="number" onChange={(e) => handleChange("basic_salary", e.target.value)} value={formData2.basic_salary} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='Masukkan Gaji Pokok...' />
                                </div>
                                <div className='mb-[20px]'>
                                    <h1 className="text-black text-base font-medium mb-[12px]">Pemasukan <span className='text-red-primer'>*</span></h1>
                                    <div className='flex gap-[20px] mb-[21.5px] w-full'>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Pemasukan</h2>
                                            {income.map((item, index) => {
                                                return (
                                                    <div key={index} className='flex justify-between gap-[12px] items-center w-full mb-[12px]'>
                                                        <div className='relative w-full'>
                                                            <select onChange={(e) => handleChangeIncome(item.id, e.target.value)} value={item.value} className='bg-white outline-none border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                                <option selected disabled value={''}>Pilih Pemasukan...</option>
                                                                {dataIncome.map((val, idx) => (
                                                                    <option key={idx} value={val.id}>{val.title}</option>
                                                                ))}
                                                            </select>
                                                            <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                                        </div>

                                                        {/* <div className='w-full'>
                                                                <input type="number" disabled value={basicSalary} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='0' />
                                                            </div> */}

                                                        <button onClick={() => handleRemoveIncome(item.id)} className='px-[12px] py-[9px] bg-[#FFE1E1] rounded-[6px] flex items-center justify-center'>
                                                            <CgTrashEmpty className='text-[#780000] text-xl' />
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                            <button onClick={handleAddIncome} className='px-[8px] py-[7px] flex gap-[4px] bg-blue-thin text-blue-thick rounded-lg'>
                                                <AiOutlinePlus />
                                                <h1 className="text-xs font-medium">Tambah Pemasukan</h1>
                                            </button>
                                        </div>
                                    </div>

                                    <h1 className="text-black text-base font-medium mb-[12px]">Potongan <span className='text-red-primer'>*</span></h1>
                                    <div className='flex gap-[20px] mb-[21.5px] w-full'>
                                        <div className='w-full'>
                                            <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Potongan</h2>
                                            {deduction.map((item, index) => (
                                                <div key={index} className='flex justify-between gap-[12px] items-center w-full mb-[12px]'>
                                                    <div className='relative w-full'>
                                                        <select onChange={(e) => handleChangeDeduction(item.id, e.target.value)} value={item.value} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                            <option selected disabled value={''}>Pilih Potongan...</option>
                                                            {dataDeduction.map((val, idx) => (
                                                                <option key={idx} value={val.id}>{val.title}</option>
                                                            ))}
                                                        </select>
                                                        <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl' />
                                                    </div>

                                                    {/* <div className='w-full'>
                                                            <input type="number" disabled value={basicSalary} className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px]' placeholder='0' />
                                                        </div> */}

                                                    <button onClick={() => handleRemoveDeduction(item.id)} className='px-[12px] py-[9px] bg-[#FFE1E1] rounded-[6px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#780000] text-xl' />
                                                    </button>
                                                </div>
                                            ))}
                                            <button onClick={handleAddDeduction} className='px-[8px] py-[7px] flex gap-[4px] bg-blue-thin text-blue-thick rounded-lg'>
                                                <AiOutlinePlus />
                                                <h1 className="text-xs font-medium">Tembah Potongan</h1>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex items-center gap-[20px]'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal Kenaikan Gaji Berkala Terakhir</h2>
                                        <input onChange={(e) => generateDate(e.target.value)} value={formData2.tanggal_kenaikan_gaji_berkala} type="date" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='Tanggal Kenaikan Gaji Berkala Terakhir...' />
                                    </div>
                                    <div className='w-full'>
                                        <h1 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Unggah SK Kenaikan Gaji Berkala Terakhir</h1>
                                        <div className='flex items-center gap-[13px]'>
                                            <label htmlFor='upload-file-KenaikanGajiBerkala' className='cursor-pointer w-full'>
                                                <div className='flex w-full h-[35px] bg-gray-300 rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                                    <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                                        <div className="text-center text-sky-950 text-[9px] font-medium">Cari</div>
                                                    </div>
                                                    <h1 className='text-zinc-700 text-xs font-medium'>{fileTanggalKenaikanGajiBerkalaPath ? fileTanggalKenaikanGajiBerkalaPath : 'Tidak ada file yang dipilih'}</h1>
                                                    <input type='file' id='upload-file-KenaikanGajiBerkala' accept=".jpeg, .jpg, .png" onChange={(e) => handleChangeFileTanggalKenaikanGajiBerkala(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required />
                                                </div>
                                            </label>
                                        </div>
                                        <h1 className=" text-red-600 text-[8px] font-normal">* Maks 500 KB</h1>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Tanggal Kenaikan Gaji Berkala Berikutnya</h2>
                                    <input value={tanggalKenaikanGajiBerkalaBerikutnya} type="date" disabled className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='Tanggal Kenaikan Gaji Berkala Berikutnya...' />
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border' />

                                <h1 className="text-black text-xl font-medium mb-[28px]">Bank Info</h1>
                                <div className='flex gap-[20px]'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nama Bank <span className='text-red-primer'>*</span></h2>
                                        <div className='relative'>
                                            <select onChange={(e) => handleChange("bank_name", e.target.value)} value={formData2.bank_name} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Pilih Nama Bank</option>
                                                <option value={'BNI'}>BNI</option>
                                                <option value={'BCA'}>BCA</option>
                                                <option value={'Mandiri'}>Mandiri</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nama Pemilik Rekening <span className='text-red-primer'>*</span></h2>
                                        <input onChange={(e) => handleChange("account_name", e.target.value)} value={formData2.account_name} type="text" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='Masukkan Nama Pemilik Rekening...' />
                                    </div>
                                </div>

                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor Rekening <span className='text-red-primer'>*</span></h2>
                                    <input onChange={(e) => handleChange("account_number", e.target.value)} value={formData2.account_number} type="number" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='Masukkan Nomor Rekening...' />
                                </div>

                                <hr className='border-base-outline mb-[29.5px] border' />

                                <h1 className="text-black text-xl font-medium mb-[28px]">Konfigurasi Pajak</h1>
                                <div className='w-full'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nama KPPN</h2>
                                    <input onChange={(e) => handleChange("nama_kppn", e.target.value)} value={formData2.nama_kppn} type="text" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='Nama KPPN...' />
                                </div>
                                <div className='flex gap-[20px]'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>NPWP</h2>
                                        <input onChange={(e) => handleChange("npwp", e.target.value)} value={formData2.npwp} type="number" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='NPWP...' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>PTKP Status</h2>
                                        <div className='relative'>
                                            <select onChange={(e) => handleChange("ptkp_status", e.target.value)} value={formData2.ptkp_status} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Select PTKP Status</option>
                                                {dataPtkpStatus && dataPtkpStatus.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.title}</option>
                                                    )
                                                })}
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                </div>

                                <div className='flex gap-[20px]'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Konfigurasi Pajak Penghasilan</h2>
                                        <div className='relative'>
                                            <select onChange={(e) => handleChange("tax_configuration", e.target.value)} value={formData2.tax_configuration} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                                <option selected disabled value={''}>Pilih Konfigurasi Pajak Penghasilan</option>
                                                <option value={'Netto'}>Netto</option>
                                                <option value={'Gross'}>Gross</option>
                                                <option value={'Gross Up'}>Gross Up</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                        </div>
                                    </div>
                                </div>
                                <hr className='border-base-outline mb-[29.5px] mt-[29.5px] border' />
                                <h1 className="text-black text-xl font-medium mb-[28px]">Benefit</h1>
                                <div className='w-full mb-[9px]'>
                                    <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>NPP BPJS Ketengakerjaan</h2>
                                    <div className='relative'>
                                        <select onChange={(e) => handleChange("npp_bpjs_ketenagakerjaan", e.target.value)} value={formData2.npp_bpjs_ketenagakerjaan} className='bg-white border rounded-[6px] text-grey-thick text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                            <option selected disabled value={''}>Select NP BPJS Ketenagakerjaan</option>
                                            {dataJkk.map((val, index) => {
                                                return (
                                                    <option key={index} value={val.id}>{val.title}</option>
                                                )
                                            })}
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                    </div>
                                </div>
                                <div className='flex gap-[20px]'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>BPJS Kesehatan</h2>
                                        <input onChange={(e) => handleChange("bpjs_kesehatan", e.target.value)} value={formData2.bpjs_kesehatan} type="text" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='BPJS Kesehatan...' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>BPJS Ketenagakerjaan</h2>
                                        <input onChange={(e) => handleChange("bpjs_ketenagakerjaan", e.target.value)} value={formData2.bpjs_ketenagakerjaan} type="text" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='BPJS Ketengakerjaan...' />
                                    </div>
                                </div>
                                <div className='flex gap-[20px]'>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor Taspen</h2>
                                        <input onChange={(e) => handleChange("nomor_taspen", e.target.value)} value={formData2.nomor_taspen} type="text" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='Nomor Taspen...' />
                                    </div>
                                    <div className='w-full'>
                                        <h2 className='text-grey-thick text-[12px] font-medium mb-[9px]'>Nomor Jasindo</h2>
                                        <input onChange={(e) => handleChange("nomor_jasindo", e.target.value)} value={formData2.nomor_jasindo} type="text" className='border pr-2 rounded-md py-[10px] px-[20px] text-grey-thick w-full text-[12px] ' placeholder='Nomor Jasindo...' />
                                    </div>
                                </div>


                                <div className='flex gap-2 justify-end'>
                                    <button onClick={() => setIndex(1)} className='text-[14px] font-medium py-[10px] px-[25px] text-[#0E5073] rounded-md border flex items-center justify-center gap-2 bg-grey-scale'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                            <path d="M6.00016 1L1.3335 5.66667L6.00016 10.3333" stroke="#113B61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M1.3335 5.66797H12.0002" stroke="#113B61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Kembali
                                    </button>
                                    <button onClick={createEmployee} className='bg-[#0E5073] text-[14px] font-medium py-[10px] px-[25px] text-white rounded-md'>Simpan</button>
                                </div>
                            </div>
                        ) : null}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEmployee