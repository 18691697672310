import React from "react";
import { LogoH } from "../../assets";


const CardDocument = ({onClick, title, description}) => {

    return(
        <button onClick={onClick} className="bg-white border shadow-md w-full h-full rounded-[6px] py-[19px] px-[16px] flex-col flex gap-1 relative overflow-hidden">
            <h1 className="text-[17px] text-[#0E5073] font-medium line-clamp-1">{title}</h1>
            <p className="text-[10px] text-[#0E5073] line-clamp-3">{description}</p>
            <img src={LogoH} className="absolute opacity-30 -right-1 w-[100px] -top-3"/>
        </button>
    )
}

export default CardDocument