import React, { useEffect, useState } from 'react'
import { LogoOmbudsman } from '../assets'
import { useParams } from 'react-router-dom'
import html2pdf from 'html2pdf.js'
import Api from '../Api'
import moment from 'moment'

function ExporAbsenManual() {
    moment.locale('id');
    const { id } = useParams()

    const [data, setData] = useState([])

    const getDataAbsenManualById = async () => {
        try {
            await Api.GetPresensiManualById(localStorage.getItem('hris-token'), id).then((response) => {
                console.log(response.data.results.presensi)
                setData(response.data.results.presensi)
                setTimeout(() => {
                    exportToPDF()
                }, 2000);
            }).catch((e) => {
                console.log(e)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const exportToPDF = () => {
        console.log("export")
        const content = document.getElementById('absen')
        const options = {
            margin: 10,
            filename: 'FORM-ABSEN-MANUAL.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(content).set(options).save();
        // const report = new jsPDF('portrait','pt', 'a4')
        // report.html(Template).then(()=>{
        //     report.save('report.pdf')
        // })
    };
    useEffect(() => {
        getDataAbsenManualById()
    }, [])
    return (
        <div id='absen' className='font-export mt-10'>
            <div className='mb-10 w-[532.35pt] mx-auto '>
                <h1 className="mx-auto text-center leading-loose text-[12pt]">
                    <strong className="font-bold font-serif">SURAT PERNYATAAN</strong>
                </h1>
            </div>
            <div className="mb-5 w-[532.35pt] mx-auto overflow-x-auto space-y-2">
                <table className="table-auto w-full text-[12pt] leading-loose">
                    <tbody>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-1 w-[30px]">1.</td>
                            <td colSpan={2} className="text-[12pt] px-2 pb-1 text-start">Saya yang bertandatangan di bawah ini:</td>
                        </tr>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-1 w-[30px]"></td>
                            <td className="text-[12pt] px-2 pb-1 text-start flex justify-between items-center">
                                <p>Nama</p>
                                <p>:</p>
                            </td>
                            <td className="text-[12pt] px-2 pb-1 text-start border-b border-dotted border-black">{data?.user?.user_detail?.gelar_depan === '-' ? "" : data?.user?.user_detail?.gelar_depan} {data?.user?.fullname} {data?.user?.user_detail?.gelar_belakang === '-' ? "" : data?.user?.user_detail?.gelar_belakang}</td>
                        </tr>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-1 w-[30px]"></td>
                            <td className="text-[12pt] px-2 pb-1 text-start flex justify-between items-center">
                                <p>NIO</p>
                                <p>:</p>
                            </td>
                            <td className="text-[12pt] px-2 pb-1 text-start border-b border-dotted border-black">{data?.user?.user_detail?.employee_id}</td>
                        </tr>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-1 w-[30px]"></td>
                            <td className="text-[12pt] px-2 pb-1 text-start flex justify-between items-center">
                                <p>Pangkat / Jenjang Jabatan</p>
                                <p>:</p>
                            </td>
                            <td className="text-[12pt] px-2 pb-1 text-start border-b border-dotted border-black">{data?.user?.user_detail?.job_position?.position_name}</td>
                        </tr>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-1 w-[30px]"></td>
                            <td className="text-[12pt] px-2 pb-1 text-start flex justify-between items-center">
                                <p>Unit Kerja</p>
                                <p>:</p>
                            </td>
                            <td className="text-[12pt] px-2 pb-1 text-start border-b border-dotted border-black">{data?.user?.data_unit_kerja?.unitKerja?.name}</td>
                        </tr>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-1 w-[30px]"></td>
                            <td colSpan={2} className="text-[12pt] px-2 pb-2 text-start">
                                Menyatakan bahwa pada tanggal {moment(data.tanggal).format('DD-MM-YYYY')} saya tidak mengisi daftar hadir pada saat {data.jenis} pukul {moment(data.jam).format('HH:mm')} namun tidak dapat menunjukkan bukti tangkap layar rekaman CCTV.
                            </td>
                        </tr>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-2 w-[30px]">2.</td>
                            <td colSpan={2} className="text-[12pt] px-2 pb-2 text-start">Demikian pernyataan ini saya buat dengan sebenarnya dan penuh rasa tanggung jawab</td>
                        </tr>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-2 w-[30px]"></td>
                            <td colSpan={2} className="text-[12pt] px-2 pb-2 text-start">untuk dapat dipergunakan sebagai administrasi pengganti daftar hadir.</td>
                        </tr>
                    </tbody>
                </table>

                <table className="table-auto w-full text-[12pt] leading-loose">
                    <tbody>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-2"></td>
                            <td className="text-[12pt] px-2 pb-2 text-end">{moment(data.createdAt).format("D MMMM YYYY")}</td>
                        </tr>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-2 text-center">Disetujui Oleh,</td>
                            <td className="text-[12pt] px-2 pb-2 text-center">Diajukan oleh,</td>
                        </tr>
                        <tr className="h-24">
                            <td className="text-[12pt] px-2 pb-2"></td>
                            <td className="text-[12pt] px-2 pb-2 text-end"></td>
                        </tr>
                        <tr className="">
                            <td className="text-[12pt] px-2 pb-2 text-center w-1/2">
                                ({data?.reportTo?.user_detail?.gelar_depan === '-' ? "" : data?.reportTo?.user_detail?.gelar_depan} {data?.reportTo?.fullname} {data?.reportTo?.user_detail?.gelar_belakang === '-' ? "" : data?.reportTo?.user_detail?.gelar_belakang})
                            </td>
                            <td className="text-[12pt] px-2 pb-2 text-center w-1/2">
                                ({data?.user?.user_detail?.gelar_depan === '-' ? "" : data?.user?.user_detail?.gelar_depan} {data?.user?.fullname} {data?.user?.user_detail?.gelar_belakang === '-' ? "" : data?.user?.user_detail?.gelar_belakang})</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ExporAbsenManual