import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineEye } from 'react-icons/ai'
import { BiDownload } from 'react-icons/bi'
import { HiPaperClip } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../../../Api'
import { Modal, Navbar } from '../../../../../../components'
import imageHandle from '../../../../../../utils/imageHandle'
import languages from '../../../../../../utils/languages'

const PenilaianLaporanBulanan = () => {
    moment.locale('id');

    const params = useLocation()
    const idLaporan = params.state.id

    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState()
    const getDetail = async () => {
        try {
            await Api.GetDetailLaporan(localStorage.getItem('hris-token'), idLaporan).then((response) => {
                console.log("ini", response)
                setData(response.data.results)
            })
        } catch (error) {
            console.log(error)
        }
    }
    // openIndex
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)

    const handleCollapse = (id) => {
        if (id) {
            setCurrent(id)
            setActive(!Active)
        }
    }
    const updateLaporan = async (status) => {
        try {
            const data = {
                "statusAtasanPenilai": status
            }
            await Api.UpdateLaporan(localStorage.getItem('hris-token'), idLaporan, data).then((response) => {
                toast.success('Berhasil Memperbarui Data')
                setRefresh(true)
            }).catch((error) => {
                console.log(error)
                const e = error.response.data
                for (let i = 0; i < e.length; i++) {
                    toast.error(e[i])
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }
    useEffect(() => {
        getDetail()
        setRefresh(false)
    }, [refresh])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Detail Laporan Kinerja Asisten'} LinkBack={'/laporan-kinerja'} />
                    <div className='space-y-[24px] bg-white px-[40px] py-[40px] rounded-[12px] border'>
                        <h1 className="text-zinc-800 text-xl font-semibold">Detail Laporan Kinerja Asisten</h1>
                        <div className='grid grid-cols-2 items-center justify-center gap-[14px] w-full h-full'>

                            <div className='w-full h-full px-[34px] py-[24px] space-y-[14px] border border-gray-500 rounded-xl'>
                                <h1 className="text-zinc-700 text-sm font-medium">{languages.language === 'id' ? "Pegawai yang Dinilai" : "Employees Assessed"}</h1>
                                <div className='grid grid-cols-12'>
                                    <div className='col-span-5 text-gray-500 text-xs font-normal'>
                                        <h1>Nama</h1>
                                        <h1>Nomor Induk Pegawai</h1>
                                        <h1>Jabatan</h1>
                                        <h1>Penempatan</h1>
                                    </div>
                                    <div className='col-span-1 text-gray-500 text-xs font-normal justify-end'>
                                        <h1>:</h1>
                                        <h1>:</h1>
                                        <h1>:</h1>
                                        <h1>:</h1>
                                    </div>
                                    <div className='col-span-6 text-gray-500 text-xs font-normal'>
                                        <h1 className='truncate'>{data?.pegawaiDinilai?.fullname}</h1>
                                        <h1 className='truncate'>{data?.pegawaiDinilai?.nomor_induk_pegawai}</h1>
                                        <h1 className='truncate'>{data?.pegawaiDinilai?.job_position}</h1>
                                        <h1 className='truncate'>{data?.pegawaiDinilai?.penempatan}</h1>
                                    </div>
                                </div>
                                <h1 className={`capitalize ${data?.pegawaiDinilai?.approach_type === 'qualitative' ? 'text-base-green' : 'text-base-yellow'} text-xs font-[600] line-clamp-1`}>{data?.pegawaiDinilai?.approach_type}</h1>
                            </div>

                            <div className='w-full h-full px-[34px] py-[24px] space-y-[14px] border border-gray-500 rounded-xl'>
                                <h1 className="text-zinc-700 text-sm font-medium">{languages.language === 'id' ? "Pejabat Penilai Kinerja" : "Appraisal Employee"}</h1>
                                <div className='text-gray-500 text-xs font-normal'>
                                    <h1 className='grid grid-cols-12'>
                                        <span className='col-span-5'>Nama</span>
                                        <span>:</span>
                                        <span className='col-span-6'>{data?.pejabatPenilai?.fullname}</span>
                                    </h1>
                                    <h1 className='grid grid-cols-12'>
                                        <span className='col-span-5'>Nomor Induk Pegawai</span>
                                        <span>:</span>
                                        <span className='col-span-6'>{data?.pejabatPenilai?.nomor_induk_pegawai}</span>
                                    </h1>
                                    <h1 className='grid grid-cols-12'>
                                        <span className='col-span-5'>Jabatan</span>
                                        <span>:</span>
                                        <span className='col-span-6'>{data?.pejabatPenilai?.job_position}</span>
                                    </h1>
                                    <h1 className='grid grid-cols-12'>
                                        <span className='col-span-5'>Penempatan</span>
                                        <span>:</span>
                                        <span className='col-span-6'>{data?.pejabatPenilai?.penempatan}</span>
                                    </h1>
                                    <h1 className='grid grid-cols-12'>
                                        <span className='col-span-5'>Catatan</span>
                                        <span>:</span>
                                        <span className='col-span-6'>{data?.laporan?.harapan}</span>
                                    </h1>
                                </div>
                            </div>

                        </div>

                        <div className='border p-5 rounded-[12px]'>
                            <h1 className='text-dark-3 text-[20px] font-[600]'>Hasil Kerja</h1>
                            <div className='mt-5'>
                                <h1 className="text-zinc-800 text-sm font-medium mb-3">Tugas Utama</h1>
                                <div className='overflow-auto'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>No.</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "RHK Atasan yang Diintervensi" : "RHK Intervening Leaders"}</h1>
                                            </div>
                                            {/* <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Jenis RHK" : "RHK Type"}</h1>
                                            </div> */}
                                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>RHK</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target RHK" : "Target RHK"}</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Rencana Aksi" : "Rencan Aksi"}</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target Kualitas" : "Target Kualitas"}</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target Kuantitas" : "Target Kuantitas"}</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target Waktu" : "Target Waktu"}</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target Biaya" : "Target Kualitas"}</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>Bukti Dukung</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>Realisasi Kuantitas</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>Realisasi Biaya</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>Umpan Balik</h1>
                                            </div>
                                        </div>

                                        {data?.rencanaHasilKerja && data?.rencanaHasilKerja.map((val, index) => (
                                            <div key={index}>
                                                <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='min-w-[50px] max-w-[50px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium '>{index + 1}</h1>
                                                    </div>
                                                    <div className='min-w-[150px] max-w-[150px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium '>{val?.rhk_atasan}</h1>
                                                    </div>
                                                    {/* <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className={`w-fit text-xs font-medium py-[4px] px-[8px] rounded-lg ${val?.type === 'utama' ? 'bg-blue-thin text-blue-thick' : 'bg-[#FFC9B4] text-red-thick'}`}>{val?.type}</h1>
                                                    </div> */}
                                                    <div className='min-w-[150px] max-w-[150px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.rhk}</h1>
                                                    </div>
                                                    <div className='min-w-[100px] max-w-[100px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.target_rhk ? val?.target_rhk : '-'}</h1>
                                                    </div>
                                                    <div className='min-w-[150px] max-w-[150px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.rencana_aksi ? val?.rencana_aksi : '-'}</h1>
                                                    </div>
                                                    <div className='min-w-[100px] max-w-[100px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.target_kualitas ? val?.target_kualitas : 0}</h1>
                                                    </div>
                                                    <div className='min-w-[100px] max-w-[100px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.target_kuantitas ? val?.target_kuantitas : 0}</h1>
                                                    </div>
                                                    <div className='min-w-[100px] max-w-[100px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.target_waktu ? val?.target_waktu : 0}</h1>
                                                    </div>
                                                    <div className='min-w-[100px] max-w-[100px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.target_biaya ? val?.target_biaya : 0}</h1>
                                                    </div>
                                                    {/* <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.bukti_dukung ? val?.bukti_dukung : '-'}</h1>
                                                    </div> */}
                                                    <div className='min-w-[150px] max-w-[150px] grid grid-cols-3 items-center'>
                                                        <h1 className='text-grey-thick text-xs font-medium col-span-2'>{val?.bukti_dukung ? val?.bukti_dukung : '-'}</h1>
                                                        <button
                                                            onClick={() => handleCollapse(val.id)}
                                                            className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'><AiOutlineEye className='text-[#003049]' /></button>
                                                    </div>
                                                    <div className='min-w-[100px] max-w-[100px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.realisasi_kuantitas ? val?.realisasi_kuantitas : '-'}</h1>
                                                    </div>
                                                    <div className='min-w-[100px] max-w-[100px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.realitas_target_biaya ? val?.realitas_target_biaya : '-'}</h1>
                                                    </div>
                                                    <div className='min-w-[100px] max-w-[100px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.feedback ? val?.feedback : '-'}</h1>
                                                    </div>
                                                </div>
                                                <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] space-y-3 transition-all ease-in-out duration-500 overflow-hidden px-[70px] py-2 ${val.id === Current && !Active ? 'h-fit' : 'h-0 border-0 hidden'}`}>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.files.length} Dokumen Bukti</h1>
                                                    {val?.files && val?.files.map((file, idx) => (
                                                        <div key={idx} className='flex items-center gap-10'>
                                                            <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.file_name}</span></div>
                                                            <a href={imageHandle(file?.file_path)} download={file?.file_name} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2'>
                                                                <BiDownload className='text-xl' />
                                                                <p>Unduh Dokumen</p>
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </table>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h1 className="text-zinc-800 text-sm font-medium mb-3">Tugas Tambahan</h1>
                                <div className='overflow-auto'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>No.</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>Tanggal</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>Uraian Kegiatan</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>Dasar Penugasan</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>Hasil</h1>
                                            </div>
                                            {/* <div className='flex items-center justify-center gap-[15px] w-full'>
                                                <h1 className='text-grey-thick text-xs font-medium'>Aksi</h1>
                                            </div> */}
                                        </div>
                                        {data?.tugasTambahan && data?.tugasTambahan.map((val, index) => (
                                            <div key={index} className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[50px] max-w-[50px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium '>{index + 1}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium '>{moment(val?.tanggal).format("D MMMM YYYY")}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val.uraian_kegiatan}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.dasar_penugasan}</h1>
                                                </div>
                                                <div className=''>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.hasil}</h1>
                                                </div>
                                                {/* <div className='flex items-center justify-center w-full gap-[12px]'>
                                                    <button
                                                        // onClick={() => {
                                                        //     setShowModalEditTambahan(!showModalEditTambahan)
                                                        //     getTambahanById(val?.id)
                                                        // }}
                                                        className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-blue-thick' />
                                                    </button>
                                                </div> */}
                                            </div>
                                        ))}
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div className='border p-5 rounded-[12px]'>
                            <h1 className='text-dark-3 text-[20px] font-[600]'>Perilaku Kerja</h1>
                            <div className='mt-5'>
                                <div className='overflow-auto'>
                                    <table className='w-full space-y-[10px]'>
                                        {data?.penilaianPerilakuKerja && data?.penilaianPerilakuKerja.map((evaluation, evaluationIndex) => (
                                            <div key={evaluationIndex} className='overflow-auto scrollbar-hide'>
                                                <table className='w-full space-y-[10px]'>
                                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                                        <div className='flex items-center gap-[15px] min-w-[80px] max-w-[80px]'>
                                                            <h1 className='text-grey-thick text-xs font-medium'>No</h1>
                                                        </div>
                                                        <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                            <h1 className='text-grey-thick text-xs font-medium'>Perilaku</h1>
                                                        </div>
                                                        <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                                            <h1 className='text-grey-thick text-xs font-medium'>Umpan Balik</h1>
                                                        </div>
                                                        <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                                            <h1 className='text-grey-thick text-xs font-medium'>Umpan Balik Rekan Kerja</h1>
                                                        </div>
                                                        <div className='flex items-center justify-center gap-[15px] w-full'>
                                                            <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                                        </div>
                                                    </div>
                                                    {evaluation && evaluation.settings.map((setting, settingIndex) => (
                                                        <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                                            <div className='min-w-[80px] max-w-[80px]'>
                                                                <h1 className='text-grey-thick text-xs font-medium'>{settingIndex + 1}</h1>
                                                            </div>
                                                            <div className='min-w-[300px] max-w-[300px]'>
                                                                <h1 className='text-grey-thick text-xs font-medium'>{setting.setting_name}</h1>
                                                                <ul className='text-grey-thick text-xs font-medium list-disc ml-4'>
                                                                    {setting && setting.points.map((point, pointIndex) => (
                                                                        <li key={pointIndex}>{point.point_name}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className='min-w-[150px] max-w-[150px]'>
                                                                <h1 className={`${setting.feedback === 'Sesuai Ekspektasi' ? 'bg-[#51A7F5]' : setting.feedback === 'Dibawah Ekspektasi' ? 'bg-[#EA5A54]' : setting.feedback === 'Diatas Ekspektasi' ? 'bg-[#AED96C]' : ''} font-medium text-xs rounded-lg text-center px-[8px] py-[8px] w-fit`}>{setting.feedback === null ? "-" : setting.feedback}</h1>
                                                            </div>
                                                            <div className='min-w-[150px] max-w-[150px]'>
                                                                {Object.entries(setting.feedback_rekan_kerja).map(([category, count]) => (
                                                                    <h1 className='text-[10px] rounded-lg px-[8px] py-1 w-fit'>
                                                                        <span>{category}</span> : <span>{count}</span>
                                                                    </h1>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </table>
                                            </div>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        </div>

                        {data?.files?.length > 0 &&
                            (
                                <div className='my-5 space-y-2'>
                                    <h1 className="text-zinc-800 text-base font-semibold">SK Penetapan Inovasi</h1>
                                    <div className='w-full grid grid-cols-2 gap-3'>
                                        <div className='space-y-2'>
                                            {data?.files?.length > 0 && (
                                                <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                                    {data?.files?.map((file, index) => (
                                                        <div key={index} className='flex items-center gap-10'>
                                                            <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                                            <a href={imageHandle(file?.file_path)} download={file?.originalname} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2'>
                                                                <BiDownload className='text-xl' />
                                                                <p>Unduh Dokumen</p>
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {data?.laporan?.statusAtasanPenilai != "approved" ?
                            <div className='flex gap-2 justify-end'>
                                <button
                                    onClick={() => {
                                        updateLaporan("approved")
                                    }}
                                    className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Setujui</h1>
                                </button>
                            </div>
                            : null
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PenilaianLaporanBulanan