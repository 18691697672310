import "leaflet/dist/leaflet.css";
import React, { useState } from "react";
import { Navbar } from "../../../../components";
import languages from "../../../../utils/languages";
import AturLokasi from "./Lokasi";
import PengaturanWaktu from "./Waktu";

const PengaturanKehadiran = () => {
  const [menu, setMenu] = useState("Attendance Distance");
  return (
    <div className="bg-[#F8F9FB] min-h-screen">
      <div className="w-full space-y-[24px] overflow-hidden">
        <Navbar
          SubNavbar={true}
          NameSubNavbar={
            languages.language == "id"
              ? "Pengaturan Kehadiran"
              : "Attendance Setting"
          }
          LinkBack={"/time-management-setting"}
        />
        <div className="border-b-2 flex items-center text-[16px]">
          <button
            onClick={() => setMenu("Attendance Distance")}
            className={`${menu === "Attendance Distance"
              ? "text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold"
              : "text-[#D1D1D1]"
              } px-[85px] py-[10px]`}
          >
            {languages.language == "id"
              ? "Jarak Presensi"
              : "Attendance Distance"}
          </button>
          {/* <button
            onClick={() => setMenu("SetTime")}
            className={`${
              menu === "SetTime"
                ? "text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold"
                : "text-[#D1D1D1]"
            } px-[85px] py-[10px]`}
          >
            {languages.language == "id" ? "Atur Waktu" : "Set Time"}
          </button> */}
          <button
            onClick={() => setMenu("pengaturan-waktu")}
            className={`${menu === "pengaturan-waktu"
              ? "text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold"
              : "text-[#D1D1D1]"
              } px-[85px] py-[10px]`}
          >
            Pengaturan Waktu
          </button>
        </div>
        {menu === "Attendance Distance" ? (
          <AturLokasi />
        ) : (
          <PengaturanWaktu />
        )}
      </div>
    </div>
  );
};

export default PengaturanKehadiran;
