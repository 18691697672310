import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { BiDownload } from 'react-icons/bi'
import { HiOutlinePencil, HiPaperClip } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdImportExport } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../../../../Api'
import { Modal, ModalDelete, Navbar } from '../../../../../../../components'
import imageHandle from '../../../../../../../utils/imageHandle'
import languages from '../../../../../../../utils/languages'

const EditRealisasiBuktiDukungBulan = () => {
    moment.locale('id');
    const params = useLocation()
    const idLaporan = params?.state?.id
    const idSkp = params?.state?.idSkp


    const [userId, setUserId] = useState('')
    const [dataRHK, setDataRHK] = useState([])
    const [dataTambahan, setDataTambahan] = useState([])
    const [dataPendidikan, setDataPendidikan] = useState([])
    const [dataFileSKInovasi, setDataFileSKInovasi] = useState([])
    const getLaporan = async () => {
        try {
            await Api.GetLaporanById(localStorage.getItem('hris-token'), idLaporan).then((response) => {
                console.log("data", response.data.results)
                setDataRHK(response.data.results.rencanaHasilKerja)
                setDataTambahan(response.data.results.tugasTambahan)
                setDataPendidikan(response.data.results.pendidikan)
                setDataFileSKInovasi(response.data.results.files)
                setMonthPeriod(moment(response.data.results.laporan.start_date).format("M"))
                setUserId(response.data.results.laporan.userId)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const navigate = useNavigate()

    const [monthPeriod, setMonthPeriod] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [file, setFile] = useState('')
    const rhkType = 'Utama'

    // openIndex
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)

    const handleCollapse = (id) => {
        if (id) {
            setCurrent(id)
            setActive(!Active)
        }
    }

    const [namePath, setNamePath] = useState([])
    const [buktiDukungId, setBuktiDukungId] = useState('')


    const handleChangeFile = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['pdf', 'doc', 'docx'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('gambar tidak boleh lebih dari 10 MB')
            setFile(null)
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat pdf atau word')
        } else {
            setNamePath(e.target.files[0].name)
            setFile(e.target.files[0])
        }
    }
    const handleRemoveClicked = (name) => {
        // const index = employeeName.indexOf(name);
        // if (index !== -1) {
        //   const newIdEmployee = [...idEmployee];
        //   const newEmployeeName = [...employeeName];

        //   newIdEmployee.splice(index, 1);
        //   newEmployeeName.splice(index, 1);

        //   setIdEmployee(newIdEmployee);
        //   setEmployeeName(newEmployeeName);
        // }
    };

    const [name, setName] = useState('')
    const [tanggal, setTanggal] = useState('')
    const [realisasiKuantitas, setRealisasiKuantitas] = useState('')
    const [realistasTargetBiaya, setRealitasTargetBiaya] = useState('')
    const [fileBukti, setFileBukti] = useState([])
    const [fileNameBukti, setFileNameBukti] = useState([])
    const [refresh, setRefresh] = useState(false)

    // const getLaporanRhkByIdSkp = async () => {
    //     try {
    //         await Api.GetLaporanRhkByIdSkp(localStorage.getItem('hris-token'), idSkp).then((response) => {
    //             // console.log(response.data.data)
    //             setDataRHK(response.data.data)
    //             setDataPendidikan(response.data.pendidikan)
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFileBukti([...fileBukti, newFiles]);
    };

    const handleRemoveFile = (index) => {
        const newFiles = [...fileBukti];
        newFiles.splice(index, 1);
        setFileBukti(newFiles);
    };

    const postRHK = async () => {
        try {
            const data = {
                "name": name,
                "tanggal": tanggal,
                "realisasi_kuantitas": realisasiKuantitas,
                "realitas_target_biaya": realistasTargetBiaya
            }
            const formData = new FormData();

            // data2 to formData
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            if (fileBukti?.length > 0) {
                for (const i in fileBukti) {
                    formData.append('bukti_dukung', fileBukti[i][0]);
                }
            } else {
                toast.error("File Bukti Tidak Boleh Kosong")
            }
            for (const pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            await Api.PostBuktiDukung(localStorage.getItem('hris-token'), formData, buktiDukungId).then((response) => {
                setRefresh(true)
                toast.success('Success Create Leave')
                setShowModal(!showModal)
            }).catch((error) => {
                console.log(error)
                const e = error.response.data
                for (let i = 0; i < e.length; i++) {
                    toast.error(e[i])
                }
            })

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }

    const [fileSK, setFileSK] = useState([])

    const handleFileSKChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFileSK([...fileSK, newFiles]);
    };

    const handleRemoveFileSK = (index) => {
        const newFiles = [...fileSK];
        newFiles.splice(index, 1);
        setFileSK(newFiles);
    };

    const [showModalDelete, setShowModalDelete] = useState(false)
    const [idFile, setIdFile] = useState(false)
    const deleteModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setIdFile(id)
        setRefresh(true)
    }

    const deleteFile = async () => {
        try {
            await Api.DeleteFileBuktiDukung(localStorage.getItem('hris-token'), idFile).then((response) => {
                setRefresh(true)
                setShowModalDelete(!showModalDelete)
                toast.success('Hapus File Berhasil')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }


    const postLaporan = async () => {
        if (monthPeriod === '') {
            toast.error("Bulan Periode wajib diisi")
        } else {
            try {
                const data = {
                    "bulan": monthPeriod,
                    "skpId": idSkp
                }
                const formData = new FormData();

                // data2 to formData
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                if (fileSK?.length > 0) {
                    for (const i in fileSK) {
                        formData.append('sk_penetapan_inovasi', fileSK[i][0]);
                    }
                }
                for (const pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }
                await Api.PostLaporanBulanan(localStorage.getItem('hris-token'), formData).then(async (response) => {
                    if (tempTambahan.length > 0) {
                        for (let i = 0; i < tempTambahan.length; i++) {
                            const temp = tempTambahan[i]
                            const idTemp = temp.id
                            delete temp.id
                            temp['laporanId'] = response.data.results.data.id
                            console.log(temp)
                            await Api.UpdateTugasTambahan(localStorage.getItem('hris-token'), temp, idTemp).then((response) => {
                                toast.success('Success Create Leave')
                                navigate('/laporan-kinerja/detail-saya', { state: { id: idSkp } })
                            }).catch((error) => {
                                console.log(error)
                                const e = error.response.data
                                for (let i = 0; i < e.length; i++) {
                                    toast.error(e[i])
                                }
                            })
                        }
                    } else if (tempTambahan.length === 0) {
                        navigate('/laporan-kinerja/detail-saya', { state: { id: idSkp } })
                    }

                }).catch((error) => {
                    console.log(error)
                    const e = error.response.data
                    for (let i = 0; i < e.length; i++) {
                        toast.error(e[i])
                    }
                })

            } catch (error) {
                console.log(error)
                toast.error('Gagal Menambahkan Data')
            }
        }
    }

    const FetchUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            // console.log(response?.data?.results?.user?.user_detail)
        } catch (error) {
            console.log(error)
        }
    }

    // Tugas Tambahan
    const [showModalTambahan, setShowModalTambahan] = useState(false)
    const [showModalEditTambahan, setShowModalEditTambahan] = useState(false)
    const [date, setDate] = useState('')
    const [kegiatan, setKegiatan] = useState('')
    const [dasarPenugasan, setDasarPenugasan] = useState('')
    const [hasil, setHasil] = useState('')
    const [id, setId] = useState('')
    const [tempTambahan, setTempTambahan] = useState([])
    const [dataTemp, setDataTemp] = useState([])

    const resetFormTambahan = () => {
        setDate('')
        setKegiatan('')
        setDasarPenugasan('')
        setHasil('')
    }

    const postTambahan = async () => {
        try {
            let data = {
                "laporanId": idLaporan,
                "tanggal": date,
                "uraian_kegiatan": kegiatan,
                "dasar_penugasan": dasarPenugasan,
                "hasil": hasil
            }
            await Api.PostTugasTambahan(localStorage.getItem('hris-token'), data).then((response) => {
                console.log(response.data.results.data)
                resetFormTambahan()
                setRefresh(true)
                toast.success('BErhasil Menambahkan Data')
                setShowModalTambahan(!showModalTambahan)
            }).catch((error) => {
                console.log(error)
                const e = error.response.data
                for (let i = 0; i < e.length; i++) {
                    toast.error(e[i])
                }
            })

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }

    const getTambahanById = (data) => {
        setId(data.id)
        setDate(data.tanggal)
        setKegiatan(data.uraian_kegiatan)
        setDasarPenugasan(data.dasar_penugasan)
        setHasil(data.hasil)
    }

    const updateTambahan = async () => {
        try {
            let data = {
                "laporanId": idLaporan,
                "tanggal": date,
                "uraian_kegiatan": kegiatan,
                "dasar_penugasan": dasarPenugasan,
                "hasil": hasil
            }
            await Api.UpdateTugasTambahan(localStorage.getItem('hris-token'), data, id).then((response) => {
                console.log(response.data.results.data)
                resetFormTambahan()
                setRefresh(true)
                toast.success('Success Create Leave')
                setShowModalEditTambahan(!showModalEditTambahan)
            }).catch((error) => {
                console.log(error)
                const e = error.response.data
                for (let i = 0; i < e.length; i++) {
                    toast.error(e[i])
                }
            })

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }

    // console.log("dataTemp", tempTambahan)


    useEffect(() => {
        FetchUser()
        getLaporan()
        setRefresh(false)
    }, [refresh])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModal}
                title={"Tambah Realisasi dan Bukti Dukung"}
                buttonClose={() => setShowModal(!showModal)}
                width={'550px'}
                content={
                    <div className='space-y-6'>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Tanggal Realisasi <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setTanggal(e.target.value)} type="date" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input rencana kinerja asisten' />
                        </div>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Bukti Realisasi <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setName(e.target.value)} type="text" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input rencana kinerja asisten' />
                        </div>
                        <div className='w-full'>
                            <div className='w-full space-y-[21px]'>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-1">File <span className='text-red-900'>*</span></h1>
                                    <label htmlFor='upload-file' className='cursor-pointer'>
                                        <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full'>
                                            <div className='flex items-center justify-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                    <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                                </svg>
                                            </div>
                                            <h1 className='text-red-primer text-[12px] font-medium text-center'>Upload File Disini</h1>
                                            <div className="text-gray-500 text-[8px] text-center">PDF/PNG/JPG/JPEG maks 2 MB</div>
                                        </div>
                                        <input type='file' id='upload-file' multiple accept=".pdf" onChange={(e) => handleFileChange(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                    </label>
                                    {fileBukti.length > 0 && (
                                        <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                            {fileBukti.map((val, index) => (
                                                <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                    <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                                        <h1>{val[0]?.name}</h1>
                                                        <button
                                                            onClick={() => handleRemoveFile(index)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                                <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Realisasi Kuantitas<span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setRealisasiKuantitas(e.target.value)} type="number" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input rencana kinerja asisten' />
                        </div>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Realitas Target Biaya<span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setRealitasTargetBiaya(e.target.value)} type="number" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input rencana kinerja asisten' />
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowModal(!showModal)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button
                                    onClick={postRHK}
                                    className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />

            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteFile}
            />
            <Modal
                activeModal={showModalTambahan}
                title={"Tambah Tugas Tambahan"}
                buttonClose={() => setShowModalTambahan(!showModalTambahan)}
                width={'550px'}
                content={
                    <div className='space-y-6'>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Tanggal <span className='text-red-primer'>*</span></h1>
                            <input type="date" onChange={(e) => setDate(e.target.value)} className='py-[10px] px-[16px] border rounded-md text-gray-500 text-xs w-full' />
                        </div>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Uraian Kegiatan <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setKegiatan(e.target.value)} type="text" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Uraian Kegiatan...' />
                        </div>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Dasar Penugasan <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setDasarPenugasan(e.target.value)} type="text" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Dasar Penugasan...' />
                        </div>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Hasil <span className='text-red-primer'>*</span></h1>
                            <input onChange={(e) => setHasil(e.target.value)} type="text" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Hasil...' />
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowModalTambahan(!showModalTambahan)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button
                                    onClick={postTambahan}
                                    className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEditTambahan}
                title={"Edit Tugas Tambahan"}
                buttonClose={() => setShowModalEditTambahan(!showModalEditTambahan)}
                width={'550px'}
                content={
                    <div className='space-y-6'>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Tanggal <span className='text-red-primer'>*</span></h1>
                            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className='py-[10px] px-[16px] border rounded-md text-gray-500 text-xs w-full' />
                        </div>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Uraian Kegiatan <span className='text-red-primer'>*</span></h1>
                            <input value={kegiatan} onChange={(e) => setKegiatan(e.target.value)} type="text" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Uraian Kegiatan...' />
                        </div>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Dasar Penugasan <span className='text-red-primer'>*</span></h1>
                            <input value={dasarPenugasan} onChange={(e) => setDasarPenugasan(e.target.value)} type="text" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Dasar Penugasan...' />
                        </div>
                        <div>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Hasil <span className='text-red-primer'>*</span></h1>
                            <input value={hasil} onChange={(e) => setHasil(e.target.value)} type="text" className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='Input Hasil...' />
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowModalEditTambahan(!showModalEditTambahan)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                </button>
                                <button
                                    onClick={updateTambahan}
                                    className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <div className='flex px-[30px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Detail Penilaian" : "Detail Evaluation"} LinkBack={'/laporan-kinerja'} />
                    {/* <h1 className="text-zinc-800 text-xl font-semibold">{languages.language === 'id' ? "Penilaian" : "Evaluation"}</h1> */}
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-dark-3 text-[20px] font-[600]'>Pengajuan Penilaian Kinerja</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[400]'>Tambah Pengajuan Penilaian Kinerja  </h1>
                        </div>

                        <div className='space-y-[4px]'>
                            <h1 className="text-zinc-700 text-base font-semibold ">Step :</h1>
                            <h2 className="text-gray-500 text-xs font-normal">{languages.language === 'id' ? "1. Sebelum mengisi penilaian, pilih bulan periode terlebih dahulu." : "1. Before filling out the assessment, select the month of the period first."}</h2>
                            <h2 className="text-gray-500 text-xs font-normal">{languages.language === 'id' ? "2. Mengunggah bukti dan merealisasikan bukti yang sedang dikerjakan." : "2. Uploading evidence and realizing the evidence being worked on."}</h2>
                            <h2 className="text-gray-500 text-xs font-normal">{languages.language === 'id' ?
                                <>
                                    3. Kirimkan bukti dan realisasi pada tombol <span className="text-zinc-800 text-xs font-medium">"Kirim"</span>.
                                </>
                                :
                                <>
                                    3. Submit evidence and realization on the button <span className="text-zinc-800 text-xs font-medium">"Submit"</span>.
                                </>
                            }</h2>
                        </div>

                        <div className='space-y-[5px]'>
                            <h1 className="text-neutral-500 text-[11px] font-medium">{languages.language === 'id' ? "Bulan Periode" : "Month of Period"}</h1>
                            <div className='w-2/5'>
                                <div className="mb-[12px]">
                                    <div className='relative w-full'>
                                        <select onChange={(e) => setMonthPeriod(e.target.value)} value={monthPeriod} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected disabled value="">Select month...</option>
                                            <option value={1}>Januari</option>
                                            <option value={2}>Februari</option>
                                            <option value={3}>Maret</option>
                                            <option value={4}>April</option>
                                            <option value={5}>Mei</option>
                                            <option value={6}>Juni</option>
                                            <option value={7}>Juli</option>
                                            <option value={8}>Agustus</option>
                                            <option value={9}>September</option>
                                            <option value={10}>Oktober</option>
                                            <option value={11}>November</option>
                                            <option value={12}>Desember</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className="text-zinc-800 text-xl font-semibold">Tugas Utama</h1>
                            <div className='mt-[44px] mb-[44px] overflow-auto'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>No.</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "RHK Atasan yang Diintervensi" : "RHK Intervening Leaders"}</h1>
                                        </div>
                                        {/* <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Jenis RHK" : "RHK Type"}</h1>
                                            </div> */}
                                        <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>RHK</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target RHK" : "Target RHK"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Rencana Aksi" : "Rencan Aksi"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target Kualitas" : "Target Kualitas"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target Kuantitas" : "Target Kuantitas"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target Waktu" : "Target Waktu"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>{languages.language === 'id' ? "Target Biaya" : "Target Kualitas"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>Bukti Dukung</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>Realisasi Kuantitas</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>Realisasi Biaya</h1>
                                        </div>
                                        {/* <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>Realisasi Target Waktu</h1>
                                            <MdImportExport className='text-grey-thick text-xs' />
                                        </div> */}
                                        <div className=''>
                                            <h1 className='text-grey-thick text-xs font-medium'>Aksi</h1>
                                        </div>
                                    </div>

                                    {dataRHK && dataRHK.map((val, index) => (
                                        <div key={index}>
                                            <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[50px] max-w-[50px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium '>{index + 1}</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium '>{val?.rhk_atasan ? val?.rhk_atasan : '-'}</h1>
                                                </div>
                                                {/* <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className={`w-fit text-xs font-medium py-[4px] px-[8px] rounded-lg ${val?.type === 'utama' ? 'bg-blue-thin text-blue-thick' : 'bg-[#FFC9B4] text-red-thick'}`}>{val?.type}</h1>
                                                    </div> */}
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.rhk}</h1>
                                                </div>
                                                <div className='min-w-[100px] max-w-[100px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.target_rhk ? val?.target_rhk : '-'}</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.rencana_aksi ? val?.rencana_aksi : '-'}</h1>
                                                </div>
                                                <div className='min-w-[100px] max-w-[100px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.target_kualitas ? val?.target_kualitas : 0}</h1>
                                                </div>
                                                <div className='min-w-[100px] max-w-[100px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.target_kuantitas ? val?.target_kuantitas : 0}</h1>
                                                </div>
                                                <div className='min-w-[100px] max-w-[100px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.target_waktu ? val?.target_waktu : 0}</h1>
                                                </div>
                                                <div className='min-w-[100px] max-w-[100px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.target_biaya ? val?.target_biaya : 0}</h1>
                                                </div>
                                                {/* <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className='text-grey-thick text-xs font-medium'>{val?.bukti_dukung ? val?.bukti_dukung : '-'}</h1>
                                                    </div> */}
                                                <div className='min-w-[150px] max-w-[150px] grid grid-cols-3 items-center'>
                                                    <h1 className='text-grey-thick text-xs font-medium col-span-2'>{val?.bukti_dukung ? val?.bukti_dukung : '-'}</h1>
                                                    <button
                                                        onClick={() => handleCollapse(val.id)}
                                                        className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'><AiOutlineEye className='text-[#003049]' /></button>
                                                </div>
                                                <div className='min-w-[100px] max-w-[100px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.realisasi_kuantitas ? val?.realisasi_kuantitas : '-'}</h1>
                                                </div>
                                                <div className='min-w-[100px] max-w-[100px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>{val?.realitas_target_biaya ? val?.realitas_target_biaya : '-'}</h1>
                                                </div>
                                                {/* <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-grey-thick text-xs font-medium'>0</h1>
                                                </div> */}
                                                <div className='flex items-center justify-center w-full gap-[12px]'>
                                                    <button
                                                        onClick={() => {
                                                            setShowModal(!showModal)
                                                            setBuktiDukungId(val?.bukti_dukung_id)
                                                        }}
                                                        className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-blue-thick' />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] space-y-3 transition-all ease-in-out duration-500 overflow-hidden px-[70px] py-2 ${val.id === Current && !Active ? 'h-fit' : 'h-0 border-0 hidden'}`}>
                                                <h1 className='text-grey-thick text-xs font-medium'>{val?.files.length} Dokumen Bukti</h1>
                                                {val?.files && val?.files.map((file, idx) => (
                                                    <div key={idx} className='flex items-center gap-10'>
                                                        <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.file_name}</span></div>
                                                        <a href={imageHandle(file?.file_path)} download={file?.file_name} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2'>
                                                            <BiDownload className='text-xl' />
                                                            <p>Unduh Dokumen</p>
                                                        </a>
                                                        <button onClick={() => deleteModal(file?.id)} className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path d="M6.32733 3.20436H6.19482C6.2677 3.20436 6.32733 3.14473 6.32733 3.07185V3.20436H11.3629V3.07185C11.3629 3.14473 11.4226 3.20436 11.4955 3.20436H11.3629V4.397H12.5556V3.07185C12.5556 2.48712 12.0802 2.01172 11.4955 2.01172H6.19482C5.61009 2.01172 5.13469 2.48712 5.13469 3.07185V4.397H6.32733V3.20436ZM14.6758 4.397H3.01444C2.72125 4.397 2.48438 4.63388 2.48438 4.92707V5.45713C2.48438 5.53001 2.54401 5.58965 2.61689 5.58965H3.61739L4.02653 14.2529C4.05303 14.8177 4.52015 15.2633 5.085 15.2633H12.6053C13.1718 15.2633 13.6372 14.8194 13.6637 14.2529L14.0729 5.58965H15.0734C15.1463 5.58965 15.2059 5.53001 15.2059 5.45713V4.92707C15.2059 4.63388 14.969 4.397 14.6758 4.397ZM12.4777 14.0707H5.21254L4.81168 5.58965H12.8786L12.4777 14.0707Z" fill="#003049" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </table>
                            </div>
                        </div>
                        <div>
                            <h1 className="text-zinc-800 text-xl font-semibold">Tugas Tambahan</h1>
                            <div className='mt-[44px] mb-[44px] overflow-auto'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>No.</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>Tanggal</h1>
                                            <MdImportExport className='text-grey-thick text-xs' />
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>Uraian Kegiatan</h1>
                                            <MdImportExport className='text-grey-thick text-xs' />
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>Dasar Penugasan</h1>
                                            <MdImportExport className='text-grey-thick text-xs' />
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-grey-thick text-xs font-medium'>Hasil</h1>
                                            <MdImportExport className='text-grey-thick text-xs' />
                                        </div>
                                        <div className='flex items-center justify-center gap-[15px] w-full'>
                                            <h1 className='text-grey-thick text-xs font-medium'>Aksi</h1>
                                            <MdImportExport className='text-grey-thick text-xs' />
                                        </div>
                                    </div>
                                    {dataTambahan && dataTambahan.map((val, index) => (
                                        <div key={index} className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[50px] max-w-[50px]'>
                                                <h1 className='text-grey-thick text-xs font-medium '>{index + 1}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-grey-thick text-xs font-medium '>{moment(val?.tanggal).format("D MMMM YYYY")}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{val.uraian_kegiatan}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{val?.dasar_penugasan}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-grey-thick text-xs font-medium'>{val?.hasil}</h1>
                                            </div>
                                            <div className='flex items-center justify-center w-full gap-[12px]'>
                                                <button
                                                    onClick={() => {
                                                        setShowModalEditTambahan(!showModalEditTambahan)
                                                        getTambahanById(val)
                                                    }}
                                                    className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-blue-thick' />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </table>
                            </div>

                            <button onClick={() => setShowModalTambahan(!showModalTambahan)} className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border border-[#8E95A2] rounded-lg'>
                                <AiOutlinePlus className='text-lg' />
                                <span>Tambah Tugas Tambahan</span>
                            </button>


                        </div>
                        <div className='my-5 space-y-2'>
                            <h1 className="text-zinc-800 text-base font-semibold">Pendidikan Terakhir</h1>
                            <div className='w-full grid grid-cols-2 gap-3'>
                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Tingkat Pendidikan Tertinggi</h1>
                                    <input value={dataPendidikan?.pendidikan_tertinggi} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='' readOnly />
                                </div>

                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Nama Program Studi</h1>
                                    <input value={dataPendidikan?.program_studi} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='' readOnly />
                                </div>
                            </div>
                        </div>
                        <div className='my-5 space-y-2'>
                            <h1 className="text-zinc-800 text-base font-semibold">Unggah SK Penetapan Inovasi (Opsional) :</h1>
                            <h1 className="text-grey-medium text-xs">Unggah surat keputusan pada saat memilih ‘Pengajuan Penilaian’</h1>
                            <div className='w-full grid grid-cols-2 gap-3'>
                                <div className='space-y-2'>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>File</h1>
                                    <div className='flex items-center gap-[13px]'>
                                        <label htmlFor='upload-file-sk' className='cursor-pointer'>
                                            <div className='flex w-[473px] h-[35px] bg-gray-300 rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                                <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                                    <div className="text-center text-sky-950 text-[9px] font-medium">Cari</div>
                                                </div>
                                                <h1 className='text-zinc-700 text-xs font-medium'>{fileSK.length > 0 ? 'Tambah File Lain' : 'Tidak ada file yang dipilih'}</h1>
                                                <input onChange={(e) => handleFileSKChange(e)} type='file' id='upload-file-sk' multiple accept=".pdf" className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                                            </div>
                                        </label>
                                    </div>
                                    <h1 className=" text-red-600 text-[8px] font-normal">* PDF maks 2 MB</h1>
                                    {fileSK.length > 0 && (
                                        <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                            {fileSK.map((val, index) => (
                                                <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                                    <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                                        <h1>{val[0]?.name}</h1>
                                                        <button
                                                            onClick={() => handleRemoveFileSK(index)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                                <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {dataFileSKInovasi && dataFileSKInovasi.map((file, idx) => (
                                        <div key={idx} className='flex items-center gap-10'>
                                            <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                            <a href={imageHandle(file?.file_path)} download={file?.file_name} target='_blank' className='text-blue-primer font-semibold text-sm flex gap-2'>
                                                <BiDownload className='text-xl' />
                                                <p>Unduh Dokumen</p>
                                            </a>
                                            <button onClick={() => deleteModal(file?.id)} className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                    <path d="M6.32733 3.20436H6.19482C6.2677 3.20436 6.32733 3.14473 6.32733 3.07185V3.20436H11.3629V3.07185C11.3629 3.14473 11.4226 3.20436 11.4955 3.20436H11.3629V4.397H12.5556V3.07185C12.5556 2.48712 12.0802 2.01172 11.4955 2.01172H6.19482C5.61009 2.01172 5.13469 2.48712 5.13469 3.07185V4.397H6.32733V3.20436ZM14.6758 4.397H3.01444C2.72125 4.397 2.48438 4.63388 2.48438 4.92707V5.45713C2.48438 5.53001 2.54401 5.58965 2.61689 5.58965H3.61739L4.02653 14.2529C4.05303 14.8177 4.52015 15.2633 5.085 15.2633H12.6053C13.1718 15.2633 13.6372 14.8194 13.6637 14.2529L14.0729 5.58965H15.0734C15.1463 5.58965 15.2059 5.53001 15.2059 5.45713V4.92707C15.2059 4.63388 14.969 4.397 14.6758 4.397ZM12.4777 14.0707H5.21254L4.81168 5.58965H12.8786L12.4777 14.0707Z" fill="#003049" />
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='flex justify-end'>
                                <button
                                    onClick={postLaporan}
                                    className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditRealisasiBuktiDukungBulan