import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaCaretDown } from "react-icons/fa"
import { IoIosSearch, IoMdArrowDropdown } from "react-icons/io"
import { TbArrowsSort, TbFileExport } from "react-icons/tb"
import { useLocation } from 'react-router-dom'
import Api from '../../../../Api'
import { Navbar, Pagination } from '../../../../components'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

function RekapAbsen({ idcompany }) {
    const [dataAbsen, setDataAbsen] = useState([])
    const [company, setCompany] = useState("")
    const [month, setMonth] = useState(moment().format('M'))
    const [year, setYear] = useState(moment().format('Y'))
    const [search, setSearch] = useState("")
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [dataSubsidiary, setDataSubsidiary] = useState([])
    const getRekapAbsen = async () => {
        try {
            await Api.GetRekapAbsen(localStorage.getItem('hris-token'), month, year, limit, page, company, search).then((response) => {
                // console.log(response.data.data)
                setTotalCount(response.data.totalCount)
                setDataAbsen(response.data.data)
                setTotalPages(response.data.totalPages)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleAbsen = (m, y, s) => {
        if (m) {
            setMonth(m.target.value)
            setRefresh(true)
        } else if (y) {
            setYear(y.target.value)
            setRefresh(true)
        } else if (s) {
            setCompany(s.target.value)
            setRefresh(true)
        }
    }
    const getSubsidiary = async () => {
        try {
            const response = await Api.GetSubsidiaryDropdown(localStorage.getItem('hris-token'))
            setDataSubsidiary(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const handlePageChange = (val) => {
        setPage(val);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (page === 1) {
            setPage(1)
        } else {
            setPage(page - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (page === 10) {
            setPage(10)
        } else {
            setPage(page + 1);
        }
        setRefresh(true)
    };
    const getDataExport = async () => {
        try {
            await Api.GetRekapAbsen(localStorage.getItem('hris-token'), month, year, totalCount, 1, company, search).then((response) => {
                const body = response.data.data;

                // Define custom headers for each table
                const Headers = ["Nama Pegawai", "Bulan", "Tahun", "Total Cuti", "Total Izin", "Total Alfa", "Total DL", "Total Terlambat", "Total Hari Masuk", "Total Hari Kerja"];

                // Create modified data arrays with custom headers
                const data = body.map(({ fullname, bulan, tahun, total_menit_terlambat, total_perjalanan_dinas, total_cuti, total_izin, total_hari_masuk, total_alpha, total_hari_kerja }) => ({
                    "Nama Pegawai": fullname,
                    "Bulan": bulan,
                    "Tahun": tahun,
                    "Total Cuti": total_cuti,
                    "Total Izin": total_izin,
                    "Total Alfa": total_alpha,
                    "Total DL": total_perjalanan_dinas,
                    "Total Terlambat": total_menit_terlambat,
                    "Total Hari Masuk": total_hari_masuk,
                    "Total Hari Kerja": total_hari_kerja
                }));

                // Create a new worksheet for each table
                const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

                // Create a new workbook
                const workbook = XLSX.utils.book_new();

                // Add the worksheets to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Absensi');
                // Generate Excel file buffer
                const excelBuffer = XLSX.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array',
                });

                // Convert buffer to Blob
                const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Save the Excel file using FileSaver.js
                saveAs(excelBlob, 'Rekap Absensi.xlsx');
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getRekapAbsen()
        getSubsidiary()
        setRefresh(false)
    }, [refresh, search])
    return (
        <div className='space-y-[20px]'>
            <div className='space-y-[4]'>
                <label className='text-grey-primer text-[20px] font-semibold'>Rekap Absen</label>
                <p className='text-grey-medium text-xs'>Reporting Data Absensi Pegawai</p>
            </div>
            <div className='flex flex-wrap items-center gap-3 justify-between'>
                <div className='flex flex-wrap items-center gap-3'>
                    <div className='relative'>
                        <select onChange={(e) => handleAbsen(e, null, null)} value={month} className='text-grey-thick text-xs outline-none font-medium rounded-md w-[150px] border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                            <option selected disabled value={''}>Pilih Bulan</option>
                            <option value={'1'} className='text-grey-medium font-normal'>Januari</option>
                            <option value={'2'} className='text-grey-medium font-normal'>Februari</option>
                            <option value={'3'} className='text-grey-medium font-normal'>Maret</option>
                            <option value={'4'} className='text-grey-medium font-normal'>April</option>
                            <option value={'5'} className='text-grey-medium font-normal'>Mei</option>
                            <option value={'6'} className='text-grey-medium font-normal'>Juni</option>
                            <option value={'7'} className='text-grey-medium font-normal'>Juli</option>
                            <option value={'8'} className='text-grey-medium font-normal'>Agustus</option>
                            <option value={'9'} className='text-grey-medium font-normal'>September</option>
                            <option value={'10'} className='text-grey-medium font-normal'>Oktober</option>
                            <option value={'11'} className='text-grey-medium font-normal'>November</option>
                            <option value={'12'} className='text-grey-medium font-normal'>Desember</option>
                        </select>
                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                    </div>
                    <div className='relative'>
                        <select onChange={(e) => handleAbsen(null, e, null)} value={year} className='text-grey-thick text-xs outline-none font-medium rounded-md w-[100px] border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                            <option selected disabled value={''} className='text-grey-medium font-normal'>Pilih Tahun</option>
                            <option value={'2023'} className='text-grey-medium font-normal'>2023</option>
                            <option value={'2024'} className='text-grey-medium font-normal'>2024</option>
                            <option value={'2025'} className='text-grey-medium font-normal'>2025</option>
                            <option value={'2026'} className='text-grey-medium font-normal'>2026</option>
                            <option value={'2027'} className='text-grey-medium font-normal'>2027</option>
                            <option value={'2028'} className='text-grey-medium font-normal'>2028</option>
                            <option value={'2029'} className='text-grey-medium font-normal'>2029</option>
                            <option value={'2030'} className='text-grey-medium font-normal'>2030</option>
                        </select>
                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                    </div>
                    {dataSubsidiary?.length > 0 ?
                        <div className='relative'>
                            <select onChange={(e) => handleAbsen(null, null, e)} className='text-grey-thick text-[11px] outline-none font-medium rounded-md w-[227px] border border-dark-7 py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                <option value={""} selected className='text-grey-medium font-normal'>Pilih Penempatan</option>
                                {dataSubsidiary && dataSubsidiary.map((val, index) => (
                                    <option key={index} value={val.id} className='text-grey-medium font-normal '>{val.company_name}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-thick text-xl' />
                        </div>
                        : null
                    }
                    <button onClick={() => getDataExport()} className='bg-white border border-dark-7 lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] py-[11px] pl-[9px] pr-[16px]'>
                        <TbFileExport className='text-grey-thick' />
                        <h1 className='text-grey-thick text-xs font-medium'>Ekspor</h1>
                    </button>
                </div>
                <div className='flex items-center gap-[14px] border border-grey-extra-thin rounded-[8px] px-[13px] py-[11px] lg:min-w-[250px] min-w-[100px]'>
                    <IoIosSearch className='text-[#8E95A2]' />
                    <input onChange={(e) => setSearch(e.target.value)} className='text-[#8E95A2] text-[10px] outline-none appearance-none' placeholder='Cari Nama Pegawai...' />
                </div>
            </div>
            <div className='overflow-auto'>
                <table className='w-full space-y-[10px]'>
                    <div className='bg-[#EBF7FF] rounded-[3px] border border-[#F1F1F1] p-[9px] flex items-center gap-[20px]'>
                        <div className='flex items-center gap-[11px] min-w-[50px] max-w-[50px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>No</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[180px] max-w-[180px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Nama Pegawai</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[180px] max-w-[180px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Bulan</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[180px] max-w-[180px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Tahun</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Total Cuti</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Total Izin</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Total Alfa</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Total DL</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Total Terlambat</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Total Hari Masuk</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                        <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-dark-5 text-[11px] font-semibold'>Total Hari Kerja</h1>
                            <TbArrowsSort className='text-grey-medium text-[8px]' />
                        </div>
                    </div>
                    {dataAbsen && dataAbsen.map((val, index) => (
                        <div key={index} className='space-y-[10px]'>
                            <div className='bg-white rounded-[3px] p-[9px] flex items-center gap-[20px]'>
                                <div className='flex items-center gap-[11px] min-w-[50px] max-w-[50px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{index + 1}</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[180px] max-w-[180px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.fullname}</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[180px] max-w-[180px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.bulan}</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[180px] max-w-[180px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.tahun}</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.total_cuti ? val.total_cuti : "0"} hari</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.total_izin ? val.total_izin : "0"} hari</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.total_alpha ? val.total_alpha : "0"} hari</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.total_perjalanan_dinas ? val.total_perjalanan_dinas : "0"} hari</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.total_menit_terlambat ? val.total_menit_terlambat : "0"} menit</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.total_hari_masuk ? val.total_hari_masuk : "0"} hari</h1>
                                </div>
                                <div className='flex items-center gap-[11px] min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-grey-medium text-[12px] font-medium line-clamp-1'>{val.total_hari_kerja ? val.total_hari_kerja : "0"} hari</h1>
                                </div>
                            </div>
                        </div>
                    ))}
                </table>
            </div>

            <Pagination
                currentPage={page}
                totalPages={totalPages}
                lengthData={totalCount}
                limitData={limit}
                onPageChange={handlePageChange}
                onPrevChange={handlePrevChange}
                onNextChange={handleNextChange}
            />
        </div>
    )
}

export default RekapAbsen