import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Sidebar } from '../../../../components'
import { MdImportExport } from 'react-icons/md'
import { CgTrashEmpty } from 'react-icons/cg'
import { AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import Api from '../../../../Api'
import moment from 'moment'

const DetailLoan = () => {
    const params = useLocation()
    const [showModal, setShowModal] = useState()
    const [showEditModal, setShowEditModal] = useState()
    const [showModalDelete, setShowModalDelete] = useState()
    
    const [loanData, setLoanData] = useState('')
    const [loanSettingData, setLoanSettingData] = useState('')
    const [loanUserData, setLoanUserData] = useState('')

    const [LoanInstallmentData, setLoanInstallmentData] = useState('')
    const [LoanInstallmentId, setLoanInstallmentId] = useState('')
    const [installmentTo, setInstallmentTo] = useState('')
    const [paymentNominal, setPaymenyNominal] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [loanId, setLoanId] = useState(params.state.loanId)
    const [refresh, setRefresh] = useState(false)

    const GetLoanById = async() => {
        try {
            const response = await Api.GetFinanceLoanById(localStorage.getItem('hris-token'), params.state.loanId)
            setLoanData(response.data[0])
            setLoanSettingData(response.data[0].loSetting)
            setLoanUserData(response.data[0].user)
        } catch (error) {
            console.log(error)
        }
    }

    const GetLoanInstallment = async() => {
        try {
            const response = await Api.GetLoanInstallment(localStorage.getItem('hris-token'))
            setLoanInstallmentData(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const CreateLoanInstallment = async() => {
        try {
            const data = {
                installment_to: installmentTo,
                payment_nominal: paymentNominal,
                payment_date: paymentDate,
                loanId: loanId,
            }
            const response = await Api.CreateLoanInstallment(localStorage.getItem('hris-token'), data)
            setRefresh(true)
            setShowModal(!showModal)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateLoanInstallmentOpen = async(id) => {
        setShowEditModal(!showEditModal)
        setLoanInstallmentId(id)
        try {
            const response = await Api.GetLoanInstallmentById(localStorage.getItem('hris-token'), id)
            setInstallmentTo(response.data[0].installment_to)
            setPaymenyNominal(response.data[0].payment_nominal)
            setPaymentDate(response.data[0].payment_date)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateLoanInstallment = async() => {
        try {
            const data = {
                installment_to: installmentTo,
                payment_nominal: paymentNominal,
                payment_date: paymentDate,
                loanId: loanId,
            }
            const response = await Api.UpdateLoanInstallment(localStorage.getItem('hris-token'), data, LoanInstallmentId)
            setRefresh(true)
            setShowEditModal(!showEditModal)
        } catch (error) {
            console.log(error)
        }
    }

    const DeleteLoanInstallmentModal = async(id) => {
        setShowModalDelete(!showModalDelete)
        setLoanInstallmentId(id)
    }

    const DeleteLoanInstallment = async(id) => {
        try {
            const response = await Api.DeleteLoanInstallment(localStorage.getItem('hris-token'), LoanInstallmentId)
            setRefresh(true)
            setShowModalDelete(!showModalDelete)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetLoanById()   
        GetLoanInstallment()
        setRefresh(false)
    }, [refresh])

    return (
        <div>
            <Modal 
                activeModal={showModal}
                title={'Add Installment'}
                buttonClose={ () => setShowModal(!showModal)}
                width={'553px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        <div className='flex gap-[19px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Installment to</h1>
                                <div className='relative'>
                                    <input type={'number'} min={0} onChange={(e) => setInstallmentTo(e.target.value)} className='bg-white border rounded-[6px] placeholder:text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='0'/>
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Date of Payment</h1>
                                <div className='relative'>
                                    <input type={'date'} onChange={(e) => setPaymentDate(e.target.value)} className='bg-white border rounded-[6px] placeholder:text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px]' placeholder='dd/mm/yyyy'/>
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Payment Nominal<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input type='text' onChange={(e) => setPaymenyNominal(e.target.value)} className='bg-white border rounded-[6px] placeholder:text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Rp' />
                            </div>
                        </div>

                        {/* <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Payment<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='flex gap-[47px]'>
                                <div class="flex items-center mb-5">
                                    <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                    <label for="default-radio-1" class="ml-2 text-dark-5 text-[11px] font-medium">Personal</label>
                                </div>
                                <div class="flex items-center mb-5">
                                    <input checked id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                    <label for="default-radio-2" class="ml-2 text-dark-5 text-[11px] font-medium">Salary</label>
                                </div>
                            </div>
                        </div> */}

                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModal(!showModal)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={CreateLoanInstallment} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditModal}
                title={'Add Installment'}
                buttonClose={ () => setShowEditModal(!showEditModal)}
                width={'553px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        <div className='flex gap-[19px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Installment to</h1>
                                <div className='relative'>
                                    <input type={'number'} min={0} value={installmentTo} onChange={(e) => setInstallmentTo(e.target.value)} className='bg-white border rounded-[6px] placeholder:text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='0'/>
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Date of Payment</h1>
                                <div className='relative'>
                                    <input type={'date'} value={paymentDate} onChange={(e) => setPaymentDate(e.target.value)}  className='bg-white border rounded-[6px] placeholder:text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px]' placeholder='dd/mm/yyyy'/>
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Payment Nominal<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input type='text' value={paymentNominal} onChange={(e) => setPaymenyNominal(e.target.value)} className='bg-white border rounded-[6px] placeholder:text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Rp' />
                            </div>
                        </div>

                        {/* <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Payment<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='flex gap-[47px]'>
                                <div class="flex items-center mb-5">
                                    <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                    <label for="default-radio-1" class="ml-2 text-dark-5 text-[11px] font-medium">Personal</label>
                                </div>
                                <div class="flex items-center mb-5">
                                    <input checked id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                    <label for="default-radio-2" class="ml-2 text-dark-5 text-[11px] font-medium">Salary</label>
                                </div>
                            </div>
                        </div> */}

                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowEditModal(!showEditModal)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={UpdateLoanInstallment} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={DeleteLoanInstallment}
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Detail Loan'} LinkBack={'/finance/loan'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <h1 className='text-[20px] text-dark-3 font-semibold'>{loanUserData.fullname}</h1>
                        <div>
                            <h2 className='text-[11px] font-semibold mb-[7px] text-dark-3'>Loan Name</h2>
                            <p className='text-[12px] text-[#A8A8A8] mb-[16px]'>{loanSettingData.name}</p>

                            <h2 className='text-[11px] font-semibold mb-[7px] text-dark-3'>Description</h2>
                            <p className='text-[12px] text-[#A8A8A8] mb-[16px]'>{loanData.note}</p>

                            <div className='flex gap-[68px]'>
                                <div>
                                    <h2 className='text-[11px] font-semibold mb-[7px] text-dark-3'>Use Date</h2>
                                    <p className='text-[12px] text-[#A8A8A8] mb-[16px]'>{moment(loanData.use_date).format('DD/MM/YYYY')}</p>
                                </div>
                                <div>
                                    <h2 className='text-[11px] font-semibold mb-[7px] text-dark-3'>Request Date</h2>
                                    <p className='text-[12px] text-[#A8A8A8] mb-[16px]'>{moment(loanData.request_date).format('DD/MM/YYYY   ')}</p>
                                </div>
                            </div>

                            {/* <h2 className='text-[11px] font-semibold mb-[7px] text-dark-3'>Payment</h2>
                            <p className='text-[12px] text-[#A8A8A8] mb-[16px]'>Personal</p> */}
                        </div>

                        <div className='w-full relative h-5'>
                            <button onClick={ () => setShowModal(!showModal)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px] absolute right-0'>
                                <AiOutlinePlus  className='text-xl text-white'/>
                                <h1 className='text-dark-9 text-sm font-[500]'>Add Installment</h1>
                            </button>
                        </div>
                        <hr />
                        <div className='border shadow rounded-lg'>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[90px] max-w-[90px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] flex items-center justify-center'>Instalment</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px] justify-center'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] flex items-center justify-center'>Payment Date</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px] justify-center'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] flex items-center justify-center'>Payment</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px] justify-center'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Remaining Payment</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Action</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                    </div>
                                    {LoanInstallmentData.length === 0 ?
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[90px] max-w-[90px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate flex justify-center'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate flex items-center justify-center'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate flex items-center justify-center'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate flex items-center justify-center'>-</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center  gap-[6px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate flex justify-center'>-</h1>
                                        </div>
                                    </div>
                                    :
                                    Object.values(LoanInstallmentData).map((data, index) => {
                                        return (
                                            <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[90px] max-w-[90px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate flex justify-center'>{data.installment_to}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate flex items-center justify-center'>{moment(data.payment_date).format('DD/MM/YYYY')}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate flex items-center justify-center'>{data.payment_nominal}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate flex items-center justify-center'>{data.loan?.amount - data.payment_nominal}</h1>
                                                </div>
                                                <div className='w-full flex items-center justify-center  gap-[6px]'>
                                                    <button onClick={ () => UpdateLoanInstallmentOpen(data.id) } className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                    </button>
                                                    <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty onClick={ () => DeleteLoanInstallmentModal(data.id) } className='text-[#003049]'/>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* <div> */}
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default DetailLoan