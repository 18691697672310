import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function PieChart() {
  const [series, setSeries] = useState([44, 55, 41]);
  const [options] = useState({
    chart: {
      type: 'donut',
    },
    labels: ['Done', 'Todo', 'Pending'],
    colors: ['#304676', '#ED6F51', '#9ED4D9'],
    dataLabels:{
        style:{
            fontSize: '12px',
        }
    },
    plotOptions: {
        pie: {
          donut: {
            size: '50%',
            distance: '10px'
          },
          expandOnClick: false
        },
      },
  });

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} width={350} height={320} type="donut" />
    </div>
  );
}

export default PieChart