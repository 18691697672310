import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { LogoH } from '../../../../assets'
import { InboxDetailCashAdvance, InboxDetailLeave, InboxDetailLoan, InboxDetailOvertime, InboxDetailPermission, InboxDetailReimburstment, InboxDetailWorkTravel, Navbar, Sidebar } from '../../../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import imageHandle from '../../../../utils/imageHandle'
import toast from 'react-hot-toast'
import InboxDetailPresensiManual from '../../../../components/InboxDetail/InboxDetailPresensiManual'

const InboxDetail = () => {
    const navigate = useNavigate()
    const params = useLocation()
    const type = params.state.type
    
    const [data, setData] = useState('')
    const [settingData, setSettingData] = useState('')
    const [dataJobPosition, setDataJobPosition] = useState('')
    const [userData, setUserData] = useState('')
    const [toUser, setToUser] = useState('')
    const [emergencyContact, setEmergencyContact] = useState('')

    // Recruitment
    const [recruitmentData, setRecruitmentData] = useState('')

    const GetDetail = async() => {
        try {
            if(params.state.type === 'Reimbursment' || params.state.type === 'Reimbursement') {
                const response = await Api.GetFinanceReimbursmentById(localStorage.getItem('hris-token'), params.state.applicantId)
                console.log(response, 'reim')
                setData(response.data[0])
                setSettingData(response.data[0].rbSetting)
                setUserData(response.data[0].user)
                setToUser(response.data[0].user.id)
                setDataJobPosition(response.data[0].user.buat[0].job_position.position_name)
            }else if(params.state.type ==='Loan') {
                const response = await Api.GetFinanceLoanById(localStorage.getItem('hris-token'), params.state.applicantId)
                setData(response.data[0])
                setSettingData(response.data[0].loSetting)
                setUserData(response.data[0].user)
                setToUser(response.data[0].user.id)
                setDataJobPosition(response.data[0].user.buat[0].job_position.position_name)


            }else if(params.state.type ==='Cash Advance') {
                const response = await Api.GetFinanceCashAdvanceById(localStorage.getItem('hris-token'), params.state.applicantId)
                setData(response.data[0])
                setSettingData(response.data[0].caSetting)
                setUserData(response.data[0].user)
                setToUser(response.data[0].user.id)


            }else if (params.state.type === 'Leave' || params.state.type ==='LeaveDelegasi'){
                const response = await Api.getLeaveById(localStorage.getItem('hris-token'), params.state.applicantId)
                // console.log(response.data, "data leave")
                setData(response.data.leave)
                setEmergencyContact(response.data.leave.emergency_contact)
                setSettingData(response.data.leave.leavetype)
                setUserData(response.data.leave.user)
                setToUser(response.data.leave.user.id)
                setDataJobPosition(response.data.leave.user.user_detail.job_position.position_name)

            }else if (params.state.type === 'Permission'){
                const response = await Api.GetPermissionById(localStorage.getItem('hris-token'), params.state.applicantId)
                console.log(response?.data?.permission)
                setData(response?.data?.permission)
                setUserData(response?.data?.permission?.user)
                setToUser(response?.data?.permission?.user.id)
                setDataJobPosition(response?.data?.permission?.user.user_detail.job_position.position_name)

            }else if (params.state.type === 'Overtime'){
                const response = await Api.GetOvertimeById(localStorage.getItem('hris-token'), params.state.applicantId)
                setData(response.data)
                setUserData(response.data.user)
                setToUser(response.data.user.id)
                setDataJobPosition(response.data.user.buat[0].job_position.position_name)

            }else if (params.state.type === 'Aplicant Recruitment') {
                const response = await Api.GetRecruitmentApplicantById(localStorage.getItem('hris-token'), params.state.applicantId)
                setRecruitmentData(response.data.applicant)
            }else if (params.state.type === 'WorkTravel') {
                const response = await Api.GetWorkTrravelSettingById(localStorage.getItem('hris-token'), params.state.applicantId)
                console.log("worktravel",response.data)
                setData(response.data)
                setUserData(response.data.user)
                setToUser(response.data.user.id)
                setDataJobPosition(response.data.user.user_detail.job_position.position_name)
            }else if (params.state.type === 'PresensiManual') {
                const response = await Api.GetPresensiManualById(localStorage.getItem('hris-token'), params.state.applicantId)
                setData(response.data.results)
                setUserData(response.data.results.presensi.user)
                setToUser(response.data.results.presensi.user.id)
                // setDataJobPosition(response.data.results.presensi.user.user_detail.job_position.position_name)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const Approve = async(id) => {
        try {
            const data = {
                status: 'approved'
            }
            const dataWotkTravel = {
                status: 'approved',
            }
            
            if(params.state.type === 'Reimbursment' || params.state.type === 'Reimbursement') {
                const response = await Api.PostInboxApprovallReimbursment(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Loan') {
                const response = await Api.PostInboxApprovallLoan(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Cash Advance') {
                const response = await Api.PostInboxApprovallCashAdvance(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Leave') {
                const response = await Api.PostInboxApprovallLeave(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='Permission') {
                const response = await Api.PostInboxApprovallPermission(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='Overtime') {
                const response = await Api.PostInboxApprovallOvertime(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='WorkTravel') {
                const response = await Api.PostInboxApprovallWorkTravel(localStorage.getItem('hris-token'), dataWotkTravel, id)
            }  else if(params.state.type ==='PresensiManual') {
                const response = await Api.PostInboxApprovallPresensiManual(localStorage.getItem('hris-token'), dataWotkTravel, id)
            }  
            toast.success(`${params.state.type} Disetujui`)
            navigate(-1)
        } catch (error) {
            console.log(error)
        }
    }
    const Ditangguhkan = async(id) => {
        try {
            const data = {
                status: 'ditangguhkan'
            }
            const dataWotkTravel = {
                status: 'approved',
            }
            
            if(params.state.type === 'Reimbursment' || params.state.type === 'Reimbursement') {
                const response = await Api.PostInboxApprovallReimbursment(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Loan') {
                const response = await Api.PostInboxApprovallLoan(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Cash Advance') {
                const response = await Api.PostInboxApprovallCashAdvance(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Leave') {
                const response = await Api.PostInboxApprovallLeave(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='Permission') {
                const response = await Api.PostInboxApprovallPermission(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='Overtime') {
                const response = await Api.PostInboxApprovallOvertime(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='WorkTravel') {
                const response = await Api.PostInboxApprovallWorkTravel(localStorage.getItem('hris-token'), dataWotkTravel, id)
            }  
            toast.success(`${params.state.type} Ditangguhkan`)
            navigate(-1)
        } catch (error) {
            console.log(error)
        }
    }
    
    const Decline = async(id) => {
        try {
            const data = {
                status: 'decline',
            }
            const dataWotkTravel = {
                status: 'decline',
            }

            if(params.state.type === 'Reimbursment' || params.state.type === 'Reimbursement') {
                const response = await Api.PostInboxApprovallReimbursment(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Loan') {
                const response = await Api.PostInboxApprovallLoan(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Cash Advance') {
                const response = await Api.PostInboxApprovallCashAdvance(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='Leave') {
                const response = await Api.PostInboxApprovallLeave(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Permission') {
                const response = await Api.PostInboxApprovallPermission(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Overtime') {
                const response = await Api.PostInboxApprovallOvertime(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='WorkTravel') {
                const response = await Api.PostInboxApprovallWorkTravel(localStorage.getItem('hris-token'), dataWotkTravel, id)
            }  else if(params.state.type ==='PresensiManual') {
                const response = await Api.PostInboxApprovallPresensiManual(localStorage.getItem('hris-token'), dataWotkTravel, id)
            }
            toast.success(`${params.state.type} Ditolak`)
            navigate(-1)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetDetail()
    }, [])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Detail Pesan Masuk'} LinkBack={'/inbox'}/>
                <div className='space-y-[24px] bg-white border px-[36px] py-[26px] rounded-[12px]'>
                    <div className='border-b-2 border-dark-5 py-2 space-y-[24px]'>
                        {type === 'Reimbursement' || type === 'Reimbursment' ?
                            <h1 className='text-dark-3 text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Pengajuan Penggantian Biaya perlu disetujui untuk ${userData.fullname}` : `Pengajuan Penggantian Biaya ${data.status}`}</h1>
                        : type === 'Loan' ?
                            <h1 className='text-dark-3 text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Pengajuan Peminjaman perlu disetujui untuk ${userData.fullname}` : `Pengajuan Peminjaman ${data.status}`}</h1>
                        : type === 'Cash Advance' ?
                            <h1 className='text-dark-3 text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Pengajuan Penarikan tunai perlu disetujui untuk ${userData.fullname}` : `Pengajuan Penarikan tunai ${data.status}`}</h1>
                        : (type === 'Leave' || type ==='LeaveDelegasi') ?
                            <h1 className='text-dark-3 text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Pengajuan Cuti perlu disetujui untuk ${userData.fullname}` : `Pengajuan Cuti ${data?.status === "approved" ? "Disetujui" : data?.status === "decline" ? "Ditolak" : data?.status === "ditangguhkan" ? "Ditangguhkan" : ""}`}</h1>
                        : type === 'Permission' ?
                            <h1 className='text-dark-3 text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Pengajuan Izin perlu disetujui untuk ${userData.fullname}` : `Pengajuan Izin ${data?.status === "approved" ? "Disetujui" : data?.status === "decline" ? "Ditolak" : data?.status === "ditangguhkan" ? "Ditangguhkan" : ""}`}</h1>
                        : type === 'Overtime' ?
                            <h1 className='text-dark-3 text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Pengajuan Lembur perlu disetujui untuk ${userData.fullname}` : `Pengajuan Lembur ${data?.status === "approved" ? "Disetujui" : data?.status === "decline" ? "Ditolak" : data?.status === "ditangguhkan" ? "Ditangguhkan" : ""}`}</h1>
                        : type === 'WorkTravel' ?
                            <h1 className='text-dark-3 text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Pengajuan Perjalanan Dinas perlu disetujui untuk ${userData.fullname}` : `Pengajuan Perjalanan Dinas ${data?.status === "approved" ? "Disetujui" : data?.status === "decline" ? "Ditolak" : data?.status === "ditangguhkan" ? "Ditangguhkan" : ""}`}</h1>
                        : type === 'PresensiManual' ?
                            <h1 className='text-dark-3 text-[20px] font-bold'>Pengajuan Formulir Khilaf Asisten</h1>
                        :
                        <h1 className='text-dark-3 text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Someone has applied for a job from ${recruitmentData.name}` : null}</h1>
                        }
                        <div className='flex flex-col lg:flex-row lg:items-center  justify-between'>
                            <div className='flex items-center gap-[8px]'>
                                <img src={LogoH} className='w-[41px] h-[41px] rounded-full object-cover border' alt='Humanusia'/>
                                <div className='space-y-0.5'>
                                    {type === 'Reimbursement' || type === 'Reimbursment' ?
                                        <h1 className='text-dark-3 text-sm font-[500]'>{userData?.fullname}</h1>
                                    : type === 'Loan' ?
                                        <h1 className='text-dark-3 text-sm font-[500]'>{userData?.fullname}</h1>
                                    : type === 'Cash Advance' ?
                                        <h1 className='text-dark-3 text-sm font-[500]'>{userData?.fullname}</h1>
                                    : (type === 'Leave' || type ==='LeaveDelegasi') ?
                                        <h1 className='text-dark-3 text-sm font-[500]'>{userData?.fullname}</h1>
                                    : type === 'Permission' ?
                                        <h1 className='text-dark-3 text-sm font-[500]'>{userData?.fullname}</h1>
                                    : type === 'Overtime' ?
                                        <h1 className='text-dark-3 text-sm font-[500]'>{userData?.fullname}</h1>
                                    : type === 'WorkTravel' ?
                                        <h1 className='text-dark-3 text-sm font-[500]'>{userData?.fullname}</h1>
                                    : type === 'PresensiManual' ?
                                        <h1 className='text-dark-3 text-sm font-[500]'>{userData?.fullname}</h1>
                                    :
                                        <h1 className='text-dark-3 text-sm font-[500]'>{recruitmentData?.name}</h1>
                                    }
                                    <h1 className='text-dark-4 text-[11px]'>{moment(data.createdAt).format('DD MMMM YYYY hh.mm')}</h1>
                                </div>
                            </div>
                            {/* <h1 className='text-dark-3 text-xs font-semibold mt-3 lg:mt-0'>{moment(data.createdAt).format('DD MMMM YYYY hh.mm')}</h1> */}
                        </div>
                    </div>
                    {/* <h1 className='text-dark-3 text-sm'>Reimbursement of funds has been approved on August 30, 2022 amounting to Rp. 350,000 and has been sent to your bank account.</h1> */}
                    { 
                        type === 'Reimbursement' || type === 'Reimbursment' ?
                            <InboxDetailReimburstment
                                EmployeeName={userData?.fullname}
                                jobPosition={dataJobPosition?? '-'}
                                Policy={settingData?.name}
                                Amount={data?.amount}
                                UseDate={moment(data?.use_date).format('DD/MM/YYYY')}
                                Notes={data?.note}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={data?.status ? true : false}
                            />
                        : type === 'Loan' ?
                            <InboxDetailLoan
                                EmployeeName={userData?.fullname}
                                jobPosition={dataJobPosition?? '-'}
                                Policy={settingData?.name}
                                UseDate={moment(data?.use_date).format('DD/MM/YYYY')}
                                Interest={data?.interest}
                                Installment={data?.max_installment}
                                Amount={data?.amount}
                                Notes={data?.note}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={data?.status ? true : false}
                            />
                        : type === 'Cash Advance' ?
                            <InboxDetailCashAdvance
                                EmployeeName={userData?.fullname}
                                jobPosition={dataJobPosition?? '-'}
                                Policy={settingData?.name}
                                RequestDate={moment(data?.request).format('DD/MM/YYYY')}
                                DateUse={moment(data?.use_date).format('DD/MM/YYYY')}
                                Amount={data?.amount}
                                Notes={data?.note}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={data?.status ? true : false}
                            />
                        : (type === 'Leave' || type ==='LeaveDelegasi') ?
                            <InboxDetailLeave
                                id={data.id}
                                EmployeeName={userData?.fullname}
                                JobPosition={dataJobPosition}
                                StartDate={moment(data?.start_date).format('DD/MM/YYYY')}
                                EndDate={moment(data?.end_date).format('DD/MM/YYYY')}
                                Type={settingData?.type_name}
                                LeaveDuration={data?.leave_duration}
                                RemainingDayOff={data?.remaining_daysof}
                                Notes={data?.notes}
                                DelegatedEmployee={data?.delegatedEmployee?.fullname}
                                DelegatedTask={data?.delegated_task}
                                EmergencyContact={emergencyContact.name}
                                EmergencyPhone={emergencyContact.mobile}
                                EmergencyAddress={emergencyContact.address}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                onClickDitangguhkan={() => Ditangguhkan(data.id)}
                                buttonHide={data}
                                delegate={type}
                            />
                        : type === 'Permission' ?
                            <InboxDetailPermission
                                EmployeeName={userData?.fullname}
                                JobPosition={dataJobPosition?? '-'}
                                PermissionReason={data?.permission_reason}
                                StartDate={data?.start_date}
                                EndDate={data?.end_date}
                                Duration={data?.duration}
                                // DateFilling={data?.dateof_filling}
                                // SubmissionDate={data?.submission_time}
                                // NumberOfDays={data?.num_ofdays}
                                // StartTime={data?.start_time}
                                // EndTime={data?.end_time}
                                // Date={data?.backto_work}
                                // Time={data?.backto_work_time}
                                Address={data?.alamat_selama_izin}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={data?.status == "pending" ? true : false}
                            />
                        : type === 'Overtime' ?
                            <InboxDetailOvertime
                                EmployeeName={userData?.fullname}
                                JobPosition={dataJobPosition?? '-'}
                                OvertimeSubDate={data?.overt_submission_date}
                                OvertimeOnDate={data?.overt_ondate}
                                StartTime={data?.start_time}
                                EndTIme={data?.end_time}
                                Description={data?.reason}
                                BackToWork={data?.back_to_work}
                                BackToWorkTime={data?.back_to_work_time}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={data?.status == "pending" ? true : false}
                            />
                        : type === 'WorkTravel' ?
                            <InboxDetailWorkTravel
                                EmployeeName={userData?.fullname}
                                JobPosition={dataJobPosition?? '-'}
                                TypeName={data?.office_work_travel?.type_name}
                                StartDate={data?.start_date}
                                EndDate={data?.end_date}
                                EndTIme={data?.end_time}
                                Location={data?.location}
                                fileDownload={'-'}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={data?.status == "pending" ? true : false}
                            />
                        : type === 'PresensiManual' ?
                            <InboxDetailPresensiManual
                                detail={data}
                                onClickDecline={() => Decline(data?.presensi?.id)}
                                onClickApprove={() => Approve(data?.presensi?.id)}
                                buttonHide={data?.presensi?.status == "pending" ? true : false}
                            />
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default InboxDetail