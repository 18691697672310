import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination} from '../../../../components'
import { MdImportExport} from 'react-icons/md'
import { AiFillCheckCircle, AiFillClockCircle, AiFillEye, AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { BiSearch } from 'react-icons/bi'
import { IoMdArrowDropdown } from 'react-icons/io'
import {useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import moment from 'moment'
import { FaTimesCircle } from 'react-icons/fa'
import { debounce } from 'lodash'
import { toast } from 'react-hot-toast'

const Loan = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [showModalAddLoan, setShowModalAddLoan] = useState()
    const [showModalEditLoan, setShowModalEditLoan] = useState()
    const [showModalDelete, setShowModalDelete] = useState()

    const [loanId, setLoanId] = useState('')
    const [loanData, setLoanData] = useState('')
    const [loanDataSetting, setLoanDataSetting] = useState('')
    const [loanDataSettingId, setLoanDataSettingId] = useState('')
    const [amount, setAmount] = useState('')
    const [useDate, setUseDate] = useState('')
    const [requestDate, setRequestDate] = useState('')
    const [note, setNote] = useState('')
    const [interest, setInterest] = useState('')
    const [maxInstallment, setMaxInstallment] = useState('')
    const [username, setUsername] = useState('')
    const [userId, setUserId] = useState('')
    const [loanSettingId, setLoanSettingId] = useState('')
    const [refresh, setRefresh] = useState(false)

    const [limit, setLimit] = useState(5)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [nameSearch, setNameSearch] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const navigate = useNavigate()

    const Fetch = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUsername(response.data.results.data.fullname)
            setUserId(response.data.results.data.id)
        } catch (error) {
            console.log(error)
        }
    }

    const GetLoanDataSetting = async() => {
        try {
            const response = await Api.GetFinanceLoanSetting(localStorage.getItem('hris-token'), '1', 100)
            setLoanDataSetting(response.data.data)
        } catch (error) {
            
        }
    }
    
    const GetLoanDataSettingId = async() => {
        try {
            const response = await Api.GetFinanceLoanSettingById(localStorage.getItem('hris-token'), loanSettingId)
            setLoanDataSettingId(response.data[0])
            setInterest(response.data[0].interest)
            setMaxInstallment(response.data[0].max_installment)
        } catch (error) {
            console.log(error)
        }
    }

    const GetLoanData = async() => {
        try {
            const response = await Api.GetFinanceLoan(localStorage.getItem('hris-token'), nameSearch, currentPage, limit)
            setLoanData(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            console.log(response, 'getLoan')
        } catch (error) {
            console.log(error)
        }
    }

    const debouncedSearchName = debounce(async(name) => {
        try {
            const response = await Api.GetFinanceLoan(localStorage.getItem('hris-token'), name, currentPage, limit)
            setLoanData(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const debouncedLimit = debounce(async(limit) => {
        try {
            const response = await Api.GetFinanceLoan(localStorage.getItem('hris-token'), nameSearch, currentPage, limit)
            setLoanData(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleLimit= (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimit(limit)
    }

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }

    const handleForm = () => {
        setAmount('')
        setUseDate('')
        setRequestDate('')
        setInterest('')
        setMaxInstallment('')
        setLoanSettingId('')
        setNote('')
    }

    const CreateLoan = async() => {
        try {
            const data = {
                amount: amount,
                use_date: useDate,
                request_date: requestDate,  
                interest: JSON.stringify(interest),
                note: note,
                max_installment: JSON.stringify(maxInstallment),
                userId: userId,
                loanSettingId: loanSettingId
            }
            if (amount < 0){
                toast.error('Amount cannot be less than zero')
            } else{
                console.log(data)
                setIsLoading(true)
                const response = await Api.CreateFinanceLoan(localStorage.getItem('hris-token'), data)
                setIsLoading(false)
                handleForm()
                setRefresh(true)
                setShowModalAddLoan(!showModalAddLoan)
                toast.success('Success create loan')
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Field cannot be empty!')
        }
    }
    
    const UpdateLoanModalOpen = async(id) => {
        setShowModalEditLoan(!showModalEditLoan)
        setLoanId(id)
        try {
            const response = await Api.GetFinanceLoanById(localStorage.getItem('hris-token'), id)
            console.log(response)
            setAmount(response.data[0].amount)
            setUseDate(response.data[0].use_date)
            setRequestDate(response.data[0].request_date)
            setInterest(response.data[0].interest)
            setMaxInstallment(response.data[0].max_installment)
            setLoanSettingId(response.data[0].loanSetting.id)
            setNote(response.data[0].note)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateLoan = async() => {
        try {
            const data = {
                amount: amount,
                use_date: useDate,
                request_date: requestDate,  
                interest: JSON.stringify(interest),
                note: note,
                max_installment: JSON.stringify(maxInstallment),
                userId: userId,
                loanSettingId: loanSettingId
            }
            if (amount < 0){
                toast.error('Amount cannot be less than zero')
            } else{
                console.log(data)
                setIsLoading(true)
                const response = await Api.UpdateFinanceLoan(localStorage.getItem('hris-token'), data, loanId)
                setIsLoading(false)
                handleForm()
                setRefresh(true)
                setShowModalEditLoan(!showModalEditLoan)
                toast.success('Success update loan')
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Field cannot be empty!')
        }
    }

    const DeleteLoanModal = async(id) => {
        setShowModalDelete(!showModalDelete)
        setLoanId(id)
    }

    const DeleteLoan = async(id) => {
        try {
            const response = await Api.DeleteFinanceLoan(localStorage.getItem('hris-token'), loanId)
            setRefresh(true)
            setShowModalDelete(!showModalDelete)
            toast.success('Success delete loan')
        } catch (error) {
            console.log(error)
            toast.error('Failed delete!')
        }
    }

    useEffect(() => {
        Fetch()
        GetLoanData()
        setRefresh(false)
    }, [refresh, limit])
    
    useEffect(() => {
        GetLoanDataSetting()
        GetLoanDataSettingId()
    }, [loanSettingId])

    return (
        <div>
            <Modal 
                activeModal={showModalAddLoan}
                title={'Add Loan'}
                buttonClose={ () => setShowModalAddLoan(!showModalAddLoan)}
                width={'553px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Loan Policy<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={(e) => setLoanSettingId(e.target.value)} value={loanSettingId} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select cash advance policy</option>
                                    {Object.values(loanDataSetting).map((data, index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input value={username} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Employee Name' readOnly/>
                            </div>
                        </div>

                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Request Date</h1>
                                <div className='relative'>
                                    <input type='date' onChange={(e) => setRequestDate(e.target.value)} value={requestDate} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' />
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Date of Use</h1>
                                <div className='relative'>
                                    <input type='date' onChange={(e) => setUseDate(e.target.value)} value={useDate} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' />
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Amount<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input type='number' onChange={(e) => setAmount(e.target.value)} value={amount} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Rp' />
                            </div>
                        </div>
                        
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Interest</h1>
                                <input value={interest} type='text'  className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' readOnly/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Max Installment</h1>
                                <input value={maxInstallment} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' readOnly/>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Note</h1>
                            <textarea rows={'2'} onChange={(e) => setNote(e.target.value)} value={note} className='resize-none bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Note...'>
                            </textarea>
                        </div>

                        {/* <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Payment<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='flex gap-[47px]'>
                                <div class="flex items-center mb-5">
                                    <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                    <label for="default-radio-1" class="ml-2 text-dark-5 text-[11px] font-medium">Personal</label>
                                </div>
                                <div class="flex items-center mb-5">
                                    <input checked id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                    <label for="default-radio-2" class="ml-2 text-dark-5 text-[11px] font-medium">Salary</label>
                                </div>
                            </div>
                        </div> */}
                        
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalAddLoan(!showModalAddLoan)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={isLoading ? null : CreateLoan} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showModalEditLoan}
                title={'Edit Loan'}
                buttonClose={ () => setShowModalEditLoan(!showModalEditLoan)}
                width={'553px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Loan Policy<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={(e) => setLoanSettingId(e.target.value)} value={loanSettingId} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select cash advance policy</option>
                                    {Object.values(loanDataSetting).map((data, index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input value={username} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Employee Name' readOnly/>
                            </div>
                        </div>

                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Request Date</h1>
                                <div className='relative'>
                                    <input type='date' value={moment(requestDate).format('YYYY-MM-DD')} onChange={(e) => setRequestDate(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' />
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Date of Use</h1>
                                <div className='relative'>
                                    <input type='date' value={moment(useDate).format('YYYY-MM-DD')} onChange={(e) => setUseDate(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' />
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Amount<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input type='number' value={amount} onChange={(e) => setAmount(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Rp' />
                            </div>
                        </div>
                        
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Interest</h1>
                                <input value={interest} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' readOnly/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Max Installment</h1>
                                <input value={maxInstallment} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' readOnly/>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Note</h1>
                            <textarea rows={'2'} value={note} onChange={(e) => setNote(e.target.value)} className='resize-none bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Note...'>
                            </textarea>
                        </div>

                        {/* <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Payment<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='flex gap-[47px]'>
                                <div class="flex items-center mb-5">
                                    <input id="default-radio-1" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                    <label for="default-radio-1" class="ml-2 text-dark-5 text-[11px] font-medium">Personal</label>
                                </div>
                                <div class="flex items-center mb-5">
                                    <input checked id="default-radio-2" type="radio" value="" name="default-radio" class="w-4 h-4 bg-gray-100 border-gray-300"/>
                                    <label for="default-radio-2" class="ml-2 text-dark-5 text-[11px] font-medium">Salary</label>
                                </div>
                            </div>
                        </div> */}
                        
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalEditLoan(!showModalEditLoan)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={isLoading ? null : UpdateLoan} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={DeleteLoan}
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Loan'} LinkBack={'/finance'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className='text-[20px]'>Loan</h1>
                            <h2 className='text-[10px] text-dark-5'>List of Loan </h2>
                        </div>
                        <div className="flex gap-2 mt-[31px] justify-between">
                            <div className='flex gap-2'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input onChange={handleSearchName} placeholder='Search...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                    <option value="" selected disabled>Set Limit</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                </select>
                            </div>
                            <button onClick={()=>setShowModalAddLoan(!showModalAddLoan)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus  className='text-xl text-white'/>
                                <h1 className='text-dark-9 text-sm font-[500]'>Add Loan</h1>
                            </button>
                        </div>
                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px] text-dark-5'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Employee</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Use Date</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Request Date</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Loan Policy </h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Interest </h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Max Installment </h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Amount</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Note</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Approval</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Actions</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                    </div>
                                    {loanData.length === 0 ?
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px] flex justify-center'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px] '>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className=' min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className=' min-w-fit max-w-fit'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px] flex items-center justify-center gap-[6px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                    </div>
                                    :
                                    Object.values(loanData).map((data, index) => {
                                        return (
                                            <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.user.fullname}</h1>
                                                </div>
                                                <div className='min-w-[120px] max-w-[120px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(data.use_date).format('DD/MM/YYYY')}</h1>
                                                </div>
                                                <div className='min-w-[120px] max-w-[120px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(data.request_date).format('DD/MM/YYYY')}</h1>
                                                </div>
                                                <div className='min-w-[120px] max-w-[120px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.loSetting.name}</h1>
                                                </div>
                                                <div className='min-w-[120px] max-w-[120px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.interest}%</h1>
                                                </div>
                                                <div className='min-w-[120px] max-w-[120px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.max_installment}</h1>
                                                </div>
                                                <div className=' min-w-[120px] max-w-[120px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.amount}</h1>
                                                </div>
                                                <div className=' min-w-[120px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.note}</h1>
                                                </div>
                                                <div className='min-w-[120px] max-w-[120px] flex justify-center items-center'>
                                                    {data.status === 'pending' ? 
                                                        <AiFillClockCircle className='text-base-yellow w-[29px] h-[29px]'/>
                                                    :data.status === 'approved' ?
                                                        <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]'/>
                                                    :
                                                        <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]'/>
                                                    }
                                                </div>
                                                <div className='w-full flex items-center justify-center gap-[12px]'>    
                                                    <button onClick={() => navigate('/finance/detail-loan', {state: {loanId : data.id}})} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <AiFillEye className='text-[#003049]'/>
                                                    </button>
                                                    {data.status === 'approved' ?
                                                    <button disabled onClick={ () => UpdateLoanModalOpen(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                     </button>
                                                    :
                                                    <button onClick={ () => UpdateLoanModalOpen(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                    </button>
                                                    }
                                                    
                                                    <button onClick={() => DeleteLoanModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]'/>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPage} 
                                totalPages={totalPages} 
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loan