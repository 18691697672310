import React from 'react'
import { Navbar, Sidebar } from '../../../../components'

const Nationalities = () => {
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Nationalities'} LinkBack={'/dashboard'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nationalities