import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ExampleNews, LogoH } from '../../../../assets'
import { DCardNews, ModalDelete, Navbar, Pagination } from '../../../../components'
import { AiOutlineSearch, AiOutlinePlus, AiOutlineEye } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import imageHandle from '../../../../utils/imageHandle'
import { toast } from 'react-hot-toast'
import { debounce } from 'lodash'
import languages from '../../../../utils/languages'

const News = () => {

    const navigate = useNavigate()
    const [refresh, setRefresh] = useState(false)
    const [idNews, setIdNews] = useState('')
    const [dataNews, setDataNews] = useState('')

    const limit = 5
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [titleSearch, setTitleSearch] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    
    const getDataNews = async() => {
        try {
            const resNews = await Api.GetNews(localStorage.getItem('hris-token'), '', currentPage, limit)
            setDataNews(resNews.data.news)
            setCurrentPage(resNews.data.currentPage)
            setTotalPages(resNews.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }

    const debouncedSearchTitle = debounce(async(title) => {
        try {
            const resNews = await Api.GetNews(localStorage.getItem('hris-token'), title , currentPage, limit)
            setDataNews(resNews.data.news)
            setCurrentPage(resNews.data.currentPage)
            setTotalPages(resNews.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchTitle = (e) => {
        const searchTitle = e.target.value
        debouncedSearchTitle(searchTitle)
    }

    const deleteNews = async() => {
        try {
            const response = await Api.DeleteNews(localStorage.getItem('hris-token'), idNews)
            setShowModalDelete(!showModalDelete)
            setRefresh(true)
            toast.success('Success delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed delete')
        }
    }

    const [showModalDelete, setShowModalDelete] = useState()

    const deleteNewsModal = (id) =>{
        setShowModalDelete(!showModalDelete)
        setIdNews(id)
        setRefresh(true)
    }


    useEffect(() => {
        getDataNews()
        setRefresh(false)
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
        <ModalDelete
            activeModal={showModalDelete}
            buttonClose={() => setShowModalDelete(!showModalDelete)}
            submitButton={deleteNews}
        />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={"Berita Ombudsman"} LinkBack={'/dashboard'}/>
                <div className='space-y-[24px]'>
                    {/* <div className='border-b-2 py-2'>
                        <h1 className='text-dark-3 text-[24px] font-bold'>{"Berita Ombudsman"}</h1>
                    </div> */}
                    <div>
                        <h1 className='text-dark-3 text-[16px] font-[500]'>{"Berita Ombudsman"}</h1>
                        <h1 className='text-[#A8A8A8] text-xs font-[500]'>{languages.language == 'id' ?"Berita terbaru tentang perusahaan":"The latest news about the company"}</h1>
                    </div>
                    <div className='flex items-center gap-[15px] overflow-auto scrollbar-hide'>
                        {Object.values(dataNews).map((data, index) => {
                            return (
                                <DCardNews
                                    key={index}
                                    id={data.id}
                                    NewsTitle={data.title}
                                    NewsDate={moment(data.createdAt).format('MMMM DD, YYYY')}
                                    image={!data.image ? ExampleNews : imageHandle(data.image)}
                                />
                            )
                        })}
                    </div>
                    <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                        <div className='flex flex-col lg:flex-row items-center justify-between'>
                            <div className='w-full lg:w-auto'>
                                <h1 className='text-dark-3 text-[16px] font-[500]'>{languages.language == 'id' ?"Semua Berita":"All News"}</h1>
                                <h1 className='text-[#A8A8A8] text-xs font-[500]'>{languages.language == 'id' ?"Lihat semua berita":"See all the news about the company"}</h1>
                            </div>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative'>
                                    <AiOutlineSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input placeholder={languages.language == 'id' ?"Cari Nama Berita":"Search ..."} onChange={handleSearchTitle} className='text-[#A8A8A8] text-sm font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                <Link to={'/news/news-create'} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus className='text-xl text-white'/>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language == 'id' ?"Tambah Berita":"Add New Post"}</h1>
                                </Link>
                            </div>
                        </div>
                        <div className='mt-[44px] overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>{languages.language == 'id' ?"Tanggal":"Date"}</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>{languages.language == 'id' ?"Foto":"Image"}</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>{languages.language == 'id' ?"Judul":"Title"}</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[400px] max-w-[400px]'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>{languages.language == 'id' ?"Deskripsi":"Description"}</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-dark-5 text-xs font-[600]'>{languages.language == 'id' ?"Aksi":"Action"}</h1>
                                    </div>
                                </div>
                                {Object.values(dataNews).map((data, index) => {
                                    return (
                                        <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]' key={index}>
                                            <div className='min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-dark-5 text-xs font-[600]'>{moment(data.createdAt).format('DD/MM/YYYY')}</h1>
                                            </div>
                                            <div className='min-w-[50px] max-w-[50px]'>
                                                <img src={!data.image ? LogoH : imageHandle(data.image)} className='w-[34px] h-[34px] rounded-[3px] object-cover border' alt='User Humanusia'/>
                                            </div>
                                            <div className='min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data.title}</h1>
                                            </div>
                                            <div className='min-w-[400px] max-w-[400px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data.description}</h1>
                                            </div>
                                            <div className='w-full flex items-center justify-center gap-[5px]'>
                                                <button onClick={() => navigate('/news/news-view', {state: {id: data.id}})} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <AiOutlineEye className='text-[#003049]'/>
                                                </button>
                                                <button onClick={() => navigate('/news/news-edit', {state: {id: data.id}})} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]'/>
                                                </button>
                                                <button onClick={ () => deleteNewsModal(data.id) } className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]'/>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage} 
                            totalPages={totalPages} 
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News