import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { Modal, ModalDelete, Pagination } from '../../../../../components'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../../Api'
import toast from 'react-hot-toast'
import Select from 'react-select'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'

const TugasBelajar = ({ refreshPage, UserId }) => {
    const [showAddTugasBelajar, setShowAddTugasBelajar] = useState(false)
    const [showEditTugasBelajar, setShowEditTugasBelajar] = useState(false)
    const [showDeleteTugasBelajar, setShowDeleteTugasBelajar] = useState(false)
    const [idTugasBelajar, setIdTugasBelajar] = useState('')
    const [dataTugasBelajar, setDataTugasBelajar] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [formData, setFormData] = useState({
        "userId": UserId,
        "jenis": "",
        "jenjang_pendidikan": "",
        "nomor_sk": "",
        "tanggal_sk": "",
        "pejabatYangMenetapkanId": "",
        "tanggal_mulai": "",
        "tanggal_selesai": "",
        "sumber_pembiayaan": ""
    })
    const resetForm = () => {
        setFormData({
            "userId": UserId,
            "jenis": "",
            "jenjang_pendidikan": "",
            "nomor_sk": "",
            "tanggal_sk": "",
            "pejabatYangMenetapkanId": "",
            "tanggal_mulai": "",
            "tanggal_selesai": "",
            "sumber_pembiayaan": ""
        })
    }
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)

    const getDataTugasBelajar = async () => {
        try {
            const response = await Api.GetTugasBelajar(localStorage.getItem('hris-token'), UserId, currentPage, limit)
            console.log(response)
            setCurrentPage(response.data.results.currentPage)
            setTotalPages(response.data.results.totalPages)
            setDataTugasBelajar(response.data.results.data)
        } catch (error) {
            console.log(error)
        }
    }
    const openEditTugasBelajar = async (id) => {
        setIdTugasBelajar(id)
        setShowEditTugasBelajar(true)
        try {
            const response = await Api.GetTugasBelajarById(localStorage.getItem('hris-token'), id)
            const data = response.data.results.data
            console.log(data)
            setFormData({
                ...formData,
                "jenis": data.jenis,
                "jenjang_pendidikan": data.jenjang_pendidikan,
                "nomor_sk": data.nomor_sk,
                "tanggal_sk": data.tanggal_sk,
                "pejabatYangMenetapkanId": {
                    "value": data?.pejabatYangMenetapkan?.id,
                    "label": data?.pejabatYangMenetapkan?.fullname
                },
                "tanggal_mulai": data.tanggal_mulai,
                "tanggal_selesai": data.tanggal_selesai,
                "sumber_pembiayaan": data.sumber_pembiayaan
            })
        } catch (error) {
            console.log(error)
        }
    }

    const createTugasBelajar = async () => {
        try {
            const data = {
                "userId": formData.userId,
                "pejabatYangMenetapkanId": formData.pejabatYangMenetapkanId.value,
                "jenis": formData.jenis,
                "jenjang_pendidikan": formData.jenjang_pendidikan,
                "nomor_sk": formData.nomor_sk,
                "tanggal_sk": formData.tanggal_sk,
                "tanggal_mulai": formData.tanggal_mulai,
                "tanggal_selesai": formData.tanggal_selesai,
                "sumber_pembiayaan": formData.sumber_pembiayaan
            }
            await Api.CreateTugasBelajar(localStorage.getItem('hris-token'), data).then((response) => {
                setRefresh(true)
                toast.success("Data Berhasil Ditambahkan!")
                resetForm()
                setShowAddTugasBelajar(!showAddTugasBelajar)
            }).catch((error) => {
                error?.response?.data?.map((val) => (
                    toast.error(val)
                ))
            })
        } catch (error) {
            toast.error('Gagal Menambahkan Data!')
        }
    }

    const editTugasBelajar = async () => {
        try {
            const data = {
                "userId": formData.userId,
                "pejabatYangMenetapkanId": formData.pejabatYangMenetapkanId.value,
                "jenis": formData.jenis,
                "jenjang_pendidikan": formData.jenjang_pendidikan,
                "nomor_sk": formData.nomor_sk,
                "tanggal_sk": formData.tanggal_sk,
                "tanggal_mulai": formData.tanggal_mulai,
                "tanggal_selesai": formData.tanggal_selesai,
                "sumber_pembiayaan": formData.sumber_pembiayaan
            }
            await Api.EditTugasBelajar(localStorage.getItem('hris-token'), data, idTugasBelajar).then((response) => {
                setRefresh(true)
                toast.success("Data Berhasil Diedit!")
                resetForm()
                setShowEditTugasBelajar(!showEditTugasBelajar)
            }).catch((error) => {
                toast.error("Data Gagal Diedit!")
                error?.response?.data?.map((val) => (
                    toast.error(val)
                ))
            })
        } catch (error) {
            toast.error('Gagal Menambahkan Data!')
        }
    }
    const deleteModal = (id) => {
        setShowDeleteTugasBelajar(!showDeleteTugasBelajar)
        setIdTugasBelajar(id)
    }

    const deleteTugasBelajar = async () => {
        try {
            const response = await Api.DeleteTugasBelajar(localStorage.getItem('hris-token'), idTugasBelajar)
            console.log(response)
            setShowDeleteTugasBelajar(!showDeleteTugasBelajar)
            setRefresh(true)
            toast.success('Berhasil Dihapus')
        } catch (error) {
            console.log(error)
            toast.error('Gagal Dihapus')
        }
    }

    // Pagination
    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    function isValidYear(year) {
        // Check if the year is between 1900 and 2100
        if (year >= 1900 && year <= 2100) {
            return true;
        } else {
            return false;
        }
    }

    const [dataPegawai, setDataPegawai] = useState([])
    const getDataPegawai = async () => {
        try {
            const response = await Api.GetDropdownEmployee(localStorage.getItem('hris-token'))
            const data = response.data.data
            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.fullname
            }))
            // console.log(data)
            setDataPegawai(options)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDataTugasBelajar()
        getDataPegawai()
        setRefresh(false)
    }, [refresh])
    return (
        <>
            <div>
                <Modal
                    activeModal={showAddTugasBelajar}
                    title={'Tambah Tugas Belajar'}
                    buttonClose={() => { setShowAddTugasBelajar(!showAddTugasBelajar); resetForm() }}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jenis Tugas Belajar <span className='text-red-primer'>*</span></h1>
                                <input value={formData.jenis} onChange={(e) => setFormData({ ...formData, jenis: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Jenis Tugas Belajar...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jenjang Pendidikan Yang Ditempuh <span className='text-red-primer'>*</span></h1>
                                <div className='relative'>
                                    <select value={formData.jenjang_pendidikan} onChange={(e) => setFormData({ ...formData, jenjang_pendidikan: e.target.value })} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option disabled value={''}>Pilih Jenjang Pendidikan Yang Ditempuh</option>
                                        <option value={"Reguler"}>Reguler</option>
                                        <option value={"Mandiri"}>Mandiri</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nomor SK Tugas Belajar <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nomor_sk} onChange={(e) => setFormData({ ...formData, nomor_sk: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nomor SK Tugas Belajar...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal SK Tugas Belajar <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_sk} onChange={(e) => setFormData({ ...formData, tanggal_sk: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal SK Tugas Belajar...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Pejabat Yang Menetapkan <span className='text-red-primer'>*</span></h1>
                                <Select
                                    value={formData.pejabatYangMenetapkanId}
                                    onChange={selectedOption => setFormData({ ...formData, pejabatYangMenetapkanId: selectedOption })}
                                    options={dataPegawai}
                                />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal Mulai <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_mulai} onChange={(e) => setFormData({ ...formData, tanggal_mulai: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal Mulai...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal Selesai <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_selesai} onChange={(e) => setFormData({ ...formData, tanggal_selesai: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal Selesai...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Sumber Pembiayaan Pendidikan <span className='text-red-primer'>*</span></h1>
                                <div className='relative'>
                                    <select value={formData.sumber_pembiayaan} onChange={(e) => setFormData({ ...formData, sumber_pembiayaan: e.target.value })} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option disabled value={''}>Pilih Sumber Pembiayaan Pendidikan</option>
                                        <option value={"Mandiri"}>Mandiri</option>
                                        <option value={"Beasiswa"}>Beasiswa</option>
                                        <option value={"BantuanOmbudsmanRI"}>Bantuan Ombudsman RI</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end">
                                <button onClick={() => { setShowAddTugasBelajar(!showAddTugasBelajar); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={createTugasBelajar} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditTugasBelajar}
                    title={'Tambah Tugas Belajar'}
                    buttonClose={() => { setShowEditTugasBelajar(!showEditTugasBelajar); resetForm() }}
                    width={'558px'}
                    content={
                        <div className='space-y-[20px] w-[full]'>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jenis Tugas Belajar <span className='text-red-primer'>*</span></h1>
                                <input value={formData.jenis} onChange={(e) => setFormData({ ...formData, jenis: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Jenis Tugas Belajar...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Jenjang Pendidikan Yang Ditempuh <span className='text-red-primer'>*</span></h1>
                                <div className='relative'>
                                    <select value={formData.jenjang_pendidikan} onChange={(e) => setFormData({ ...formData, jenjang_pendidikan: e.target.value })} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option disabled value={''}>Pilih Jenjang Pendidikan Yang Ditempuh</option>
                                        <option value={"Reguler"}>Reguler</option>
                                        <option value={"Mandiri"}>Mandiri</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Nomor SK Tugas Belajar <span className='text-red-primer'>*</span></h1>
                                <input value={formData.nomor_sk} onChange={(e) => setFormData({ ...formData, nomor_sk: e.target.value })} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Nomor SK Tugas Belajar...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal SK Tugas Belajar <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_sk} onChange={(e) => setFormData({ ...formData, tanggal_sk: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal SK Tugas Belajar...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Pejabat Yang Menetapkan <span className='text-red-primer'>*</span></h1>
                                <Select
                                    value={formData.pejabatYangMenetapkanId}
                                    onChange={selectedOption => setFormData({ ...formData, pejabatYangMenetapkanId: selectedOption })}
                                    options={dataPegawai}
                                />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal Mulai <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_mulai} onChange={(e) => setFormData({ ...formData, tanggal_mulai: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal Mulai...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Tanggal Selesai <span className='text-red-primer'>*</span></h1>
                                <input value={formData.tanggal_selesai} onChange={(e) => setFormData({ ...formData, tanggal_selesai: e.target.value })} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-grey-medium w-full' placeholder='Tanggal Selesai...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-grey-medium mb-[9px]'>Sumber Pembiayaan Pendidikan <span className='text-red-primer'>*</span></h1>
                                <div className='relative'>
                                    <select value={formData.sumber_pembiayaan} onChange={(e) => setFormData({ ...formData, sumber_pembiayaan: e.target.value })} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option disabled value={''}>Pilih Sumber Pembiayaan Pendidikan</option>
                                        <option value={"Mandiri"}>Mandiri</option>
                                        <option value={"Beasiswa"}>Beasiswa</option>
                                        <option value={"BantuanOmbudsmanRI"}>Bantuan Ombudsman RI</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl' />
                                </div>
                            </div>
                            <div className="flex gap-[10px] items-end  justify-end">
                                <button onClick={() => { setShowEditTugasBelajar(!showEditTugasBelajar); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-blue-primer text-sm font-[500]'>Batal</h1>
                                </button>
                                <button onClick={editTugasBelajar} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                </button>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showDeleteTugasBelajar}
                    buttonClose={() => setShowDeleteTugasBelajar(!showDeleteTugasBelajar)}
                    submitButton={deleteTugasBelajar}
                />
            </div>
            <div className='px-[19px] py-[19px] space-y-[20px]'>
                <div className='flex relative'>
                    <div>
                        <h1 className='text-base-black text-[20px] font-medium'>Tugas Belajar</h1>
                        <h2 className='text-dark-5 text-[10px]'>Daftar Tugas Belajar</h2>
                    </div>
                    <div className='absolute right-0'>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <button onClick={() => setShowAddTugasBelajar(true)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white' />
                                <h1 className='text-dark-9 dark:place text-sm font-[500]'>Tambah Tugas Belajar</h1>
                            </button>
                        </div>
                    </div>
                </div>
                <hr className='border border-grey-extra-thin' />
                <div className='rounded shadow'>
                    <div className='px-2 py-4'>
                        <div className='overflow-auto'>
                            <table className='w-full space-y-[10px] text-dark-5'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Jenis Tugas Belajar</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Jenjang Pendidikan Yang Ditempuh</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Nomor SK Tugas Belajar</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal SK Tugas Belajar</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Pejabat Yang Menetapkan</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal Mulai Tugas Belajar</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Tanggal Selesai Tugas Belajar</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-center text-dark-5 text-[11px] font-[500]'>Sumber Pembiayaan Pendidikan</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                                    </div>
                                </div>
                                {dataTugasBelajar && dataTugasBelajar.map((val, index) => (
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.jenis ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.jenjang_pendidikan ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.nomor_sk ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.tanggal_sk ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val?.pejabatYangMenetapkan?.fullname ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.tanggal_mulai ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.tanggal_selesai ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-center text-dark-5 text-[11px] font-[500]'>{val.sumber_pembiayaan ?? "-"}</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <button onClick={() => openEditTugasBelajar(val.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]' />
                                            </button>
                                            <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty onClick={() => deleteModal(val.id)} className='text-[#003049]' />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            limitData={dataTugasBelajar?.length}
                            lengthData={totalCount}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TugasBelajar