import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlinePlus } from "react-icons/ai";
import { HiOutlinePencil } from "react-icons/hi";
import { IoMdArrowDropdown } from "react-icons/io";
import { MultiSelect } from "react-multi-select-component";
import Api from "../../../../../../Api";
import { Modal, ModalDelete, Pagination } from "../../../../../../components";
import InputComponent from "../../../../../../components/Input/inputComponent";
import languages from "../../../../../../utils/languages";

function DetailKhusus({ idPeriode }) {
    const [showModalKhusus, setShowModalKhusus] = useState(false);
    const [showEditModalKhusus, setShowEditModalKhusus] = useState(false);
    const [refresh, setRefresh] = useState(false)

    const handleInputChange = (inputName, inputValue) => {
        setFormData((prevData) => ({
            ...prevData,
            [inputName]: inputValue,
        }));
    };


    const [formData, setFormData] = useState({
        "hari": "", // Senin, Selasa, Rabu, Kamis, Jumat
        "jam_mulai": "",
        "toleransi_sebelum_jam_mulai": "",
        "toleransi_sesudah_jam_mulai": "",
        "jam_selesai": "",
        "toleransi_sebelum_jam_selesai": "",
        "toleransi_sesudah_jam_selesai": ""
    });

    const resetFormData = () => {
        setFormData({
            "hari": "",
            "jam_mulai": "",
            "toleransi_sebelum_jam_mulai": "",
            "toleransi_sesudah_jam_mulai": "",
            "jam_selesai": "",
            "toleransi_sebelum_jam_selesai": "",
            "toleransi_sesudah_jam_selesai": ""
        });
        setSelectedAssignTo("")
        setAssignTo("")
    };


    const postSetTimeKhusus = async () => {
        try {
            const data = formData;
            var dataAssigTo = {}
            if (assignTo === "ALL") {
                dataAssigTo.ALL = true
            } else if (assignTo === "job_grade") {
                dataAssigTo.job_grade = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssigTo.job_position = selectedAssignTo
            } else if (assignTo === "job_level") {
                dataAssigTo.job_level = selectedAssignTo
            } else if (assignTo === "job_category") {
                dataAssigTo.job_category = selectedAssignTo
            }
            data.setTimePeriodeId = idPeriode
            data.ditujukan_untuk = dataAssigTo
            await Api.PostSetTimeKhusus(localStorage.getItem('hris-token'), data).then((response) => {
                // console.log(response)
                resetFormData()
                setRefresh(true)
                toast.success('Sukses Menambahkan Data')
                setShowModalKhusus(false)
            }).catch((error) => {
                if (error.response.data.errors.error.details) {
                    const e = error.response.data.errors.error.details
                    for (let i = 0; i < e.length; i++) {
                        toast.error(error.response.data.errors.error.details[i].message)
                    }
                } else {
                    toast.error(error.response.data.message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    };

    const [id, setId] = useState('')
    const getSetTimeKhususById = async (id) => {
        setShowEditModalKhusus(true)
        try {
            await Api.GetSetTimeKhususById(localStorage.getItem('hris-token'), id).then((response) => {
                // console.log(response.data.results.data)
                const res = response.data.results.data
                setId(res.id)
                setFormData({
                    "hari": res.hari,
                    "jam_mulai": res.jam_mulai,
                    "toleransi_sebelum_jam_mulai": res.toleransi_sebelum_jam_mulai,
                    "toleransi_sesudah_jam_mulai": res.toleransi_sesudah_jam_mulai,
                    "jam_selesai": res.jam_selesai,
                    "toleransi_sebelum_jam_selesai": res.toleransi_sebelum_jam_selesai,
                    "toleransi_sesudah_jam_selesai": res.toleransi_sesudah_jam_selesai
                });
                const assign_to = Object.keys(response.data.results.data.ditujukan_untuk);
                if (assign_to[0] === "ALL") {
                    setAssignTo(assign_to[0])
                } else if (assign_to[0] === "job_category") {
                    setAssignTo(assign_to[0])
                    // console.log(response.data.results.data.ditujukan)
                    const options = response.data.results.data.ditujukan && response.data.results.data.ditujukan.map(val => ({
                        "value": val.id,
                        "label": val.name
                    }))
                    setSelected(options)
                } else if (assign_to[0] === "job_position") {
                    setAssignTo(assign_to[0])
                    // console.log(response.data.results.data.ditujukan)
                    const options = response.data.results.data.ditujukan && response.data.results.data.ditujukan.map(val => ({
                        "value": val.id,
                        "label": val.name
                    }))
                    setSelected(options)
                }
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    };

    const updateSetTimeKhusus = async () => {
        try {
            const data = formData;
            var dataAssigTo = {}
            if (assignTo === "ALL") {
                dataAssigTo.ALL = true
            } else if (assignTo === "job_grade") {
                dataAssigTo.job_grade = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssigTo.job_position = selectedAssignTo
            } else if (assignTo === "job_level") {
                dataAssigTo.job_level = selectedAssignTo
            } else if (assignTo === "job_category") {
                dataAssigTo.job_category = selectedAssignTo
            }
            if (assignTo !== "" || selectedAssignTo.length !== 0) {
                data.ditujukan_untuk = dataAssigTo
            }
            data.setTimePeriodeId = idPeriode
            // console.log(data);
            await Api.UpdateSetTimeKhusus(localStorage.getItem('hris-token'), data, id).then((response) => {
                // console.log(response)
                resetFormData()
                setRefresh(true)
                toast.success('Sukses Edit Data')
                setShowEditModalKhusus(false)
            }).catch((error) => {
                if (error.response.data.errors.error.details) {
                    const e = error.response.data.errors.error.details
                    for (let i = 0; i < e.length; i++) {
                        toast.error(error.response.data.errors.error.details[i].message)
                    }
                } else {
                    toast.error(error.response.data.message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Edit Data')
        }
    };

    const [dataKhusus, setDataKhusus] = useState([])
    const [dataPeriode, setDataPeriode] = useState([])
    const getSetTimeKhusus = async () => {
        try {
            await Api.GetSetTimeKhusus(localStorage.getItem('hris-token'), idPeriode).then((response) => {
                // console.log("ini", response.data.results.data)
                setDataKhusus(response.data.results.data)
                setDataPeriode(response.data.results.periode)
                setCurrentPage(response.data.results.currentPage)
                setTotalPages(response.data.results.totalPages)
                setTotalCount(response.data.results.totalCount)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    };

    const [assignTo, setAssignTo] = useState('')
    const [selectedAssignTo, setSelectedAssignTo] = useState([])
    const [selected, setSelected] = useState([])
    const [jobCategory, setJobCategory] = useState([])
    const [jobPosition, setJobPosition] = useState([])

    const getSelectJobCategory = async () => {
        try {
            const res = await Api.GetJobCategoryDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.category_name
            }))

            setJobCategory(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobPosition = async () => {
        try {
            const res = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.position_name
            }))

            setJobPosition(options)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const data = []
        e && e.map(val => (
            data.push(val.value)
        ))
        setSelected(e)
        setSelectedAssignTo(data)
    }

    const [showModalDeleteKhusus, setShowModalDeleteKhusus] = useState(false)
    const [idSetTimeKhusus, setIdSetTimeKhusus] = useState(false)
    const deleteModal = (id) => {
        setShowModalDeleteKhusus(!showModalDeleteKhusus)
        setIdSetTimeKhusus(id)
        setRefresh(true)
    }

    const deleteSetTimeKhusus = async () => {
        try {
            await Api.DeleteSetTimeKhusus(localStorage.getItem('hris-token'), idSetTimeKhusus).then((response) => {
                setRefresh(true)
                setShowModalDeleteKhusus(!showModalDeleteKhusus)
                toast.success('Hapus Data Berhasil')
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            toast.error('Data Gagal dihapus')
        }
    }


    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState(1)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    useEffect(() => {
        getSetTimeKhusus()
        setRefresh(false)
    }, [refresh])

    useEffect(() => {
        getSelectJobCategory()
        getSelectJobPosition()
    }, [])
    return (
        <>
            <div className="relative">
                <Modal
                    activeModal={showModalKhusus}
                    title={"Tambah Jam Kerja"}
                    buttonClose={() => { setShowModalKhusus(!showModalKhusus); resetFormData() }}
                    width={"770px"}
                    content={
                        <div>
                            <div className="grid grid-cols-2 gap-x-5 gap-y-2">
                                <div className="w-full">
                                    <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">
                                        Nama Hari <span className="text-red-primer">*</span>
                                    </h2>
                                    <div className="relative">
                                        <select
                                            value={formData.hari}
                                            onChange={(e) => handleInputChange("hari", e.target.value)}
                                            className="bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none"
                                        >
                                            <option selected disabled value={""}>
                                                Pilih Hari
                                            </option>
                                            <option value={"Senin"}>Senin</option>
                                            <option value={"Selasa"}>Selasa</option>
                                            <option value={"Rabu"}>Rabu</option>
                                            <option value={"Kamis"}>Kamis</option>
                                            <option value={"Jumat"}>Jumat</option>
                                        </select>
                                        <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                                    </div>
                                </div>
                                <hr className="border col-span-2 my-3" />
                                <div className="col-span-2">
                                    <h2 className="text-grey-thick text-sm font-bold">Jam Mulai</h2>
                                </div>
                                <div className="col-span-2">
                                    <InputComponent
                                        disabled={false}
                                        label={"Jam Khusus"}
                                        value={formData.jam_mulai}
                                        onChange={(e) => handleInputChange("jam_mulai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        disabled={false}
                                        label={"Toleransi Sebelum"}
                                        value={formData.toleransi_sebelum_jam_mulai}
                                        onChange={(e) => handleInputChange("toleransi_sebelum_jam_mulai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        disabled={false}
                                        label={"Toleransi Sesudah"}
                                        value={formData.toleransi_sesudah_jam_mulai}
                                        onChange={(e) => handleInputChange("toleransi_sesudah_jam_mulai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <hr className="border col-span-2 my-3" />
                                <div className="col-span-2">
                                    <h2 className="text-grey-thick text-sm font-bold">Jam Selesai</h2>
                                </div>
                                <div className="col-span-2">
                                    <InputComponent
                                        disabled={false}
                                        label={"Jam Khusus"}
                                        value={formData.jam_selesai}
                                        onChange={(e) => handleInputChange("jam_selesai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        disabled={false}
                                        label={"Toleransi Sebelum"}
                                        value={formData.toleransi_sebelum_jam_selesai}
                                        onChange={(e) => handleInputChange("toleransi_sebelum_jam_selesai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        disabled={false}
                                        label={"Toleransi Sesudah"}
                                        value={formData.toleransi_sesudah_jam_selesai}
                                        onChange={(e) => handleInputChange("toleransi_sesudah_jam_selesai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>

                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                                    <div className='space-y-[16px]'>
                                        <div className='flex items-center gap-[14px] font-medium'>
                                            <input type="radio" id="all" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                            <label htmlFor="all" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                        </div>
                                        {/* <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="search" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                <label htmlFor="search" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                            </div> */}
                                        <div className='flex items-center gap-[14px] font-medium'>
                                            <input type="radio" id="jenisKepegawaian" name="radio-option" value='job_category' onChange={(e) => setAssignTo(e.target.value)} />
                                            <label htmlFor="jenisKepegawaian" className='text-grey-thick font-medium text-xs'>Berdasarkan Jenis Kepegawaian</label>
                                        </div>
                                        {
                                            assignTo === "job_category" ?
                                                <MultiSelect
                                                    options={jobCategory}
                                                    value={selected}
                                                    onChange={(e) => handleChange(e)}
                                                    labelledBy="Select"
                                                />
                                                : null
                                        }
                                        <div className='flex items-center gap-[14px] font-medium'>
                                            <input type="radio" id="jabatan" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                            <label htmlFor="jabatan" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                        </div>
                                        {
                                            assignTo === "job_position" ?
                                                <MultiSelect
                                                    options={jobPosition}
                                                    value={selected}
                                                    onChange={(e) => handleChange(e)}
                                                    labelledBy="Select"
                                                />
                                                : null
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowModalKhusus(!showModalKhusus); resetFormData() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={postSetTimeKhusus} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Tambah</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <Modal
                    activeModal={showEditModalKhusus}
                    title={"Edit Jam Kerja"}
                    buttonClose={() => { setShowEditModalKhusus(!showEditModalKhusus); resetFormData() }}
                    width={"770px"}
                    content={
                        <div>
                            <div className="grid grid-cols-2 gap-x-5 gap-y-2">
                                <div className="w-full">
                                    <h2 className="text-grey-thick text-[12px] font-medium mb-[9px]">
                                        Nama Hari <span className="text-red-primer">*</span>
                                    </h2>
                                    <div className="relative">
                                        <select
                                            value={formData.hari}
                                            onChange={(e) => handleInputChange("hari", e.target.value)}
                                            className="bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none"
                                        >
                                            <option selected disabled value={""}>
                                                Pilih Hari
                                            </option>
                                            <option value={"Senin"}>Senin</option>
                                            <option value={"Selasa"}>Selasa</option>
                                            <option value={"Rabu"}>Rabu</option>
                                            <option value={"Kamis"}>Kamis</option>
                                            <option value={"Jumat"}>Jumat</option>
                                        </select>
                                        <IoMdArrowDropdown className="absolute top-[10px] right-3 text-[#2E2E2E ] text-xl" />
                                    </div>
                                </div>
                                <hr className="border col-span-2 my-3" />
                                <div className="col-span-2">
                                    <h2 className="text-grey-thick text-sm font-bold">Jam Mulai</h2>
                                </div>
                                <div className="col-span-2">
                                    <InputComponent
                                        disabled={false}
                                        label={"Jam Khusus"}
                                        value={formData.jam_mulai}
                                        onChange={(e) => handleInputChange("jam_mulai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        disabled={false}
                                        label={"Toleransi Sebelum"}
                                        value={formData.toleransi_sebelum_jam_mulai}
                                        onChange={(e) => handleInputChange("toleransi_sebelum_jam_mulai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        disabled={false}
                                        label={"Toleransi Sesudah"}
                                        value={formData.toleransi_sesudah_jam_mulai}
                                        onChange={(e) => handleInputChange("toleransi_sesudah_jam_mulai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <hr className="border col-span-2 my-3" />
                                <div className="col-span-2">
                                    <h2 className="text-grey-thick text-sm font-bold">Jam Selesai</h2>
                                </div>
                                <div className="col-span-2">
                                    <InputComponent
                                        disabled={false}
                                        label={"Jam Khusus"}
                                        value={formData.jam_selesai}
                                        onChange={(e) => handleInputChange("jam_selesai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        disabled={false}
                                        label={"Toleransi Sebelum"}
                                        value={formData.toleransi_sebelum_jam_selesai}
                                        onChange={(e) => handleInputChange("toleransi_sebelum_jam_selesai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        disabled={false}
                                        label={"Toleransi Sesudah"}
                                        value={formData.toleransi_sesudah_jam_selesai}
                                        onChange={(e) => handleInputChange("toleransi_sesudah_jam_selesai", e)}
                                        required={true}
                                        type={"time"}
                                    />
                                </div>

                                <div>
                                    <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>Ditujukan Untuk <span className='text-red-primer'>*</span></h1>
                                    <div className='space-y-[16px]'>
                                        <div className='flex items-center gap-[14px] font-medium'>
                                            <input type="radio" checked={assignTo === "ALL"} id="allEdit" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                            <label htmlFor="allEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                        </div>
                                        {/* <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" checked={assignTo === "job_position"} id="search" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                <label htmlFor="search" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                            </div> */}
                                        <div className='flex items-center gap-[14px] font-medium'>
                                            <input type="radio" checked={assignTo === "job_category"} id="jenisKepegawaianEdit" name="radio-option" value='job_category' onChange={(e) => setAssignTo(e.target.value)} />
                                            <label htmlFor="jenisKepegawaianEdit" className='text-grey-thick font-medium text-xs'>Berdasarkan Jenis Kepegawaian</label>
                                        </div>
                                        {
                                            assignTo === "job_category" ?
                                                <MultiSelect
                                                    options={jobCategory}
                                                    value={selected}
                                                    onChange={(e) => handleChange(e)}
                                                    labelledBy="Select"
                                                />
                                                : null
                                        }
                                        <div className='flex items-center gap-[14px] font-medium'>
                                            <input type="radio" checked={assignTo === "job_position"} id="jabatanEdit" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                            <label htmlFor="jabatanEdit" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                        </div>
                                        {
                                            assignTo === "job_position" ?
                                                <MultiSelect
                                                    options={jobPosition}
                                                    value={selected}
                                                    onChange={(e) => handleChange(e)}
                                                    labelledBy="Select"
                                                />
                                                : null
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className='relative pb-[37px]'>
                                <div className="flex gap-[10px] absolute right-0">
                                    <button onClick={() => { setShowEditModalKhusus(!showEditModalKhusus); resetFormData() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-[#003049] text-sm font-[500]'>Batal</h1>
                                    </button>
                                    <button onClick={updateSetTimeKhusus} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                        <h1 className='text-dark-9 text-sm font-[500]'>Simpan</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDeleteKhusus}
                    buttonClose={() => setShowModalDeleteKhusus(!showModalDeleteKhusus)}
                    submitButton={deleteSetTimeKhusus}
                />
            </div>
            <div className="w-full flex justify-between items-center">
                <div className='w-full lg:w-auto mb-[20px]'>
                    <h1 className='text-dark-3 text-[20px] font-[600]'>Pengaturan Waktu {dataPeriode?.name}</h1>
                    <h1 className='text-[#A8A8A8] text-xs font-[400]'>Daftar Jam Kerja {dataPeriode?.name}</h1>
                </div>
                <button onClick={() => setShowModalKhusus(true)} className='h-fit bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                    <AiOutlinePlus className='text-xl text-white' />
                    <h1 className='text-dark-9 text-sm font-[500]'>Tambah Jam Kerja</h1>
                </button>
            </div>
            <div className="mt-5 overflow-auto">
                <table className="w-full space-y-[10px]">
                    <div className="flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]">
                        <div className="flex items-center gap-[15px] min-w-[50px] max-w-[50px]">
                            <h1 className="text-grey-thick text-xs font-medium">No.</h1>
                        </div>
                        <div className="flex items-center gap-[15px] min-w-[100px] max-w-[100px]">
                            <h1 className="text-grey-thick text-xs font-medium">Hari</h1>
                        </div>
                        <div className="flex items-center gap-[15px] min-w-[200px] max-w-[200px]">
                            <h1 className="text-grey-thick text-xs font-medium">
                                Jam Mulai
                            </h1>
                        </div>
                        <div className="flex items-center gap-[15px] min-w-[200px] max-w-[200px]">
                            <h1 className="text-grey-thick text-xs font-medium">
                                Jam Pulang
                            </h1>
                        </div>
                        <div className="flex items-center gap-[15px] min-w-[150px] max-w-[150px]">
                            <h1 className="text-grey-thick text-xs font-medium">
                                Ditujukan Kepada
                            </h1>
                        </div>
                        <div className="flex items-center gap-[15px]">
                            <h1 className="text-grey-thick text-xs font-medium">Aksi</h1>
                        </div>
                    </div>
                    {dataKhusus && dataKhusus.map((val, index) => (
                        <div key={index} className="flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]">
                            <div className="min-w-[50px] max-w-[50px]">
                                <h1 className="text-grey-thick text-xs font-medium ">{index + 1}.</h1>
                            </div>
                            <div className="min-w-[100px] max-w-[100px]">
                                <h1 className="text-grey-thick text-xs font-medium ">{val.hari}</h1>
                            </div>
                            <div className="min-w-[200px] max-w-[200px]">
                                <h1 className="text-grey-thick text-xs font-medium ">
                                    <span>Jam Normal : </span>
                                    <span>{val.jam_mulai}</span>
                                </h1>
                                <h1 className="text-grey-thick text-xs font-medium ">
                                    <span>Toleransi Sebelum : </span>
                                    <span>{val.toleransi_sebelum_jam_mulai}</span>
                                </h1>
                                <h1 className="text-grey-thick text-xs font-medium ">
                                    <span>Tolerabsi Sesudah : </span>
                                    <span>{val.toleransi_sesudah_jam_mulai}</span>
                                </h1>
                            </div>
                            <div className="min-w-[200px] max-w-[200px]">
                                <h1 className="text-grey-thick text-xs font-medium ">
                                    <span>Jam Normal : </span>
                                    <span>{val.jam_selesai}</span>
                                </h1>
                                <h1 className="text-grey-thick text-xs font-medium ">
                                    <span>Toleransi Sebelum : </span>
                                    <span>{val.toleransi_sebelum_jam_selesai}</span>
                                </h1>
                                <h1 className="text-grey-thick text-xs font-medium ">
                                    <span>Tolerabsi Sesudah : </span>
                                    <span>{val.toleransi_sesudah_jam_selesai}</span>
                                </h1>
                            </div>
                            <div className="min-w-[150px] max-w-[150px]">
                                {val.ditujukan.length !== 0 ?
                                    val.ditujukan.map((v, idx) => (
                                        <h1 className='text-grey-thick text-xs font-medium truncate'>{v.name}{val.ditujukan.length - (idx + 1) !== 0 ? "," : ""}</h1>
                                    ))
                                    : <h1 className="text-grey-thick text-xs font-medium uppercase">Seluruh Pegawai</h1>}
                            </div>
                            <div className="flex gap-2">
                                <button onClick={() => getSetTimeKhususById(val.id)} className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                    <HiOutlinePencil className='text-blue-thick' />
                                </button>
                                <button onClick={() => deleteModal(val.id)} className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M6.32733 3.20436H6.19482C6.2677 3.20436 6.32733 3.14473 6.32733 3.07185V3.20436H11.3629V3.07185C11.3629 3.14473 11.4226 3.20436 11.4955 3.20436H11.3629V4.397H12.5556V3.07185C12.5556 2.48712 12.0802 2.01172 11.4955 2.01172H6.19482C5.61009 2.01172 5.13469 2.48712 5.13469 3.07185V4.397H6.32733V3.20436ZM14.6758 4.397H3.01444C2.72125 4.397 2.48438 4.63388 2.48438 4.92707V5.45713C2.48438 5.53001 2.54401 5.58965 2.61689 5.58965H3.61739L4.02653 14.2529C4.05303 14.8177 4.52015 15.2633 5.085 15.2633H12.6053C13.1718 15.2633 13.6372 14.8194 13.6637 14.2529L14.0729 5.58965H15.0734C15.1463 5.58965 15.2059 5.53001 15.2059 5.45713V4.92707C15.2059 4.63388 14.969 4.397 14.6758 4.397ZM12.4777 14.0707H5.21254L4.81168 5.58965H12.8786L12.4777 14.0707Z" fill="#003049" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ))}
                </table>
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                limitData={dataKhusus?.length}
                lengthData={totalCount}
                onPageChange={handlePageChange}
                onPrevChange={handlePrevChange}
                onNextChange={handleNextChange}
            />
        </>
    )
}

export default DetailKhusus