import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { BsExclamationCircle } from 'react-icons/bs'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import languages from '../../../../utils/languages'


const LeaveSetting = () => {
    const navigate = useNavigate()
    const [menu, setMenu] = useState('Leave Setting')
    const [showAddLeaveType, setShowAddLeaveType] = useState()
    const [showEditLeaveType, setShowEditLeaveType] = useState()
    const [data, setData] = useState('')
    const [showModalDelete, setShowModalDelete] = useState()

    //Post Data Leave Type
    const [typeName, setTypeName] = useState()
    const [maxDuration, setMaxDuration] = useState(0)
    const [submissionBefore, setSubmissionBefore] = useState()
    const [lengthOfWork, setLengthOfWork] = useState()
    const [idLeaveType, setIdLeaveType] = useState()
    const [refresh, setRefresh] = useState(false)
    const limit = 5
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };


    const OpenEditLeaveType = async (id) => {
        setIdLeaveType(id)
        setShowEditLeaveType(!showEditLeaveType)
        try {
            const response = await Api.getLeaveTypeById(localStorage.getItem('hris-token'), id)
            setTypeName(response.data.type_name)
            setMaxDuration(response.data.max_duration)
            // setMaxSubmission(response.data.max_submission)
            setSubmissionBefore(response.data.submission_before)
            setLengthOfWork(response.data.length_of_work)
            const assign_to = Object.keys(response.data.assign_to);
            if (assign_to[0] === "ALL") {
                setAssignTo(assign_to[0])
            } else if (assign_to[0] === "job_grade") {
                setAssignTo(assign_to[0])
                // console.log(response.data.assignTo)
                const options = response.data.assignTo && response.data.assignTo.map(val => ({
                    "value": val.id,
                    "label": val.name
                }))
                setSelected(options)
            } else if (assign_to[0] === "job_position") {
                setAssignTo(assign_to[0])
                // console.log(response.data.assignTo)
                const options = response.data.assignTo && response.data.assignTo.map(val => ({
                    "value": val.id,
                    "label": val.name
                }))
                setSelected(options)
            } else if (assign_to[0] === "job_level") {
                setAssignTo(assign_to[0])
                // console.log(response.data.assignTo)
                const options = response.data.assignTo && response.data.assignTo.map(val => ({
                    "value": val.id,
                    "label": val.name
                }))
                setSelected(options)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const resetForm = () => {
        setTypeName('')
        setSubmissionBefore('')
        setMaxDuration('')
        setLengthOfWork('')
        setAssignTo('')
        setSelectedAssignTo([])
    }

    const postDataLeaveType = async () => {
        setIsLoading(true)
        try {
            var dataAssigTo = {}
            if (assignTo === "ALL") {
                dataAssigTo.ALL = true
            } else if (assignTo === "job_grade") {
                dataAssigTo.job_grade = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssigTo.job_position = selectedAssignTo
            } else if (assignTo === "job_level") {
                dataAssigTo.job_level = selectedAssignTo
            }
            const data = {
                "type_name": typeName,
                "submission_before": submissionBefore,
                "max_duration": maxDuration,
                "length_of_work": lengthOfWork,
                "assign_to": dataAssigTo
            }
            // console.log(data)
            await Api.PostLeaveType(localStorage.getItem('hris-token'), data).then((response) => {
                setShowAddLeaveType(!showAddLeaveType)
                resetForm()
                setRefresh(true)
                setIsLoading(false)
                toast.success('Berhasil Menambahkan Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error("Gagal Menambahkan Data!")
        }
    }

    const editDataLeaveType = async () => {
        setIsLoading(true)
        try {
            var dataAssigTo = {}
            if (assignTo === "ALL") {
                dataAssigTo.ALL = true
            } else if (assignTo === "job_grade") {
                dataAssigTo.job_grade = selectedAssignTo
            } else if (assignTo === "job_position") {
                dataAssigTo.job_position = selectedAssignTo
            } else if (assignTo === "job_level") {
                dataAssigTo.job_level = selectedAssignTo
            }
            const data = {
                type_name: typeName,
                submission_before: submissionBefore,
                max_duration: maxDuration,
                length_of_work: lengthOfWork,
                assign_to: dataAssigTo
            }
            await Api.UpdateLeaveType(localStorage.getItem('hris-token'), data, idLeaveType).then((response) => {
                resetForm()
                setShowEditLeaveType(!showEditLeaveType)
                setRefresh(true)
                setIsLoading(false)
                toast.success('Berhasil Edit Data!')
            }).catch((error) => {
                // console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(error.response.data.errors.error.details[i].message)
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Edit Data!')
        }
    }
    const getDataLeaveType = async () => {
        try {
            const response = await Api.getLeaveType(localStorage.getItem('hris-token'), currentPage, limit)
            setData(response.data.leaveTypeList)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            // console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteLeaveTypeModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setIdLeaveType(id)
        setRefresh(true)
    }

    const deleteLeaveType = async (id) => {
        try {
            await Api.DeleteLeaveType(localStorage.getItem('hris-token'), idLeaveType).then((res) => {
                setShowModalDelete(!showModalDelete)
                setRefresh(true)
                toast.success('Berhasil Menghapus Data!')
            }).catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
            toast.error('Gagal Menghapus Data!')
        }
    }


    const [assignTo, setAssignTo] = useState('')
    const [selectedAssignTo, setSelectedAssignTo] = useState([])
    const [selected, setSelected] = useState([])
    const [jobGrade, setJobGrade] = useState([])
    const [jobLevel, setJobLevel] = useState([])
    const [jobPosition, setJobPosition] = useState([])

    const getSelectJobGrade = async () => {
        try {
            const res = await Api.GetJobGradeDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.job_grade
            }))

            setJobGrade(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobLevel = async () => {
        try {
            const res = await Api.GetJobLevelDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.level_name
            }))

            setJobLevel(options)
        } catch (error) {
            console.log(error)
        }
    }
    const getSelectJobPosition = async () => {
        try {
            const res = await Api.GetJobPositionDropdown(localStorage.getItem('hris-token'))
            const data = res.data.results.data;

            const options = data && data.map(val => ({
                "value": val.id,
                "label": val.position_name
            }))

            setJobPosition(options)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const data = []
        e && e.map(val => (
            data.push(val.value)
        ))
        setSelected(e)
        setSelectedAssignTo(data)
    }
    const [role, setRole] = useState('')
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setRole(response.data.results.user.role)
        } catch (error) {
            navigate('/')
        }
    }
    useEffect(() => {
        Fetch()
        getDataLeaveType()
        getSelectJobGrade()
        getSelectJobLevel()
        getSelectJobPosition()
        setRefresh(false)
    }, [refresh])

    const [isLoading, setIsLoading] = useState(false)
    if (isLoading) {
        return (
            <div className='bg-[#F8F9FB] min-h-screen content-center w-full text-center pt-52 flex-row justify-center items-center'>
                <div className="text-center space-y-2">
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-16 h-16 text-gray-200 animate-spin dark:text-red-thick fill-blue-medium" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p>Memuat Data...</p>
                </div>
            </div>
        )
    }


    return (
        <div>
            <Modal
                activeModal={showAddLeaveType}
                title={languages.language === 'id' ? "Tambah Tipe Cuti" : "Add Leave Type"}
                buttonClose={() => { setShowAddLeaveType(!showAddLeaveType); resetForm() }}
                width={'550px'}
                content={
                    <div className='space-y-[24px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>{languages.language === 'id' ? "Nama Jenis Cuti" : "Type Name"} <span className='text-red-primer'> *</span></h1>
                            <input onChange={(e) => setTypeName(e.target.value)} value={typeName} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Nama Jenis Cuti...' />
                        </div>

                        <div className="flex gap-[14px] w-full">
                            <div className='w-full'>
                                <div>
                                    <div className='flex items-center gap-1 mb-[4px]'>
                                        <h1 className='text-[12px] text-dark-5 font-medium'>{languages.language === 'id' ? "Durasi Maksimum" : "Maximum Duration"} <span className='text-red-primer'> *</span></h1>
                                        <div className="relative">
                                            <button
                                                className="text-[12px] text-dark-5 font-medium"
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <BsExclamationCircle />
                                            </button>
                                            {isTooltipVisible && (
                                                <div className="absolute bg-gray-200 text-gray-800 px-2 py-1 rounded whitespace-nowrap text-sm bottom-full left-0 mt-2 opacity-100 pointer-events-auto transition-opacity duration-300">
                                                    <p className="text-[12px] text-dark-5 font-medium">
                                                        Apabila tidak ada batasan,<br /> maka berikan nilai 0.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='flex items-center'>
                                        <input onChange={(e) => setMaxDuration(e.target.value)} value={maxDuration} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' min={0} />
                                        <h1 className='text-[12px] px-[22px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-dark-5'>{languages.language === 'id' ? "Hari" : "Days"}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>{languages.language === 'id' ? "Batas Waktu Pengajuan" : "Submission Before"}<span className='text-red-primer'> *</span></h1>
                                <div className='flex items-center'>
                                    <input onChange={(e) => setSubmissionBefore(e.target.value)} value={submissionBefore} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' />
                                    <h1 className='text-[12px] px-[12px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-dark-5'>{languages.language === 'id' ? "Hari" : "Days"}</h1>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>{languages.language === 'id' ? "Diperoleh pada saat pegawai telah bekerja berapa lama?" : "Obtained when employees have worked for how long?"}<span className='text-red-primer'> *</span></h1>
                            <div className='flex items-center'>
                                <input onChange={(e) => setLengthOfWork(e.target.value)} value={lengthOfWork} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[142px]' min={0} />
                                <h1 className='text-[12px] px-[12px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-dark-5 truncate'>{languages.language === 'id' ? "Bulan" : "Month"}</h1>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>{languages.language === 'id' ? "Ditujukan Kepada" : "Assign To"}<span className='text-red-primer'>*</span></h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="allIncome" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="allIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                </div>
                                {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="searchIncome" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="searchIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="jobGradeIncome" name="radio-option" value='job_grade' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobGradeIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Kelas Jabatan" : "Based on Job Grade"}</label>
                                </div>
                                {
                                    assignTo === "job_grade" ?
                                        <MultiSelect
                                            options={jobGrade}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="jobLevelIncome" name="radio-option" value='job_level' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobLevelIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jenis Jabatan" : "Based on Job Level"}</label>
                                </div>
                                {
                                    assignTo === "job_level" ?
                                        <MultiSelect
                                            options={jobLevel}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" id="jobPositionIncome" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="jobPositionIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                </div>
                                {
                                    assignTo === "job_position" ?
                                        <MultiSelect
                                            options={jobPosition}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowAddLeaveType(!showAddLeaveType); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                </button>
                                <button onClick={postDataLeaveType} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah" : "Add"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showEditLeaveType}
                title={languages.language === 'id' ? "Edit Jenis Cuti" : "Edit Leave Type"}
                buttonClose={() => { setShowEditLeaveType(!showEditLeaveType); resetForm() }}
                width={'550px'}
                content={
                    <div className='space-y-[24px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>{languages.language === 'id' ? "Nama Jenis Cuti" : "Type Name"}<span className='text-red-primer'> *</span></h1>
                            <input onChange={(e) => setTypeName(e.target.value)} value={typeName} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' placeholder='Type Name...' />
                        </div>

                        <div className="flex gap-[14px] w-full">
                            <div className='w-full'>
                                <div>
                                    <div className='flex items-center gap-1 mb-[4px]'>
                                        <h1 className='text-[12px] text-dark-5 font-medium'>{languages.language === 'id' ? "Durasi Maksimum" : "Maximum Duration"}<span className='text-red-primer'> *</span></h1>
                                        <div className="relative">
                                            <button
                                                className="text-[12px] text-dark-5 font-medium"
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <BsExclamationCircle />
                                            </button>
                                            {isTooltipVisible && (
                                                <div className="absolute bg-gray-200 text-gray-800 px-2 py-1 rounded whitespace-nowrap text-sm bottom-full left-0 mt-2 opacity-100 pointer-events-auto transition-opacity duration-300">
                                                    <p className="text-[12px] text-dark-5 font-medium">
                                                        Apabila tidak ada batasan,<br /> maka berikan nilai 0.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='flex items-center'>
                                        <input onChange={(e) => setMaxDuration(e.target.value)} value={maxDuration} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' min={0} />
                                        <h1 className='text-[12px] px-[22px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-dark-5'>{languages.language === 'id' ? "Hari" : "Days"}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>{languages.language === 'id' ? "Batas Waktu Pengajuan" : "Submission Before"}<span className='text-red-primer'> *</span></h1>
                                <div className='flex items-center'>
                                    <input onChange={(e) => setSubmissionBefore(e.target.value)} value={submissionBefore} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-full' />
                                    <h1 className='text-[12px] px-[12px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-dark-5'>{languages.language === 'id' ? "Hari" : "Days"}</h1>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-dark-5 mb-[9px] font-medium'>{languages.language === 'id' ? "Diperoleh pada saat pegawai telah bekerja berapa lama?" : "Obtained when employees have worked for how long?"}<span className='text-red-primer'> *</span></h1>
                            <div className='flex items-center'>
                                <input onChange={(e) => setLengthOfWork(e.target.value)} value={lengthOfWork} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-dark-5 w-[142px]' min={0} />
                                <h1 className='text-[12px] px-[12px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-dark-5 truncate'>{languages.language === 'id' ? "Bulan" : "Month"}</h1>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-grey-thick text-[11px] font-medium mb-[8px]'>{languages.language === 'id' ? "Ditujukan Kepada" : "Assign To"}<span className='text-red-primer'> *</span></h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "ALL"} id="editAllIncome" name="radio-option" value='ALL' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="editAllIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Semua Pegawai" : "All Employee"}</label>
                                </div>
                                {/* <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo ===""} id="searchIncome" name="radio-option" value='' onChange={(e)=>setAssignTo(e.target.value)} />
                                    <label htmlFor="searchIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Cari Pegawai" : "Search Employee"}</label>
                                </div> */}
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_grade"} id="editJobGradeIncome" name="radio-option" value='job_grade' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="editJobGradeIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Kelas Jabatan" : "Based on Job Grade"}</label>
                                </div>
                                {
                                    assignTo === "job_grade" ?
                                        <MultiSelect
                                            options={jobGrade}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_level"} id="editJobLevelIncome" name="radio-option" value='job_level' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="editJobLevelIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jenis Jabatan" : "Based on Job Level"}</label>
                                </div>
                                {
                                    assignTo === "job_level" ?
                                        <MultiSelect
                                            options={jobLevel}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                                <div className='flex items-center gap-[14px] font-medium'>
                                    <input type="radio" checked={assignTo === "job_position"} id="editJobPositionIncome" name="radio-option" value='job_position' onChange={(e) => setAssignTo(e.target.value)} />
                                    <label htmlFor="editJobPositionIncome" className='text-grey-thick font-medium text-xs'>{languages.language === 'id' ? "Berdasarkan Jabatan" : "Based on Job Position"}</label>
                                </div>
                                {
                                    assignTo === "job_position" ?
                                        <MultiSelect
                                            options={jobPosition}
                                            value={selected}
                                            onChange={(e) => handleChange(e)}
                                            labelledBy="Select"
                                        />
                                        : null
                                }
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => { setShowEditLeaveType(!showEditLeaveType); resetForm() }} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>{languages.language === 'id' ? "Batal" : "Cancel"}</h1>
                                </button>
                                <button onClick={editDataLeaveType} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Submit" : "Submit"}</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteLeaveType}
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Pengaturan Cutis" : "Time Of Setting"} LinkBack={'/time-management-setting'} />
                    <h1 className="text-zinc-800 text-xl font-semibold">{languages.language === 'id' ? "Pengaturan Cuti" : "Time Off Setting"}</h1>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='border-b-2 flex items-center text-[16px]'>
                            <button onClick={() => setMenu('Leave Setting')} className={`${menu === 'Leave Setting' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px]`}>{languages.language === 'id' ? "Pengaturan Cuti" : "Leave Setting"}</button>
                        </div>
                        <div className="py-5 space-y-5 bg-white">
                            <div>
                                <h1 className='text-xl font-semibold text-grey-primer'>{languages.language === 'id' ? "Jenis Cuti" : "Leave Type"}</h1>
                                <h2 className='text-xs text-grey-medium'>{languages.language === 'id' ? "Daftar Jenis Cuti" : "List of leave type"}</h2>
                            </div>
                            <div className="flex items-center justify-end mb-[36px]">
                                {/* <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-blue-thick font-medium border rounded-lg'>
                                <CgTrashEmpty className='text-lg' />
                                <h1>Delete</h1>
                            </button> */}
                                <div className='flex gap-[12px] items-center'>
                                    {/* <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                                        <BiSearch className='absolute left-[12px] text-lg' />
                                        <input type="text" placeholder={languages.language === 'id' ? "Cari" : "Search..."} className='outline-none text-[10px] w-full' />
                                    </div> */}
                                    {role === 'ADMIN' ?
                                        <button onClick={() => setShowAddLeaveType(!showAddLeaveType)} className='bg-blue-primer py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                            <AiOutlinePlus className='text-xl text-white' />
                                            <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ? "Tambah Jenis Cuti" : "Add Leave Type"}</h1>
                                        </button>
                                        : null
                                    }
                                </div>

                            </div>
                            <div>
                                <div className='overflow-auto scrollbar-hide'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Nama Jenis Cuti" : "Type Name"}</h1>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Durasi Maksimum" : "Maximal Duration"}</h1>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Waktu Pengisian Sebelumnya" : "Submission Before"}</h1>
                                            </div>
                                            {role === 'ADMIN' ?
                                                <div className='flex items-center justify-center gap-[6px] w-full'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        {Object.values(data).map((item, idx) => (
                                            <>
                                                <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='min-w-[250px] max-w-[250px]'>
                                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.type_name}</h1>
                                                    </div>
                                                    <div className='min-w-[150px] max-w-[150px]'>
                                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.max_duration} Hari</h1>
                                                    </div>
                                                    <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.submission_before} Hari</h1>
                                                    </div>
                                                    {role === 'ADMIN' ?
                                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                                            <button onClick={() => OpenEditLeaveType(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                <HiOutlinePencil className='text-[#003049]' />
                                                            </button>
                                                            <button onClick={() => deleteLeaveTypeModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                <CgTrashEmpty className='text-[#003049]' />
                                                            </button>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </>
                                        ))}
                                    </table>
                                </div>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    onPrevChange={handlePrevChange}
                                    onNextChange={handleNextChange}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaveSetting