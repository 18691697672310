import React, { useEffect, useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';

const Dropdown = ({ data, onChange, value, label }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option, optionName) => {
        setSelectedOption(optionName);
        onChange(option)
        setIsOpen(false);
    };

    useEffect(() => {
        setSelectedOption(label)
        onChange(value)
    }, [])
    return (
        <div className="relative inline-block text-left w-full z-10">
            <button
                onClick={toggleDropdown}
                className="inline-flex items-center justify-between w-full rounded-md border shadow-sm px-4 py-2 bg-white hover:bg-gray-50 "
            >
                <p className='text-left truncate text-xs font-medium text-gray-500 '>
                    {selectedOption || label || 'Pilih RHK Atasan yang Diintervensi...'}
                </p>
                <div>
                    <IoMdArrowDropdown className='text-xl text-grey-medium' />
                </div>
            </button>
            {isOpen && (
                <div className="origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {data.map((val, index) => (
                            <button
                                key={index}
                                onClick={() => handleOptionClick(val?.id, val?.name)}
                                className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                role="menuitem"
                            >
                                <p className=''>{val?.name}</p>
                                <p className='text-[9px]'>{val?.user?.fullname}</p>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
