import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiCloudUpload, BiDownload } from 'react-icons/bi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { RxCross2 } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../../Api'
import { Navbar } from '../../../../../components'
import imageHandle from '../../../../../utils/imageHandle'
import languages from '../../../../../utils/languages'

const DataImport = () => {
    const navigate = useNavigate()
    const [namepath, setNamePath] = useState('')
    const [file, setFile] = useState('')
    const [typeTemplate, setTypeTemplate] = useState('')

    const handleChangeFile = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['xlsx', 'xls'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize) {
            toast.error('file tidak boleh lebih dari 10 MB')
            setFile(null)
        } else if (!allowedExtensions.includes(fileExtension)) {
            toast.error('file harus berformat excel')
        } else {
            setNamePath(e.target.files[0].name)
            setFile(e.target.files[0])
        }
    }

    const ImportData = async () => {
        if (file != "") {
            const data = {
                file: file
            }
            try {
                setIsLoading(true)
                if (typeTemplate === 'Job Management') {
                    await Api.UploadJobManagement(localStorage.getItem('hris-token'), data).then((response) => {
                        toast.success('Import Data Job Berhasil')
                        navigate('/job-management')
                        setIsLoading(false)
                    }).catch((e) => {
                        console.log(e.response.data.message)
                        toast.error(e.response.data.message)
                        setFile("")
                        setIsLoading(false)
                    })
                } else if (typeTemplate === "Employee Data") {
                    await Api.UploadEmployee(localStorage.getItem('hris-token'), data).then((response) => {
                        toast.success('Import Data Pegawai Berhasil')
                        navigate('/employee')
                        setIsLoading(false)
                    }).catch((e) => {
                        console.log(e.response.data.message)
                        toast.error(e.response.data.message)
                        setFile("")
                        setIsLoading(false)
                    })
                } else if (typeTemplate === "Penempatan") {
                    await Api.UploadPenempatan(localStorage.getItem('hris-token'), data).then((response) => {
                        toast.success('Import Data Penempatan')
                        navigate('/subsidiary')
                        setIsLoading(false)
                    }).catch((e) => {
                        console.log(e.response.data.message)
                        toast.error(e.response.data.message)
                        setFile("")
                        setIsLoading(false)
                    })
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const [template, setTemplate] = useState()
    const getTemplateJob = async () => {
        try {
            const response = await Api.GetTemplateJob(localStorage.getItem('hris-token'))
            setTemplate(response.data.results.path)
        } catch (error) {
            console.log(error)
        }
    }
    const getTemplateEmployee = async () => {
        try {
            const response = await Api.GetTemplateEmployee(localStorage.getItem('hris-token'))
            setTemplate(response.data.results.path)
        } catch (error) {
            console.log(error)
        }
    }
    const getTemplatePenempatan = async () => {
        try {
            const response = await Api.GetTemplatePenempatan(localStorage.getItem('hris-token'))
            setTemplate(response.data.results.path)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (typeTemplate === "Job Management") {
            getTemplateJob()
        } else if (typeTemplate === "Penempatan") {
            getTemplatePenempatan()
        } else if (typeTemplate === "Employee Data") {
            getTemplateEmployee()
        }
    }, [typeTemplate])

    const [isLoading, setIsLoading] = useState(false)
    if (isLoading) {
        return <div className='bg-[#F8F9FB] min-h-screen content-center w-full text-center pt-96'>Memuat Data...</div>
    }
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Import Data'} LinkBack={'/dashboard'} />
                <div className='space-y-[32px] bg-white px-[46px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] font-[600] text-[20px]'>{languages.language === 'id' ? "Import Data Pekerjaan dan Pegawai" : "Import Job and Employee Data"}</h1>
                        <h1 className='text-dark-5 text-[14px]'>{languages.language === 'id' ? "Impor file untuk memperbarui data pengguna eksternal" : "Import a file to update external user data"}</h1>
                    </div>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] text-[16px] font-[600]'>{languages.language === 'id' ? "Langkah-langkah" : "Step :"}</h1>
                        <h1 className='text-[#464E5F] text-[14px]'>{languages.language === 'id' ? "Ikuti langkah-langkah berikut untuk melakukan impor yang benar" : "Follow these steps to perform the correct import"}</h1>
                        <ol className='list-decimal text-[#464E5F] text-[14px] list-outside ml-3'>
                            <li>
                                {languages.language === 'id' ? "Pada bagian download template, pilih jenis template sesuai urutan pengisian yang benar, Pastikan Anda mengikuti urutan impor dokumen yang benar, yaitu:" : "In the download template section, select the template type according to the correct filling order, Make sure you follow the correct document import sequence, namely:"}
                            </li>
                        </ol>
                        <ul className='list-disc text-[#464E5F] text-[14px] list-inside ml-3'>
                            <li>
                                Import Penempatan
                            </li>
                            <li>
                                {languages.language === 'id' ? "Import Manajemen Job" : "Import Job Management"}
                            </li>
                            <li>
                                {languages.language === 'id' ? "Impor Data Pegawai" : "Import Employee Data"}
                            </li>
                        </ul>
                        <h1 className='text-[#464E5F] text-[14px]'>{languages.language === 'id' ? "Kemudian pilih" : "then click"} <span className='font-bold'>{languages.language === 'id' ? "Unduh Template" : "'Download Template'"}</span></h1>
                        <ol className='list-decimal text-[#464E5F] text-[14px] list-outside ml-3' start={2}>
                            <li>
                                {languages.language === 'id' ? "Isikan data sesuai template yang tersedia" : "Fill in data according to the available template"}
                            </li>
                            <li>
                                {languages.language === 'id' ? "pastikan data yang diisi sudah benar" : "Make sure the data  you fill is correct"}
                            </li>
                            <li>
                                {languages.language === 'id' ? "setelah data sudah diisi, Pilih File untuk di unggah, kemudian pilih" : "After all the data is filled in,Choose File to upload, then click "}<span className='font-bold'>{languages.language === 'id' ? "Unggah File" : "‘Upload File’"}</span>
                            </li>
                        </ol>
                    </div>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] text-[16px] font-[600]'>{languages.language === 'id' ? "Unduh Template" : "Download Template :"}</h1>
                        <div className='flex items-center gap-[8px]'>
                            <div className='relative'>
                                <select onChange={(e) => setTypeTemplate(e.target.value)} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option selected className='text-grey-medium font-normal '>{languages.language === 'id' ? "Pilih Jenis Template" : "Select template type"}</option>
                                    <option className='text-grey-primer' value="Penempatan">Penempatan</option>
                                    <option className='text-grey-primer' value="Job Management">{languages.language === 'id' ? "Manajemen Job" : "Job Management"}</option>
                                    <option className='text-grey-primer' value="Employee Data">{languages.language === 'id' ? "Data Pegawai" : "Employee Data"}</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl' />
                            </div>
                            <button className='flex bg-blue-primer items-center justify-center px-[8px] py-[7px] gap-[4px] rounded-lg text-white font-medium text-[14px]'>
                                <BiDownload className='text-xl' />
                                <a href={imageHandle(template)} download={'template'}>Download Template</a>
                            </button>
                        </div>
                    </div>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] text-[16px] font-[600]'>{languages.language === 'id' ? "Unggah File" : "Upload File"} <span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <h1 className='text-[#C1121F] text-[8px]'>*{languages.language === 'id' ? "Unggah File maks 500kb" : "Upload File max 10 MB"}</h1>
                        <div className='flex items-center gap-[13px]'>
                            <label htmlFor='upload-file' className='cursor-pointer'>
                                <div className='border shadow-sm rounded-lg relative flex flex-col text-center items-center px-[70px] py-[11px] w-[227px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M5.95829 18.3327C4.56496 18.3327 3.37635 17.853 2.39246 16.8935C1.40857 15.928 0.916626 14.7516 0.916626 13.3643C0.916626 12.1727 1.27413 11.1093 1.98913 10.1743C2.71024 9.23935 3.65135 8.64352 4.81246 8.38685C5.19746 6.98129 5.96135 5.84463 7.10413 4.97685C8.25301 4.10296 9.55163 3.66602 11 3.66602C12.7905 3.66602 14.3061 4.28935 15.5466 5.53602C16.7933 6.77657 17.4166 8.29213 17.4166 10.0827C18.4738 10.2049 19.3477 10.6632 20.0383 11.4577C20.735 12.2399 21.0833 13.1566 21.0833 14.2077C21.0833 15.3566 20.683 16.3313 19.8825 17.1318C19.0819 17.9324 18.1072 18.3327 16.9583 18.3327H11.9166C11.4155 18.3327 10.9847 18.1524 10.6241 17.7918C10.2636 17.4374 10.0833 17.0066 10.0833 16.4993V11.7785L8.61663 13.1993L7.33329 11.916L11 8.24935L14.6666 11.916L13.3833 13.1993L11.9166 11.7785V16.4993H16.9583C17.6 16.4993 18.1408 16.2763 18.5808 15.8302C19.0269 15.3902 19.25 14.8493 19.25 14.2077C19.25 13.566 19.0269 13.0252 18.5808 12.5852C18.1408 12.1391 17.6 11.916 16.9583 11.916H15.5833V10.0827C15.5833 8.81768 15.1372 7.73602 14.245 6.83768C13.3527 5.94546 12.2711 5.49935 11 5.49935C9.73496 5.49935 8.65329 5.94546 7.75496 6.83768C6.86274 7.73602 6.41663 8.81768 6.41663 10.0827H5.95829C5.07218 10.0827 4.31746 10.3974 3.69413 11.0268C3.06468 11.6502 2.74996 12.4049 2.74996 13.291C2.74996 14.1771 3.06468 14.941 3.69413 15.5827C4.31746 16.1938 5.07218 16.4993 5.95829 16.4993H8.24996V18.3327" fill="#6B7280" />
                                    </svg>
                                    <h1 className='text-[#8E95A2] text-[12px] font-medium'>{languages.language === 'id' ? "Pilih file" : "Choose File"}</h1>
                                </div>
                                <input type='file' id='upload-file' accept=".xlsx, .xls" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required />
                            </label>
                            <button className='flex bg-blue-primer items-center justify-center px-[8px] py-[7px] gap-[4px] rounded-lg text-white font-medium text-[14px]'>
                                <BiCloudUpload className='text-xl' />
                                <h1>{languages.language === 'id' ? "Unggah File" : "Upload File"}</h1>
                            </button>
                        </div>
                        {(namepath && file) &&
                            <div className='py-[6px] px-[8px] bg-base-outline rounded flex gap-[8px] justify-between w-fit'>
                                <h1 className='text-[10px] text-dark-5 truncate'>{namepath}</h1>
                                <button onClick={() => setFile('')}> <RxCross2 className='text-sm' /></button>
                            </div>
                        }
                    </div>

                    <button onClick={ImportData} className='bg-blue-primer rounded-[6px] flex items-center gap-[12px] px-[17px] py-[10px]'>
                        <h1 className='text-white text-sm font-[500]'>{languages.language === 'id' ? "Unggah" : "Upload"}</h1>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DataImport