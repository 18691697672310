import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { IoMdArrowDropdown } from 'react-icons/io';
import { TbFileExport } from 'react-icons/tb';
import * as XLSX from 'xlsx';
import Api from '../../../../Api';
import { Navbar, Pagination } from '../../../../components';

const RekapCuti = () => {
    moment.locale('id');
    const [refresh, setRefresh] = useState(false)
    const [tahun, setTahun] = useState(new Date().getFullYear())
    const [search, setSearch] = useState("")
    const [data, setData] = useState([])

    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };


    const [admin, setAdmin] = useState(false)
    const getData = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setAdmin(response.data.results.user.role === 'ADMIN' || response.data.results.user.role === 'SUBSIDIARY')
            if (response.data.results.user.role === 'ADMIN' || response.data.results.user.role === 'SUBSIDIARY') {
                const response = await Api.GetRekapCuti(localStorage.getItem('hris-token'), currentPage, limit, tahun, search)
                setData(response.data.results.data)
                setCurrentPage(response.data.results.currentPage)
                setTotalPages(response.data.results.totalPages)
                setTotalCount(response.data.results.totalCount)
            } else {
                const response = await Api.GetRekapCutiById(localStorage.getItem('hris-token'), currentPage, limit, tahun)
                setData(response.data.results.data)
                setCurrentPage(response.data.results.currentPage)
                setTotalPages(response.data.results.totalPages)
                setTotalCount(response.data.results.totalCount)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [tahunOptions, setTahunOptions] = useState([]);

    const getTahun = () => {
        const tahunSaatIni = new Date().getFullYear();
        const options = [];
        for (let i = tahunSaatIni; i >= tahunSaatIni - 100; i--) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        setTahunOptions(options);
    }

    const exportRekap = async () => {
        // Sample data array
        const response = await Api.GetRekapCuti(localStorage.getItem('hris-token'), 1, totalCount, tahun, search)
        const body = response.data.results.data

        // Define custom headers for each table
        const Headers = ["JENIS PEGAWAI", "NIP", "NAMA", "TAHUN MASUK OMBUDSMAN", "TOTAL SISA CUTI N-1", "PENANGGUHAN TAHUN LALU", "AKUMULASI KE TAHUN BERIKUTNYA", "HAK CUTI N-1", "HAK CUTI N", "AKUMULASI CUTI TAHUNAN DIAMBIL", `SISA CUTI ${tahun - 1}`, `SISA CUTI ${tahun}`, "DITANGGUHKAN", "Akumulasi Cuti Sakit", "Akumulasi Cuti Alasan Penting", "Cuti Besar", "Melahirkan", "CLTN", "SELESAI CLTN"];

        // Create modified data arrays with custom headers
        const data = body.map(({ jenis_pegawai, nip, nama, tanggal_masuk_ombudsman, total_sisa_cuti_tahun_lalu, penangguhan_tahun_lalu, akumulasi_tahun_berikutnya, hak_cuti_tahun_lalu, hak_cuti_tahun_ini, akumulasi_cuti_tahunan_diambil, sisa_cuti_tahun_lalu, sisa_cuti_tahun_ini, ditangguhkan, akumulasi_cuti_sakit, akumulasi_cuti_alasan_penting, cuti_besar, cuti_melahirkan }) => ({
            "JENIS PEGAWAI": jenis_pegawai,
            "NIP": nip,
            "NAMA": nama,
            "TAHUN MASUK OMBUDSMAN": moment(tanggal_masuk_ombudsman).format('D MMMM YYYY'),
            "TOTAL SISA CUTI N-1": total_sisa_cuti_tahun_lalu,
            "PENANGGUHAN TAHUN LALU": penangguhan_tahun_lalu,
            "AKUMULASI KE TAHUN BERIKUTNYA": akumulasi_tahun_berikutnya,
            "HAK CUTI N-1": hak_cuti_tahun_lalu,
            "HAK CUTI N": hak_cuti_tahun_ini,
            "AKUMULASI CUTI TAHUNAN DIAMBIL": akumulasi_cuti_tahunan_diambil,
            [`SISA CUTI ${tahun - 1}`]: sisa_cuti_tahun_lalu,
            [`SISA CUTI ${tahun}`]: sisa_cuti_tahun_ini,
            "DITANGGUHKAN": ditangguhkan,
            "Akumulasi Cuti Sakit": akumulasi_cuti_sakit,
            "Akumulasi Cuti Alasan Penting": akumulasi_cuti_alasan_penting,
            "Cuti Besar": cuti_besar,
            "Melahirkan": cuti_melahirkan,
            "CLTN": "",
            "SELESAI CLTN": ""
        }));

        // Create a new worksheet for each table
        const worksheetGrade = XLSX.utils.json_to_sheet(data, { header: Headers });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Rekap Cuti');
        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, 'Rekap Cuti.xlsx');
    };

    useEffect(() => {
        getData()
        getTahun()
        setRefresh(false)
    }, [refresh, tahun, search])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={"Rekap Cuti"} LinkBack={'/time-management/time-off'} />
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div>
                        <h1 className='text-[20px] text-dark-3 font-semibold'>Rekap Cuti</h1>
                        <h2 className='text-dark-5 text-[10px]'>Daftar Rekap Cuti</h2>
                    </div>
                    <div className="flex flex-wrap justify-between items-center gap-2 mt-[31px]">
                        <div className='flex flex-wrap items-center gap-2'>
                            <div className='relative'>
                                <select value={tahun} onChange={(e) => setTahun(e.target.value)} className='text-grey-medium text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option disabled value={""} className='text-grey-medium font-normal'>Pilih Tahun</option>
                                    {tahunOptions}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-grey-medium text-xl' />
                            </div>
                            {admin ?
                                <button onClick={exportRekap} className='bg-white border border-dark-7 rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <TbFileExport className='text-[#003049]' />
                                    <h1 className='text-[#003049] text-sm font-[500]'>Ekspor</h1>
                                </button>
                                : null
                            }
                        </div>
                        {admin ?
                            <div onChange={(e) => setSearch(e.target.value)} className='relative'>
                                <BiSearch className='absolute left-[14px] top-[10px] text-grey-medium text-lg' />
                                <input placeholder='Cari Nama Pegawai...' className='h-[38px] text-grey-medium text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[200px]' />
                            </div>
                            : null
                        }
                    </div>
                    <div>
                        <div className='overflow-auto'>
                            <table className='w-full space-y-[10px] text-dark-5'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[50px] max-w-[50px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>No.</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Jenis Pegawai</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>NIP</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Nama</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Tahun Masuk Ombudsman</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Total Sisa Cuti N-1</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Penangguhan Tahun Lalu</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Akumulasi Ke Tahun Berikutnya</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Hak Cuti N-1</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Hak Cuti N</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Akumulasi Cuti Tahunan Diambil</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Sisa Cuti {tahun - 1}</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Sisa Cuti {tahun}</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Ditangguhkan</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Akumulasi Cuti Sakit</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Akumulasi Cuti Alasan Penting</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Cuti Besar</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Melahirkan</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>CLTN</h1>
                                    </div>
                                    <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px] text-center'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Selesai CLTN</h1>
                                    </div>
                                    {/* <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500]'>Keterangan</h1>
                                    </div> */}
                                </div>
                                {data && data.map((val, index) => (
                                    <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[50px] max-w-[50px] text-center'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{index + 1}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.jenis_pegawai}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.nip}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.nama}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{moment(val.tanggal_masuk_ombudsman).format('D MMMM YYYY')}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.total_sisa_cuti_tahun_lalu}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.penangguhan_tahun_lalu}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.akumulasi_tahun_berikutnya}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.hak_cuti_tahun_lalu}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.hak_cuti_tahun_ini}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.akumulasi_cuti_tahunan_diambil}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.sisa_cuti_tahun_lalu}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.sisa_cuti_tahun_ini}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.ditangguhkan}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.akumulasi_cuti_sakit}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.akumulasi_cuti_alasan_penting}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.cuti_besar}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>{val.cuti_melahirkan}</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>-</h1>
                                        </div>
                                        <div className='flex justify-center items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>-</h1>
                                        </div>
                                        {/* <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Keterangan</h1>
                                        </div> */}
                                    </div>
                                ))}
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            limitData={limit}
                            lengthData={totalCount}
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RekapCuti