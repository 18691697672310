import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import { BiSearch } from 'react-icons/bi'
import { AiFillCheckCircle, AiOutlinePlus, AiFillClockCircle } from 'react-icons/ai'
import { MdImportExport} from 'react-icons/md'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import moment from 'moment'
import { FaTimesCircle } from 'react-icons/fa'
import { debounce } from 'lodash'
import { toast } from 'react-hot-toast'

const CashAdvance = () => {
    const [showModalAddCashAdvance, setShowModalAddCashAdvance] = useState()
    const [showModalEditCashAdvance, setShowModalEditCashAdvance] = useState()
    const [showModalDelete, setShowModalDelete] = useState()
    const [refresh, setRefresh] = useState(false)

    const [cashAdvanceId, setCashAdvanceId] = useState('')
    const [cashAdvanceData, setCashAdvanceData] = useState('')
    const [cashAdvanceDataSetting, setCashAdvanceDataSetting] = useState('')
    const [cashAdvanceDataSettingById, setCashAdvanceDataSettingById] = useState('')
    const [amount, setAmount] = useState('')
    const [useDate, setUseDate] = useState('')
    const [requestDate, setRequestDate] = useState('')
    const [note, setNote] = useState('')
    const [userName, setUserName] = useState('')
    const [userId, setUserId] = useState('')
    const [caSettingId, setCaSettingId] = useState('')

    const [limit, setLimit] = useState(5)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const FetchUser = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserName(response.data.results.data.fullname)
            setUserId(response.data.results.data.id)
        } catch (error) {
            console.log(error)
        }
    }

    const GetDataCashAdvanceSetting = async() => {
        try {
            const response = await Api.GetFinanceCashAdvanceSetting(localStorage.getItem('hris-token'), '1', 100)
            setCashAdvanceDataSetting(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const GetDataCashAdvanceSettingById = async() => {
        try {
            const response = await Api.GetFinanceCashAdvanceSettingById(localStorage.getItem('hris-token'), caSettingId)
            setCashAdvanceDataSettingById(response.data[0])
        } catch (error) {

        }
    }

    const GetDataCashAdvance = async() => {
        try {
            const response = await Api.GetFinanceCashAdvance(localStorage.getItem('hris-token'), nameSearch, currentPage, limit)
            setCashAdvanceData(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const debouncedSearchName = debounce(async(name) => {
        try {
            const response = await Api.GetFinanceCashAdvance(localStorage.getItem('hris-token'), name, currentPage, limit)
            setCashAdvanceData(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const debouncedLimit = debounce(async(limit) => {
        try {
            const response = await Api.GetFinanceCashAdvance(localStorage.getItem('hris-token'), nameSearch, currentPage, limit)
            setCashAdvanceData(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleLimit= (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimit(limit)
    }

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }

    const handleForm = () => {
        setAmount('')
        setUseDate('')
        setRequestDate('')
        setCaSettingId('')
        setNote('')
    }

    const CreateCashAdvance = async() => {
        try {
            const data = {
                amount: amount,
                use_date: useDate,
                request_date: requestDate,
                note: note,
                user: userId,
                userId: userId,
                caSettingId: caSettingId
            }
            if(amount < 0){
                toast.error('Amount cannot be less than zero!')
            } else if(amount > cashAdvanceDataSettingById.limit_amount){
                toast.error('amount kelewbihan')
            } else {
                console.log(data)
                setIsLoading(true)
                const response = await Api.CreateFinanceCashAdvance(localStorage.getItem('hris-token'), data)
                setIsLoading(false)
                console.log(response)
                handleForm()
                setRefresh(true)
                setShowModalAddCashAdvance(!showModalAddCashAdvance)
                toast.success('Success create cash advance')
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error(error.message)
        }
    }

    const UpdateCashAdvanceModalOpen = async(id) => {
        setShowModalEditCashAdvance(!showModalEditCashAdvance)
        setCashAdvanceId(id)
        try {
            const response = await Api.GetFinanceCashAdvanceById(localStorage.getItem('hris-token'), id)
            setAmount(response.data[0].amount)
            setUseDate(response.data[0].use_date)
            setRequestDate(response.data[0].request_date)
            setCaSettingId(response.data[0].caSetting.id)
            setNote(response.data[0].note)
        } catch (error) {
            
        }
    }

    const UpdateCashAdvance = async() => {
        try {
            const data = {
                amount: amount,
                use_date: useDate,
                request_date: requestDate,
                note: note,
                user: userId,
                caSettingId: caSettingId
            }
            console.log(data)
            if(amount < 0){
                toast.error('Amount cannot be less than zero')
            } else if (amount > cashAdvanceDataSettingById.limit_amount){
                toast.error('Ampunt must be less than limit amount')
            } else {
                console.log(data)
                setIsLoading(true)
                const response = await Api.UpdateFinanceCashAdvance(localStorage.getItem('hris-token'), data, cashAdvanceId)
                setIsLoading(false)
                handleForm()
                setRefresh(true)
                setShowModalEditCashAdvance(!showModalEditCashAdvance)
                toast.success('Success update cash advance')
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const DeleteCashAdvanceModal = async(id) => {
        setShowModalDelete(!showModalDelete)
        setCashAdvanceId(id)
    }

    const DeleteCashAdvance = async(id) => {
        try {
            const response = await Api.DeleteFinanceCashAdvance(localStorage.getItem('hris-token'), cashAdvanceId)
            setRefresh(true)
            setShowModalDelete(!showModalDelete)
            toast.success('Success delete cash advance')
        } catch (error) {
            console.log(error)
            toast.error('Failed delete!')
        }
    }

    useEffect(() => {
        FetchUser()
        GetDataCashAdvance()
        setRefresh(false)
    }, [refresh, limit])
    
    useEffect(() => {
        GetDataCashAdvanceSetting()
        GetDataCashAdvanceSettingById()
    }, [caSettingId])

    return (
        <div>
            <Modal 
                activeModal={showModalAddCashAdvance}
                title={'Add Cash Finance'}
                buttonClose={ () => setShowModalAddCashAdvance(!showModalAddCashAdvance)}
                width={'553px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        {/* <div className='flex items-center gap-[20px]'> */}
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input value={userName} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Employee Name' readOnly/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Cash Advance Policy<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={(e)=>setCaSettingId(e.target.value)} value={caSettingId} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select cash advance policy</option>
                                    {Object.values(cashAdvanceDataSetting).map((data, index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                            {cashAdvanceDataSettingById.length === 0 ?
                                null
                                :
                                <div>
                                    <h1 className='text-[#B70000] text-[10px] italic'>*The policy you choose has a limit of Rp. {cashAdvanceDataSettingById.limit_amount}</h1>
                                    <h1 className='text-[#B70000] text-[10px] italic'>*The policy you choose has a settlement due for {cashAdvanceDataSettingById.settlement_due} days</h1>
                                </div>
                            }
                        </div>
                        
                        {/* </div> */}
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Request Date</h1>
                                <div className='relative'>
                                    <input type={'date'} onChange={(e)=>setRequestDate(e.target.value)}  value={requestDate}className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='dd/mm/yyyy'/>
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Date of Use</h1>
                                <div className='relative'>
                                    <input type={'date'} onChange={(e)=>setUseDate(e.target.value)} value={useDate} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='dd/mm/yyyy'/>
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                        </div>

                        <div className='w-full'>
                        <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Amount<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <div className='relative'>
                            <input type='number' onChange={(e) => setAmount(e.target.value)} min={0} value={amount} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Rp' />
                        </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Note</h1>
                            <textarea rows={'2'} onChange={(e) => setNote(e.target.value)} value={note} className='resize-none bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Note...'>
                            </textarea>
                        </div>
                        
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalAddCashAdvance(!showModalAddCashAdvance)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={isLoading ? null : CreateCashAdvance} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showModalEditCashAdvance}
                title={'Edit Cash Finance'}
                buttonClose={ () => setShowModalEditCashAdvance(!showModalEditCashAdvance)}
                width={'553px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        {/* <div className='flex items-center gap-[20px]'> */}
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Employee</h1>
                            <div className='relative'>
                                <input value={userName} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Employee Name' readOnly/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Cash Advance Policy</h1>
                            <div className='relative'>
                                <select onChange={(e)=>setCaSettingId(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select cash advance policy</option>
                                    {Object.values(cashAdvanceDataSetting).map((data, index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                            {cashAdvanceDataSettingById.length === 0 ?
                                null
                                :
                                <div>
                                    <h1 className='text-[#B70000] text-[10px] italic'>*The policy you choose has a limit of Rp. 1</h1>
                                    <h1 className='text-[#B70000] text-[10px] italic'>*The policy you choose has a Min Next Claim for 1 days</h1>
                                </div>
                            }
                        </div>
                        
                        {/* </div> */}
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Request Date</h1>
                                <div className='relative'>
                                    <input type={'date'} value={moment(requestDate).format('YYYY-MM-DD')} onChange={(e) => setRequestDate(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='dd/mm/yyyy'/>
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[11px] font-[500] mb-[7px]'>Date of Use</h1>
                                <div className='relative'>
                                    <input type={'date'} value={moment(useDate).format('YYYY-MM-DD')} onChange={(e) => setUseDate(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='dd/mm/yyyy'/>
                                    {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                </div>
                            </div>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Amount<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input type='number' value={amount} onChange={(e) => setAmount(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Rp' />
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Note<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <textarea rows={'2'} value={note} onChange={(e) => setNote(e.target.value)} className='resize-none bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Note...'>
                            </textarea>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalEditCashAdvance(!showModalEditCashAdvance)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={isLoading ? null : UpdateCashAdvance} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={DeleteCashAdvance}
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Cash Advancee'} LinkBack={'/finance'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className='text-[20px]'>Cash Advance</h1>
                            <h2 className='text-[10px] text-dark-5'>List of Cash Advance </h2>
                        </div>
                        <div className="flex gap-2 mt-[31px] justify-between">
                            <div className='flex gap-2'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input onChange={handleSearchName} placeholder='Search...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                    <option value="" selected disabled>Set Limit</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                </select>
                            </div>
                            <button onClick={()=>setShowModalAddCashAdvance(!showModalAddCashAdvance )} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus  className='text-xl text-white'/>
                                <h1 className='text-dark-9 text-sm font-[500]'>Add Cash Advance</h1>
                            </button>
                        </div>
                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px] text-dark-5'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Employee</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Cash Advance Policy </h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Request Date</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Date of Use</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Amount</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Note</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Approval</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-dark-5 text-[11px] font-[500]'>Actions</h1>
                                            <MdImportExport className='text-dark-5 text-xs'/>
                                        </div>
                                    </div>
                                    {cashAdvanceData.length === 0 ?
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className=' min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className=' min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px] flex items-center justify-center gap-[6px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <h1 className='text-dark-5 text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                    </div>
                                    :
                                    Object.values(cashAdvanceData).map((data, index) => {
                                        return (
                                            <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[250px] max-w-[250px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.user? data.user.fullname : '-'}</h1>
                                                </div>
                                                <div className='min-w-[250px] max-w-[250px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.caSetting.name}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(data.request_date).format('DD/MM/YYYY')}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{moment(data.use_date).format('DD/MM/YYYY')}</h1>
                                                </div>
                                                <div className=' min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.amount}</h1>
                                                </div>
                                                <div className=' min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{data.note}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px] flex items-center justify-center gap-[6px]'>
                                                    {data.status === 'pending' ? 
                                                        <AiFillClockCircle className='text-base-yellow w-[29px] h-[29px]'/>
                                                    :data.status === 'approved' ?
                                                        <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]'/>
                                                    :
                                                        <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]'/>
                                                    }
                                                </div>
                                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                                    {data.status === 'approved' ? (
                                                    <button disabled onClick={() => UpdateCashAdvanceModalOpen(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                    </button>
                                                    ) : (
                                                    <button onClick={() => UpdateCashAdvanceModalOpen(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                    </button>)}
                                                    <button onClick={ () => DeleteCashAdvanceModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]'/>
                                                </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPage} 
                                totalPages={totalPages} 
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CashAdvance