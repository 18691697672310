import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { Modal, ModalDelete } from '../../../../../components'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../../Api'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-hot-toast'

const ReportTo = ({modalAddSupervisor, modalEditSupervisor, modalAddSubordinate, modalEditSubordinate, refreshPage}) => {
    const [showModalDeleteSupervisor, setShowModalDeleteSupervisor] = useState()
    const [reportToOption, setReportToOption] = useState('Supervisor')
    const [idSupervisor, setIdSupervisor] = useState()
    const [data, setData] = useState('')
    const [userId, setUserId] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState([])
    const params = useLocation()
    
    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserId(response.data.results.data.id)
        } catch (error) {
            console.log(error)
        }
    }
    
    const getDataReportToSupervisor = async () => {
        try {
            if(!params.state){
                const response = await Api.GetReportToSupervisorByLogin(localStorage.getItem('hris-token'))
                setData(response.data)
            } else {
                const response = await Api.GetReportToSupervisorByParam(localStorage.getItem('hris-token'), params.state.employeeId)
                console.log(response, 'reportTo')
                setData(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAllEmployee = async() => {
        try {
            const response = await Api.getAllEmployee(localStorage.getItem('hris-token'))
            const res = response.data.data

            const options = res && res.map(val => ({
                "label": val.fullname,
                "value": val.id,
            }))

            setSelectedEmployee(options)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteReportToSupervisorModal = (id) =>{
        setShowModalDeleteSupervisor(!showModalDeleteSupervisor)
        setIdSupervisor(id)
    }

    const deleteReportToSupervisor = async () => {
        try {
            const response = await Api.DeleteReportToSupervisor(localStorage.getItem('hris-token'), idSupervisor)
            setShowModalDeleteSupervisor(!showModalDeleteSupervisor)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }
    
    useEffect(() => {
        getDataReportToSupervisor()
        Fetch()
        getAllEmployee()
        setRefresh(false)
    }, [refresh, refreshPage])

  return (
    <div>
        <ModalDelete
            activeModal={showModalDeleteSupervisor}
            buttonClose={() => setShowModalDeleteSupervisor(!showModalDeleteSupervisor)}
            submitButton={deleteReportToSupervisor}
        />
        <div className='mb-[8.5px]'>
            <h1 className='text-[20px] font-medium'>Unit Kerja Atasan Langsung</h1>
            <p className='text-[10px] text-dark-5'>Daftar Unit kerja atasan langsung</p>
        </div>
        <hr className='mb-[8.5px]'/>
        <div className='border-b-2 flex items-center text-[16px]'>
            <button onClick={ () => setReportToOption('Supervisor') } className={`${reportToOption === 'Supervisor' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[85px] py-[10px]`}>Atasan</button>
            <button onClick={ () => setReportToOption('Subordinate') } className={`${reportToOption === 'Subordinate' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[85px] py-[10px]`}>Bawahan</button>
        </div>
        <hr className='mb-[22px]'/>
        {
            reportToOption === 'Supervisor' ?
            <div className='border rounded-xl mb-[36px] bg-white shadow'>
                <div className='mb-[8.5px]'>
                    <div className='flex relative items-center justify-between pt-[32px] px-[19px]'>
                        <div>
                            <h1 className='text-[16px] font-medium'>Atasan</h1>
                            <p className='text-[10px] text-dark-5'>Daftar Atasan</p>
                        </div>
                        <div className=''>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <button onClick={modalAddSupervisor}  className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Tambah Atasan</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='mb-[24.5px] mx-5'/>
                <div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px] text-dark-5'>
                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[6px] min-w-[30px] max-w-[30px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[280px] max-w-[280px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Nama</h1>
                                    <MdImportExport className='text-dark-5 text-xs'/>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[280px] max-w-[280px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Metode Report-To</h1>
                                    <MdImportExport className='text-dark-5 text-xs'/>
                                </div>
                                <div className='flex items-center justify-center gap-[6px] w-full'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                                    <MdImportExport className='text-dark-5 text-xs'/>
                                </div>
                            </div>
                            {Object.values(data).map((item, idx) => (
                                <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[30px] max-w-[30px]'>
                                        <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    </div>
                                    <div className='min-w-[280px] max-w-[280px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.name ? item.name : '-'}</h1>
                                    </div>
                                    <div className='min-w-[280px] max-w-[280px]'>
                                        <h1 className='text-dark-5 text-[11px] font-[500] truncate'>{item.report_method? item.report_method.name : '-'}</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <button onClick={() => deleteReportToSupervisorModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]'/>
                                        </button>
                                        <button onClick={() => modalEditSupervisor(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-[#003049]'/>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </table>
                    </div>
                    <div className='flex items-center justify-between mt-[30px] bg-[#FBFBFB] pt-[19px] pl-[28px] pb-[26px] pr-[25px] rounded-b-xl'>
                        <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                        <div className='flex items-center gap-[8px]'>
                            <MdKeyboardArrowLeft className='text-[#A098AE]'/>
                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                <h1 className='text-[#780000] text-[10px]'>1</h1>
                            </div>
                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                <h1 className='text-white text-[10px]'>2</h1>
                            </div>
                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                <h1 className='text-[#780000] text-[10px]'>3</h1>
                            </div>
                            <MdKeyboardArrowRight className='text-[#A098AE]'/>
                        </div>
                    </div>
                </div>
            </div>
        : 
            <div className='border rounded-xl mb-[36px] bg-white shadow'>
                <div className='mb-[8.5px]'>
                    <div className='flex relative items-center justify-between pt-[32px] px-[19px]'>
                        <div>
                            <h1 className='text-[16px] font-medium'>Bawahan</h1>
                            <p className='text-[10px] text-dark-5'>Daftar Bawahan</p>
                        </div>
                        <div className=''>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <button onClick={modalAddSubordinate} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-dark-9 text-sm font-[500]'>Tambah Bawahan</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='mb-[24.5px] mx-5'/>
                <div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px] text-dark-5'>
                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[6px] min-w-[30px] max-w-[30px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[280px] max-w-[280px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Nama</h1>
                                    <MdImportExport className='text-dark-5 text-xs'/>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[280px] max-w-[280px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Metode Report-To</h1>
                                    <MdImportExport className='text-dark-5 text-xs'/>
                                </div>
                                <div className='flex items-center justify-center gap-[6px] w-full'>
                                    <h1 className='text-dark-5 text-[11px] font-[500]'>Aksi</h1>
                                    <MdImportExport className='text-dark-5 text-xs'/>
                                </div>
                            </div>
                            <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[6px] min-w-[30px] max-w-[30px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                </div>
                                <div className='min-w-[280px] max-w-[280px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Rizieq</h1>
                                </div>
                                <div className='min-w-[280px] max-w-[280px]'>
                                    <h1 className='text-dark-5 text-[11px] font-[500] truncate'>Direct</h1>
                                </div>
                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                    <button  className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <CgTrashEmpty className='text-[#003049]'/>
                                    </button>
                                    <button onClick={modalEditSubordinate}  className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <HiOutlinePencil className='text-[#003049]'/>
                                    </button>
                                </div>
                            </div>
                        </table>
                    </div>
                    <div className='flex items-center justify-between mt-[30px] bg-[#FBFBFB] pt-[19px] pl-[28px] pb-[26px] pr-[25px] rounded-b-xl'>
                        <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                        <div className='flex items-center gap-[8px]'>
                            <MdKeyboardArrowLeft className='text-[#A098AE]'/>
                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                <h1 className='text-[#780000] text-[10px]'>1</h1>
                            </div>
                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                <h1 className='text-white text-[10px]'>2</h1>
                            </div>
                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                <h1 className='text-[#780000] text-[10px]'>3</h1>
                            </div>
                            <MdKeyboardArrowRight className='text-[#A098AE]'/>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
  )
}

export default ReportTo